import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { GlobalHeaderSettings } from '../global-header/global-header-settings.class';
import { WindowService } from '../../services/window.service';
import { MsaSettingsService } from './msa.settings.service';
import { MsaSettings } from '@interfaces/msa-settings.model';
import { AuthService } from '../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { Logo } from '@interfaces/logo.model';
import { GatedEntryService } from '../../services/gated-entry/gated-entry.service';
import { TrackByUtilities } from '../../utilities/trackByFn.utilities';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { isAfter, isBefore, isValid, lightFormat } from 'date-fns';
@Component({
  selector: 'app-msa-form',
  templateUrl: './msa.form.component.html',
})
export class MsaFormComponent implements OnInit, OnDestroy {
  public settings: MsaSettings = null;
  // banner automatically redirects to form if submission fails, so this is all that's needed
  public formSubmitted =
    this.windowService['sessionStorage'].getItem('msaFormSubmitted');
  public attemptedSubmit: boolean = false;
  public minDateInternal: Date;
  public minDate: string;
  public maxDateInternal: Date;
  public maxDate: string;
  public year = new Date().getFullYear();
  public month = new Date().getMonth();
  public day = new Date().getDate();
  public disableSubmitBtn: boolean = true;
  public headerLogo: Observable<Logo> = this.globalHeaderSettings.logo;
  public trackByFn: any = TrackByUtilities.trackByFnIndex;
  private subscriptions = new SubscriptionManager();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private headers = new HttpHeaders({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
    Accept: 'text/html',
  });

  constructor(
    private msaSettingsService: MsaSettingsService,
    private http: HttpClient,
    public globalHeaderSettings: GlobalHeaderSettings,
    public windowService: WindowService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: any,
    private gatedEntryService: GatedEntryService
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.getMsaSettings());
    this.gatedEntryService.dismiss();
    this.minDateInternal = new Date(this.year - 125, this.month, this.day);
    this.minDate = lightFormat(this.minDateInternal, 'yyyy-MM-dd');
    this.maxDateInternal = new Date(this.year, this.month, this.day);
    this.maxDate = lightFormat(this.maxDateInternal, 'yyyy-MM-dd');
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public clearForm(): void {
    this.settings.form.inputs.forEach((input) => {
      input.model = '';
    });
  }

  public postForm(): Promise<any> {
    this.windowService['sessionStorage'].setItem('msaFormSubmitted', true);
    this.attemptedSubmit = true;

    return this.http
      .post('/msa/member', this.getFormInputValues(), {
        headers: this.headers,
        withCredentials: true,
        responseType: 'text',
      })
      .toPromise()
      .then((body) => this.loadSelfPostingForm(body));
  }

  public logout(): void {
    this.windowService['sessionStorage'].removeItem('msaFormSubmitted');
    this.authService.logoutMsa();
  }

  public shouldShowSelectError(inputModel: any): boolean {
    return !!(!inputModel && this.attemptedSubmit);
  }

  public shouldShowTextError(input): boolean {
    if (input.model) {
      return input.model.length < input.minLength;
    }
    return true;
  }

  public disableSubmit(): void {
    const { inputs } = this.settings.form;
    const dobRequired = inputs.find((obj) => obj.type === 'date');
    const userInputs = inputs.filter((x) => x.model);
    const allInputs = userInputs.length < inputs.length;

    if (!dobRequired) {
      this.disableSubmitBtn = allInputs;
    }

    if (dobRequired) {
      const hasDate = userInputs.filter((obj) => obj.type === 'date')[0] || [];

      let dateValid = false;
      if (hasDate && hasDate['model']) {
        const testDate = new Date(hasDate['model']);
        dateValid =
          isValid(testDate) &&
          isBefore(testDate, this.maxDateInternal) &&
          isAfter(testDate, this.minDateInternal);
      }

      this.disableSubmitBtn = allInputs || !dateValid;
    }
  }

  private getMsaSettings(): Subscription {
    return this.msaSettingsService.getSettings().subscribe((settings) => {
      this.settings = settings;
    });
  }

  private getFormInputValues(): string {
    const out = {};

    this.settings.form.inputs.forEach((input) => {
      out[input.name] = input.model;
    });

    return JSON.stringify(out);
  }

  private loadSelfPostingForm(data: string): void {
    this.document.open('text/html');
    this.document.write(data);
    this.document.close();
  }
}
