import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { StorageUtilities } from '@utilities/storage.utilities';
import DefaultTranslationsEn from '@translations/en.json';
import DefaultTranslationsEs from '@translations/es.json';

@Injectable()
export class MissingTranslationCustomHandler
  implements MissingTranslationHandler
{
  private defaultTranslations = {
    en: DefaultTranslationsEn,
    es: DefaultTranslationsEs,
  };
  private configTranslations = this.storageUtilities.sessionStorageGet(
    'englishTranslations'
  );

  constructor(private storageUtilities: StorageUtilities) {}

  public handle(params: MissingTranslationHandlerParams): string {
    const lang = params.translateService.currentLang;
    if (this.shouldReturnDefaultTranslation(lang, params)) {
      return (
        this.configTranslations?.[params.key] ||
        this.defaultTranslations?.[lang]?.[params.key] ||
        this.defaultTranslations?.en?.[params.key] ||
        ''
      );
    }
    return params.key;
  }

  private shouldReturnDefaultTranslation(
    lang: string,
    params: MissingTranslationHandlerParams
  ): boolean {
    const coreTranslation = this.defaultTranslations?.[lang]?.[params.key];
    return (
      coreTranslation ||
      coreTranslation === '' ||
      this.configTranslations?.[params.key]
    );
  }
}
