<div class="buttons high-contrast-theme px-1">
  <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
    <div *ngFor="let type of actionTypes; index as i" fxFlex>
      <button
        (click)="openMobileMenu(type)"
        [attr.data-cy]="'mobile-action-buttons.button-' + i"
        class="trigger"
        [ngClass]="{
          'button-filters-applied':
            type === 'filter' && (searchFilters.areFiltersSelected | async)
        }"
        [class.active]="showAction && currentActive === type"
        type="button"
      >
        <span class="trigger-text text-small">{{
          getTitle(type) | translate
        }}</span>
        <div
          class="arrow-up"
          *ngIf="showAction && currentActive === type"
        ></div>
      </button>
    </div>
  </div>
</div>
