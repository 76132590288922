import { Injectable } from '@angular/core';
import { AuthStatus } from '@interfaces/auth-status.model';
import { AutocompleteResult } from '@interfaces/autocomplete-result.model';
import { AuthService } from '@services/auth.service';
import { MembersService } from '@services/members.service';
import { StorageUtilities } from '@utilities/storage.utilities';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take, takeWhile, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RecentAutosuggestService {
  public recentSearches: Observable<AutocompleteResult[]>;
  private recentSearchesSubject: BehaviorSubject<AutocompleteResult[]> =
    new BehaviorSubject([]);
  private memberId: string;

  constructor(
    private authService: AuthService,
    private storageUtilities: StorageUtilities,
    private memberService: MembersService
  ) {
    this.recentSearches = this.recentSearchesSubject.asObservable();
  }

  public getRecentSearch(): AutocompleteResult[] {
    return this.recentSearchesSubject.getValue();
  }

  public initRecentSearches(): Observable<[boolean, string]> {
    return combineLatest([this.getAuthStatus(), this.getMemberId()]).pipe(
      takeWhile(([authStatus, _memberId]) => authStatus),
      tap(([authStatus, memberId]) => this.handleResponse(authStatus, memberId))
    );
  }

  public addRecentSearch(option: AutocompleteResult): void {
    this.getAuthStatus().subscribe((authStatus: boolean) => {
      if (authStatus) {
        const updatedSearches = this.removeDupSearches([
          option,
          ...this.recentSearchesSubject.getValue(),
        ]);
        // store a max of 10 unique searches
        this.emitUpdate(updatedSearches.slice(0, 10));
      }
    });
  }

  public deleteRecentSearch(search: AutocompleteResult): void {
    if (!!this.recentSearchesSubject.getValue().length) {
      const updatedSearches = this.recentSearchesSubject
        .getValue()
        .filter((option: AutocompleteResult) => option.name !== search.name);
      this.emitUpdate(updatedSearches);
    }
  }

  private handleResponse(authStatus: boolean, memberId: string): void {
    if (authStatus && memberId) {
      this.memberId = memberId;
      this.recentSearchesSubject.next(this.getMemberRecentSearches());
    }
  }

  private getMemberRecentSearches(): AutocompleteResult[] {
    const searches = this.getRecentSearchesLocalStorage();
    const memberSearches = searches.get(this.memberId) || [];
    return memberSearches as [];
  }

  private getRecentSearchesLocalStorage(): Map<unknown, unknown> {
    const searches =
      this.storageUtilities.localStorageGet('autoCompleteSearches') || [];
    // Map remembers the original insertion order of the keys
    return new Map(searches);
  }

  private emitUpdate(updatedSearches): void {
    this.recentSearchesSubject.next(updatedSearches);
    this.updateLocalStorage(updatedSearches);
  }

  private updateLocalStorage(updatedSearches: AutocompleteResult[]): void {
    this.storageUtilities.localStorageSet(
      'autoCompleteSearches',
      this.buildSearchesMap(updatedSearches)
    );
  }

  private buildSearchesMap(
    updatedSearches: AutocompleteResult[]
  ): [unknown, unknown][] {
    const mapSearches = this.getRecentSearchesLocalStorage();
    // if key already exists puts back in newest position
    mapSearches.delete(this.memberId);
    mapSearches.set(this.memberId, updatedSearches);
    if (mapSearches.size > 20) {
      // remove oldest member search, which is the first key
      mapSearches.delete(mapSearches.keys().next().value);
    }
    // return array since JSON.stringify does not work on Map
    return Array.from(mapSearches.entries());
  }

  private getAuthStatus(): Observable<boolean> {
    return this.authService.storeAuthStatus().pipe(
      filter((authStatus: AuthStatus) => authStatus.resolved),
      map((authStatus: AuthStatus) => authStatus.auth_status),
      take(1)
    );
  }

  private getMemberId(): Observable<string> {
    return this.memberService.member.pipe(map((member) => member?.id));
  }

  private removeDupSearches(
    searches: AutocompleteResult[]
  ): AutocompleteResult[] {
    return [
      ...searches
        .reduce((acc, search) => acc.set(search.name, search), new Map())
        .values(),
    ];
  }
}
