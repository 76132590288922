import { EpisodeOfCareConfigAssistance } from '@interfaces/episode-of-care-config-assistance.type';

export class EpisodeOfCareConfig {
  public enabled: boolean;
  public assistance: EpisodeOfCareConfigAssistance;

  constructor(config: any) {
    config = config || {};
    this.enabled = config.enabled === true || false;
    this.assistance = this.validateAssistance(config.assistance);
  }

  private validateAssistance?(value: string): EpisodeOfCareConfigAssistance {
    const valid = ['PAT', 'MEG', 'DEFAULT'];
    if (!value || valid.indexOf(value) === -1) {
      return 'DEFAULT';
    }
    return value as EpisodeOfCareConfigAssistance;
  }
}
