export class AlphaPrefixConfig {
  public home_enabled?: boolean;
  public network_enabled?: boolean;
  public length?: number;
  public member_card?: string;
  public request_ci?: boolean;

  constructor(config: AlphaPrefixConfig) {
    config = config || {};
    this.home_enabled = !!config.home_enabled;
    this.network_enabled = !!config.network_enabled;
    this.length = config.length || null;
    this.member_card = config.member_card || null;
    this.request_ci = !!config.request_ci;
  }
}
