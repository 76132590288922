import { DefaultMember } from '@interfaces/default-member.model';

export class DefaultMemberConfig {
  public allowed_states?: string[];
  public user?: DefaultMember;

  constructor(config: DefaultMemberConfig) {
    config = config || {};
    this.allowed_states = config.allowed_states || [];
    this.user = config.user ? new DefaultMember(config.user) : null;
  }
}
