import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[platformClickOutside]',
})
export class ClickOutsideDirective {
  @Input() public clickOutsideExcludeClass: string;

  @Output() public platformClickOutside: EventEmitter<Element> =
    new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: Element): void {
    if (
      this.clickOutsideExcludeClass &&
      target.classList.contains(this.clickOutsideExcludeClass)
    ) {
      return;
    }
    const insideClick = this.el.nativeElement.contains(target);
    if (!insideClick) {
      this.platformClickOutside.emit(target);
    }
  }
}
