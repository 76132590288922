<div class="gated-entry-overlay-home" ngClass.lt-md="mobile">
  <h2
    class="page-title text-center text-light"
    data-cy="gated-entry-home.page-title"
  >
    {{ 'gated_entry_home_title' | translate }}
  </h2>
  <div class="title-separator"></div>
  <div
    class="main-content"
    fxLayout.gt-sm="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="center stretch"
  >
    <!-- Just Browsing: Continue -->
    <div class="section-container" fxFlex="nogrow">
      <div class="section-inner" (click)="onSectionClick('network-location')">
        <div
          fxLayout.gt-sm="column"
          fxLayout.lt-md="row"
          fxLayoutAlign.lt-md="start stretch"
          fxLayoutGap.lt-md="8px"
        >
          <div class="section-icon" fxFlex="nogrow">
            <mat-icon svgIcon="dls-search-plain-light"></mat-icon>
          </div>
          <div fxFlex>
            <h3 class="section-title">
              {{ 'gated_entry_home_section_title_browsing' | translate }}
            </h3>
            <div
              class="section-description"
              [innerHTML]="
                'gated_entry_home_section_description_browsing' | translate
              "
            ></div>
          </div>
          <div
            class="section-arrow"
            fxFlex="nogrow"
            fxHide.gt-sm
            fxFlexAlign="center"
          >
            <i class="icon-arrow-1-right"></i>
          </div>
        </div>
      </div>
      <div class="section-action">
        <zelis-button
          variant="flat"
          [forwardedClass]="'w-1/2 ' + (ccss.loginButtonClass | async)"
          color="accent"
          (clickFn)="onSectionButtonClick('network-location')"
          appGtm
          [gtmOnClick]="true"
          [gtmData]="{ event: 'gtm.gated_entry.continue' }"
          data-cy="gated-entry-home.continue"
        >
          {{ 'gated_entry_home_section_cta_browsing' | translate }}
        </zelis-button>
      </div>
    </div>
    <!-- Member: Log In -->
    <div class="section-container" fxFlex="nogrow">
      <div class="section-inner" (click)="onSectionClick('login')">
        <div
          fxLayout.gt-sm="column"
          fxLayout.lt-md="row"
          fxLayoutAlign.lt-md="start space-between"
          fxLayoutGap.lt-md="8px"
        >
          <div class="section-icon" fxFlex="nogrow">
            <mat-icon svgIcon="dls-user-circle-light"></mat-icon>
          </div>
          <div fxFlex>
            <h3 class="section-title">
              {{ 'gated_entry_home_section_title_member' | translate }}
            </h3>
            <div
              class="section-description"
              [innerHTML]="
                'gated_entry_home_section_description_member' | translate
              "
            ></div>
          </div>
          <div
            class="section-arrow"
            fxFlex="nogrow"
            fxHide.gt-sm
            fxFlexAlign="center"
          >
            <i class="icon-arrow-1-right"></i>
          </div>
        </div>
      </div>
      <div class="section-action">
        <zelis-button
          variant="flat"
          forwardedClass="{{ ccss.loginButtonClass | async }} w-1/2"
          color="accent"
          (clickFn)="onSectionButtonClick('login')"
          appGtm
          [gtmOnClick]="true"
          data-cy="gated-entry-home.login"
          [gtmData]="{ event: 'gtm.gated_entry.login' }"
        >
          {{ 'gated_entry_home_section_cta_member' | translate }}
        </zelis-button>
      </div>
    </div>
    <!-- Use Member ID: Enter Alpha Prefix -->
    <div
      class="section-container"
      [fxHide]="
        (alphaPrefixConfig | async)?.network_enabled !== true ||
        !!initialAppParams.network_id
      "
      fxFlex="nogrow"
    >
      <div class="section-inner" (click)="onSectionClick('alpha-prefix-location')">
        <div
          fxLayout.gt-sm="column"
          fxLayout.lt-md="row"
          fxLayoutAlign.lt-md="start space-between"
          fxLayoutGap.lt-md="8px"
        >
          <div class="section-icon" fxFlex="nogrow">
            <mat-icon svgIcon="dls-id-card-light"></mat-icon>
          </div>
          <div fxFlex>
            <h3 class="section-title">
              {{ 'gated_entry_home_section_title_alpha_prefix' | translate }}
            </h3>
            <div
              class="section-description"
              [innerHTML]="
                'gated_entry_home_section_description_alpha_prefix' | translate
              "
            ></div>
          </div>
          <div
            class="section-arrow"
            fxFlex="nogrow"
            fxHide.gt-sm
            fxFlexAlign="center"
          >
            <i class="icon-arrow-1-right"></i>
          </div>
        </div>
      </div>
      <div class="section-action">
        <zelis-button
          forwardedClass="w-1/2"
          variant="flat"
          color="accent"
          (click)="onSectionButtonClick('alpha-prefix-location')"
          data-cy="gated-entry-home.alpha"
        >
          {{ 'gated_entry_home_section_cta_alpha_prefix' | translate }}
        </zelis-button>
      </div>
    </div>
  </div>
</div>
