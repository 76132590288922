<div
  class="chat-message-container"
  fxLayout="row"
  fxFlex="100%"
  [fxLayoutAlign]="inbound ? 'end end' : 'start end'"
>
  <div class="profile-pic" [fxFlexOrder]="inbound ? '1' : '0'">
    <img alt="user" src="assets/images/chat-default-profile.png" />
  </div>
  <div
    [ngClass]="
      inbound
        ? 'chat-bubble chat-inbound-color'
        : 'chat-bubble chat-outbound-color'
    "
    class="pad left-2x right-2x top-1x bottom-1x"
  >
    <div class="message">
      <span>{{ message.text }}</span>
    </div>
    <svg
      class="bubble-point"
      [ngClass]="
        inbound
          ? 'inbound chat-bubble-point-inbound-color'
          : 'outbound chat-bubble-point-outbound-color'
      "
      data-name="bubble"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 35"
    >
      <path d="M0,35S14,26,13,0L30,18C19,34,0,35,0,35Z" />
    </svg>
  </div>
</div>
