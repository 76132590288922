import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
} from '@angular/core';

@Directive({
  selector: '[zelisResponsiveTypography]',
})
export class ResponsiveTypographyDirective
  implements OnChanges, AfterViewChecked
{
  @Input() steps!: string[];

  @HostBinding('class') get classes() {
    return this.steps[this.activeClassIndex];
  }

  activeClassIndex = 0;
  lastCheckedContent: string | null = null;

  get isOverflowing() {
    const el = this.elementRef.nativeElement;
    return el.offsetWidth < el.scrollWidth || el.offsetHeight < el.scrollHeight;
  }

  get canTryAnotherClass() {
    return this.activeClassIndex + 1 < this.steps.length;
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['steps']) {
      this.activeClassIndex = 0;
      return;
    }
  }

  ngAfterViewChecked() {
    this.checkActiveClassIndex();
  }

  checkActiveClassIndex() {
    const newActiveClassIndex = this.getNewActiveClassIndex();

    if (newActiveClassIndex !== this.activeClassIndex) {
      setTimeout(() => {
        this.activeClassIndex = newActiveClassIndex;
      }, 0);
    }
  }

  getNewActiveClassIndex() {
    if (this.hasContentChanged()) {
      this.lastCheckedContent = this.elementRef.nativeElement.textContent;
      return 0;
    }

    if (this.isOverflowing && this.canTryAnotherClass) {
      return this.activeClassIndex + 1;
    }

    return this.activeClassIndex;
  }

  hasContentChanged() {
    const { textContent } = this.elementRef.nativeElement;

    return this.lastCheckedContent !== textContent;
  }
}
