import { Component, HostBinding, Input } from '@angular/core';
import { LozengeBase } from '../lozenge/lozenge.component';

@Component({
  selector: 'zelis-high-emphasis-yellow-lozenge',
  templateUrl: '../theme-lozenge/theme-lozenge.component.html',
})
export class HighEmphasisYellowLozengeComponent extends LozengeBase {
  @HostBinding() class = 'zelis-dls';
  @Input() classes?: string;

  override get ngStyleExpr() {
    return {
      'background-color': `rgb(var(--palette-m3-system-yellow-65))`,
      color: `rgb(var(--palette-primary-500))`,
    };
  }
}
