import { MemberHsaBalance } from '@interfaces/member-hsa-balance.model';

export class MemberBenefits {
  individual_deductible_limit?: number;
  individual_deductible_accum?: number;
  individual_deductible_remaining?: number;
  family_deductible_limit?: number;
  family_deductible_accum?: number;
  family_deductible_remaining?: number;
  individual_opx_limit?: number;
  individual_opx_accum?: number;
  individual_opx_remaining?: number;
  family_opx_limit?: number;
  family_opx_accum?: number;
  family_opx_remaining?: number;
  is_plan_aca?: boolean;
  coinsurance_rate?: number;
  plan_start_date?: string;
  plan_end_date?: string;
  date_updated?: string;
  tier_number?: number;
  tier_name?: string;
  co_pay?: number;
  hsa_balances?: MemberHsaBalance[];
  message?: string;
  member_network_type?: string;

  constructor(benefits: MemberBenefits = {}) {
    this.individual_deductible_limit =
      benefits.individual_deductible_limit || null;
    this.individual_deductible_accum =
      benefits.individual_deductible_accum || null;
    this.individual_deductible_remaining =
      benefits.individual_deductible_remaining || null;
    this.family_deductible_limit = benefits.family_deductible_limit || null;
    this.family_deductible_accum = benefits.family_deductible_accum || null;
    this.family_deductible_remaining =
      benefits.family_deductible_remaining || null;
    this.individual_opx_limit = benefits.individual_opx_limit || null;
    this.individual_opx_accum = benefits.individual_opx_accum || null;
    this.individual_opx_remaining = benefits.individual_opx_remaining || null;
    this.family_opx_limit = benefits.family_opx_limit || null;
    this.family_opx_accum = benefits.family_opx_accum || null;
    this.family_opx_remaining = benefits.family_opx_remaining || null;
    this.is_plan_aca = benefits.is_plan_aca || null;
    this.coinsurance_rate = benefits.coinsurance_rate || null;
    this.plan_start_date = benefits.plan_start_date || null;
    this.plan_end_date = benefits.plan_end_date || null;
    this.date_updated = benefits.date_updated || null;
    this.tier_number = benefits.tier_number >= 0 ? benefits.tier_number : 1;
    this.tier_name = benefits.tier_name || null;
    this.co_pay = benefits.co_pay || null;
    this.hsa_balances = (benefits.hsa_balances || []).map(
      (hsa) => new MemberHsaBalance(hsa)
    );
    this.message = benefits.message || null;
    this.member_network_type = benefits.member_network_type || null;
  }
}
