import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: 'input[zelisSearchInput]',
})
export class SearchInputDirective implements OnInit {
  @HostBinding('attr.type') type = 'search';
  @HostBinding('style.padding-right') paddingRight = '0px';
  @Input() initialFocus = false;
  @Output() clearInput = new EventEmitter<boolean>();

  valueChanges$ = new BehaviorSubject('');

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private ngModel: NgModel
  ) {}

  @HostListener('ngModelChange', ['$event']) onChange(value: string) {
    this.valueChanges$.next(value);
  }

  ngOnInit() {
    this.valueChanges$.next(this.ngModel.model);
    if (this.initialFocus) {
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
      });
    }
  }

  clear() {
    this.elementRef.nativeElement.value = '';
    this.ngModel.update.emit('');
    this.clearInput.emit(true);
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    });
  }
}
