import { Component, Input } from '@angular/core';
import { Msa } from '@interfaces/msa.model';

@Component({
  selector: 'app-bva-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class BvaInfoComponent {
  @Input() msa: Msa;
}
