import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './network.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('network', reducer)],
})
export class NetworkModule {}
