<div class="platform-member-card">
  <div class="pad all-1x">
    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="8px">
      <div fxFlex="grow">
        <img
          *ngIf="!suppressLogo"
          [attr.src]="assetPath + 'bcbs-logo.svg'"
          alt=""
          class="bcbs-logo"
        />
      </div>
      <div fxFlex="nogrow">
        <div
          class="magnify magnify-plan-name"
          [ngClass]="{ 'start-magnify': magnify === 'PLAN_NAME' }"
        >
          <div class="plan-name">{{ translations.planName }}</div>
          <div class="magnified">
            <div>
              <div>{{ translations.planName }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" fxFlex="nogrow">
        <div class="alpha">ALPHA</div>
        <div>{{ translations.employerGroup }}</div>
      </div>
    </div>
    <div
      class="margin top-2x"
      fxLayout="row"
      fxLayoutAlign="top space-between"
      fxLayoutGap="8px"
    >
      <div class="hr-top" fxFlex="50">
        <div class="card-label">{{ translations.memberName }}</div>
        <div class="text-medium">Jane Doe</div>
        <div class="card-label">{{ translations.memberId }}</div>
        <div
          class="magnify magnify-alpha-prefix"
          [ngClass]="{ 'start-magnify': magnify === 'ALPHA_PREFIX' }"
        >
          <div class="text-medium">XYZ123456789</div>
          <div class="magnified">
            <div>
              <div class="card-label">{{ translations.memberId }}</div>
              <div class="text-bold hr-bottom">
                XYZ<span class="text-color-underemphasis text-regular"
                  >123456789</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hr-top" fxFlex="50">
        <div class="card-label">{{ translations.dependents }}</div>
        <div class="text-medium">John Doe</div>
        <div class="text-medium">Robbie Doe</div>
        <div class="text-medium">Billy Doe</div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="top space-between" fxLayoutGap="8px">
      <div class="hr-top hr-bottom" fxFlex="50">
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="60">
            {{ translations.groupNumber }}
          </div>
          <div class="text-medium" fxFlex="40">023457</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="60">
            {{ translations.bin }}
          </div>
          <div class="text-medium" fxFlex="40">987654</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="60">
            {{ translations.benefitPlan }}
          </div>
          <div class="text-medium" fxFlex="40">HIOPT</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="60">
            {{ translations.effectiveDate }}
          </div>
          <div class="text-medium" fxFlex="40">00/00/00</div>
        </div>
      </div>
      <div class="hr-top hr-bottom" fxFlex="50">
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="70">
            {{ translations.plan }}
          </div>
          <div class="text-medium" fxFlex="30">PPO</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="70">
            {{ translations.officeVisit }}
          </div>
          <div class="text-medium" fxFlex="30">$15</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="70">
            {{ translations.specialistCopay }}
          </div>
          <div class="text-medium" fxFlex="30">$15</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="70">
            {{ translations.emergency }}
          </div>
          <div class="text-medium" fxFlex="30">$75</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start space-between"
          fxLayoutGap="8px"
        >
          <div class="card-label" fxFlex="70">
            {{ translations.deductible }}
          </div>
          <div class="text-medium" fxFlex="30">$50</div>
        </div>
      </div>
    </div>
    <div class="margin top-1x text-right">
      <img [attr.src]="assetPath + 'rx-logo.svg'" alt="" class="rx-logo" />
    </div>
  </div>
</div>
