<div
  [class]="{
    'horizontal-scroll-container': true,
    'stage-is-scrollable': (isStageScrollable | async)
  }"
>
  <div
    class="horizontal-scroll-control"
    *ngIf="showControls"
    [fxHide.lt-md]="hideMobileControls"
  >
    <button
      mat-icon-button
      class="previous-item-button platform-button button-basic button-small button-dark button-border"
      [attr.aria-label]="translations.scrollLeft"
      (click)="previousItem()"
    >
      <i class="icon-arrow-1-left"></i>
    </button>
    <button
      mat-icon-button
      class="next-item-button platform-button button-basic button-small button-dark button-border"
      [attr.aria-label]="translations.scrollRight"
      (click)="nextItem()"
    >
      <i class="icon-arrow-1-right"></i>
    </button>
  </div>

  <div class="horizontal-scroll-stage-container">
    <div class="horizontal-scroll-stage" #ScrollStage>
      <div class="horizontal-scroll-item-container" #ScrollItemContainer>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
