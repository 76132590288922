import { map, filter, catchError, take, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { SettingsService } from '@services/settings.service';
import { Network } from '@interfaces/network.model';
import { NetworksService } from '@services/networks.service';
import { HttpClient } from '@angular/common/http';
import { Msa } from '@interfaces/msa.model';
import { ConfigurationService } from '@services/configuration.service';

@Injectable()
export class MsaSettingsService {
  constructor(
    private settingsService: SettingsService,
    private networksService: NetworksService,
    private http: HttpClient,
    private configurationService: ConfigurationService
  ) {}

  public getSettings(): Observable<any> {
    return combineLatest([
      this.networksService.mappedNetworks,
      this.settingsService.getSetting('msa').pipe(catchError(() => of(null))),
    ]).pipe(
      filter(([networks, settings]) => !!(networks && settings)),
      map(([networks, settings]) => this.mapSettings(networks, settings))
    );
  }

  public getMsaSso(): Observable<Msa> {
    const url = `/api/msa_sso.json`;
    return this.configurationService.listenForResolvedSignature().pipe(
      take(1),
      switchMap((_sig) => {
        return this.http
          .get(url, { withCredentials: true })
          .pipe(catchError(() => of(null))) as Observable<Msa>;
      })
    );
  }

  private mapSettings(networks: Network[], settings: any): any {
    if (settings && settings.form && settings.form.inputs) {
      settings.form.inputs.map((input) => {
        if (input.options === 'getAllowableNetworks') {
          input.options = networks;
        }
      });
    }

    return settings;
  }
}
