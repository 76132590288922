import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { CloseButtonModule } from '../close-button/close-button.module';
import { GpsErrorOverlayComponent } from './gps-error-overlay.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [GpsErrorOverlayComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    CloseButtonModule,
    MatDialogModule,
    MatIconModule
  ],
  providers: [],
  exports: [GpsErrorOverlayComponent],
})
export class GpsErrorOverlayModule {}
