import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from './../utility/pipes/pipes.module';
import { ContactInformationComponent } from './contact-information.component';

@NgModule({
  exports: [ContactInformationComponent],
  imports: [CommonModule, PipesModule],
  declarations: [ContactInformationComponent],
})
export class ContactInformationModule {}
