import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SettingsService } from '../services/settings.service';

const SORT_CONFIG = require('../components/+search/classes/search_sort.config.json');

@Injectable({
  providedIn: 'root',
})
export class SerpLiteRedirectGuard implements CanActivate {
  constructor(
    private settingsService: SettingsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const searchSpecialtyId: number = parseInt(
      route.paramMap.get('search_specialty_id'),
      10
    );
    return this.settingsService.getSetting('serp_lite_specialties').pipe(
      // Clean setting array
      map((specialties: any[]) => specialties || []),
      // Should redirect if requested specialty ID is in the array
      map((specialties: any[]) =>
        specialties.find(
          (value) => value.search_specialty_id === searchSpecialtyId
        )
      ),
      // Redirect or stay on requested route
      switchMap((specialty: any) =>
        iif(
          () => !!specialty,
          this.redirectToSpecialtySearch(specialty),
          of(true)
        )
      )
    );
  }

  private findSortQuery(
    defaultOptions: any[],
    sortTranslation: string
  ): string {
    const sortIndex = defaultOptions.findIndex(
      (option: any) => option.translation === sortTranslation
    );
    return defaultOptions[sortIndex]?.query;
  }

  private getSearchSortSetting(): Observable<string> {
    return this.settingsService.getSetting('search_sort').pipe(
      map((searchSort: object) => searchSort || SORT_CONFIG),
      map((searchSort: { default: any[]; serp_lite_default: string }) =>
        this.findSortQuery(searchSort.default, searchSort.serp_lite_default)
      )
    );
  }

  private getRedirectUrlTree(specialty: any, sortDefault: string): UrlTree {
    return this.router.createUrlTree(
      ['specialty_search', specialty.search_specialty_id],
      {
        queryParamsHandling: 'merge',
        queryParams: {
          sort: sortDefault,
          radius: specialty.radius,
        },
      }
    );
  }

  private redirectToSpecialtySearch(specialty: any): Observable<UrlTree> {
    return this.getSearchSortSetting().pipe(
      map((sortDefault: string) =>
        this.getRedirectUrlTree(specialty, sortDefault)
      )
    );
  }
}
