import {
  Component,
  Output,
  Input,
  OnInit,
  EventEmitter,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AlphaListService } from '@services/alpha-list/alpha-list.service';
import { Breakpoints } from '@classes/breakpoints.class';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { EpisodesOfCareConfigService } from '@services/eoc/eoc.config.service';
import { AlphaListItem } from '@services/alpha-list/alpha-list.interface.item';
import {
  CdkVirtualScrollViewport,
  ViewportRuler,
} from '@angular/cdk/scrolling';
import { AlphaListMenuItemComponent } from './alpha-list-menu-item/alpha-list-menu-item.component';

@Component({
  selector: 'app-alpha-list',
  templateUrl: './alpha-list.component.html',
  styleUrls: ['./alpha-list.component.scss'],
})
export class AlphaListComponent implements OnInit, OnDestroy {
  @Input() listName: string;
  @Input() currentIndex: number;

  @Output() selected: EventEmitter<AlphaListItem> = new EventEmitter();

  @ViewChild('searchFilter') searchFilter: ElementRef;
  @ViewChild(CdkVirtualScrollViewport)
  virtualScrollViewport: CdkVirtualScrollViewport;

  @ViewChildren(AlphaListMenuItemComponent)
  menuItems: QueryList<AlphaListMenuItemComponent>;

  public filterControl: UntypedFormControl;
  public showLegend = true;
  public carepathEnabled: boolean = false;
  public height: number =
    this.getWindowHeight() > 650 ? 400 : this.getWindowHeight() - 270;

  private subs = new SubscriptionManager();

  constructor(
    public alphaListService: AlphaListService,
    public breakpoints: Breakpoints,
    private carepathConfigService: EpisodesOfCareConfigService,
    private viewportRuler: ViewportRuler
  ) {}

  ngOnInit() {
    this.filterControl = new UntypedFormControl();
    this.subscribeToFilterControl();
    setTimeout(() => {
      this.searchFilter.nativeElement.focus();
    });
    this.subscribeToCarepathEnabled();
    this.subscribeToViewport();
  }

  ngOnDestroy() {
    this.subs.destroy();
  }

  public jumpTo(letter: string): void {
    const i = this.alphaListService.getFocusIndex(letter);
    if (i < 0) {
      return;
    }
    this.currentIndex = i;
    this.focusItemByIndex(i);
  }

  public showAlphaLetter(i: number): boolean {
    return this.alphaListService.showLetter(i);
  }

  public activeLetters(): string[] {
    return this.alphaListService.activeLetters[this.listName]
      ? this.alphaListService.activeLetters[this.listName]
      : [];
  }

  public alphaListFocus(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      this.focusFirstItem();
    }
  }

  public focusFirstItem(): void {
    this.focusItemByIndex(0);
  }

  public trackByFn(index: number, item: AlphaListItem): number {
    return item.id;
  }

  private subscribeToCarepathEnabled(): void {
    this.subs.add(
      this.carepathConfigService
        .isEnabled()
        .subscribe((enabled) => (this.carepathEnabled = enabled))
    );
  }

  private subscribeToViewport(): void {
    this.subs.add(
      this.viewportRuler.change().subscribe((resizeEvent) => {
        if (resizeEvent.target['innerHeight'] < 660) {
          this.height = resizeEvent.target['innerHeight'] - 300;
        }
      })
    );
  }

  private getWindowHeight(): number {
    return this.viewportRuler.getViewportSize().height;
  }

  private subscribeToFilterControl(): void {
    this.subs.add(
      this.filterControl.valueChanges.subscribe((query: string) => {
        this.alphaListService.filter(query);
        if (query && query.length > 1) {
          this.showLegend = false;
        } else {
          this.showLegend = true;
        }
      })
    );
  }

  private focusItemByIndex(index: number, isRetry: boolean = false): void {
    this.virtualScrollViewport.scrollToIndex(index);
    const item = this.getItemByIndex(index);
    if (item) {
      item.focus();
    } else if (!isRetry) {
      setTimeout(() => this.focusItemByIndex(index, true));
    }
  }

  private getItemByIndex(index: number): AlphaListMenuItemComponent {
    return this.menuItems.find(
      (item: AlphaListMenuItemComponent) => item.index === index
    );
  }
}
