export class ChatMessage {
  text: string;
  name: string;
  agentId: string;
  inbound: boolean;
  schedule: any;
  reason?: string;
  queuePosition?: any;
  position?: any;

  constructor(obj: ChatMessage) {
    this.text = obj.text || '';
    this.name = obj.name || '';
    this.agentId = obj.agentId || '';
    this.inbound = obj.inbound || true;
    this.schedule = obj.schedule || {};
    this.reason = obj.reason || null;
    this.queuePosition = obj.queuePosition || null;
    this.position = obj.position || null;
  }
}

export type ChatMessageType =
  | 'ChatMessage'
  | 'ChatEstablished'
  | 'ChatRequestSuccess'
  | 'QueueUpdate'
  | 'ChatRequestFail'
  | 'ChatEnded';

export class ChatMessagePayload {
  public type: ChatMessageType;
  public message: ChatMessage;

  constructor(data: { type: ChatMessageType; message: ChatMessage }) {
    this.type = data.type;
    this.message = data.message;
  }

  get positionInQueue(): number {
    if (this.type === 'ChatRequestSuccess') {
      return this.message.queuePosition;
    }

    return this.message.position;
  }
}

export interface ChatMessagesResponse {
  sequence: number;
  messages: any[];
}

export class ChatMessagesBundle {
  readonly messagesResponse;

  constructor(messagesResponse: ChatMessagesResponse) {
    this.messagesResponse = messagesResponse;
  }

  get isSuccess(): boolean {
    return this.messagesResponse === null || this.isValid;
  }

  get isValid(): boolean {
    return (
      this.messagesResponse &&
      this.messagesResponse.hasOwnProperty('messages') &&
      this.messagesResponse.hasOwnProperty('sequence')
    );
  }

  get sequence(): number {
    return this.messagesResponse.sequence;
  }

  get messages(): ChatMessagePayload[] {
    return this.messagesResponse.messages.map(
      (msg) => new ChatMessagePayload(msg)
    );
  }
}
