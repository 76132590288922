import { PrsQuestionMetadata } from './prs.interface.question-metadata';

export class PrsQuestionAttributes {
  name: string;
  kind: string;
  display_title: string;
  display_text: string;
  required: boolean;
  slope: number;
  intercept: number;
  metadata: PrsQuestionMetadata;

  constructor(obj: PrsQuestionAttributes) {
    this.name = obj.name;
    this.kind = obj.kind;
    this.display_title = obj.display_title;
    this.display_text = obj.display_text;
    this.slope = obj.slope;
    this.intercept = obj.intercept;
    this.required = obj.required;
    this.metadata = new PrsQuestionMetadata(obj.metadata);
  }
}
