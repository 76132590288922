import { Directive, HostBinding } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[platformHorizontalScrollItem]',
})
export class HorizontalScrollItemDirective {
  @HostBinding('class') public elementClass = 'horizontal-scroll-item';

  public addElementClass(elClass: string): void {
    setTimeout(() => {
      const classes = [...this.elementClass.split(' '), ...elClass.split(' ')];
      this.elementClass = _.uniq(classes).join(' ');
    });
  }

  public removeElementClass(elClass: string): void {
    setTimeout(() => {
      const classes = this.elementClass.split(' ');
      _.pull(classes, ...elClass.split(' '));
      this.elementClass = classes.join(' ');
    });
  }
}
