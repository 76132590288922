import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { distinctUntilChanged, switchMap, first } from 'rxjs/operators';

@Injectable()
export class HttpConfigSignatureInterceptor implements HttpInterceptor {
  constructor(private configurationService: ConfigurationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.startsWith('/api/') &&
      !request.url.startsWith('/api/configuration_profile/signature.json')
    ) {
      return this.configurationService.signatureAppParamsResolved.pipe(
        distinctUntilChanged(),
        first(),
        switchMap((signature) => {
          const hasSignatureParam: boolean =
            !!request.params.get('config_signature');
          if (signature && !hasSignatureParam) {
            request = request.clone({
              setParams: {
                config_signature: signature,
              },
            });
          }
          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
