import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { MembersService } from '../members.service';
import { EpisodeOfCareConfig } from '@interfaces/episode-of-care-config.model';
import { iif, Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { Member } from '../../components/members';
import { AuthService } from '../auth.service';
import { AuthStatus } from '@interfaces/auth-status.model';

@Injectable({
  providedIn: 'root',
})
export class EpisodesOfCareConfigService {
  constructor(
    private settingsService: SettingsService,
    private membersService: MembersService,
    private authService: AuthService
  ) {}

  /**
   * Get the Carepath config if member has benefits.
   * @returns Observable<EpisodeOfCareConfig>
   */
  public getConfig(): Observable<EpisodeOfCareConfig> {
    return this.authService.authStatus.pipe(
      filter((authStatus) => !!authStatus.resolved),
      first(),
      switchMap((authStatus: AuthStatus) => {
        return iif(
          () => !!authStatus.auth_status,
          this.checkMemberBenefits(),
          of(null)
        );
      })
    );
  }

  /**
   * Get only the Carepath "enabled" config.
   * @returns Observable<EpisodeOfCareConfig>
   */
  public isEnabled(): Observable<boolean> {
    return this.getConfig().pipe(
      map((config: EpisodeOfCareConfig) => !!(config && config.enabled))
    );
  }

  private requestConfig(): Observable<EpisodeOfCareConfig> {
    return this.settingsService.getSetting('carepath').pipe(
      first(),
      map((config) => new EpisodeOfCareConfig(config))
    );
  }

  private memberHasBenefits(member: Member): boolean {
    return member && member.benefits && member.benefits.length > 0;
  }

  private checkMemberBenefits(): Observable<EpisodeOfCareConfig> {
    return this.membersService.member.pipe(
      first((member) => member !== undefined),
      switchMap((member: Member) => {
        return iif(
          () => this.memberHasBenefits(member),
          this.requestConfig(),
          of(null)
        );
      })
    );
  }
}
