export class TelehealthVendor {
  code?: string;
  prodtype?: string;
  name?: string;
  logo?: string;
  primary_text?: string;
  description?: string;
  secondary_text?: string;
  phone?: string;
  wait_time?: string;
  link?: string;
  link_format?: string;
  external_link_indicator?: boolean;
  type?: string;

  constructor(obj: TelehealthVendor) {
    obj = obj || {};
    this.code = obj.code || null;
    this.prodtype = obj.prodtype || null;
    this.name = obj.name || null;
    this.logo = obj.logo || null;
    this.primary_text = obj.primary_text || null;
    this.description = obj.primary_text || null;
    this.secondary_text = obj.secondary_text || null;
    this.phone = obj.phone || null;
    this.wait_time = obj.wait_time || null;
    this.link = obj.link || null;
    this.link_format = obj.link_format || null;
    this.external_link_indicator = obj.external_link_indicator || null;
    this.type = 'telehealth';
  }
}
