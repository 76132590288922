import { NgModule } from '@angular/core';
import { CapitalizePipe } from './capitalize.pipe';
import { ReplacePipe } from './replace.pipe';
import { PhonePipe } from './phone.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { LegendNumberPipe } from './legend-number.pipe';

@NgModule({
  imports: [],
  declarations: [
    CapitalizePipe,
    PhonePipe,
    ReplacePipe,
    SanitizePipe,
    LegendNumberPipe,
  ],
  exports: [
    CapitalizePipe,
    PhonePipe,
    ReplacePipe,
    SanitizePipe,
    LegendNumberPipe,
  ],
})
export class PipesModule {}
