import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnfocusDirective } from './unfocus.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [UnfocusDirective],
  exports: [UnfocusDirective],
})
export class UnfocusModule {}
