import { Injectable } from '@angular/core';
import { SkipLink } from '@interfaces/skip-link.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SkipLinksService {
  private _links = new BehaviorSubject<SkipLink[]>([]);

  public links(): Observable<SkipLink[]> {
    return this._links;
  }

  public add(link: SkipLink): void {
    this._links.next(this._links.getValue().concat(link));
  }

  public reset(): void {
    this._links.next(
      this._links.getValue().filter((link) => link.persistAcrossNavigation)
    );
  }
}
