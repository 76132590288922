<svg
  role="img"
  aria-labelledby="svg_title"
  data-name="Zelis Logo"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 444.63 194.55"
>
  <title id="svg_title">{{ logoAltText }}</title>
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
      }
    </style>
  </defs>
  <path
    class="cls-1"
    d="M387.69,122.24c-18.12-4.67-25.25-6.59-25.25-15.37,0-6.86,5.76-10.71,16.2-10.71s18.39,4.94,23.88,11.25l16.2-15.92c-8.78-9.61-21.69-17.29-40.08-17.29-22.78,0-41.18,10.98-41.18,34.04s17.02,30.74,34.04,35.41c19.49,5.49,28,6.86,28,16.2,0,7.68-6.31,12.35-20.32,12.35-12.38,0-22.58-4.12-29.69-13.05l-16.71,16.21c8.92,10.38,25.16,19.08,48.05,19.08,32.67,0,44.74-16.2,44.74-35.96,0-24.43-19.76-31.57-37.88-36.23"
  />
  <path
    class="cls-1"
    d="M198.35,124.48c-2.47-15.66-12.09-27.2-31.32-27.2-17.86,0-30.23,9.89-33.52,27.2h64.85Zm-28.3,46.71c15.11,0,26.1-6.04,32.7-12.37l16.21,17.04c-10.72,10.72-25.55,18.68-48.64,18.68-39.57,0-65.12-25.56-65.12-60.18s26.93-60.17,61.55-60.17c39.29,0,61.55,29.13,59.63,69.52h-92.88c3.3,16.76,15.39,27.48,36.55,27.48"
  />
  <rect class="cls-1" x="245.69" width="26.89" height="192.25" />
  <rect class="cls-1" x="291.38" y="75.31" width="27.16" height="116.84" />
  <path
    class="cls-1"
    d="M287.24,36.55c0-10.12,8.25-17.57,17.84-17.57s17.84,7.45,17.84,17.57c0,10.65-8.26,17.84-17.84,17.84s-17.84-7.19-17.84-17.84"
  />
  <polygon
    class="cls-1"
    points="92.44 99.92 108.54 75.22 9.09 75.23 9.09 99.91 62.74 99.91 92.44 99.92"
  />
  <polygon
    class="cls-1"
    points="16.11 167.77 0 192.43 99.76 192.45 99.76 167.76 16.11 167.77"
  />
  <polygon
    class="cls-1"
    points="21.41 159.49 53.41 159.45 87.1 107.99 55.21 107.99 21.41 159.49"
  />
  <path
    class="cls-1"
    d="M438.41,182.09c.74,0,1.41,.58,1.41,1.44,0,.8-.67,1.38-1.41,1.38h-2.15v-2.82h2.15Zm1.28,6.86h1.28l-2.08-3.11c.45,0,1.99-.58,1.99-2.31,0-1.44-1.12-2.37-2.47-2.37h-3.17v7.79h1.02v-3.08h1.41l2.02,3.08Zm-1.86-9.74c3.24,0,5.83,2.56,5.83,5.83s-2.6,5.87-5.83,5.87-5.87-2.66-5.87-5.87,2.63-5.83,5.87-5.83m0,12.63c3.78,0,6.8-3.05,6.8-6.8s-3.01-6.8-6.8-6.8-6.8,3.01-6.8,6.8,3.05,6.8,6.8,6.8"
  />
</svg>
