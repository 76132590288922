import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyHideDirective } from './empty-hide.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [EmptyHideDirective],
  exports: [EmptyHideDirective],
})
export class EmptyHideModule {}
