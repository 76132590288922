import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DlsButtonModule } from '@zelis/dls/button';
import { NotificationOverlayComponent } from './notification-overlay.component';
import { DlsIconModule } from '@zelis/dls/icons';
import { DlsDialogModule } from '@zelis/dls/dialog';
import { CloseButtonModule } from '@components/close-button/close-button.module';

@NgModule({
  declarations: [NotificationOverlayComponent],
  imports: [
    CommonModule,
    DlsIconModule,
    TranslateModule,
    DlsButtonModule,
    DlsDialogModule,
    CloseButtonModule,
  ],
  providers: [],
  exports: [NotificationOverlayComponent],
})
export class NotificationOverlayModule {}
