import * as _ from 'lodash';

interface AgentAvailabilityData {
  isAvailable: boolean;
  id: string;
}

export class AgentAvailability {
  readonly agentAvailability: AgentAvailabilityData;

  constructor(availabilityResponse) {
    this.agentAvailability = this.parseResponse(availabilityResponse) || {};
  }

  get isAvailable(): boolean {
    return !!this.agentAvailability.isAvailable;
  }

  get agentId(): string {
    return this.agentAvailability.id;
  }

  private parseResponse(availabilityResponse) {
    if (
      !availabilityResponse ||
      !Array.isArray(availabilityResponse.messages)
    ) {
      return null;
    }

    return this.parseMessage(this.parseMessages(availabilityResponse.messages));
  }

  private parseMessages(availabilityMessages: any[]) {
    return availabilityMessages.find(
      (message) => message.message && Array.isArray(message.message.results)
    );
  }

  private parseMessage(message) {
    return message?.message.results.find((result) => result.isAvailable);
  }
}
