import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SkipLinksService } from './skip-links.service';
import { SkipLink } from '@interfaces/skip-link.interface';

@Directive({
  selector: '[appSkipLinkTarget]',
})
export class SkipLinkTargetDirective implements OnChanges {
  @Input() skipLinkText: string;
  @Input() skipLinkIndex: number;
  @Input() skipLinkPersistAcrossNavigation: boolean;

  constructor(private el: ElementRef, private service: SkipLinksService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.skipLinkText) {
      this.service.add(this.createSkipLink());
    }
  }

  private createSkipLink(): SkipLink {
    return {
      text: this.skipLinkText,
      target: this.el,
      index: this.skipLinkIndex,
      persistAcrossNavigation: this.skipLinkPersistAcrossNavigation,
    };
  }
}
