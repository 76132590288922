export class FaqQuestionItem {
  public id?: number;
  public title?: string;
  public content?: string;

  constructor(obj: FaqQuestionItem) {
    obj = obj || {};
    this.id = obj.id || null;
    this.title = obj.title || null;
    this.content = obj.content || null;
  }
}
