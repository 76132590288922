import { ProviderLocation } from './provider.location.interface';
import { SearchRouteType } from '@interfaces/search-route-type.interface';

export class AutocompleteResult {
  id?: string;
  description?: string;
  locale_name?: string;
  name: string;
  name_translated?: string;
  type?: SearchRouteType;
  episode_procedure?: boolean;
  provider_id?: string;
  primary_field_specialty?: string;
  specialty?: string;
  specialty_count?: number;
  locations?: ProviderLocation[];
  radius?: string;
  client_canonical_id?: string;
  provider_type?: string;
  out_of_network?: boolean;
  npi_identifier?: string;
  gender?: string;
  photo?: string;

  constructor(obj: AutocompleteResult, lang: string, type: SearchRouteType) {
    this.id = obj.id || null;
    this.description = this.mapDescription(obj.description, lang);
    this.locale_name = obj.locale_name || null;
    this.name = this.mapName(obj.name, obj.name_translated, lang);
    this.name_translated = obj.name_translated || null;
    this.episode_procedure = obj.episode_procedure || null;
    this.provider_id = obj.provider_id || null;
    this.primary_field_specialty = obj.primary_field_specialty || null;
    this.specialty = obj.specialty || null;
    this.specialty_count = obj.specialty_count;
    this.locations = obj.locations || null;
    this.type = type;
    this.client_canonical_id = obj.client_canonical_id || null;
    this.provider_type = obj.type || null;
    this.out_of_network = obj.out_of_network || null;
    this.npi_identifier = obj.npi_identifier || null;
    this.gender = obj.gender || null;
    this.photo = obj.photo || null;
  }

  private mapDescription?(description: any, lang: string): string {
    if (description && description.hasOwnProperty(lang)) {
      return description[lang];
    }
    return typeof description === 'string' ? description : '';
  }

  private mapName?(name: string, name_translated: any, lang: string): string {
    if (name && name_translated && name_translated[lang]) {
      return name_translated[lang];
    }
    return name;
  }
}
