export enum SearchType {
  name = 'Full Text',
  search_specialties = 'Specialty',
  incentivized_search_specialties = 'Incentivized Specialty',
  procedures = 'Procedure Claims',
  incentivized_procedures = 'Incentivized Procedure Claims',
  rates = 'Procedure Rates',
  RxSavingsMedFinder = 'RX Savings / Medication Finder',
  RxCouponsMedFinder = 'RX Coupons / Medication Finder',
}
