import { Component, HostBinding, Input } from '@angular/core';
import { LozengeBase } from '../lozenge/lozenge.component';

@Component({
  selector: 'zelis-low-emphasis-gray-lozenge',
  templateUrl: '../theme-lozenge/theme-lozenge.component.html',
})
export class LowEmphasisGrayLozengeComponent extends LozengeBase {
  @HostBinding() class = 'zelis-dls';
  @Input() classes?: string;

  override get ngStyleExpr() {
    return {
      'background-color': `rgb(var(--palette-m3-system-gray-85))`,
      color: `rgb(var(--palette-m3-system-gray-10))`,
    };
  }
}
