<div class="text-center text-palette-m3-system-gray-10 flex flex-col items-center p-4 md:p-0">
  <h3
    class="heading-3 font-bold"
    [innerHTML]="'network_selection_wizard_medicare_title' | translate"
  >
  </h3>
  <div
    class="text-base mt-2 mb-10 max-w-[582px]"
    [innerHTML]="'network_selection_wizard_medicare_description' | translate">
  </div>
  <app-wizard-card-layout-template>
    <app-wizard-selection-card
      [title]="'network_selection_wizard_medicare_retail_card_title' | translate"
      [description]="'network_selection_wizard_medicare_retail_card_description' | translate"
      [ctaText]="'app_global_select' | translate"
      (cta)="setSubMarketSegment(SubMarketSegment.retailMedicare)"
      
    >
    </app-wizard-selection-card>
    <app-wizard-selection-card
      [title]="'network_selection_wizard_medicare_group_card_title' | translate"
      [description]="'network_selection_wizard_medicare_group_card_description' | translate"
      [ctaText]="'app_global_select' | translate"
      (cta)="setSubMarketSegment(SubMarketSegment.groupMedicare)"
    >
    </app-wizard-selection-card>
  </app-wizard-card-layout-template>
  <app-wizard-login-link></app-wizard-login-link>
</div>
