// *** deprecated: use dls event-stop-propagation-directive ***

import { Directive, HostListener } from '@angular/core';

@Directive({
  // TODO: Fix tslint
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[eventStopPropagation]',
})
export class EventStopPropagationDirective {
  @HostListener('click', ['$event']) onClick(event: Event): void {
    event.stopPropagation();
  }
}
