import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { GlobalHeaderAutosuggestComponent } from './global-header-autosuggest.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { DlsFocusModule } from '@zelis/dls/focus';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';
import { DlsChipModule } from '@zelis/dls/chip';
import { DlsSearchInputModule } from '@zelis/dls/search-input';
import { PendoModule } from '@directives/pendo/pendo.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UnfocusModule } from '@directives/unfocus/unfocus.module';
import { DlsIconModule } from '@zelis/dls/icons';
import { MatChipsModule } from '@angular/material/chips';
import { DlsListModule } from '@zelis/dls/list';

@NgModule({
  declarations: [GlobalHeaderAutosuggestComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    DlsFocusModule,
    DlsListModule,
    DlsProgressSpinnerModule,
    DlsChipModule,
    DlsSearchInputModule,
    PendoModule,
    MatInputModule,
    MatFormFieldModule,
    UnfocusModule,
    DlsIconModule,
    MatChipsModule,
  ],
  providers: [NgModel],
  exports: [GlobalHeaderAutosuggestComponent],
})
export class GlobalHeaderAutosuggestModule {}
