import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalHeaderService } from '../global-header/global-header.service';
import { AuthService } from '../../services/auth.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { AuthStatus } from '@interfaces/auth-status.model';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from '../../services/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mobile-header-overlay',
  templateUrl: './mobile-header-overlay.component.html',
  styleUrls: ['./mobile-header-overlay.component.scss'],
})
export class MobileHeaderOverlayComponent implements OnInit, OnDestroy {
  public showLanguage: boolean = false;
  public logoutDisabled: boolean = true;
  public suppressLanguage: boolean = false;
  public subscriptions = new SubscriptionManager();
  public auth: AuthStatus = new AuthStatus();
  public ssEnabled: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<MobileHeaderOverlayComponent>,
    public globalHeaderService: GlobalHeaderService,
    private settingsService: SettingsService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.authService.getSuppressLogoutConfig().subscribe((result) => {
        this.logoutDisabled = result;
      })
    );
    this.subscriptions.add(this.subscribeToAuthStore());
    this.subscriptions.add(
      this.getSuppressLanguageConfig().subscribe((result) => {
        this.suppressLanguage = String(result) === 'true';
      })
    );
    this.ssEnabled = this.globalHeaderService.isSsEnabled();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public mobileLogout() {
    this.dialogRef.close();
    this.authService.logout();
  }

  public showLoginLink() {
    return !!(!this.auth.auth_status && this.auth.url);
  }

  public goto(path: string) {
    this.dialogRef.close();
    this.router.navigate(['/', path], { queryParamsHandling: 'preserve' });
  }

  private subscribeToAuthStore(): Subscription {
    return this.authService.authStatus.subscribe((data) => (this.auth = data));
  }

  private getSuppressLanguageConfig(): Observable<boolean> {
    return this.settingsService.getSetting('suppress_language');
  }
}
