import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  bigDialog,
  defaultDialog,
  dialogSize,
  DIALOG_FULL_SCREEN_CLASS,
  smallDialog,
} from './dialog/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

export interface FullScreenState {
  isFullScreen: boolean;
}

@Injectable()
export class DialogService {
  /**
   * Stream that emits when all open dialog have finished closing.
   * Will emit on subscribe if there are no open dialogs to begin with.
   */
  afterAllClosed: MatDialog['afterAllClosed'] = this.dialog.afterAllClosed;
  /**
   * Stream that emits when a dialog has been opened.
   */
  afterOpened: MatDialog['afterOpened'] = this.dialog.afterOpened;
  /**
   * Keeps track of the currently-open dialogs.
   */
  openDialogs: MatDialog['openDialogs'] = this.dialog.openDialogs;

  /**
   * TODO: use tailwind breakpoints instead
   */
  private isExtraSmall = this.breakpointObserver.observe('(max-width: 640px)');
  /**
   * TODO: use tailwind breakpoints instead
   */
  private isSmall = this.breakpointObserver.observe('(max-width: 768px)');

  private fullScreenStateSubjects = new Map<
    string,
    BehaviorSubject<FullScreenState>
  >();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog
  ) {}

  openSmall: MatDialog['open'] = (template, config = {}) => {
    return this.open(template, {
      ...smallDialog,
      ...config,
    });
  };

  openBig: MatDialog['open'] = (template, config = {}) => {
    return this.open(template, {
      ...bigDialog,
      ...config,
    });
  };

  open: MatDialog['open'] = (template, _config = {}) => {
    const config: any = {
      maxWidth: '100vw',
      height: '100%',
      ...defaultDialog,
      ..._config,
    };
    const dialogRef = this.dialog.open(template, config);

    this.createSubject(dialogRef);
    this.subscribeToBreakpointChanges(dialogRef, config);

    /**
     * In order for our generated DLS class based style encapsulation to work correctly
     * for dialogs we need to manually apply the class in the right location for the
     * selector. Angular Material does not provide a dedicated extensibility point for
     * the .cdk-overlay-container element.
     */
    const el = document.querySelector('.cdk-overlay-container');
    el?.classList.add('zelis-dls');

    return dialogRef;
  };

  /**
   * Closes all of the currently-open dialogs.
   */
  closeAll: MatDialog['closeAll'] = () => {
    return this.dialog.closeAll();
  };

  /**
   * Finds an open dialog by its id.
   *
   * @param id — ID to use when looking up the dialog.
   */
  getDialogById: MatDialog['getDialogById'] = (id) => {
    return this.dialog.getDialogById(id);
  };

  getFullScreenStateStream(dialogId: string) {
    return this.fullScreenStateSubjects.get(dialogId);
  }

  private subscribeToBreakpointChanges<T, D, R>(
    dialogRef: MatDialogRef<T, R>,
    config: MatDialogConfig<D>
  ) {
    if (!config.width) {
      return;
    }
    const dialogWidth = parseInt(config.width);

    const isDefaultDialog = dialogWidth === dialogSize.default;
    const isBigDialog = dialogWidth === dialogSize.big;

    const breakpoint = isDefaultDialog
      ? this.isExtraSmall
      : isBigDialog
      ? this.isSmall
      : undefined;

    /**
     * means that dialog has a non-standard width.
     * therefore we don't need to conditionally change it
     */
    if (!breakpoint) {
      return;
    }

    const fullScreenStateSubject = this.getFullScreenStateStream(dialogRef.id);

    breakpoint
      .pipe(takeUntil(dialogRef.afterClosed()))
      .subscribe((breakpointState) => {
        if (breakpointState.matches) {
          dialogRef.addPanelClass(DIALOG_FULL_SCREEN_CLASS);

          if (fullScreenStateSubject) {
            fullScreenStateSubject.next({ isFullScreen: true });
          }
        } else {
          dialogRef.removePanelClass(DIALOG_FULL_SCREEN_CLASS);

          if (fullScreenStateSubject) {
            fullScreenStateSubject.next({ isFullScreen: false });
          }
        }
      });
  }

  private createSubject<T, R>(dialogRef: MatDialogRef<T, R>) {
    this.fullScreenStateSubjects.set(
      dialogRef.id,
      new BehaviorSubject({ isFullScreen: false } as FullScreenState)
    );

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        /**
         * Since the dialog is closed, we need to remove the zelis-dls class
         * so it does not cause styling issues for non-dls dialogs
         */
        const el = document.querySelector('.cdk-overlay-container');
        el?.classList.remove('zelis-dls');

        this.fullScreenStateSubjects.delete(dialogRef.id);
      });
  }
}
