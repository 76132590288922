export class VimAppointments {
  config?: string | boolean;
  api_key?: string;
  member_token?: string;
  url?: string;

  constructor(obj: VimAppointments) {
    this.config = obj.config || false;
    this.api_key = obj.api_key || '';
    this.member_token = obj.member_token || '';
    this.url = obj.url || 'https://api-sandbox.getvim.com';
  }
}
