import { map, switchMap, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '@services/settings.service';
import { Logo } from '@interfaces/logo.model';
import { AppConfigService } from '@services/app.config.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalHeaderSettings {
  public logo: Observable<Logo>;

  constructor(
    private settingsService: SettingsService,
    private appConfigService: AppConfigService
  ) {
    this.logo = this.getLogo();
  }

  private getLogo(): Observable<Logo> {
    return this.appConfigService.config.pipe(
      switchMap((appConfig) =>
        this.settingsService.getSetting('Logo', Logo).pipe(
          map((logo) => {
            logo.client = appConfig.client;
            return logo;
          })
        )
      ),
      shareReplay(1)
    );
  }
}
