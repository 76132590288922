import { Directive, HostListener } from '@angular/core';

@Directive({
  // TODO: Fix tslint
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[eventPreventDefault]',
})
export class EventPreventDefaultDirective {
  @HostListener('click', ['$event']) onClick(event: Event): void {
    event.preventDefault();
  }
}
