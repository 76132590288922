<span class="bva-notes-container">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'bva_notes' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <textarea class="bva-notes-textarea" [(ngModel)]="bvaService.notes">
    </textarea>
  </mat-expansion-panel>
</span>
