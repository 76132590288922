import { Action, createReducer, on } from '@ngrx/store';
import * as DisclaimersActions from './serp-disclaimers.actions';
import { SerpDisclaimersState, initialState } from './serp-disclaimers.state';

export const featureKey = 'serp-disclaimers';

export const serpDisclaimersReducer = createReducer(
  initialState,
  on(DisclaimersActions.requestDisclaimers, (state) => ({
    ...state,
    status: 'Loading',
  })),
  on(
    DisclaimersActions.requestDisclaimersSuccess,
    (state, { disclaimers }) => ({
      ...state,
      disclaimers,
      status: 'Loaded',
    })
  ),
  on(DisclaimersActions.requestDisclaimersFailure, (state, { error }) => ({
    ...state,
    status: { error },
  }))
);

export const reducer = (state: SerpDisclaimersState, action: Action) => {
  return serpDisclaimersReducer(state, action);
};
