import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DOCUMENT } from '@angular/common';
import { PageAnimations } from '@utilities/page.animations';
import { PageTransition } from '@interfaces/page-transition.interface';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { StorageUtilities } from '@utilities/storage.utilities';
import { MarketSegment, ProductType, SubMarketSegment } from "@enums/uux-marketing-plans.enum";
import { RouteUtilities } from "@utilities/route.utilities";
import { SubscriptionManager } from "@zelis/platform-ui-components";
import { UuxNetwork } from "@interfaces/uux-network.model";
import { difference, find, flow, map, sortBy, uniqBy, without } from "lodash";
import { PlanState } from "@interfaces/plan-state.interface";
import { planListTitleConfig, TitleConfig } from "@interfaces/network-selection-wizard-plan-list-title-config.model";
import { NetworkSelectionWizardService } from "@services/network-selection-wizard/network-selection-wizard.service";
import { WizardPreviousSelections } from "@interfaces/wizard-previous-selections.interface";
import { Place } from "@classes/place.class";

@Component({
  selector: 'app-network-selection-wizard',
  templateUrl: './network-selection-wizard.component.html',
  animations: PageAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class NetworkSelectionWizardComponent implements OnInit, OnDestroy {
  public pageTransition: PageTransition = {
    page: 'location-of-care',
    direction: 'none',
  };
  public initialPage: PageTransition = this.pageTransition;
  public pageHistory: string[] = [];
  public plans: UuxNetwork[];
  public planStates: PlanState[] = [];
  public selectedMarketSegment: MarketSegment;
  public selectedSubMarketSegment: SubMarketSegment;
  public selectedProductType: ProductType = ProductType.all;
  public selectedPlanState: PlanState;
  public planListTitle: string;
  public planListDescription: string;
  public isReturnUser: boolean;
  public previousSelections: WizardPreviousSelections;

  private storage: StorageUtilities = new StorageUtilities();
  private routeUtilities = new RouteUtilities();
  private subscriptions: SubscriptionManager = new SubscriptionManager();
  private isPlanListScreen: boolean;

  constructor(
    public media: MediaObserver,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    @Inject(DOCUMENT) private document: Document,
    private sheetRef: MatBottomSheetRef<NetworkSelectionWizardComponent>,
    public networkSelectWizardService: NetworkSelectionWizardService,
  ) {}

  ngOnInit(): void {
    this.checkIfReturnUser();
    this.disableBodyScroll();
    this.pageHistory = this.storage.sessionStorageGet('wizardPageHistory') || [];
    this.pageTransition = this.storage.sessionStorageGet('wizardCurrentPage') || {
      ...this.setInitialPage(),
    };
    this.plans = this.storage.sessionStorageGet('wizardPlans');
    this.planStates = this.storage.sessionStorageGet('wizardPlanStates');
    this.selectedPlanState = this.storage.sessionStorageGet('wizardSelectedPlanState');
    this.planListTitle = this.storage.sessionStorageGet('wizardPlanTitleConfig')?.title;
    this.planListDescription = this.storage.sessionStorageGet('wizardPlanTitleConfig')?.desc;
    this.selectedMarketSegment = this.storage.sessionStorageGet('wizardMarketSegment');
    this.selectedSubMarketSegment = this.storage.sessionStorageGet('wizardSubMarketSegment');
  }

  ngOnDestroy() {
    this.enableBodyScroll();
    this.subscriptions.destroy();
  }

  public closeOverlay(): void {
    this.sheetRef.dismiss();
  }

  public onGoToOverlayPage(transition: PageTransition) {
    this.isPlanListScreen = this.storage.sessionStorageGet('wizardPlanListPage');
    this.pageTransition.direction = transition.direction || 'forward';
    // Force template to apply new animation state before changing page
    this.changeDetectorRef.detectChanges();
    !this.isPlanListScreen && this.pageHistory.push(this.pageTransition.page);
    this.pageTransition.page = transition.page;

    this.storage.sessionStorageSet('wizardCurrentPage', this.pageTransition);
    this.storage.sessionStorageSet('wizardPageHistory', this.pageHistory);
  }

  public onGoBack(): void {
    this.pageTransition.direction = 'back';
    this.changeDetectorRef.detectChanges();
    this.pageTransition.page = this.pageHistory.pop();

    this.storage.sessionStorageSet('wizardCurrentPage', this.pageTransition);
    this.storage.sessionStorageSet('wizardPageHistory', this.pageHistory);
  }

  public showBackButton(): boolean {
    return !(this.pageTransition.page === this.initialPage.page);
  }

  public handleLocationSubmit(): void {
    this.fetchPlans();
    this.onGoToOverlayPage({ page: 'plan-type-selection' });
  }

  public handleModifyLocation(): void {
    this.networkSelectWizardService.setSelections();
    this.storage.sessionStorageSet('hasSetWizardSelections', true)
    this.closeOverlay();
  }

  public handleMarketSegment(segment: MarketSegment): void {
    this.selectedMarketSegment = segment;
    this.storage.sessionStorageSet('wizardMarketSegment', this.selectedMarketSegment);
    segment === MarketSegment.medicare ?
      this.onGoToOverlayPage({ page: MarketSegment.medicare }) :
      this.fetchPlans({ market_segment: segment });
  }

  public handleSubMarketSegment(segment: SubMarketSegment): void {
    this.selectedSubMarketSegment = segment;
    this.storage.sessionStorageSet('wizardSubMarketSegment', this.selectedSubMarketSegment);
    this.fetchPlans({ sub_market_segment: segment });
  }

  public handlePlanStateSelect(planState: PlanState): void {
    this.selectedPlanState = planState;
    this.storage.sessionStorageSet('wizardSelectedPlanState', this.selectedPlanState);

    this.fetchPlans({
      market_segment: MarketSegment.employer,
      state_code: this.selectedPlanState.state_code
    }, false);
  }

  public handleProductType(productType: ProductType): void {
    const isMedicareFlow = this.selectedMarketSegment === MarketSegment.medicare;
    const isEmployerFlow = this.selectedMarketSegment === MarketSegment.employer;
    let params: any;

    switch (true) {
      case isMedicareFlow:
        params = this.getMedicareParams;
        break;
      case isEmployerFlow:
        params = this.getEmployerParams;
        break;
      default:
        params = this.getParams;
    }

    const requestParams = {
      ...params,
      ...(productType !== ProductType.all && { product_type: productType })
    }

    this.selectedProductType = productType;
    this.storage.sessionStorageSet('wizardSelectedProductType', this.selectedProductType);
    this.fetchPlans(requestParams, false);
  }

  get getParams() {
    return { market_segment: this.selectedMarketSegment };
  }

  get getMedicareParams() {
    return { sub_market_segment: this.selectedSubMarketSegment };
  }

  get getEmployerParams() {
    return { market_segment: this.selectedMarketSegment, state_code: this.selectedPlanState.state_code };
  }

  public fetchPlans(params?: any, isEntry: boolean = true): void {
    const corpCode = this.routeUtilities.getParamFromUrl('corp_code');
    const locale = this.routeUtilities.getParamFromUrl('locale');
    const fetchingAllPlans = !params;

    this.subscriptions.add(
      this.networkSelectWizardService
        .getPlans({
          corp_code: corpCode,
          locale: locale,
          ...params
        })
        .subscribe((plans: UuxNetwork[]) =>
          fetchingAllPlans
            ? this.checkForSegmentsWithoutPlans(plans)
            : this.setPlans(plans, isEntry))
    );
  }

  private setPlans(plans: UuxNetwork[], isEntry: boolean = true) {
    const planProductTypes: Set<ProductType> = this.getUniqueProductTypes(plans);
    const isEmployerFlow = this.selectedMarketSegment === MarketSegment.employer;
    const onlyContainsHMOandPPO = planProductTypes.size === 2 && this.hasHMOAndPPOProductTypes(planProductTypes);

    if (isEntry && isEmployerFlow) {
      this.handleEmployerPlans(plans);
      return;
    }

    if (onlyContainsHMOandPPO) {
      this.onGoToOverlayPage({ page: 'product-type-selection' });
    } else {
      if (planProductTypes.size > 1) {
        this.selectedProductType = ProductType.all;
        this.storage.sessionStorageSet('wizardSelectedProductType', this.selectedProductType);
      }
      this.plans = plans;
      this.storage.sessionStorageSet('wizardPlans', this.plans);
      this.setPlanListTitles();
      this.onGoToOverlayPage({ page: 'plan-list-selection' });
    }
  }

  private handleEmployerPlans(plans: UuxNetwork[]): void {
    this.planStates = this.getStatesFromPlans(plans);
    this.storage.sessionStorageSet('wizardPlanStates', this.planStates);
    this.onGoToOverlayPage({ page: MarketSegment.employer });
  }

  private setPlanListTitles() {
    const productType: ProductType = this.getUniqueProductTypes(this.plans).size > 1 ? ProductType.all : this.plans[0].product_type as ProductType;
    const { title, description } =
      this.getPlanListTitleConfig(
        this.selectedMarketSegment,
        productType,
        this.selectedSubMarketSegment,
      );
    this.planListTitle = title;
    this.planListDescription = description;
    this.storage.sessionStorageSet('wizardPlanTitleConfig', { title: this.planListTitle, desc: this.planListDescription });
  }

  private getPlanListTitleConfig = (
    segment: MarketSegment,
    type: ProductType,
    subSegment: SubMarketSegment,
  ): TitleConfig => {
    let titleConfig: any;

    switch (true) {
      case type === ProductType.all:
        titleConfig = planListTitleConfig.default;
        break;
      case segment === MarketSegment.medicare:
        titleConfig = planListTitleConfig[MarketSegment.medicare][subSegment]?.[type];
        break;
      case segment === MarketSegment.employer:
        titleConfig = planListTitleConfig[segment]?.[type]
        break;
      default:
        titleConfig = planListTitleConfig[segment];
    }

    return titleConfig;
  };
  private getStatesFromPlans(uuxNetworks: UuxNetwork[]): PlanState[] {
    const corpCode = this.routeUtilities.getParamFromUrl('corp_code');
    return flow([
      // Extract state details from UUX Network list
      (plans: UuxNetwork[]) => plans.map((plan) => ({state_name: plan.state_name, state_code: plan.state_code})),
      // Uniqify state list by code
      (states: PlanState[]) => uniqBy(states, 'state_code'),
      // Sort state list by name
      (states: PlanState[]) => sortBy(states, 'state_name'),
      // Find state element that matches the corp_code
      (states: PlanState[]) => ({
        states: states,
        corpCodeState: find(states, { state_code: corpCode })
      }),
      // Remove state element that matches the corp_code (if it exists)
      ({states, corpCodeState}) => ({
        states: corpCodeState ? without(states, corpCodeState) : states,
        corpCodeState: corpCodeState
      }),
      // Append state element that matches the corp_code to beginning of array (if it exists)
      ({states, corpCodeState}) => [...(corpCodeState ? [corpCodeState] : []), ...states]
    ])(uuxNetworks);
  }

  private hasHMOAndPPOProductTypes(productTypes: Set<ProductType>) {
    return (
      productTypes.has(ProductType.hmo) &&
      productTypes.has(ProductType.ppo)
    )
  }

  private getUniqueProductTypes(plans: UuxNetwork[]) {
    return new Set<ProductType>(plans.map(p => p.product_type as ProductType));
  }

  private checkForSegmentsWithoutPlans(plans: UuxNetwork[]): void {
    const marketSegmentList = Object.values(MarketSegment);
    const planSegments = map(plans, 'market_segment');
    const segmentsWithoutPlans = difference(marketSegmentList, planSegments) as MarketSegment[];
    this.storage.sessionStorageSet('marketSegmentsWithoutPlans', segmentsWithoutPlans);
    this.storage.localStorageSet('allPlans', plans);
  }

  private checkIfReturnUser(): void {
    this.isReturnUser = this.networkSelectWizardService.isReturnUser;

    if (this.isReturnUser) {
      this.setPreviousSelections();
    }
  }

  private setPreviousSelections(): void {
    const savedLocation= this.networkSelectWizardService.getSavedLocation();
    const savedPlan = this.networkSelectWizardService.getSavedPlan();
    this.previousSelections = { location: new Place(savedLocation).toString(), plan: savedPlan.displayName };
    this.setInitialPage();
  }

  private setInitialPage(): PageTransition {
    let initialPage: string;

    if (this.data.triggerFromDropdown) {
      initialPage = 'plan-type-selection'
    } else if (this.isReturnUser) {
      initialPage = 'confirm-previous-selections'
    } else {
      initialPage = 'location-of-care'
    }

    return this.initialPage = { page: initialPage, direction: 'none' }
  }

  private disableBodyScroll(): void {
    this.getBodyElement().classList.add('no-scroll');
  }

  private enableBodyScroll(): void {
    this.getBodyElement().classList.remove('no-scroll');
  }

  private getBodyElement(): HTMLBodyElement {
    return this.document.getElementsByTagName('body')[0];
  }
}
