import { concatMap, debounceTime, filter, first, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { MleService } from '../mle.service';
import { AuthService } from '../auth.service';
import { MembersService } from '../members.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { RouteUtilities } from '../../utilities/route.utilities';
import { LnpService } from '../lnp/lnp.service';
import { ConfigurationService } from '../configuration.service';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';

interface PageLoadedEvent {
  state: string;
  params: any;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerDataLayerService {
  private routeUtilities = new RouteUtilities();
  private lastRouterEvent = null;

  constructor(
    private authService: AuthService,
    private mleService: MleService,
    private membersService: MembersService,
    private gtmService: GoogleTagManagerService,
    private router: Router,
    private lnpService: LnpService,
    private configurationService: ConfigurationService,
    private criticalParamsService: CriticalParamsService
  ) {}

  public initialize(): void {
    this.authService.authStatus.subscribe((auth) =>
      this.setAuthToDataLayer(auth)
    );
    this.authService.msaAuthStatus.subscribe((msa) =>
      this.setMSAAuthToDataLayer(msa)
    );
    this.mleService.status.subscribe((mleStatus) =>
      this.setMLEToDataLayer(mleStatus)
    );
    this.membersService.incentivesEnabled.subscribe((incentivesEnabled) =>
      this.setIncentivesEnabled(incentivesEnabled)
    );
    this.configurationService.account_id.subscribe(() =>
      this.setAccountIdToDataLayer()
    );
    this.listenForAppLoad();
  }

  public listenForAppLoad(): Subscription {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(500),
        take(1),
        concatMap(() =>
          this.criticalParamsService.criticalParamsSubject.pipe(
            first((criticalParams) => {
              return (
                !!criticalParams.ci &&
                this.criticalParamsAreSet(
                  this.routeUtilities.getParamsFromUrl()
                )
              );
            })
          )
        )
      )
      .subscribe(() => {
        const href = window.location.href;
        const event = { url: href.substring(href.lastIndexOf('/')) };
        this.setPageView(this.setEvent(event));
        this.listenForPageViews();
      });
  }

  private setAuthToDataLayer(data): void {
    this.gtmService.pushToDataLayer({
      auth: data.auth_status,
    });
  }

  private setMSAAuthToDataLayer(data): void {
    this.gtmService.pushToDataLayer({
      msa: data,
    });
  }

  private setMLEToDataLayer(data): void {
    this.gtmService.pushToDataLayer(data);
  }

  private setIncentivesEnabled(data): void {
    this.gtmService.pushToDataLayer({
      incentives: data,
    });
  }

  private setAccountIdToDataLayer(): void {
    this.gtmService.pushToDataLayer({
      configuration_account_id: this.configurationService.account_id.getValue(),
    });
  }

  private listenForPageViews(): Subscription {
    return this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => this.setPageView(this.setEvent(event)));
  }

  private criticalParamsAreSet(params): boolean {
    return !['geo_location', 'network_id', 'ci', 'locale'].filter(
      (key) => !params[key]
    ).length;
  }

  private setEvent(event: any): PageLoadedEvent {
    return {
      state: event['url'].substring(0, event['url'].lastIndexOf('?')),
      params: this.routeUtilities.getParamsFromUrl(event['url']),
    };
  }

  private subsequentPageLoaded(event: PageLoadedEvent): boolean {
    return !!(
      event &&
      this.lastRouterEvent &&
      event.state !== this.lastRouterEvent.state
    );
  }

  private pageLoaded(event: PageLoadedEvent): boolean {
    return !!(!this.lastRouterEvent || this.subsequentPageLoaded(event));
  }

  private setPageView(event: PageLoadedEvent): void {
    if (this.pageLoaded(event)) {
      this.lnpService.appLoadedEvent
        .pipe(filter((loaded) => !!loaded))
        .subscribe(() =>
          this.gtmService.pushToDataLayer({ event: 'gtm.pageview' })
        );
    }
    this.lastRouterEvent = event;
  }
}
