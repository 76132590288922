import { MapSvgIcon } from './map-svg-icon.interface';

export const MapDefaultSvgIcons: MapSvgIcon[] = [
  {
    name: 'provider',
    width: '54px',
    height: '71px',
    filter: { provider_type: 'P', selected: false },
    url: 'map-provider-icon.svg',
    colors: [['#455A64', 'accent']],
    anchor: 'bottom',
    offset: [0, 4],
  },
  {
    name: 'provider-selected',
    width: '59px',
    height: '77px',
    filter: { provider_type: 'P', selected: true },
    url: 'map-provider-selected-icon.svg',
    colors: [['#455A64', 'accent']],
    anchor: 'bottom',
    offset: [0, 4],
  },
  {
    name: 'facility',
    width: '54px',
    height: '71px',
    filter: { provider_type: 'F', selected: false, incentive: false },
    url: 'map-facility-icon.svg',
    colors: [['#455A64', 'accent']],
    anchor: 'bottom',
    offset: [0, 4],
  },
  {
    name: 'facility-selected',
    width: '59px',
    height: '77px',
    filter: { provider_type: 'F', selected: true, incentive: false },
    url: 'map-facility-selected-icon.svg',
    colors: [['#455A64', 'accent']],
    anchor: 'bottom',
    offset: [0, 4],
  },
  {
    name: 'incentive',
    width: '54px',
    height: '71px',
    filter: { provider_type: 'F', selected: false, incentive: true },
    url: 'map-incentive-icon.svg',
    colors: [['#449c4d', 'incentive']],
    anchor: 'bottom',
    offset: [0, 4],
  },
  {
    name: 'incentive-selected',
    width: '59px',
    height: '77px',
    filter: { provider_type: 'F', selected: true, incentive: true },
    url: 'map-incentive-selected-icon.svg',
    colors: [['#449c4d', 'incentive']],
    anchor: 'bottom',
    offset: [0, 4],
  },
];
