import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
export interface CardEmphasis {
  // TODO: implement all possible emphasis positions and colors
  // position: 'left' | 'right' | 'top' | 'bottom';
  position: 'top' | 'left';
  // TODO: Come up with a way to validate palette names across the whole monorepo
  // like we do with icon names, widen to any string for now to allow all palettes
  // to be supported.
  color: string;
}

@Component({
  selector: 'zelis-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @HostBinding() class = 'zelis-dls';

  @Input() stroke = true;
  @Input() elevated = false;

  // Optional visual emphasis controls for the card
  @Input() emphasis: CardEmphasis | undefined;

  // Optional inputs for when the desired behavior is that clicking anywhere on the card triggers navigation
  @Input() href: HTMLAnchorElement['href'] | undefined;
  @Input() target: HTMLAnchorElement['target'] = '_self';

  @Input() disableHover = false;
  hovered = false;

  @Input() oveflowHidden = true;

  /**
   * Because we need to dynamically construct the background color being used, we cannot leverage tailwind
   * classes here. This is because the JIT compiler in tailwind would never be able to statically discover
   * the usage of the background classes (because they are only known at runtime once an emphasis has been
   * applied), and this would therefore potentially not work because the css class would have never been
   * generated by tailwind.
   *
   * We therefore instead access the underlying css variables directly and construct the appropriate background
   * color using ngStyle.
   */
  get ngStyleExpr(): {
    [klass: string]: any;
  } | null {
    if (!this.emphasis) {
      return null;
    }
    return {
      'background-color': `rgb(var(--palette-m3-${this.emphasis.color}-40))`,
    };
  }
}
