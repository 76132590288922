import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { first } from 'rxjs/operators';
import { TranslationsSettingsService } from '@services/translations/translations.settings.service';

@Injectable()
export class TranslationResolver implements Resolve<any> {
  constructor(
    private translationsSettingService: TranslationsSettingsService
  ) {}

  resolve(): Promise<void> {
    return new Promise((resolve) => {
      this.translationsSettingService.translationsResolved
        .pipe(first((resolved) => resolved))
        .subscribe(() => resolve());
    });
  }
}
