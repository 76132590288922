import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { billingCodeAdapter, BillingCodeState } from './billing-code.state';

const { selectAll, selectEntities } = billingCodeAdapter.getSelectors();

export const billingCodeSelectAll = selectAll;
export const billingCodeSelectOne = select;
export const billingCodeSelectEntities = selectEntities;

const selectBillingCodesState =
  createFeatureSelector<BillingCodeState>('billing-codes');

export const selectBillingCodesAutocomplete = createSelector(
  selectBillingCodesState,
  billingCodeSelectAll
)

export const selectBillingCodesMeta = createSelector(
  selectBillingCodesState,
  (state) => state.meta
)

export const selectBillingCodesLoading = createSelector(
  selectBillingCodesState,
  (state) => state.loading
)

export const selectBillingCodeEntities = createSelector(
  selectBillingCodesState,
  billingCodeSelectEntities
);

export const selectSelectedBillingCodeObject = createSelector(
  selectBillingCodesState,
  (state) => state.selectedBillingCode
);

export const selectBillingCodeBenefitLimits = createSelector(
  selectBillingCodesState,
  (state) => state.billingCodeBenefitLimits
);

export const selectBillingCodePreRequisite = createSelector(
  selectBillingCodesState,
  (state) => state.billingCodePreRequisite
);

export const selectBillingCodeOON = createSelector(
  selectBillingCodesState,
  (state) => state.billingCodeOON
);
