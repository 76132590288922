import { Injectable } from '@angular/core';
import { FeaturesService } from '../services/features/features.service';
import { Resolve } from '@angular/router';

@Injectable()
export class FeaturesResolver implements Resolve<any> {
  constructor(private featuresService: FeaturesService) {}

  resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.featuresService.getFeatureFlags().subscribe((features) => {
        resolve(true);
      });
    });
  }
}
