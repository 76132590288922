import { FacetGeo } from '@interfaces/facet-geo.model';
import { SearchFilter } from '@interfaces/search-filter.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectFacetGeoOptions } from '../settings-filters/settings-filters.selectors';

export const selectRadiusFiltersState =
  createFeatureSelector<SearchFilter>('radius_filters');

export const selectRadiusFilters = createSelector(
  selectRadiusFiltersState,
  (state: SearchFilter) => {
    return state;
  }
);

export const selectDefaultRadiusFilter = createSelector(
  selectRadiusFiltersState,
  (state: SearchFilter) => {
    return state.default;
  }
);
export const selectDefaultRadiusFilterRates = createSelector(
  selectRadiusFiltersState,
  (state: SearchFilter) => {
    if (!state['loading']) {
      return state.default.rates;
    }
    return null;
  }
);

export const selectValidRadiusOptions = createSelector(
  selectRadiusFiltersState,
  selectFacetGeoOptions,
  (state: SearchFilter, facetGeoFilters: FacetGeo[]) => {
    if (!state['loading'] && facetGeoFilters) {
      return (state.options || []).filter((option) =>
        facetGeoFilters.find((facet) => `${facet.radius}` === option.value)
      );
    }
    return null;
  }
);
