import { HttpParams } from '@angular/common/http';
import { SearchFilter } from '@interfaces/search-filter.model';
import * as _ from 'lodash';
import { SearchFiltersFacetQuery } from '@interfaces/search-filters-facet-query.model';
import { Injectable } from '@angular/core';
import { SearchFiltersSettings } from './search-filters-settings.class';
import { WindowService } from '@services/window.service';

@Injectable({
  providedIn: 'root',
})
export class SearchFacetParamsService {
  constructor(
    private searchFiltersSettings: SearchFiltersSettings,
    private windowService: WindowService
  ) {}

  public calculate(
    initialParams: HttpParams,
    facetsRequest: boolean
  ): HttpParams {
    return this.searchFiltersSettings.searchFilters
      .getValue()
      .reduce((params: HttpParams, filter: SearchFilter) => {
        // Top-level filters
        if (facetsRequest) {
          if (filter.facet && !filter.disabled) {
            params = this.addFacetParams(filter, params);
          }

          // Nested filters
          _.each(
            filter.items,
            (item) => (params = this.addFacetParams(item, params))
          );
        }

        return params;
      }, initialParams);
  }

  /**
   *  @description Replace facet[tiers:key]=value with facet[tiers_facetable:key]=value for API to handle tiers faceting properly.
   *  @param params: HttpParams
   *  @returns HttpParams
   */
  public updateTiersFacetableParam(params: HttpParams): HttpParams {
    params.keys().forEach((key: string) => {
      if (key.indexOf('facet[tiers:') !== -1) {
        const newKey = key.replace('facet[tiers:', 'facet[tiers_facetable:');
        params = params.set(newKey, params.get(key));
        params = params.delete(key);
      }
    });
    return params;
  }

  /**
   *  @description Replace facet[tiers_facetable:key] property with facet[tiers:key] to compensate
   *  for the way the API handles tiers facet indexing.
   *  @param facets: any
   *  @returns any
   */
  public updateTiersFacetableProperty(facets: any): any {
    _.each(facets, (value: any, key: string) => {
      if (key.indexOf('age_limitations:') !== -1) {
        if (typeof facets['age_limitations'] === 'undefined') {
          facets['age_limitations'] = [];
        }
        const newName = key.split(':')[1];
        facets['age_limitations'].push({
          name: newName,
          value: newName,
          count: value[0].count,
        });
        delete facets[key];
      }
      if (key.indexOf('tiers_facetable:') !== -1) {
        const newKey = key.replace('tiers_facetable:', 'tiers:');
        facets[newKey] = value;
        delete facets[key];
      }
    });
    return facets;
  }

  /**
   *  @description Group facet request params into one request
   *  @param initialParams: HttpParams
   *  @param searchFilters: SearchFilter[]
   *  @returns HttpParams
   */
  public getFacetRequestParams(
    initialParams: HttpParams,
    searchFilters: SearchFilter[]
  ): HttpParams {
    initialParams = this.removeFacetParams(initialParams);
    let combinedParams: HttpParams = initialParams;

    searchFilters.forEach((filter: SearchFilter) => {
      // Top-level filters
      if (filter.facet && !filter.disabled) {
        combinedParams = this.addFacetParams(filter, combinedParams);
      }
      // Nested filters
      if (filter.items && filter.items.length > 0) {
        filter.items.forEach((item) => {
          combinedParams = this.addFacetParams(item, combinedParams);
        });
      }
    });
    return combinedParams;
  }

  private addFacetParams(filter: SearchFilter, params: HttpParams): HttpParams {
    params = _.clone(params);
    const facetQueries = filter.facet_queries;
    if (facetQueries) {
      params = this.addFacetParamsForQueries(params, facetQueries);
    } else if (filter.facet && !filter.disabled) {
      params = params.set(`facet[${filter.facet}]`, 'true');
    } else {
      return null;
    }
    // search specialties searches always include the search_specialty_id
    const url_pieces = this.windowService['location'].href.split('/');
    if (
      url_pieces[4] === 'search_specialties' &&
      !params.has('search_specialty_id')
    ) {
      params = params.set('search_specialty_id', url_pieces[5]);
    }
    return params;
  }

  private addFacetParamsForQueries(
    params: HttpParams,
    facetQueries: SearchFiltersFacetQuery[]
  ): HttpParams {
    _.each(facetQueries, (fq: SearchFiltersFacetQuery) => {
      params = params.set(`facet[${fq.facetQuery || fq['string']}]`, fq.value);
    });

    return params;
  }

  private removeFacetParams(params: HttpParams): HttpParams {
    params.keys().forEach((key: string) => {
      if (key.indexOf('facet[') !== -1) {
        params = params.delete(key);
      }
    });
    return params;
  }
}
