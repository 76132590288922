import { SearchMethods } from '@enums/search-methods.enum';
import { createAction, props } from '@ngrx/store';

export enum searchActionTypes {
	setSearchStateMethod = '[SET] Set Search Method',
  setFacilitySuppressWriteReview = '[SET] Set FacilitySuppressWriteReview Client Setting'
}

export const setSearchMethodAction = createAction(
  searchActionTypes.setSearchStateMethod,
  props<{ searchMethod: SearchMethods }>()
);

export const setFacilitySuppressWriteReviewAction = createAction(
  searchActionTypes.setFacilitySuppressWriteReview,
  props<{ facilitySuppressWriteReview: boolean }>()
);
