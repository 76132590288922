import { Action, createReducer, on } from '@ngrx/store';
import * as SettingsFilterActions from './settings-filters.actions';
import { SettingsFiltersState, initialState } from './settings-filters.state';

export const searchFiltersFromConfigSettingsReducer = createReducer(
  initialState,
  on(SettingsFilterActions.requestSettingsSearchFiltersFromConfig, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SettingsFilterActions.requestSettingsSearchFiltersFromConfigSuccess,
    (state, { settings_filters }): any => { 
      const locationGeoFilter = state.filters.filter(filter => filter.facet === 'location_geo');
      return {
        filters: [...settings_filters.filters, ...locationGeoFilter],
        loading: false,
        error: null,
      };
    }
  ),
  on(
    SettingsFilterActions.requestSettingsSearchFiltersFromConfigFailure,
    (state, { error }) => ({
      ...state,
      error: error.message,
      loading: false,
    })
  ),
  on(
    SettingsFilterActions.setSearchFiltersWithFacetData,
    (state, { appliedFilters }) => ({
      ...state,
      appliedFilters: appliedFilters,
      loading: false,
      error: null,
    })
  ),
  on(SettingsFilterActions.requestFacets, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SettingsFilterActions.requestFacetsFailure, (state, { error }) => ({
    ...state,
    error: error.message,
    loading: false,
  })),
  on(
    SettingsFilterActions.setSearchFiltersWithLocationGeoFacet,
    (state, { location_geo_filter }) => {
      const filteredFilters = state.filters.filter(filter => filter.facet !== 'location_geo');
      return {
        ...state,
        filters: [...filteredFilters, location_geo_filter],
      };
    }
  )
);

export const reducer = (state: SettingsFiltersState, action: Action) => {
  return searchFiltersFromConfigSettingsReducer(state, action);
};
