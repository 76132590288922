import { HttpMockConfigIgnoredQueryParam } from '@interfaces/http-mock-config-ignored-query-param.interface';

export class StringifyParsedUrlOptions {
  public fullyQualified?: boolean;
  public includeSearch?: boolean;
  public excludeQueryParams?: HttpMockConfigIgnoredQueryParam[];

  constructor(obj?: StringifyParsedUrlOptions) {
    obj = obj || {};
    this.fullyQualified = obj.fullyQualified !== false;
    this.includeSearch = obj.includeSearch !== false;
    this.excludeQueryParams = obj.excludeQueryParams || null;
  }
}
