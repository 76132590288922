import { of, Observable, Subject, forkJoin } from 'rxjs';
import { MapLngLat } from './map-lng-lat.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, NgZone } from '@angular/core';
import {
  MapColors,
  MapSvgIcon,
  MapFitBounds,
  MapMoveTo,
} from './map.interfaces';
import { FeatureCollection, Feature, Point } from 'geojson';
import { catchError, map } from 'rxjs/operators';
import {
  Map,
  GeolocateControl,
  LngLat,
  LngLatBounds,
  FitBoundsOptions,
} from 'mapbox-gl';
import * as MapboxGl from 'mapbox-gl';

@Injectable()
export class MapService {
  public mapboxgl = MapboxGl;
  public map: Map;
  public hasGeoLocationSupport: boolean;
  public geolocateControl: GeolocateControl;
  public geolocationState = 'unavailable';
  public geoJson: FeatureCollection<Point>;
  public geoJsonBounds: LngLatBounds;
  public geoJsonBoundsOptions: FitBoundsOptions;
  public subjectFitBounds: Subject<MapFitBounds> = new Subject();
  public subjectMoveTo: Subject<MapMoveTo> = new Subject();
  public subjectMapType: Subject<string> = new Subject();
  public subjectFeatureSelect: Subject<number> = new Subject();
  public mapTypes = {
    street: 'mapbox://styles/mapbox/streets-v9',
    satellite: 'mapbox://styles/mapbox/satellite-streets-v9',
  };
  public selectedFeature: Feature<Point>;

  constructor(private http: HttpClient, private zone: NgZone) {}

  /**********************************************
   * Public Methods
   **********************************************/

  /**
   * Get browser support (WebGL, etc.)
   * @returns boolean
   */
  public getBrowserSupport(): boolean {
    return this.mapboxgl.supported({ failIfMajorPerformanceCaveat: false });
  }

  /**
   * Set map reference to service
   * @param map Mapbox GL Map reference
   */
  public setMap(mapRef: Map): void {
    this.map = mapRef;
  }

  /**
   * Set GeoJSON data and options to service and calculate bounds
   * @param geoJson GeoJSON feature collection
   * @param options Mapbox fit bounds options
   * @returns LngLatBounds
   */
  public setGeoJsonAndBounds(
    geoJson: FeatureCollection<Point>,
    options: FitBoundsOptions
  ): LngLatBounds {
    this.geoJson = geoJson;
    this.geoJsonBounds = undefined;
    if (geoJson && geoJson.features && geoJson.features.length > 0) {
      this.geoJsonBounds = new LngLatBounds();
      geoJson.features.forEach((feature: Feature<Point>) => {
        this.geoJsonBounds.extend(
          new LngLat(
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1]
          )
        );
      });
    }
    this.geoJsonBoundsOptions = options;
    return this.geoJsonBounds;
  }

  /**
   * Prepare icons for map use
   * @param icons Array of SVG icon configs
   * @param colors Colors to use
   * @param assetPath Path to assets once compiled
   * @returns Observable, resolves when all icons have been loaded and encoded. Self-completing subscription.
   */
  public prepareIcons(
    icons: MapSvgIcon[],
    colors: MapColors,
    assetPath: string = ''
  ): Observable<MapSvgIcon[]> {
    colors = new MapColors(colors);
    this.generateColorCss(colors);
    const observables: Observable<MapSvgIcon>[] = [];
    icons.forEach((icon) =>
      observables.push(this.prepareIcon(icon, colors, assetPath))
    );
    return forkJoin(observables);
  }

  /**
   * Prepare an icon for map use
   * @param icon SVG icon config
   * @param colors Colors to use
   * @param assetPath Path to assets once compiled
   * @returns Observable, resolves when icon has been loaded and encoded. Self-completing subscription.
   */
  public prepareIcon(
    icon: MapSvgIcon,
    colors: MapColors,
    assetPath: string
  ): Observable<MapSvgIcon> {
    icon = this.cleanIcon(icon);
    return this.loadSvgFile(icon, assetPath).pipe(
      map((svg) => {
        icon.svg = svg;
        this.prepareSvg(icon, colors);
        this.generateIconCss(icon);
        return icon;
      })
    );
  }

  /**
   * Get Geolocation support
   * Ported From: Mapbox Geolocation control
   * @param callback Called with a boolean after support has been determined
   */
  public getGeolocationSupport(callback): void {
    if (this.hasGeoLocationSupport !== undefined) {
      callback(this.hasGeoLocationSupport);
    } else if (window.navigator.permissions !== undefined) {
      // navigator.permissions has incomplete browser support
      // http://caniuse.com/#feat=permissions-api
      // Test for the case where a browser disables Geolocation because of an
      // insecure origin
      window.navigator.permissions.query({ name: 'geolocation' }).then((p) => {
        this.hasGeoLocationSupport = p.state !== 'denied';
        callback(this.hasGeoLocationSupport);
      });
    } else {
      this.hasGeoLocationSupport = !!window.navigator.geolocation;
      callback(this.hasGeoLocationSupport);
    }
  }

  /**
   * Get a cluster at a specific point
   * @param coords Point coordinates
   * @param offset Coordinate offset for generating bounding box. Default: 0.0001
   * @returns Cluster feature
   */
  public getClusterAtPoint(
    coords: number[],
    offset: number = 0.0001
  ): Feature<Point> {
    const features = this.map
      .querySourceFeatures('clusterSource')
      .filter(
        (feature: any) =>
          feature.properties.cluster &&
          this.pointIsNearCoordinates(
            feature.geometry.coordinates,
            coords,
            offset
          )
      );
    if (features && features[0]) {
      return features[0] as Feature<Point>;
    }
    return null;
  }

  /**
   * Gets the distance in miles between two GPS points using the Haversine formula rounded to 2 decimals
   * Ported from: Nathan Lippi: https://stackoverflow.com/questions/14560999/using-the-haversine-formula-in-javascript
   * @param point1 {lng, lat}
   * @param point2 {lng, lat}
   * @returns Distance in miles
   */
  public getDistance(point1: MapLngLat, point2: MapLngLat): number {
    const toRad = (x) => (x * Math.PI) / 180;
    const lon1 = point1.lng;
    const lat1 = point1.lat;
    const lon2 = point2.lng;
    const lat2 = point2.lat;
    const R = 6371; // Earth radius in km
    const x1 = lat2 - lat1;
    const dLat = toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = toRad(x2);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    d /= 1.60934; // To miles
    return Math.round(d * 100) / 100;
  }

  /**
   * Get feature from GeoJSON by property ID
   * @param geoJson GeoJSON feature collection
   * @param id Feature property ID
   * @returns Feature
   */
  public getFeature(geoJson: FeatureCollection, id: number): Feature<Point> {
    let selectedFeature: Feature<Point> = null;
    if (id) {
      for (const geoFeature of geoJson.features) {
        if (geoFeature.properties.id === id) {
          selectedFeature = geoFeature as Feature<Point>;
          break;
        }
      }
    }
    return selectedFeature;
  }

  /**
   * Mark a feature as selected and all others not
   * @param geoJson Feature collection
   * @param id Feature property ID to select
   * @returns Selected feature
   */
  public selectFeature(geoJson: FeatureCollection, id: number): Feature<Point> {
    geoJson.features.forEach((feature: Feature<Point>) => {
      if (feature.properties.id === id) {
        feature.properties.selected = true;
        this.selectedFeature = feature;
      } else {
        feature.properties.selected = false;
      }
    });

    return this.selectedFeature;
  }

  /**
   * Mark a feature from a cluster as selected
   * @param geoJson Feature collection
   * @param cluster Cluster feature
   * @param id Feature property ID to select
   * @returns Cluster feature
   */
  public selectClusterFeature(
    geoJson: FeatureCollection,
    cluster: Feature<Point>,
    id: number
  ): Feature<Point> {
    this.selectFeature(geoJson, id);
    return cluster;
  }

  /**
   * Deselect all features
   * @param geoJson GeoJSON reference
   * @returns True if a feature was deselected, else false
   */
  public deselectAllFeatures(geoJson: FeatureCollection): boolean {
    if (this.selectedFeature) {
      geoJson.features.forEach((geoFeature: Feature<Point>) => {
        geoFeature.properties.selected = false;
      });
      this.selectedFeature = null;
      return true;
    }
    return false;
  }

  /**
   * Add a geolocation control to the Mapbox map and setup event listeners
   * @param event Reference to the event emitter
   */
  public addGeolocateControl(event: EventEmitter<string>): void {
    this.getGeolocationSupport((support) => {
      if (support) {
        this.geolocateControl = new GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        });
        this.zone.runOutsideAngular(() => {
          this.map.addControl(this.geolocateControl, 'top-right');
        });
        this.geolocationState = 'inactive';
        event.emit(this.geolocationState);
        this.geolocateControl.on('trackuserlocationstart', () => {
          if (
            this.geolocationState === 'unavailable' ||
            this.geolocationState === 'inactive'
          ) {
            this.geolocationState = 'starting';
          } else {
            this.geolocationState = 'active';
          }
          event.emit(this.geolocationState);
        });
        this.geolocateControl.on('trackuserlocationend', (track: any) => {
          this.geolocationState =
            track.target._watchState === 'OFF' ? 'inactive' : 'background';
          event.emit(this.geolocationState);
        });
        this.geolocateControl.on('geolocate', () => {
          this.geolocationState = 'active';
          event.emit(this.geolocationState);
        });
        this.geolocateControl.on('error', () => {
          this.geolocationState = 'unavailable';
          event.emit(this.geolocationState);
        });
      } else {
        this.geolocationState = 'unavailable';
        event.emit(this.geolocationState);
      }
    });
  }

  /**
   * Map Interaction Controls
   */

  /**
   * Trigger Geolocation control
   */
  public controlGeolocate(): void {
    if (this.map && this.geolocateControl) {
      this.geolocateControl.trigger();
    }
  }

  /**
   * Move the map camera to new coordinates and/or zoom level
   * @param center Optional: LngLat coordinates
   * @param zoom Optional: Zoom level
   * @param animate Animate movement. Default: true
   */
  public controlMoveTo(
    center?: LngLat,
    zoom?: number,
    animate: boolean = true
  ): void {
    const moveTo: MapMoveTo = {
      animate: animate,
    };
    if (center) {
      moveTo.center = center;
    }
    if (zoom) {
      moveTo.zoom = zoom;
    }
    this.subjectMoveTo.next(moveTo);
  }

  /**
   * Zoom the map in one level
   */
  public controlZoomIn(): void {
    if (!this.map) {
      return;
    }
    const maxZoom = this.map.getMaxZoom();
    const zoom = this.map.getZoom();
    let next = zoom + 1;
    if (next > maxZoom) {
      next = maxZoom;
    }
    this.controlMoveTo(undefined, next, true);
  }

  /**
   * Zoom the map out one level
   */
  public controlZoomOut(): void {
    if (!this.map) {
      return;
    }
    const minZoom = this.map.getMinZoom();
    const zoom = this.map.getZoom();
    let next = zoom - 1;
    if (next < minZoom) {
      next = minZoom;
    }
    this.controlMoveTo(undefined, next, true);
  }

  /**
   * Recenter the map on the current GeoJSON bounds
   * @param animate Animate movement. Default: true
   */
  public controlRecenter(animate: boolean = true): void {
    if (this.geoJsonBounds && !this.geoJsonBounds.isEmpty()) {
      const bounds = new LngLatBounds().extend(this.geoJsonBounds);
      this.subjectFitBounds.next({
        bounds: bounds,
        animate: animate,
      });
    }
  }

  /**
   * Select a feature (marker) and zoom into it
   * @param id Feature property ID
   */
  public controlSelectFeature(id: number): void {
    this.subjectFeatureSelect.next(id);
  }

  /**
   * Set map type
   * @param type Map type (street, satellite)
   */
  public controlSetMapType(type: string): void {
    const style = this.mapTypes[type];
    if (style) {
      this.subjectMapType.next(style);
    }
  }

  /**********************************************
   * Private Methods
   **********************************************/

  /**
   * Create a new SVG icon
   * @param icon SVG icon config
   */
  private cleanIcon(icon: MapSvgIcon): MapSvgIcon {
    const clean: MapSvgIcon = {
      name: icon.name,
      width: icon.width,
      height: icon.height,
      filter: icon.filter,
      colors: icon.colors || null,
      anchor: icon.anchor || 'center',
      offset: icon.offset || [0, 0],
      url: icon.url || '',
      svg: icon.svg || '',
    };
    return clean;
  }

  /**
   * Load SVG icon from the server
   * @param icon SVG icon config
   * @param assetPath Map asset path after compile
   */
  private loadSvgFile(icon: MapSvgIcon, assetPath: string): Observable<string> {
    if (icon.url) {
      return this.http
        .get(assetPath + icon.url, { responseType: 'text' })
        .pipe(catchError(() => of('')));
    }
    return of('');
  }

  /**
   * Replace SVG colors and base64 encode SVG
   * @param icon SVG icon config
   * @param colors Colors config
   */
  private prepareSvg(icon: MapSvgIcon, colors: MapColors): void {
    if (icon.svg) {
      const dataType = 'data:image/svg+xml;base64,';
      if (icon.colors && icon.colors.length) {
        icon.colors.forEach((color) => {
          icon.svg = icon.svg.replace(
            new RegExp(color[0], 'gi'),
            colors[color[1]] || color[1]
          );
        });
      }
      icon.svg = dataType + btoa(icon.svg);
    }
  }

  /**
   * Generate icon CSS. Background image used for symbol reuse and performance.
   * @param icon SVG icon config
   */
  private generateIconCss(icon: MapSvgIcon): any {
    const style = window.document.createElement('style');
    style.innerHTML = `
      platform-map .marker[data-type="${
        icon.filter.provider_type
      }"][data-incentive="${icon.filter.incentive ? 'true' : 'false'}"]${
      icon.filter.selected ? '.selected' : ''
    } {
        background-image: url('${icon.svg}');
        width: ${icon.width};
        height: ${icon.height};
        transform: translate(${icon.offset[0]}px, calc(-50% + ${
      icon.offset[1]
    }px));
      }
    `;
    window.document.body.appendChild(style);
  }

  /**
   * Generate colors CSS
   * @param colors Colors config
   */
  private generateColorCss(colors: MapColors): void {
    const style = window.document.createElement('style');
    style.innerHTML = `
      platform-map .primary-color {
        background-color: ${colors.primary};
      }
      platform-map .primary-color-background {
        background-color: ${colors.primary};
      }
      platform-map .primary-color-contrast {
        color: ${colors.primaryContrast};
      }
      platform-map .accent-color {
        background-color: ${colors.accent};
      }
      platform-map .accent-color-background {
        background-color: ${colors.accent};
      }
      platform-map .accent-color-contrast {
        color: ${colors.accentContrast};
      }
      platform-map .incentive-color {
        background-color: ${colors.incentive};
      }
      platform-map .incentive-color-background {
        background-color: ${colors.incentive};
      }
      platform-map .incentive-color-contrast {
        color: ${colors.incentiveContrast};
      }
    `;
    window.document.body.appendChild(style);
  }

  /**
   * Determine if a point is near to coordinates.
   * @param coords: number[] Point coordinates to check
   * @param center: number[] Point coordinates of center of bounding box
   * @param offset: number Offset from center to generate bounding box
   */
  private pointIsNearCoordinates(
    coords: number[],
    center: number[],
    offset: number
  ): boolean {
    const boundingBox: LngLatBounds = new LngLatBounds([
      new LngLat(center[0] - offset / 2, center[1] - offset / 2),
      new LngLat(center[0] + offset / 2, center[1] + offset / 2),
    ]);
    const point = new LngLat(coords[0], coords[1]);
    return boundingBox.contains(point);
  }
}
