<div
  *ngIf="provider"
  class="intended-provider-card pad all-3x text-left"
  data-cy="intended-provider.container"
>
  <div fxlayout="row" fxLayoutAlign="space-between">
    <div class="eyebrow margin bottom-1x">
      {{ 'bva_intended_provider' | translate }}
    </div>
    <div>
      <a (click)="changeProvider()" data-cy="intended-provider.change">{{
        'bva_change' | translate
      }}</a>
    </div>
  </div>
  <div class="margin bottom-2x provider-name">
    <a
      [routerLink]="
        navigationService.providerProfileLink(provider, 'procedure_id')
      "
      queryParamsHandling="preserve"
      >{{ provider.name }}</a
    >
  </div>
  <div *ngIf="hasCost()" class="column-cost">
    <div
      *ngIf="!getIncentiveAmount()"
      class="no-incentive-cost margin bottom-2x"
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="8px"
    >
      <h4 class="text-bold">{{ getCost().amount }}</h4>
      <div class="text-small">{{ getCost().translation | translate }}</div>
    </div>
    <div
      *ngIf="getIncentiveAmount()"
      class="incentive-container text-light margin bottom-2x"
      fxLayout="row"
      fxLayoutAlign="start stretch"
    >
      <div class="ss-reward" fxFlex="50">
        <h4 class="text-medium">{{ getIncentiveAmount() }}</h4>
        <div class="text-small text-color-light">
          {{ 'search_incentive_amount' | translate }}
        </div>
      </div>
      <div class="estimated-cost" fxFlex="50">
        <h4 class="text-medium">{{ getCost().amount }}</h4>
        <div class="text-small">{{ getCost().translation | translate }}</div>
      </div>
    </div>
    <div class="text-small margin bottom-2x">
      {{ 'bva_insurance_pays' | translate }} {{ getInsuranceCost() }}.
      {{ 'bva_total_cost' | translate }} {{ getTotalCost() }}.
    </div>
  </div>
  <div class="margin bottom-2x">
    <div class="location-address">
      {{ provider?.addr_line1 }}, {{ provider?.city }}, {{ provider?.state }}
      {{ provider?.postal_code }}
    </div>
    <div class="location-phone">
      {{ 'app_global_phone' | translate }}: {{ provider?.phone | phone }}
    </div>
  </div>
</div>
