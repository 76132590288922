import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringParseUtilities {
  public getBracketText = new RegExp(
    '(^\\(.*?\\))|(^\\[.*?\\])|(^\\{.*?\\})',
    'g'
  );
  public getDashesParenthesesText = new RegExp('(\\-)|(\\()|(\\))|(\\&)', 'g');
  public trimBeginningWhitespace = new RegExp('(^\\s+|\\s+$)', 'g');
  public trimSpaces = new RegExp('\\s', 'g');

  public escapeHtml(unsafe: string): string {
    return String(unsafe)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  public jsonParse(unparsed: string, onErrorValue: any = null): any {
    let parsed;
    try {
      parsed = JSON.parse(unparsed);
    } catch (e) {
      parsed = onErrorValue;
    }
    return parsed;
  }
}
