import { createReducer, on } from '@ngrx/store';
import { isEqual } from 'lodash';
import { AuthActions } from './actions';
import { initialState, AuthState } from './auth-state';

export const authReducer = createReducer<AuthState>(
  initialState,
  on(AuthActions.setAuthStatus, (state, { auth }): AuthState => {
    return isEqual(state, auth) ? state : auth;
  })
);
