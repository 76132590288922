import { SearchMethods } from '@enums/search-methods.enum';
import { SearchStoreState } from '@interfaces/search-state.interface';

export type SearchState = SearchStoreState;

export const initialState: SearchStoreState = {
  searchMethod: SearchMethods.DirectLink,
  searchClientSettings: {
    facilitySuppressWriteReview: false
  }
};
