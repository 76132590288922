export class MemberHsaBalance {
  type?: string;
  balance?: number;
  vendor_url?: string;
  vendor_name?: string;
  as_of_date?: string;

  constructor(hsa: MemberHsaBalance = {}) {
    this.type = hsa.type || null;
    this.balance = hsa.balance || 0;
    this.vendor_url = hsa.vendor_url || null;
    this.vendor_name = hsa.vendor_name || hsa['description'] || null;
    this.as_of_date = hsa.as_of_date || null;
  }
}
