import { Injectable } from '@angular/core';
import { Network } from '@interfaces/network.model';
import { SearchFilter } from '@interfaces/search-filter.model';
import { SortConfig } from '@interfaces/sort-config.model';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { NetworksService } from '@services/networks.service';
import { RadiusFiltersActions } from '@store/search-filters/radius-filters';
import { selectRadiusFilters } from '@store/search-filters/radius-filters/radius-filters.selectors';
import { SettingsFiltersActions } from '@store/search-filters/settings-filters';
import { selectSettingsFilters } from '@store/search-filters/settings-filters/settings-filters.selectors';
import { SortFiltersActions } from '@store/search-filters/sort-filters';
import { selectSortFilters } from '@store/search-filters/sort-filters/sort-filters.selectors';
import { combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  tap,
  map,
  debounceTime,
} from 'rxjs/operators';
import { AppState } from '@state/app.state';
import { SettingsFiltersState } from '@store/search-filters/settings-filters/settings-filters.state';
import { RadiusFiltersState } from '@store/search-filters/radius-filters/radius-filters.state';
import { SortFiltersState } from '@store/search-filters/sort-filters/sort-filters.state';

@Injectable({
  providedIn: 'root',
})
export class ConfigSearchFiltersService {
  constructor(
    private networksService: NetworksService,
    private store: Store<AppState>
  ) {}

  public listenToConfigSettingsFilters(): Observable<
    [Network, SearchFilter[], SearchFilter, SortConfig]
  > {
    return combineLatest([
      this.networksService.resolvedNetwork,
      this.store.select(selectSettingsFilters),
      this.store.select(selectRadiusFilters),
      this.store.select(selectSortFilters),
    ]).pipe(
      filter((filters) => !filters.some((f) => f['loading'])),
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      ),
      debounceTime(0),
      map(
        ([network, filtersState, radius, sort]: [
          Network,
          SettingsFiltersState,
          RadiusFiltersState,
          SortFiltersState
        ]) => [network, filtersState.filters, radius, sort]
      )
    );
  }

  public requestSettingsOnResolvedNetwork(): Observable<any> {
    return this.networksService.resolvedNetwork.pipe(
      tap(() => {
        this.dispatchRequest(
          SettingsFiltersActions.requestSettingsSearchFiltersFromConfig()
        ),
          this.dispatchRequest(
            SortFiltersActions.requestSortSearchFiltersFromConfig()
          ),
          this.dispatchRequest(
            RadiusFiltersActions.requestRadiusSearchFiltersFromConfig()
          );
      })
    );
  }

  private dispatchRequest(action: TypedAction<any>): void {
    this.store.dispatch(action);
  }
}
