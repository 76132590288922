import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { MediaChange, MediaObserver } from '@ngbracket/ngx-layout';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';

interface Breakpoint {
  mobile: any;
  desktop: any;
}

@Injectable({
  providedIn: 'root',
})
export class Breakpoints {
  public isMobile: boolean = false;
  public isDesktop: boolean = false;
  public isSmallMobile: boolean = false;
  public isLargeMobile: boolean = false;
  public isSmallDesktop: boolean = false;
  public isLargeDesktop: boolean = false;

  public profile: Breakpoint = {
    mobile: {
      navWidth: '0%',
      padWidth: '0%',
      containerWidth: '100%',
      componentsWidth: '100%',
    },
    desktop: {
      padWidth: '5%',
      navWidth: '200px',
      componentsWidth: '90%',
      containerWidth: '100%',
    },
  };

  public costCompareOverlay: Breakpoint = {
    mobile: {
      align: 'column',
      cardWidth: '90%',
    },
    desktop: {
      align: 'row',
      cardWidth: '30%',
    },
  };

  public search: Breakpoint = {
    mobile: {
      padWidth: '0',
      containerWidth: '100%',
    },
    desktop: {
      padWidth: '5%',
      containerWidth: '90%',
    },
  };

  public home: Breakpoint = {
    mobile: {
      margin: '5%',
    },
    desktop: {
      margin: '5%',
    },
  };

  private renderer: Renderer2;

  constructor(
    public mediaQuery: MediaObserver,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.mediaQuery
      .asObservable()
      .pipe(map((change: MediaChange[]) => change[0]))
      .subscribe((change: MediaChange) => {
        this.isSmallMobile = change.mqAlias === 'xs';
        this.isLargeMobile = change.mqAlias === 'sm';
        this.isSmallDesktop =
          change.mqAlias === 'md' || change.mqAlias === 'lg';
        this.isLargeDesktop = change.mqAlias === 'xl';
        this.isMobile = this.isSmallMobile || this.isLargeMobile;
        this.isDesktop = this.isSmallDesktop || this.isLargeDesktop;
        this.setBodyClasses();
      });
  }

  public getBreakpoints(component: string): any {
    if (this.isMobile) {
      return this[component].mobile;
    } else {
      return this[component].desktop;
    }
  }

  public getMobileClass(): string {
    if (this.isMobile) {
      return 'mobile';
    }
    return '';
  }

  private setBodyClasses(): void {
    if (this.isMobile) {
      this.renderer.addClass(this.document.body, 'mobile');
    } else {
      this.renderer.removeClass(this.document.body, 'mobile');
    }
    if (this.isSmallMobile) {
      this.renderer.addClass(this.document.body, 'small-mobile');
    } else {
      this.renderer.removeClass(this.document.body, 'small-mobile');
    }
    if (this.isLargeMobile) {
      this.renderer.addClass(this.document.body, 'large-mobile');
    } else {
      this.renderer.removeClass(this.document.body, 'large-mobile');
    }
    if (this.isDesktop) {
      this.renderer.addClass(this.document.body, 'desktop');
    } else {
      this.renderer.removeClass(this.document.body, 'desktop');
    }
    if (this.isSmallDesktop) {
      this.renderer.addClass(this.document.body, 'small-desktop');
    } else {
      this.renderer.removeClass(this.document.body, 'small-desktop');
    }
    if (this.isLargeDesktop) {
      this.renderer.addClass(this.document.body, 'large-desktop');
    } else {
      this.renderer.removeClass(this.document.body, 'large-desktop');
    }
  }
}
