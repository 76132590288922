import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, fromEvent } from 'rxjs';
import { catchError, map, tap, switchMap, take } from 'rxjs/operators';
import { AppConfigService } from '../app.config.service';
import { VimAppointments } from '@interfaces/vim-appointments.model';
import { DynamicAssetLoaderService } from '@services/dynamic-asset-loader/dynamic-asset-loader.service';
import { Provider } from '@interfaces/provider.model';
import { AppConfig } from '@interfaces/app-config.model';
import { ConfigurationService } from '@services/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class VimAppointmentsService {
  public vimSettings = new BehaviorSubject<VimAppointments>(
    new VimAppointments({})
  );
  public vimProviderUpdated = new BehaviorSubject<boolean>(false);
  public vimReady = new BehaviorSubject<boolean>(false);
  private vimInstance: any;

  constructor(
    private configurationService: ConfigurationService,
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private assetLoader: DynamicAssetLoaderService,
    @Inject(DOCUMENT) private document: any
  ) {}

  public checkVim(auth_status: boolean): boolean {
    let out = false;
    switch (this.vimSettings.value.config) {
      case 'auth':
        out = auth_status && !!this.vimSettings.value.member_token;
        break;
      case 'unauth':
        out = !auth_status;
        break;
      case 'both':
        out = true;
        break;
    }
    return out;
  }

  public getVimConfig(): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('cache', 'false');
    return this.appConfigService.config.pipe(
      switchMap((appConfig) =>
        this.configurationService.listenForResolvedSignature().pipe(
          take(1),
          map(() => appConfig)
        )
      ),
      switchMap((appConfig) =>
        this.http
          .get('/api/vim_appointments.json', {
            params: params,
            withCredentials: true,
          })
          .pipe(
            map((data: VimAppointments) => {
              const settings = new VimAppointments(data);
              this.vimSettings.next(settings);
              if (settings.member_token !== '') {
                // member tokens expire after 30 minutes, set timeout to refresh settings
                const twenty_five_min = 1000 * 60 * 25;
                setTimeout(
                  () => this.getVimConfig().subscribe(),
                  twenty_five_min
                );
              }
              if (this.loadVim()) {
                this.loadVimSdk();
                fromEvent(
                  this.document.getElementById('vimsdk'),
                  'load'
                ).subscribe(() => this.initVim(appConfig));
              } else {
                this.vimReady.next(true);
              }
              return this.vimSettings.value;
            }),
            catchError((error) => of(false)),
            tap((error) => this.vimReady.next(true))
          )
      )
    );
  }

  public checkAvailability(providers: Provider[], auth_status: boolean): void {
    if (!this.vimInstance) {
      setTimeout(() => this.checkAvailability(providers, auth_status), 100);
      return;
    }
    const vimProviders = [],
      vimOptions = {};
    providers.forEach((prov) => {
      if (!!prov.npi_identifier) {
        vimProviders.push({
          npi: prov.npi_identifier,
          address: this.buildAddress(prov),
        });
      }
    });
    if (vimProviders.length === 0) {
      this.vimProviderUpdated.next(false);
      return;
    }
    if (!!this.vimSettings.value.member_token) {
      vimOptions['memberToken'] = this.vimSettings.value.member_token;
    }
    this.vimInstance
      .getProviderAvailability(vimProviders, vimOptions)
      .then((results) => {
        results.forEach((prov) => {
          if (prov.bookingType !== 'NONE') {
            const index = providers.findIndex(
              (provider) => provider.npi_identifier === prov.npi
            );
            if (index > -1) {
              providers[index].vim_available = this.showButton(
                prov.bookingType,
                auth_status
              );
              providers[index].vim_booking_type = prov.bookingType;
              this.vimProviderUpdated.next(true);
            }
          }
        });
      });
  }

  public showBooking(provider: Provider): void {
    const vimOptions = {};
    if (!!this.vimSettings.value.member_token) {
      vimOptions['memberToken'] = this.vimSettings.value.member_token;
    }
    this.vimInstance
      .showBookingDialog(
        provider.npi_identifier,
        this.buildAddress(provider),
        vimOptions
      )
      .then((appointmentId) => {
        // booking success, what should we do with the appointmentId?, do we capture counts
      })
      .catch((error) => {
        // booking canceled, capture anything?
      });
  }

  public getTranslationKey(which: string, book_type: string | boolean): string {
    return 'appointments_' + which + '_' + book_type;
  }

  private showButton(bookingType: string, auth_status: boolean): boolean {
    let out = false;
    switch (this.vimSettings.value.config) {
      case 'auth':
        out = auth_status && bookingType !== 'NONE';
        break;
      case 'unauth':
        out = !auth_status && bookingType !== 'NONE';
        break;
      case 'both':
        out = bookingType !== 'NONE';
        break;
    }
    return out;
  }

  private vimEnvironment(appConfig: AppConfig): string {
    return appConfig.environment === 'production' ||
      (appConfig.environment === 'uat' && appConfig.isClient('premera'))
      ? 'production'
      : 'sandbox';
  }

  private loadVim(): boolean {
    let out = false;
    switch (this.vimSettings.value.config) {
      case 'auth':
        out = !!this.vimSettings.value.member_token;
        break;
      case 'unauth':
      case 'both':
        out = true;
        break;
    }
    return out && !window['Vim'];
  }

  private loadVimSdk(): void {
    const url = 'https://js.getvim.com/web/v2.0/sdk.js'; // this version is on CDN
    // const url = '../../../assets/scripts/vim-sdk-2.0.js'; // this version is local copy of SDK
    this.assetLoader.loadAsset(url, 'script', 'vimsdk');
  }

  private buildAddress(provider: Provider): string {
    const addrFields = [
      'addr_line1',
      'addr_line2',
      'city',
      'state',
      'postal_code',
    ];
    let address = '';
    addrFields.forEach((field) => {
      if (provider[field]) {
        address = (address + ' ' + provider[field]).trim();
      }
    });
    return address;
  }

  private initVim(appConfig: AppConfig): void {
    window['Vim'](this.vimSettings.value.api_key, {
      env: this.vimEnvironment(appConfig),
    })
      .then((vim) => {
        // Initialized
        this.vimInstance = vim;
        this.vimReady.next(true);
      })
      .catch((error) => {
        // Failed to initialize
        this.vimSettings.next(new VimAppointments({})); // clear settings so won't attempt to use
        this.vimReady.next(true);
      });
  }
}
