export class AutocompleteResultsCounter {
  displayIndex: number;
  remaining: number;
  showMore: number;

  constructor(obj?: any) {
    if (obj) {
      this.displayIndex = obj.displayIndex >= 0 ? obj.displayIndex + 10 : 10;
      this.remaining = obj.remaining - 10;
      this.showMore = this.remaining > 10 ? 10 : this.remaining;
    }
  }
}
