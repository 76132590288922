import { Injectable } from '@angular/core';
import { catchError, map, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MedicationSavings } from '@interfaces/medication-savings.interface';
import { MedicationFinderSearchResults } from '@interfaces/medication-drug-search.interface';
import { LocationService } from './location/location.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  DrugDetailsAPIResponse,
  DrugsAPIResponse,
  RecommendationsAPIResponse,
  SearchResultsAPIData,
} from '@zelis/medication-finder/feature-search-results';
import { AppParamsService } from './app.params.service';
import { ConfigurationService } from './configuration.service';
import { SettingsService } from './settings.service';
import { Place } from '@classes/place.class';

@Injectable({
  providedIn: 'root',
})
export class MedicationFinderService {
  public defaultSearchRadius: number = 5;
  private selectedOptions: BehaviorSubject<ParamMap> = new BehaviorSubject(
    null
  );
  constructor(
    private http: HttpClient,
    private appParams: AppParamsService,
    private configService: ConfigurationService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private settingsService: SettingsService
  ) {}

  public getSelectedOptions(): ParamMap {
    return this.selectedOptions.getValue();
  }

  public getSavings(): Observable<MedicationSavings> {
    const url = `/api/medication_finder/savings.json`;
    return this.http
      .get<MedicationSavings>(url, { withCredentials: true })
      .pipe(catchError(() => of(null)));
  }

  public getEligibility(): Observable<boolean> {
    return this.settingsService.getSetting('rx_savings_eligible').pipe(
      take(1),
      switchMap((eligible: boolean) =>
        iif(() => !!eligible, this.getRxEligibility(), of(false))
      )
    );
  }

  public getSearchResultsAPIData(
    drug: string
  ): Observable<SearchResultsAPIData> {
    return this.getDrug(drug).pipe(
      switchMap((drugResult: DrugsAPIResponse) => {
        return combineLatest([
          this.getDrugDetailsAndRecommendations(drugResult),
          of(drugResult),
        ]).pipe(
          map(([detailsAndReco, drugRes]) => ({
            ...detailsAndReco,
            drugs: drugRes,
          }))
        );
      })
    );
  }

  public getDrugNames(searchTerm: string): Observable<string[]> {
    const url = `/api/medication_finder/drugs_search.json`;
    return this.http
      .get<MedicationFinderSearchResults>(url, {
        withCredentials: true,
        params: { search_value: searchTerm },
      })
      .pipe(
        map((results: MedicationFinderSearchResults) => results.drugs_search),
        catchError(() => of([]))
      );
  }

  public getDrug(searchTerm: string): Observable<DrugsAPIResponse> {
    const url = `/api/medication_finder/drugs.json`;
    return this.http.get<DrugsAPIResponse>(url, {
      withCredentials: true,
      params: { search_value: searchTerm, cache: 'false' },
    });
  }

  public getDrugDetails(ndc: string): Observable<DrugDetailsAPIResponse> {
    const url = `/api/medication_finder/drug_details.json?`;
    return this.http
      .get(url, {
        withCredentials: true,
        params: { representative_ndc: ndc },
      })
      .pipe(
        map((results: any) => results.drug_details[0]),
        map((results: any) => this.cleanDrugDetailsBody(results))
      );
  }

  public getRecommendations(
    ndc: string,
    quantity: number,
    daysSupply: number,
    radius: number
  ): Observable<RecommendationsAPIResponse> {
    return this.getPharmacies(radius).pipe(
      switchMap((ncpdp: string[]) => {
        const url = `/api/medication_finder/recommendations.json?${this.buildNcpdpParam(
          ncpdp
        )}`;
        return this.http.get<RecommendationsAPIResponse>(url, {
          withCredentials: true,
          params: {
            ndc: ndc,
            quantity: quantity,
            days_supply: daysSupply,
          },
        });
      })
    );
  }

  public getPharmacies(radius: number): Observable<string[]> {
    return combineLatest([
      of(radius),
      this.locationService.geo.pipe(filter((geo: Place) => !!geo.zip)),
    ]).pipe(
      switchMap(([rad, location]: [number, Place]) => {
        const url = `/api/medication_finder/pharmacies.json?`;
        return this.http
          .get(url, {
            withCredentials: true,
            params: {
              zip: location.zip,
              radius: rad,
            },
          })
          .pipe(map((results: { pharmacies: string[] }) => results.pharmacies));
      })
    );
  }

  public redirectToRxss(relaystate = '') {
    window.open('', 'medfinder');
    const url = `/rx/auth`;
    const form = document.createElement('form');
    form.target = 'medfinder';
    form.method = 'POST';
    form.action = url;
    const relayInput = document.createElement('input');
    relayInput.type = 'hidden';
    relayInput.name = 'RelayState';
    relayInput.value = relaystate;
    form.appendChild(relayInput);
    document.body.appendChild(form);
    form.submit();
  }

  private getRxEligibility(): Observable<boolean> {
    const url = `/api/medication_finder/member_eligibility.json`;
    return this.getSignature().pipe(
      switchMap((signature: string) => {
        const params: HttpParams = this.appParams.setHttpParams({
          config_signature: signature,
        });
        return this.http
          .get(url, {
            params: params,
            withCredentials: true,
          })
          .pipe(
            catchError(() => of({ rx_savings_eligible: false })),
            map((result: any) => !!result?.rx_savings_eligible)
          );
      })
    );
  }

  private getDrugDetailsAndRecommendations(drugResult: DrugsAPIResponse) {
    const { most_common } = drugResult;
    return combineLatest([
      this.getDrugDetails(most_common.ndc),
      this.getRecommendationsOnQueryParamsChange(most_common),
    ]).pipe(
      map(
        ([details, recommendations]: [
          DrugDetailsAPIResponse,
          RecommendationsAPIResponse
        ]) => ({
          drugDetails: details,
          recommendations: recommendations,
        })
      )
    );
  }

  private getRecommendationsOnQueryParamsChange(
    mostCommon
  ): Observable<RecommendationsAPIResponse> {
    return this.route.queryParamMap.pipe(
      switchMap((params: ParamMap) => {
        this.selectedOptions.next(params);
        const args = this.setRecommendationsArgs(mostCommon, params);
        return this.getRecommendations(...args);
      })
    );
  }

  private setRecommendationsArgs(
    mostCommon,
    selected: ParamMap
  ): [string, number, number, number] {
    if (selected.has('ndc')) {
      const quantityDaysSupply = selected.get('quantity').split(':');
      const quantity = Number(quantityDaysSupply[0]);
      const daysSupply = Number(quantityDaysSupply[1]);
      return [
        selected.get('ndc'),
        quantity,
        daysSupply,
        Number(selected.get('radius')),
      ];
    }

    return [
      mostCommon.ndc,
      mostCommon.quantity,
      mostCommon.days_supply,
      this.defaultSearchRadius,
    ];
  }

  private buildNcpdpParam(ncpdp: string[]): string {
    if (ncpdp?.length === 0) {
      return `ncpdp=[]`;
    }
    return ncpdp
      ?.map((x) => {
        return `ncpdp[]=${x}`;
      })
      ?.join('&');
  }

  private getSignature(): Observable<string> {
    return this.configService.signature.pipe(
      filter((sig) => !!sig),
      take(1)
    );
  }
  // This is to remove an extra line in the body of the drug details response.  Rx is unable to remove it due to it coming from a partner company.
  private cleanDrugDetailsBody(
    results: DrugDetailsAPIResponse
  ): DrugDetailsAPIResponse {
    return {
      ...results,
      details: results.details?.map((details) => ({
        type: details.type,
        body: details.body.replace(/\n\n\*/g, '\n*'),
      })),
    };
  }
}
