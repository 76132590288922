<div
  class="page-container"
  [ngClass]="{ 'content-spacing': contentSpacing, fullscreen: fullscreen }"
  [ngStyle.gt-sm]="{ 'background-color': backgroundColor || 'transparent' }"
  [ngStyle.lt-md]="{
    'background-color': backgroundColorMobile || 'transparent'
  }"
>
  <ng-content></ng-content>
</div>
