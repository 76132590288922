import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { ChatMessageComponent } from './chat-message.component';

@NgModule({
  declarations: [ChatMessageComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [ChatMessageComponent],
})
export class ChatMessageModule {}
