import { Component, EventEmitter, Output } from "@angular/core";
import { ProductType } from "@enums/uux-marketing-plans.enum";

@Component({
  selector: 'app-product-type-selection',
  templateUrl: './product-type-selection.component.html',
})
export class ProductTypeSelectionComponent {
  @Output() public productType: EventEmitter<ProductType> = new EventEmitter();
  readonly ProductType = ProductType;

  public setProductType(type: ProductType) {
    this.productType.emit(type);
  }
}
