import { Injectable } from '@angular/core';
import { MembersService } from '../services/members.service';
import { AuthService } from '../services/auth.service';
import { FeaturesService } from '../services/features/features.service';
import { Resolve } from '@angular/router';
import { first } from 'rxjs/operators';

@Injectable()
export class MembersResolver implements Resolve<any> {
  constructor(
    private authService: AuthService,
    private membersService: MembersService,
    private featuresService: FeaturesService
  ) {}

  resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authService.isLoggedIn().then((auth) => {
        this.featuresService.getFeatureFlags().subscribe((features) => {
          if (!auth || !features?.cost) {
            resolve(true);
          } else {
            this.membersService.member
              .pipe(first((member) => member !== undefined))
              .subscribe(() => resolve(true));
          }
        });
      });
    });
  }
}
