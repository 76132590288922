export class AverageCostTranslations {
  public averageLabel?: string;
  public messageWellBelow?: string;
  public messageSlightlyBelow?: string;
  public messageIsAverage?: string;
  public messageSlightlyAbove?: string;
  public messageWellAbove?: string;

  constructor(translations?: AverageCostTranslations) {
    translations = translations || {};
    this.averageLabel = translations.averageLabel || 'Avg';
    this.messageWellBelow =
      translations.messageWellBelow || 'Well below average cost';
    this.messageSlightlyBelow =
      translations.messageSlightlyBelow || 'Slightly below average cost';
    this.messageIsAverage = translations.messageIsAverage || 'Average cost';
    this.messageSlightlyAbove =
      translations.messageSlightlyAbove || 'Slightly above average cost';
    this.messageWellAbove =
      translations.messageWellAbove || 'Well above average cost';
  }
}
