import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { ConfigurationService } from './configuration.service';
import { combineLatest, Observable, of } from 'rxjs';
import { MleStatus } from '@interfaces/mle-status.model';
import { MembersService } from './members.service';
import { map, switchMap } from 'rxjs/operators';
import { Member } from '../components/members';
import { DefaultMember } from '@interfaces/default-member.model';

@Injectable({
  providedIn: 'root',
})
export class MleService {
  public status: Observable<MleStatus> = this.getMleStatus();

  constructor(
    private configurationService: ConfigurationService,
    private settingsService: SettingsService,
    private membersService: MembersService
  ) {}

  private getMleStatus(): Observable<MleStatus> {
    return combineLatest([
      this.configurationService.signature,
      this.membersService.member,
      this.membersService.defaultMember,
    ]).pipe(
      switchMap(
        ([signature, member, defaultMember]: [
          string,
          Member,
          DefaultMember
        ]) => {
          if (member !== undefined || defaultMember) {
            return this.settingsService
              .requestSetting(signature, 'mle_status')
              .pipe(
                map((status) => new MleStatus({ ...status, resolved: true }))
              );
          }
          return of(new MleStatus({}));
        }
      )
    );
  }
}
