import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './sort-filters.reducers';
import { SortFiltersEffects } from '../search-filters.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('sort_filters', reducer),
    EffectsModule.forFeature([SortFiltersEffects]),
  ],
})
export class SortFiltersModule {}
