import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModifyPreviousSelectionsComponent } from './modify-previous-selections.component';
import { TranslateModule } from "@ngx-translate/core";
import { DlsButtonModule } from "@zelis/dls/button";
import { WizardLoginLinkModule } from "@components/network-selection-wizard/wizard-login-link/wizard-login-link.module";
import { DlsListModule } from '@zelis/dls/list';

@NgModule({
  declarations: [ModifyPreviousSelectionsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DlsListModule,
    DlsButtonModule,
    WizardLoginLinkModule,
  ],
  exports: [ModifyPreviousSelectionsComponent],
})
export class ModifyPreviousSelectionsModule {}
