import { Component, ElementRef, OnInit } from '@angular/core';
import { SkipLinksService } from './skip-links.service';
import { SkipLink } from '@interfaces/skip-link.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-skip-links',
  templateUrl: './skip-links.component.html',
  styleUrls: ['./skip-links.component.scss'],
  animations: [],
  providers: [],
})
export class SkipLinksComponent implements OnInit {
  public links: SkipLink[];

  constructor(private service: SkipLinksService) {}

  ngOnInit(): void {
    this.service
      .links()
      .subscribe((links) => (this.links = _.sortBy(links, (l) => l.index)));
  }

  goToFragment(el: ElementRef): void {
    el.nativeElement.focus();
  }
}
