import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SettingsService } from '../settings.service';
import { AuthService } from '../auth.service';
import { first, switchMap } from 'rxjs/operators';
import { AuthStatus } from '@interfaces/auth-status.model';
import { StorageUtilities } from '../../utilities/storage.utilities';

@Injectable({
  providedIn: 'root',
})
export class NetworkToutService {
  private storage: StorageUtilities = new StorageUtilities();

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService
  ) {}

  /**
   * Get the tout_network_selection config if unauthenticated on a defaultNetwork and session is not set.
   * @returns Observable<boolean>
   */
  public getToutEnabled(defaultNetwork: boolean): Observable<boolean> {
    if (
      !defaultNetwork ||
      this.storage.sessionStorageGet('toutNetworkSelection') === false
    ) {
      return of(false);
    }
    return this.authService.authStatus.pipe(
      first((status: AuthStatus) => !!status.resolved),
      switchMap((status) => {
        if (!status.auth_status) {
          return this.settingsService.getSetting('tout_network_selection');
        }
        return of(false);
      })
    );
  }

  public removeSessionToutNetworkSelection(): void {
    this.storage.sessionStorageRemove('toutNetworkSelection');
  }
}
