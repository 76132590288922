<!-- imitation of the network-selection input field -->
<mat-form-field
  class="w-full cursor-pointer"
  appearance="{{ appearance }}"
  color="accent"
  floatLabel="always"
  subscriptSizing="dynamic"
  [ngClass]="{ 'high-contrast-theme': darkTheme, 'dark-bg': darkTheme }"
  (focus)="networkDropdown.openMenu()"
  [matMenuTriggerFor]="networkSelectionDropdown"
  #dropdownTrigger="matMenuTrigger"
  tabindex="0"
  >
  <mat-label>
    {{ 'network_selection_dropdown_label' | translate }}
  </mat-label>
  <input
    class="hidden"
    type="text"
    matInput
    readonly
    [attr.aria-label]="'network_selection_dropdown_label' | translate"
    tabindex="-1"
  />
  <span class="line-clamp-1" [innerHTML]="selectedNetwork?.displayName | translate"></span>
  <mat-icon
    matSuffix
    svgIcon="dls-caret-down-solid"
    class="h-5 w-5 leading-8"
  >
  </mat-icon>
</mat-form-field>

<!-- network-selection dropdown menu -->
<mat-menu
  #networkSelectionDropdown="matMenu"
  yPosition="below"
  class="network-selection-dropdown w-full max-h-full fixed top-0 left-0 bottom-0 md:max-h-[90vh] md:w-80 md:relative"
>
  <div class="h-full" (click)="$event.stopPropagation()">
    <!-- dropdown header/title -->
    <zelis-list-item-subhead
      primaryText="Select a network"
      size="large"
    ></zelis-list-item-subhead>
    <mat-icon
      svgIcon="dls-times-plain-light"
      class="absolute top-3 right-2 cursor-pointer md:invisible"
      (click)="onCloseMenu()"
    ></mat-icon>

    <mat-divider></mat-divider>

    <!-- link to the plan-type-selection page (opens up network wizard) -->
    <div
      mat-menu-item class="m-0 py-2"
      (click)="onOpenNetworkWizard()"
      (keydown.enter)="onOpenNetworkWizard()"
    >
      <div class="flex flex-col">
        <p
          class="m-0 link-blue underline tracking-normal"
          [innerHTML]="'network_selection_dropdown_wizard_redirect_title' | translate"
        ></p>
        <span
          class="text-xs text-gray-500 tracking-normal"
          [innerHTML]="'network_selection_dropdown_wizard_redirect_subtitle' | translate"
        ></span>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- similar networks -->
    <ng-container *ngIf="similarNetworks?.length > 0">
      <!-- title -->
      <p
        class="mx-4 my-2 text-gray-600 tracking-normal"
        [innerHTML]="'network_selection_dropdown_similar_networks_title' | translate"
      ></p>
      <!-- networks list -->
      <div
        mat-menu-item
        *ngFor="let network of similarNetworks"
        class="px-4 py-2"
        (click)="onSelectNetwork(network)"
        (keydown.enter)="onSelectNetwork(network)"
      >
        <ng-container
          [ngTemplateOutlet]="networkListItem"
          [ngTemplateOutletContext]="{ network }"
        ></ng-container>
      </div>
    </ng-container>

    <mat-divider></mat-divider>

    <!-- all networks expandable section -->
    <mat-expansion-panel
    *ngIf="!!allNetworks"
      (opened)="allNetworksExpanded = true"
      (closed)="allNetworksExpanded = false"
      class="all-networks-expansion-panel"
    >
      <!-- all networks header -->
      <mat-expansion-panel-header class="p-0 pr-4">
        <mat-panel-title mat-menu-item disableRipple>
          <p class="m-0 text-gray-600 tracking-normal">
            {{
              (
                allNetworksExpanded
                  ? 'network_selection_dropdown_hide_all_networks_title'
                  : 'network_selection_dropdown_show_all_networks_title'
              ) | translate
            }}
          </p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- list of all networks -->
      <ng-container *ngIf="allNetworksExpanded">
        <div *ngFor="let networkLetterGroup of allNetworks">
          <p class="mx-4 my-2 text-gray-600 text-bold">{{ networkLetterGroup.letter }}</p>
          <div
            mat-menu-item
            *ngFor="let network of networkLetterGroup.networkList"
            class="px-4 py-3"
            (click)="onSelectNetwork(network)"
            (keydown.enter)="onSelectNetwork(network)"
          >
            <ng-container
              [ngTemplateOutlet]="networkListItem"
              [ngTemplateOutletContext]="{ network }"
            ></ng-container>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>

    <mat-divider class="md:invisible"></mat-divider>
  </div>
</mat-menu>

<!-- network list-item/tile element -->
<ng-template #networkListItem let-network="network">
  <div class="flex items-center justify-between">
    <p
      [innerHTML]="network?.displayName"
      [class.text-bold]="network.displayName === selectedNetwork?.displayName"
      class="tracking-normal"
    ></p>
    <span>
      <mat-icon
        *ngIf="network?.displayName === selectedNetwork?.displayName"
        svgIcon="dls-check-plain-light"
        class="flex mr-0"
      ></mat-icon>
    </span>
  </div>
</ng-template>
