export class Place {
  state_code?: string;
  region?: string;
  zip: string;
  city: string;
  name: string;
  geo: string;
  is_detected_location?: boolean;
  sso_location?: boolean;

  constructor(obj: any) {
    obj = obj || {};
    this.state_code = obj.state_code || obj.region || '';
    this.region = obj.region || '';
    this.zip = obj.zip || '';
    this.city = obj.city || '';
    this.name = obj.name || '';
    this.geo = obj.geo || '';
    this.is_detected_location = !!obj.is_detected_location;
    this.sso_location = !!obj.sso_location;
  }

  public toString(): string {
    return `${this.city}, ${this.state_code} — ${this.zip}`;
  }

  public isValid(): boolean {
    return !!this.geo;
  }
}
