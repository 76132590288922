export class BillingCodeBenefitLimit {
  serviceLimitMet?: boolean;
  calculated: null;
  limitType: 'Visit' | 'DollarAmount' | 'Day' | 'SurgicalProcedure' | 'Unit';
  limit: number;
  remainingAmount: number | null;
  used?: number;
  message: string;

  constructor(obj?: any) {
    this.serviceLimitMet = obj?.service_limit_met || null;
    this.calculated = obj?.calculated || null;
    this.limitType = obj?.limit_type || null;
    this.limit = obj?.limit ?? null;
    this.remainingAmount = obj?.remaining_amount ?? null;
    this.used = this.calculateUsage();
    this.message = obj?.message || null;
  }

  private calculateUsage(): number {
    if (this.limit !== null && this.remainingAmount !== null) {
      return this.limit - this.remainingAmount;
    }
    return null;
  }
}
