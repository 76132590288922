<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'fab'">
    <button
      mat-fab
      zelisButton
      data-cy="button-fab"
      [color]="color"
      [type]="type"
      [disabled]="disabled"
      zelisResponsiveTypography
      [steps]="truncate ? [] : steps"
      [truncate]="truncate"
      [loading]="loading"
      (click)="buttonClicked($event)"
      [attr.aria-label]="ariaLabel"
      class="rounded-full {{ forwardedClass }}"
      [class.dark-bg]="darkBackground"
    >
      <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="!(href || forwardedRouterLink); else anchorTag">
    <ng-container *ngSwitchCase="'basic'">
      <button
        mat-button
        zelisButton
        data-cy="button-basic"
        [size]="size"
        [fullWidth]="fullWidth"
        [color]="color"
        [type]="type"
        [disabled]="disabled"
        [iconButton]="iconButton"
        zelisResponsiveTypography
        [steps]="truncate ? [] : steps"
        [truncate]="truncate"
        [loading]="loading"
        (click)="buttonClicked($event)"
        [attr.aria-label]="ariaLabel"
        [class]="forwardedClass"
        [class.dark-bg]="darkBackground"
        [class.no-hover]="noHover"
      >
        <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="'flat'">
      <button
        mat-flat-button
        zelisButton
        data-cy="button-flat"
        [size]="size"
        [fullWidth]="fullWidth"
        [color]="color"
        [type]="type"
        [disabled]="disabled"
        [iconButton]="iconButton"
        zelisResponsiveTypography
        [steps]="truncate ? [] : steps"
        [truncate]="truncate"
        [loading]="loading"
        (click)="buttonClicked($event)"
        [attr.aria-label]="ariaLabel"
        [class]="forwardedClass"
        [class.dark-bg]="darkBackground"
      >
        <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="'stroked'">
      <button
        mat-stroked-button
        zelisButton
        data-cy="button-stroked"
        [size]="size"
        [fullWidth]="fullWidth"
        [color]="color"
        [type]="type"
        [disabled]="disabled"
        [alternateHover]="alternateHover"
        [iconButton]="iconButton"
        zelisResponsiveTypography
        [steps]="truncate ? [] : steps"
        [truncate]="truncate"
        [loading]="loading"
        (click)="buttonClicked($event)"
        [attr.aria-label]="ariaLabel"
        [class]="forwardedClass"
        [class.dark-bg]="darkBackground"
      >
        <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="'elevated'">
      <button
        mat-raised-button
        zelisButton
        data-cy="button-elevated"
        [size]="size"
        [fullWidth]="fullWidth"
        [color]="color"
        [type]="type"
        [disabled]="disabled"
        [alternateHover]="alternateHover"
        [iconButton]="iconButton"
        zelisResponsiveTypography
        [steps]="truncate ? [] : steps"
        [truncate]="truncate"
        [loading]="loading"
        (click)="buttonClicked($event)"
        [attr.aria-label]="ariaLabel"
        [class]="forwardedClass"
        [class.dark-bg]="darkBackground"
      >
        <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
      </button>
    </ng-container>
  </ng-container>
  <ng-template #anchorTag>
    <ng-container *ngIf="href; else routerLink">
      <ng-container *ngSwitchCase="'basic'">
        <a
          mat-button
          zelisButton
          data-cy="button-basic-anchor"
          [size]="size"
          [fullWidth]="fullWidth"
          [href]="href"
          [target]="target"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          (keydown.enter)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="'flat'">
        <a
          mat-flat-button
          zelisButton
          data-cy="button-flat-anchor"
          [size]="size"
          [fullWidth]="fullWidth"
          [href]="href"
          [target]="target"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          (keydown.enter)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="'stroked'">
        <a
          mat-stroked-button
          zelisButton
          data-cy="button-stroked-anchor"
          [size]="size"
          [fullWidth]="fullWidth"
          [href]="href"
          [target]="target"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [alternateHover]="alternateHover"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          (keydown.enter)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="'elevated'">
        <button
          mat-raised-button
          zelisButton
          data-cy="button-elevated"
          [size]="size"
          [fullWidth]="fullWidth"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [alternateHover]="alternateHover"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </button>
      </ng-container>
    </ng-container>
    <ng-template #routerLink>
      <ng-container *ngSwitchCase="'basic'">
        <a
          mat-button
          zelisButton
          data-cy="button-basic-anchor"
          [size]="size"
          [fullWidth]="fullWidth"
          [routerLink]="forwardedRouterLink"
          [target]="target"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          (keydown.enter)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="'flat'">
        <a
          mat-flat-button
          zelisButton
          data-cy="button-flat-anchor"
          [size]="size"
          [fullWidth]="fullWidth"
          [routerLink]="forwardedRouterLink"
          [target]="target"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          (keydown.enter)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="'stroked'">
        <a
          mat-stroked-button
          zelisButton
          data-cy="button-stroked-anchor"
          [size]="size"
          [fullWidth]="fullWidth"
          [routerLink]="forwardedRouterLink"
          [target]="target"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [alternateHover]="alternateHover"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          (keydown.enter)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="'elevated'">
        <button
          mat-raised-button
          zelisButton
          data-cy="button-elevated"
          [size]="size"
          [fullWidth]="fullWidth"
          [color]="color"
          [type]="type"
          [disabled]="disabled"
          [alternateHover]="alternateHover"
          [iconButton]="iconButton"
          zelisResponsiveTypography
          [steps]="truncate ? [] : steps"
          [truncate]="truncate"
          [loading]="loading"
          (click)="buttonClicked($event)"
          [attr.aria-label]="ariaLabel"
          [class]="forwardedClass"
          [class.dark-bg]="darkBackground"
        >
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </button>
      </ng-container>
    </ng-template>
  </ng-template>
</ng-container>
<ng-template #tempOutlet>
  <mat-spinner *ngIf="loading" diameter="24"></mat-spinner>
  <span
    class="flex items-center"
    [class.whitespace-nowrap]="nowrap"
    [class.truncate]="truncate"
    [class.hidden]="loading"
    ><ng-content></ng-content
  ></span>
</ng-template>
