export class CcssSettings {
  global_autosuggest?: any;
  header_location?: any;
  header_network?: any;
  ss_promo?: any;
  benefits?: any;
  external_links?: string;
  disclaimers?: any;
  footer?: any;
  guided_search?: any;
  guided_search_header?: any;
  guided_search_option_name?: any;
  header?: any;
  home?: any;
  home_location_network?: any;
  home_background_color?: string;
  login_button?: any;
  compare_button?: any;
  navigation_background_color?: string;
  logo_img?: any;
  marketing_hero_image?: string;
  search?: any;
  profile?: any;
  search_overlay?: any;
  ss_why_use_bg_color?: string;

  constructor(obj?: any) {
    obj = obj || {};
    this.global_autosuggest = obj.global_autosuggest || null;
    this.header_location = obj.header_location || null;
    this.header_network = obj.header_network || null;
    this.benefits = obj.benefits || null;
    this.disclaimers = obj.disclaimers || null;
    this.external_links = obj.external_links || null;
    this.footer = obj.footer || null;
    this.guided_search = obj.guided_search || null;
    this.guided_search_header = obj.guided_search_header || null;
    this.guided_search_option_name = obj.guided_search_option_name || null;
    this.header = obj.header || null;
    this.home = obj.home || null;
    this.home_location_network = obj.home_location_network || null;
    this.home_background_color =
      obj.home_background_color ||
      'background-color: rgb(var(--palette-primary-500))';
    this.login_button = obj.login_button || null;
    this.compare_button = obj.compare_button || null;
    this.navigation_background_color =
      obj.navigation_background_color ||
      'background-color: rgb(var(--palette-primary-700));';
    this.logo_img = obj.logo_img || null;
    this.marketing_hero_image =
      obj.marketing_hero_image ||
      'background: url("../../../assets/images/marketing_background.jpg")';
    this.search = obj.search || null;
    this.profile = obj.profile || null;
    this.search_overlay = obj.search_overlay || null;
    this.ss_promo = obj.ss_promo || null;
    this.ss_why_use_bg_color = obj.ss_why_use_bg_color || null;
  }
}
