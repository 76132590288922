import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
} from '@angular/forms';
import { ChatService } from '../../../services/pat-chat/chat.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { ChatMessage } from '../../../services/pat-chat/chat.message.class';

type Timeout = number;
const USER_IDLE_TIMEOUT_SECONDS = 60;
const USER_TYPING_TIMEOUT_SECONDS = 2;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  loading = true;
  messages: ChatMessage[] = [];
  userIdleTimeout: Timeout;
  public chatStarted: Observable<boolean>;
  public isEstablished: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public agentIsTyping: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public messageIsSending: boolean = false;
  public positionInQueue: BehaviorSubject<number> = new BehaviorSubject(null);
  public panelOpenState: boolean;
  public form: UntypedFormGroup;
  public agentIsUnavailable = false;
  private userTypingTimeout: Timeout;
  private subs = new SubscriptionManager();

  constructor(
    public chatService: ChatService,
    private formBuilder: UntypedFormBuilder,
    private ngZone: NgZone
  ) {
    this.chatStarted = this.chatService.chatStarted;
    this.isEstablished = this.chatService.isEstablished;
    this.isConnected = this.chatService.isConnected;
    this.agentIsTyping = this.chatService.agentIsTyping;
    this.positionInQueue = this.chatService.positionInQueue;
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.subscribeToMessages();
    this.subscribeToUserTyping();
    this.subscribeToPositionInQueue();
    this.chatService.pollAvailability();
    this.subscribeToChatEnded();
  }

  ngOnDestroy() {
    if (this.chatService.isConnected.getValue()) {
      this.chatService.endChat();
    }
    this.unsubscribe();
  }

  public sendMessage(): void {
    if (this.form.controls.message.value === '') {
      return;
    }

    this.resetIdleCountdown();
    this.messageIsSending = true;

    this.chatService
      .sendMessage(this.form.controls.message.value)
      .subscribe((result) => {
        if (!result) {
          return;
        }

        const msg = new ChatMessage({
          text: this.form.controls.message.value,
          name: 'Users',
          inbound: false,
          agentId: '',
          schedule: {},
        });

        this.messages.push(msg);
        this.messageIsSending = false;
        this.form.controls.message.patchValue('');
        this.form.markAsPristine();
      });
  }

  public endChat(e: Event): void {
    if (this.panelOpenState) {
      e.stopPropagation();
    }

    clearTimeout(this.userIdleTimeout);
    this.userIdleTimeout = null;
    clearTimeout(this.userTypingTimeout);
    this.userTypingTimeout = null;

    this.chatService.endChat();
    this.chatService.chatEnded.next(true);
  }

  public closeChat() {
    if (this.chatService.isConnected.getValue()) {
      this.chatService.endChat();
    }
    this.chatService.chatStarted.next(false);
    this.chatService.pollAvailability();
    this.messages = [];
  }

  private buildForm(): UntypedFormGroup {
    const group = this.formBuilder.group({});
    group.addControl(
      'message',
      new UntypedFormControl({ value: '', disabled: true })
    );
    return group;
  }

  private subscribeToUserTyping(): void {
    if (this.form.controls && this.form.controls.message) {
      this.form.controls.message.valueChanges.subscribe((value) => {
        if (value === '') {
          return;
        }

        if (!this.userTypingTimeout) {
          this.chatService.userStartedTyping();
        }

        this.waitForUserToStopTyping();
      });
    }
  }

  private waitForUserToStopTyping() {
    if (this.userTypingTimeout) {
      clearTimeout(this.userTypingTimeout);
    }

    this.ngZone.runOutsideAngular(() => {
      this.userTypingTimeout = window.setTimeout(
        () =>
          this.ngZone.run(() => {
            this.chatService.userStoppedTyping();
            this.userTypingTimeout = null;
          }),
        USER_TYPING_TIMEOUT_SECONDS * 1000
      );
    });
  }

  private subscribeToPositionInQueue() {
    const sub = this.chatService.positionInQueue.subscribe((position) => {
      if (position === null) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    });

    this.subs.add(sub);
  }

  private subscribeToMessages(): void {
    const sub = this.chatService.messages.subscribe((incomingMessages) => {
      if (incomingMessages[0] && incomingMessages[0].reason) {
        this.agentIsUnavailable = true;
        this.chatService.chatRequested.next(false);
      } else {
        this.agentIsUnavailable = false;
        this.messages = this.messages.concat(incomingMessages);
      }
    });

    this.subs.add(sub);
  }

  private subscribeToChatEnded() {
    const sub = this.chatService.chatEnded.subscribe((result) =>
      this.form.disable()
    );
    this.subs.add(sub);
  }

  private resetIdleCountdown(): void {
    if (this.userIdleTimeout) {
      clearTimeout(this.userIdleTimeout);
      this.userIdleTimeout = null;
    }

    this.ngZone.runOutsideAngular(() => {
      this.userIdleTimeout = window.setTimeout(
        () => this.ngZone.run(() => this.userTimedOut()),
        USER_IDLE_TIMEOUT_SECONDS * 1000
      );
    });
  }

  private userTimedOut(): void {
    clearTimeout(this.userIdleTimeout);
    this.userIdleTimeout = null;
  }

  private unsubscribe() {
    this.subs.destroy();
  }
}
