export class ChatSessionInterface {
  id: string;
  affinityToken: string;
  key: string;
  seq: number;
  clientPollTimeout: number;

  constructor(obj: {
    id: string;
    affinityToken: string;
    key: string;
    seq: number;
    clientPollTimeout: number;
  }) {
    this.id = obj.id;
    this.affinityToken = obj.affinityToken;
    this.key = obj.key;
    this.seq = obj.seq || -1;
    this.clientPollTimeout = obj.clientPollTimeout || 30;
  }
}
