import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonSize } from './button.types';
import { coerceBoolean } from 'coerce-property';

@Directive({
  selector: '[zelisButton]',
})
export class ButtonDirective {
  @Input() color = 'primary';
  @Input() size: ButtonSize = 'medium';
  @Input() darkBackground = false;
  @Input() @coerceBoolean fullWidth = false;
  @Input() @coerceBoolean disabled = false;
  /**
   * Whether to apply the alternative hover styling.
   * Currently only supported for stroke buttons.
   */
  @Input() @coerceBoolean alternateHover?: boolean;
  /**
   * Whether the button only contains an icon - no other content
   */
  @Input() @coerceBoolean iconButton?: boolean;
  /**
   * Whether the button truncates overflow text.
   * Responsive typography is not applied as part of this directive.
   */
  @Input() truncate = false;
  @Input() @coerceBoolean loading?: boolean;

  @HostBinding('class.zelis-button') classButton = true;
  @HostBinding('class.dark-bg') get classDarkBg() {
    return this.darkBackground;
  }
  @HostBinding('class.sapphire-large') get classLarge() {
    return this.isLarge;
  }
  @HostBinding('class.w-full') get classWFull() {
    return this.fullWidth;
  }
  @HostBinding('class.cursor-not-allowed') get classCursorNotAllowed() {
    return this.disabled;
  }
  @HostBinding('class.alternate-hover') get classAlternateHover() {
    return this.alternateHover;
  }
  @HostBinding('class.icon-button') get classIconButton() {
    return this.iconButton;
  }
  @HostBinding('class.deep-truncate') get classTruncateButton() {
    return !this.loading && this.truncate;
  }

  @HostBinding('class.max-w-full') get classMaxWidthButton() {
    return this.truncate;
  }

  get isLarge() {
    return this.size === 'large';
  }
}
