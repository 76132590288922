export class AutocompleteParams {
  radius: string;
  sort: string;
  limit: string;

  constructor(obj?: any) {
    obj = obj || {};
    this.radius = obj.radius || '10';
    this.sort = obj.sort || 'score desc, distance asc';
    this.limit = obj.limit || '10';
  }
}
