import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabPeekerComponent } from './fab-peeker.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  exports: [FabPeekerComponent],
  imports: [CommonModule, MatButtonModule],
  declarations: [FabPeekerComponent],
})
export class FabPeekerModule {}
