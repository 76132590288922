export class HttpSpecMock {
  // Request URL path to match for mocking (query params not matched, leading slash optional)
  public url: string;
  // Mock data
  public mock: any;
  // Only match request URLs that contain these query params (['foo=bar', 'baz=faz'])
  public queryParams?: string[];
  // Status code. Defaults to 200.
  public statusCode?: number;

  constructor(obj?: HttpSpecMock) {
    this.url = obj?.url;
    this.mock = obj?.mock;
    this.queryParams = obj?.queryParams || [];
    this.statusCode = obj?.statusCode || 200;
  }
}
