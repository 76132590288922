import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { LocationAutosuggest } from '@classes/location-autosuggest.class';
import { NetworksService } from '@services/networks.service';
import { PageTransition } from '@interfaces/page-transition.interface';
import { PageAnimations } from '@utilities/page.animations';
import { LocationService } from '@services/location/location.service';
import { Observable, of, iif, combineLatest, BehaviorSubject } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';
import { AlphaPrefixConfig } from '@interfaces/alpha-prefix-config.model';
import { SettingsService } from '@services/settings.service';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { GatedEntryOverlayConfig } from '@interfaces/gated-entry-overlay-config.model';
import { GoogleTagManagerService } from '@services/analytics/google-tag-manager.service';
import { Place } from '@classes/place.class';
import { Network } from '@interfaces/network.model';
import { MobileAutosuggestOverlayComponent } from '@components/mobile-autosuggest-overlay';
import { MatDialog } from '@angular/material/dialog';
import { Breakpoints } from '@classes/breakpoints.class';
import { LocationGpsStatus } from '@interfaces/location-gps-status.interface';
import { CcssService } from '@services/ccss/ccss.service';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import { StorageUtilities } from '@utilities/storage.utilities';

@Component({
  selector: 'app-gated-entry-overlay-network-location',
  templateUrl: './gated-entry-overlay-network-location.component.html',
  styleUrls: ['./gated-entry-overlay-network-location.component.scss'],
  animations: PageAnimations,
})
export class GatedEntryOverlayNetworkLocationComponent
  implements OnInit, OnDestroy
{
  @Input() public initialAppParams: any;
  @Input() public gatedEntryConfig: GatedEntryOverlayConfig;
  @Input() public loggedIn: boolean;
  @Input() public pageTransition: PageTransition;

  @Output() public goToOverlayPage: EventEmitter<PageTransition> =
    new EventEmitter();
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter();
  @Output() public goBack: EventEmitter<void> = new EventEmitter();

  public selectedNetwork: BehaviorSubject<string> = new BehaviorSubject('');
  public autoSelectNetwork: boolean;
  public networkUnavailable: boolean;
  public noValidNetworks: boolean;
  public locationSelected: boolean = false;
  public locationAutosuggestLocations: Place[];
  public hasSelectedOption: boolean = false;
  public gpsIsLoading: boolean;
  private usedGps: boolean = false;
  private alphaPrefixEnabled: boolean;
  private validNetworksCount: number = 0;
  private subscriptions = new SubscriptionManager();

  constructor(
    public ccss: CcssService,
    public locationAutosuggest: LocationAutosuggest,
    public locationService: LocationService,
    public networksService: NetworksService,
    public breakpoints: Breakpoints,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<any>,
    private settingsService: SettingsService,
    private gtm: GoogleTagManagerService,
    private dialog: MatDialog,
    private storage: StorageUtilities
  ) {}

  ngOnInit() {
    if (this.initialAppParams?.network_id) {
      this.selectedNetwork.next(this.initialAppParams.network_id);
    };
    this.subscribeToNoValidNetworks();
    this.subscribeToAlphaPrefixConfig();
    this.subscribeToValidNetworksCount();
    this.subscribeToLocationAutosuggest();
    this.initLocationSelected();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  /**
   * Advance from location field to network field, or close.
   * Skip network select step if current network matches network passed into the app,
   *  or if there is only one network available.
   */
  public onContinueClick(): void {
    if (this.pageTransition.page === 'network-location') {
      if (this.shouldSkipNetworkStep()) {
        if (this.shouldGetErrorState()) {
          this.subscribeToErrorStateLocation();
        }
        this.storage.sessionStorageSet('gatedEntryCompleted', true);
        this.closeOverlay.emit();
      } else {
        this.goToOverlayPage.emit({ page: 'network-select' });
      }
    } else {
      this.storage.sessionStorageSet('gatedEntryCompleted', true);
      this.closeOverlay.emit();
    }
  }

  public onOptionSelect(event?: Place | string): void {
    if (event !== 'current-location') {
      this.usedGps = false;
    }
    this.subscribeToGeoChange();
  }

  public onLocationSelect(place: Place): void {
    this.hasSelectedOption = true;
    this.locationAutosuggest.onLocationSelect(place);
    this.gtmTagLocationSelect(place.zip);
  }

  public onGpsLocateSelect(): void {
    this.usedGps = true;
    this.locationAutosuggest.requestBrowserLocation();
    this.gtmTagLocationSelect('Use Current Location');
  }

  public onNetworkSelect(network: Network): void {
    this.gtmTagNetworkSelect(network.name);
  }

  public updateSelectedNetwork(network_id: string): void {
    this.selectedNetwork.next(network_id);
  }

  public openMobileLocation(): void {
    if (this.breakpoints.isMobile) {
      const dialogRef = this.dialog.open(MobileAutosuggestOverlayComponent, {
        backdropClass: 'fullscreen-overlay-autosuggest',
        panelClass: 'fullscreen-overlay',
        height: '100%',
        restoreFocus: false,
        data: { locationSelected: this.locationSelected },
      });
      this.subscriptions.add(
        dialogRef.afterClosed().subscribe((res) => {
          if (res && res.optionSelected) {
            this.onOptionSelect();
            this.changeDetectorRef.detectChanges();
          }
        })
      );
    }
  }

  public continueButtonDisabled(): boolean {
    return this.gpsIsLoading
      ||  (
          (!this.locationSelected && !this.hasSelectedOption) &&
          !(!!this.storage.sessionStorageGet('userSelectedPlace'))
          )
      || (this.noValidNetworks && !this.loggedIn);
  }

  private getNetworkUnavailable(): boolean {
    return (
      this.initialAppParams &&
      this.initialAppParams.network_id &&
      this.initialAppParams.network_id !==
        this.networksService.getSelectedNetwork()?.id
    );
  }

  private subscribeToNoValidNetworks(): void {
    this.subscriptions.add(
      this.networksService
        .getNoValidNetworks()
        .subscribe((noValid: boolean) => {
          this.noValidNetworks = noValid;
        })
    );
  }

  private getNetworkAutoResolvedToSameNetwork(): boolean {
    return !!(
      this.initialAppParams &&
      this.initialAppParams.network_id &&
      this.initialAppParams.network_id ===
        this.networksService.getSelectedNetwork().id
    );
  }

  private getAlphaPrefixConfig(): Observable<AlphaPrefixConfig> {
    return this.store.pipe(
      select(AuthStoreSelectors.getAuthStatus),
      filter((status) => status.resolved),
      switchMap((status) =>
        iif(
          // If logged in
          () => status.auth_status === true,
          // Then not enabled
          of({ network_enabled: false }),
          // Else get config
          this.settingsService.getSetting('alpha_prefix', AlphaPrefixConfig)
        )
      ),
      map((config) => new AlphaPrefixConfig(config))
    );
  }

  private subscribeToAlphaPrefixConfig(): void {
    this.subscriptions.add(
      this.getAlphaPrefixConfig().subscribe(
        (config) => (this.alphaPrefixEnabled = config.network_enabled)
      )
    );
  }

  private subscribeToValidNetworksCount(): void {
    this.subscriptions.add(
      combineLatest([
        this.selectedNetwork,
        this.networksService.getValidNetworksCount()
      ]).subscribe(([selectedNetwork, count]: [string, number]) => {
        this.validNetworksCount = count;
        this.networkUnavailable = this.getNetworkUnavailable();
        this.autoSelectNetwork = selectedNetwork !== ''
      })
    );
  }

  private subscribeToGeoChange(): void {
    this.subscriptions.add(
      combineLatest([
        this.locationService.geo,
        this.locationAutosuggest.gpsStatus,
      ]).subscribe(([geo, gps]) => {
        this.gpsIsLoading = gps?.loading
        this.setLocationSelected(geo, gps);
      })
    );
  }

  private subscribeToLocationAutosuggest(): void {
    this.subscriptions.add(
      this.locationAutosuggest.autosuggestLocations.subscribe((places) => {
        this.locationAutosuggestLocations = places;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  private shouldSkipNetworkStep(): boolean {
    return (
      this.gatedEntryConfig.networkSelectionDisabled ||
      this.getNetworkAutoResolvedToSameNetwork() ||
      this.alphaPrefixEnabled ||
      this.validNetworksCount === 1 ||
      (this.loggedIn && this.validNetworksCount === 0)
    );
  }

  private initLocationSelected(): void {
    this.locationSelected = this.gatedEntryConfig?.locationAutoResolve;
  }

  private setLocationSelected(place: Place, gps: LocationGpsStatus): void {
    if (this.usedGps && !gps.place) {
      this.locationSelected = false;
    } else {
      this.locationSelected = !!(place && place.geo);
    }
  }

  private shouldGetErrorState(): boolean {
    return this.loggedIn && this.validNetworksCount === 0;
  }

  private subscribeToErrorStateLocation(): void {
    this.subscriptions.add(
      this.getOnErrorState().subscribe((place) => {
        this.onLocationSelect(place);
      })
    );
  }

  private getOnErrorState(): Observable<Place> {
    return this.settingsService.getSetting('geo_location').pipe(
      filter((response) => response && response.on_error),
      map((place) => new Place(place.on_error))
    );
  }

  private gtmTagLocationSelect(zip: string): void {
    this.gtm.pushToDataLayer({
      event: 'gtm.gated_entry.location_change',
      gated_entry_selected_location: zip,
    });
  }

  private gtmTagNetworkSelect(name: string): void {
    this.gtm.pushToDataLayer({
      event: 'gtm.gated_entry.network_change',
      gated_entry_selected_network: name,
    });
  }
}
