import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AverageCostComponent } from './average-cost.component';

@NgModule({
  exports: [AverageCostComponent],
  imports: [CommonModule],
  declarations: [AverageCostComponent],
})
export class AverageCostModule {}
