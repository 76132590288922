import {
  trigger,
  state,
  style,
  animate,
  transition,
  AnimationTriggerMetadata,
} from '@angular/animations';
import { EaseInOutCubic } from './easings';

/**
 * Slide up and down animation
 * Usage: <div [@slideUpDown]="isOpened ? 'up' : 'down'">...</div>
 */
export const SlideUpDownAnimation: AnimationTriggerMetadata = trigger(
  'slideUpDown',
  [
    state('up', style({ transform: 'translateY(0)' })),
    state('down', style({ transform: 'translateY(100%)' })),
    transition('up <=> down', [animate(`500ms ${EaseInOutCubic}`)]),
  ]
);
