<div
  class="global-footer"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between start"
  fxLayoutGap.gt-sm="100px"
>
  <div class="disclaimer-wrapper" fxFlex.gt-sm="70">
    <ng-content
      select=".footer-internal-links"
      class="internal-links"
    ></ng-content>
    <ng-content
      select=".footer-external-links"
      class="external-links"
    ></ng-content>
    <ng-content select=".footer-disclaimers" class="disclaimers"></ng-content>
    <div>
      {{ translations.lastUpdated }}: {{ lastUpdate | date: 'longDate' }}
    </div>
  </div>
  <div class="copyright-wrapper" fxFlex.gt-sm="30">
    <!-- Footer copy -->
    <div class="copyright">
      <div *ngIf="translations.legals" [innerHTML]="translations.legals"></div>
      <div
        *ngIf="translations.copyright"
        [innerHTML]="translations.copyright"
      ></div>
    </div>

    <div class="logo-legal">
      <ul class="legal-links">
        <li>
          <button
            class="platform-button white-text text-regular"
            routerLink="/support/termsofuse"
            queryParamsHandling="preserve"
            un-focus
            [innerHTML]="translations.termsOfUse"
          ></button>
        </li>
        <li>
          <button
            class="platform-button white-text text-regular"
            routerLink="/support/privacypolicy"
            queryParamsHandling="preserve"
            un-focus
            [innerHTML]="translations.privacyPolicy"
          ></button>
        </li>
      </ul>
      <a
        class="attribution"
        [attr.href]="
          suppressLogoLink
            ? null
            : 'https://www.zelis.com/solutions/member-engagement/'
        "
        target="_blank"
      >
        <p class="my-3.5">{{ translations.poweredBy }}</p>
        <platform-company-logo
          [logoAltText]="translations.logoAltText"
        ></platform-company-logo>
      </a>
    </div>
  </div>
</div>
