const globalHeaderHiddenRoutes = {
  mobile: [
    'home',
    'sspromo',
    'carepath',
    'medication-finder',
    'billing-code-search',
    'coupons',
    'saved-providers',
  ],
  desktop: [
    'home',
    'search?carepath_change',
    'profile?carepath_change',
    'medication-finder',
    'billing-code-search',
    'coupons',
    'saved-providers',
  ],
};

export default globalHeaderHiddenRoutes;
