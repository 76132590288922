import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ProfileHoursComponent } from './profile-hours.component';

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  declarations: [ProfileHoursComponent],
  exports: [ProfileHoursComponent],
})
export class ProfileHoursModule {}
