<div
  class="flex items-center relative bg-white w-full h-full border rounded-md"
  [class.border-palette-m3-system-gray-35]="!onDarkBackground"
  [class.hover:border-palette-m3-system-gray-10]="!onDarkBackground"
  [class.focus-within:border-palette-m3-system-gray-10]="!onDarkBackground"
  [class.border-palette-m3-system-gray-85]="onDarkBackground"
  [class.hover:bg-palette-m3-system-gray-95]="onDarkBackground"
  [class.h-[64px]]="isPrimary"
  [class.search-input-secondary]="isSecondary"
  [class.with-search-icon]="hideButton"
  #container
>
  <ng-content select="[matPrefix]"></ng-content>

  <ng-content select="mat-form-field"></ng-content>

  <div
    class="absolute flex dark-divider"
    [class.vertical-divider]="(showClearButton$ | async) && audioInput"
    [class.right-4]="isPrimary && !hideButton"
    [class.right-1]="hideButton || isSecondary"
    #buttonsContainer
    (waResizeObserver)="onButtonsContainerResize($event)"
  >
    <div class="flex items-center" [class.pr-4]="audioInput">
      <button
        *ngIf="showClearButton$ | async"
        class="flex items-center close-button"
        aria-label="clear"
        (click)="clear($event)"
        data-cy="search-input-clear-button"
      >
        <mat-icon svgIcon="dls-times-plain-light"></mat-icon>
      </button>
    </div>

    <div
      class="flex"
      [class.pl-4]="audioInput"
      [class.pl-7]="!audioInput && isPrimary"
      [class.pl-5]="!audioInput && isSecondary"
    >
      <button
        *ngIf="audioInput"
        class="flex items-center text-palette-m3-system-blue-40 hover:text-palette-m3-system-blue-30"
        [class.mr-6]="isPrimary"
        [class.mr-4]="isSecondary"
        aria-label="microphone audio search"
        (click)="audioInputButtonClick.emit($event)"
        data-cy="search-input-audio-input-button"
      >
        <mat-icon svgIcon="dls-microphone-plain-light"></mat-icon>
      </button>

      <zelis-button
        *ngIf="!hideButton"
        [variant]="buttonVariant!"
        color="accent"
        [iconButton]="true"
        [size]="buttonSize"
        [loading]="loading"
        (click)="searchButtonClick.emit($event)"
        ariaLabel="search"
        [alternateHover]="true"
        data-cy="search-input-search-flat-button"
      >
        <mat-icon svgIcon="dls-search-plain-light"></mat-icon>
      </zelis-button>
    </div>
  </div>
</div>
