import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.authStatus.pipe(
      filter((status: AuthStatus) => status.resolved),
      map(
        (auth: AuthStatus) =>
          auth.auth_status ||
          this.router.createUrlTree(['/'], { queryParamsHandling: 'preserve' })
      )
    );
  }
}
