import { FacetGeo } from '@interfaces/facet-geo.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsFiltersState } from './settings-filters.state';

export const selectFiltersState =
  createFeatureSelector<SettingsFiltersState>('settings_filters');

export const selectSettingsFilters = createSelector(
  selectFiltersState,
  (state: SettingsFiltersState) => {
    return state;
  }
);

export const selectHighlightedFilters = createSelector(
  selectSettingsFilters,
  (state) => state.appliedFilters?.filter((x) => x?.type === 'dropdown')
);

export const selectMoreFilters = createSelector(
  selectSettingsFilters,
  (state) => state.appliedFilters?.filter((x) => x?.type === 'nested_dropdown')
);

export const selectViewOnlyCheckboxFilters = createSelector(
  selectSettingsFilters,
  (state) => state.appliedFilters?.filter((x) => x?.type === 'checkbox')
);

export const selectFacetGeoOptions = createSelector(
  selectSettingsFilters,
  (state) => {
    if (!state['loading'] && state.appliedFilters) {
      const locationGeoFilter = state.appliedFilters.filter(
        (x) => x?.facet === 'location_geo'
      );
      const geoFacetOptions = locationGeoFilter[0]?.options || [];
      const mappedOptions: FacetGeo[] = geoFacetOptions
        .filter((facet) => facet.value)
        .map((facet) => new FacetGeo(facet))
        .sort((a, b) => a.radius - b.radius);
      return mappedOptions;
    }
    return null;
  }
);
