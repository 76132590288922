import {
  ChangeDetectorRef,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Component, Output, Input } from '@angular/core';
import { Member } from './member.class';
import { MembersService } from '../../services/members.service';
import { AppParamsService } from '../../services/app.params.service';
import { first } from 'rxjs/operators';
import { MemberDependent } from '@interfaces/member-dependent.model';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { Subscription } from 'rxjs';
import { BillingCodeService } from '@services/billing-code.service';

@Component({
  selector: 'app-global-members-dropdown',
  templateUrl: './global-members-dropdown.component.html',
  styleUrls: ['./global-members-dropdown.component.scss'],
})
export class GlobalMembersDropdownComponent implements OnInit, OnDestroy {
  @Input() public fullWidth: boolean = true;
  @Input() darkTheme?: boolean = false;

  @Output() selectedMember = new EventEmitter<MemberDependent>(true);

  public members: MemberDependent[];
  public selected: MemberDependent = new MemberDependent();

  private subscriptions = new SubscriptionManager();

  constructor(
    private membersService: MembersService,
    private appParamsService: AppParamsService,
    private changeDetector: ChangeDetectorRef,
    private billingCodeService: BillingCodeService
  ) {}

  ngOnInit() {
    this.subscriptions.add(this.subscribeToMembers());
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
  }

  public handleSelect(member: MemberDependent, skipDispatch?: boolean) {
    this.appParamsService.setMemberParams(member);
    this.selected = member;
    if (!skipDispatch) {
      this.billingCodeService.dispatchSelectedBillingCode(null);
    }
  }

  public emitMemberSelection(member: MemberDependent) {
    this.selectedMember.emit(member);
  }

  public trackByFn(index: number, member: MemberDependent): string {
    return member.member_number;
  }

  private subscribeToMembers(): Subscription {
    return this.membersService.member
      .pipe(first((member) => !!member))
      .subscribe((member) => this.initializeMembers(member));
  }

  private initializeMembers(member: Member): void {
    this.members = member.dependents;
    this.handleSelect(
      this.findMember(
        this.members,
        this.appParamsService.getSelectedMemberNumber()
      ),
      true
    );
    this.changeDetector.detectChanges();
  }

  private findMember(
    members: MemberDependent[],
    memberNumber: string
  ): MemberDependent {
    if (memberNumber) {
      return members.find((member) => member.member_number === memberNumber);
    }
    return members[0];
  }
}
