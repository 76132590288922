import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent {
  @Output() public closeCalled = new EventEmitter<boolean>();
  @Input() public type: string = 'icon';
  @Input() public closeText: string;
  @Input() public contrast: 'light' | 'dark' = 'light';
}
