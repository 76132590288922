import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SortConfig } from '@interfaces/sort-config.model';
import {
  SearchFilter,
  setDefaultSelected,
} from '@interfaces/search-filter.model';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';
import searchTypeMap from '@utilities/search-type-map.utilities';
import { cloneDeep, filter, includes, isEqual } from 'lodash';
import { Network } from '@interfaces/network.model';
import { ConfigSearchFiltersService } from '@services/config-search-filters/config-search-filters.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchFiltersSettings {
  // Settings
  public search_radius = this.mapSearchRadiusConfig(
    require('./search_radius.config.json')
  );
  public search_sort = new SortConfig({});
  public searchSort: BehaviorSubject<SortConfig> = new BehaviorSubject(
    new SortConfig({})
  );
  public searchFilters: BehaviorSubject<SearchFilter[]> = new BehaviorSubject(
    undefined
  );

  public resolved: Observable<boolean> = this.resolvedSearchFiltersSettings();

  // For Default Settings
  private setDefaultSelected = setDefaultSelected;
  private incentivizedRadius: string = null;

  constructor(private configSearchFiltersService: ConfigSearchFiltersService) {}

  public setIncentivizedRadius(radius: number): void {
    this.incentivizedRadius = radius ? radius.toString() : null;
  }

  public getIncentivizedRadius(): string {
    return this.incentivizedRadius;
  }

  public checkConfigToHideOrDisable(
    searchParamType: SearchParamType,
    selectedFilters: any,
    filtersFromStore: SearchFilter[] = null
  ): Observable<SearchFilter[]> {
    const filters = filtersFromStore
      ? filtersFromStore
      : this.searchFilters.getValue();
    return of(
      filter(filters, (configItem: SearchFilter) => {
        const mappedType = searchTypeMap[searchParamType] || searchParamType;
        this.disableFilters(mappedType, configItem, selectedFilters);
        if (configItem.type === 'nested_dropdown') {
          configItem.items = this.removeHiddenNested(
            searchParamType,
            configItem,
            mappedType
          );
        }
        const routeMatch = configItem.facet === searchParamType;
        return !(routeMatch || includes(configItem.hide, mappedType));
      })
    );
  }

  public getSortDefaultByType(
    searchParamType: SearchParamType
  ): SearchSortOption {
    const mappedType = searchTypeMap[searchParamType] || searchParamType;
    const defaultOption = this.search_sort[mappedType + '_default'];
    if (defaultOption) {
      const sort_option = this.search_sort[mappedType].find(
        (option) => option.translation === defaultOption
      );
      if (sort_option && sort_option.query) {
        return sort_option;
      }
    }

    return this.search_sort.default[0];
  }

  public searchRadiusToString(): string {
    const searchRadiusString: string[] = [];
    this.search_radius.options.forEach((option) =>
      searchRadiusString.push(option.value)
    );
    return searchRadiusString.join(',');
  }

  public getFilterDefaultsAsFacets(searchParamType: SearchParamType): any {
    const defaults = {};
    this.searchFilters.getValue().filter((searchFilter: SearchFilter) => {
      if (searchFilter.default && searchFilter.default[searchParamType]) {
        defaults[searchFilter.facet] = this.setDefaultSelected(
          searchFilter,
          searchParamType
        );
        return true;
      }
      return false;
    });
    return defaults;
  }

  public mapSearchSortConfig(
    settings: SortConfig,
    network: Network
  ): SortConfig {
    settings = new SortConfig(settings);

    if (this.search_sort?.selected?.query) {
      settings.selected = this.search_sort.selected;
    }
    ['default', 'name', 'search_specialty_id', 'procedure_id', 'rates'].forEach(
      (type) => {
        settings[type] = cloneDeep(settings)[type].map(
          (setting: SearchSortOption) => {
            return new SearchSortOption(setting, network?.tier_code || '');
          }
        );
      }
    );

    settings = this.removeSortOptionsByType(settings);
    return settings;
  }

  public mapSearchFiltersConfig(
    settings: {
      [filter: string]: SearchFilter;
    },
    network: Network
  ): SearchFilter[] {
    const filters = [];
    const tierCode = network?.tier_code || '';

    for (const key in settings) {
      if (settings[key]) {
        filters.push(new SearchFilter(settings[key], tierCode));
      }
    }
    return filters.filter((obj) => obj.facet !== 'location_geo');
  }

  private disableFilters(
    searchParamType: SearchParamType,
    configItem: SearchFilter,
    selectedFilters: any
  ): void {
    configItem['disabled'] = false;
    if (includes(configItem.disable, searchParamType)) {
      if (selectedFilters[searchParamType]) {
        configItem['disabled'] = true;
        configItem['options'] = [];
      }
    }
  }

  private removeHiddenNested(
    searchParamType: SearchParamType,
    configItem: SearchFilter,
    mappedType: SearchParamType
  ): SearchFilter[] {
    return filter(configItem.items, (item) => {
      const routeMatch = item.facet === searchParamType;
      return !(routeMatch || includes(item.hide, mappedType));
    });
  }

  private mapSearchRadiusConfig(settings: any): SearchFilter {
    if (this.search_radius?.selected) {
      settings = { ...settings };
      settings.selected = this.search_radius.selected;
    }

    return new SearchFilter(settings);
  }

  private removeSortOptionsByType(settings: SortConfig): SortConfig {
    ['procedure_id', 'name', 'search_specialty_id', 'rates'].forEach(
      (type: SearchParamType) => {
        settings[type] = settings.default.filter(
          (setting: SearchSortOption) => {
            if (
              settings[type + '_remove'].indexOf(setting.translation) === -1
            ) {
              return setting;
            }
          }
        );
      }
    );

    return settings;
  }

  private resolvedSearchFiltersSettings(): Observable<boolean> {
    return this.configSearchFiltersService
      .listenToConfigSettingsFilters()
      .pipe(switchMap((filters) => this.mapSearchFilters(filters)));
  }

  private mapSearchFilters([
    network,
    search_filters,
    search_radius,
    search_sort,
  ]): Observable<boolean> {
    const mappedSearchFiltersConfig = this.mapSearchFiltersConfig(
      search_filters,
      network
    );
    if (!isEqual(mappedSearchFiltersConfig, this.searchFilters.getValue())) {
      this.searchFilters.next(mappedSearchFiltersConfig);
    }
    this.search_radius = this.mapSearchRadiusConfig(search_radius);
    this.search_sort = this.mapSearchSortConfig(search_sort, network);
    if (!isEqual(this.search_sort, this.searchSort.getValue())) {
      this.searchSort.next(this.search_sort);
    }
    return of(true);
  }
}
