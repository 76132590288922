import { Component, Input } from '@angular/core';

@Component({
  selector: 'platform-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent {
  @Input() public fullscreen = false;
  @Input() public contentSpacing = true;
  @Input() public backgroundColor: string;
  @Input() public backgroundColorMobile: string;
}
