<div
  class="popup"
  *ngIf="show"
  ngClass.lt-sm="mobile-popup"
  data-cy="fixed-tooltip.popup"
  clickOutsideExcludeClass="rating"
  (platformClickOutside)="closeTooltip()"
>
  <div class="pad all-2x">
    <app-close-button
      class="close-button"
      data-cy="fixed-tooltip.close"
      (closeCalled)="onClose()"
    >
    </app-close-button>
    <h5 class="popup-title" *ngIf="title">
      {{ title }}
    </h5>
    <p *ngIf="text" [innerHTML]="text | translate">
    </p>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <div fxFlex="nogrow" *ngIf="dontShowAgainBtn">
          <span
            class="dontShowAgainLink link-blue"
            (click)="dismissForSessionClick()"
          >
            {{ dontShowAgainText }}
          </span>
        </div>
        <div fxFlex="nogrow" *ngIf="showDismissButton">
          <zelis-button
            variant="stroked"
            color="accent"
            (clickFn)="dismissBtnClick()"
          >
            {{ 'app_global_dismiss' | translate }}
          </zelis-button>
        </div>
        <div fxFlex="nogrow" *ngIf="callToActionText">
          <zelis-button
            variant="flat"
            color="accent"
            data-cy="fixed-tooltip.action-button"
            (clickFn)="callToActionClick.emit()"
          >
            {{ callToActionText }}
          </zelis-button>
        </div>
      </div>
      <div fxFlex="nogrow" *ngIf="linkText">
        <a class="tooltip-link" tabindex="0" [attr.href]="link" target="_blank">
          {{ linkText }}
        </a>
      </div>
    </div>
  </div>
</div>
