import { createAction, props } from '@ngrx/store';
import { SearchFilter } from '@interfaces/search-filter.model';

export enum RadiusFiltersSearchActionsTypes {
  requestRadiusFilters = '[GET] Get all radius search filters from config',
  requestRadiusFiltersSuccess = '[GET] Get all radius search filters from config success',
  requestRadiusFiltersFailure = '[GET] Get all radius search filters from config failed',
  setSelectedSettingsFilters = '[SET] Set selected radius on radius search filters ',
}

export const requestRadiusSearchFiltersFromConfig = createAction(
  RadiusFiltersSearchActionsTypes.requestRadiusFilters
);

export const requestRadiusSearchFiltersFromConfigSuccess = createAction(
  RadiusFiltersSearchActionsTypes.requestRadiusFiltersSuccess,
  props<{ radius_filters: SearchFilter }>()
);

export const requestRadiusSearchFiltersFromConfigFailure = createAction(
  RadiusFiltersSearchActionsTypes.requestRadiusFiltersFailure,
  props<{ error: any }>()
);

export const setSelectedSettingsFiltersFromConfig = createAction(
  RadiusFiltersSearchActionsTypes.setSelectedSettingsFilters,
  props<{ selected_radius_filter: string }>()
);
