import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { GatedEntryOverlayComponent } from './gated-entry-overlay.component';
import { GatedEntryOverlayHomeModule } from './gated-entry-overlay-home/gated-entry-overlay-home.module';
import { PageContainerModule } from '@zelis/platform-ui-components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GatedEntryOverlayNetworkLocationModule } from './gated-entry-overlay-network-location/gated-entry-overlay-network-location.module';
import { GatedEntryOverlayAlphaPrefixModule } from './gated-entry-overlay-alpha-prefix/gated-entry-overlay-alpha-prefix.module';
import { DlsButtonModule } from '@zelis/dls/button';
import { MatIconModule } from '@angular/material/icon';
import { DlsTrapFocusModule } from '@zelis/dls/trap-focus';
import { OverlayHeaderModule } from '@components/overlay-header/overlay-header.module';

@NgModule({
  declarations: [GatedEntryOverlayComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    GatedEntryOverlayHomeModule,
    GatedEntryOverlayNetworkLocationModule,
    GatedEntryOverlayAlphaPrefixModule,
    PageContainerModule,
    BrowserAnimationsModule,
    DlsButtonModule,
    MatIconModule,
    DlsTrapFocusModule,
    OverlayHeaderModule,
  ],
  exports: [GatedEntryOverlayComponent],
})
export class GatedEntryOverlayModule {}
