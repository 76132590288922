import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CareGuidanceService } from '@services/care-guidance/care-guidance.service';
import { CareGuidanceFlags } from '@interfaces/care-guidance-flags.model';

@Injectable({
  providedIn: 'root',
})
export class CareGuidanceGuard implements CanActivate {
  constructor(
    private careGuidanceService: CareGuidanceService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.careGuidanceService
      .getEligibilityByType(route.routeConfig.path)
      .pipe(
        map((eligibility: CareGuidanceFlags) => {
          return (
            eligibility?.is_service_enabled ||
            this.router.createUrlTree(['/'], {
              queryParamsHandling: 'preserve',
            })
          );
        })
      );
  }
}
