import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SearchParamsService } from '../search-params.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Disclaimer } from '@components/disclaimers';
import { AppParamsService } from '@services/app.params.service';
import { cloneDeep, concat, sortBy } from 'lodash';

@Injectable()
export class SearchDisclaimersService {
  constructor(
    private searchParamsService: SearchParamsService,
    private http: HttpClient,
    private appParamsService: AppParamsService
  ) {}

  public getDisclaimers(selectedFilters: any): Observable<any> {
    return this.searchParamsService.lastSearchParams.pipe(
      filter(params => !!params),
      switchMap((params: HttpParams) => {
        if (this.appParamsService.params.corp_code) {
          params = params.set(
            'corp_code',
            this.appParamsService.params.corp_code
          );
        }
        const url = `/api/providers/disclaimers.json`;
        return this.http
          .get(url, { params: params, withCredentials: true })
          .pipe(
            catchError(() => of(null)),
            map((data: any) => {
              let disclaimerData = cloneDeep(data);
              disclaimerData = this.augmentTopResults(disclaimerData, selectedFilters);
              return disclaimerData.search_disclaimers;
            })
          );
      })
    );
  }

  private augmentTopResults(data: any, selectedFilters: any): any {
    if (!data) {
      data = { search_disclaimers: [] };
    }
    if (data.search_disclaimers === undefined) {
      data.search_disclaimers = [];
    }
    const top =
      data.search_disclaimers && data.search_disclaimers.top_results
        ? data.search_disclaimers.top_results
        : [];
    this.addBoardCertifiedDisclaimer(top, selectedFilters);
    const top_always =
      data.search_disclaimers && data.search_disclaimers.top_results_always
        ? data.search_disclaimers.top_results_always
        : [];
    data.search_disclaimers.top_results_mapped = sortBy(
      concat(top, top_always),
      ['level', 'priority']
    );
    return data;
  }

  private addBoardCertifiedDisclaimer(
    disclaimers: Disclaimer[],
    selectedFilters: any
  ): void {
    const boardCertDisclaimer = {
      text: 'search_filter_board_certified_disclaimer',
      level: 3,
    };
    const containsDisclaimer = () =>
      !!disclaimers.find(
        (disclaimer) => disclaimer.text === boardCertDisclaimer.text
      );
    if (
      selectedFilters &&
      selectedFilters.is_certified === 'Y' &&
      !containsDisclaimer()
    ) {
      disclaimers.push(boardCertDisclaimer);
    }
  }
}
