import { DirectionsSelectorTranslations } from './directions-selector.translations.model';
import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'platform-directions-selector',
  templateUrl: './directions-selector.component.html',
  styleUrls: ['./directions-selector.component.scss'],
})
export class DirectionsSelectorComponent {
  @Input() public origin: string;
  @Input() public destination: string;
  @Input()
  public set services(value: string[]) {
    this._services = value || this.defaultServices;
  }
  public get services(): string[] {
    return this._services;
  }
  @Input()
  public set translations(value: DirectionsSelectorTranslations) {
    this._translations = new DirectionsSelectorTranslations(value);
  }
  public get translations(): DirectionsSelectorTranslations {
    return this._translations;
  }
  @Input() public assetPath = './assets/directions-selector/assets/';

  private defaultServices = ['google', 'apple', 'bing'];
  private serviceProps = {
    google: {
      name: 'Google Maps',
      urlFn: this.googleUrl,
      icon: 'icon-google-maps.svg',
    },
    apple: {
      name: 'Apple Maps',
      urlFn: this.appleUrl,
      icon: 'icon-apple-maps.svg',
    },
    bing: {
      name: 'Bing Maps',
      urlFn: this.bingUrl,
      icon: 'icon-bing-maps.svg',
    },
  };
  private _services: string[];
  private _translations = new DirectionsSelectorTranslations();

  constructor(private device: DeviceDetectorService) {}

  public serviceEnabled(service: string): boolean {
    if (service === 'apple') {
      return this.device.os.toLowerCase() === 'mac';
    }
    return true;
  }

  public serviceName(service: string): string {
    return this.serviceProps[service].name;
  }

  public serviceUrl(
    service: string,
    origin: string,
    destination: string
  ): string {
    return this.serviceProps[service].urlFn(origin, destination);
  }

  public serviceTarget(service: string): string {
    if (service === 'apple') {
      return '_self';
    }
    return '_blank';
  }

  public serviceIcon(service: string): string {
    return this.serviceProps[service].icon;
  }

  public trackByFn(index, item): string {
    return item;
  }

  // Reference: https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html#//apple_ref/doc/uid/TP40007899-CH5-SW1
  private appleUrl?(origin: string, destination: string): string {
    const params = [];
    if (origin) {
      params.push(`saddr=${encodeURI(origin)}`);
    }
    if (destination) {
      params.push(`daddr=${encodeURI(destination)}`);
    }
    return `https://maps.apple.com/?${params.join('&')}`;
  }

  // Reference: https://developers.google.com/maps/documentation/urls/guide
  private googleUrl?(origin: string, destination: string): string {
    const params = [];
    if (origin) {
      params.push(`origin=${encodeURI(origin)}`);
    }
    if (destination) {
      params.push(`destination=${encodeURI(destination)}`);
    }
    return `https://www.google.com/maps/dir/?api=1&${params.join('&')}`;
  }

  // Reference: https://msdn.microsoft.com/en-us/library/dn217138.aspx
  private bingUrl?(origin: string, destination: string): string {
    if (origin) {
      origin = `adr.${encodeURI(origin)}`;
    } else {
      origin = '';
    }
    if (destination) {
      destination = `adr.${encodeURI(destination)}`;
    } else {
      destination = '';
    }
    return `https://bing.com/maps/default.aspx?rtp=${origin}~${destination}`;
  }
}
