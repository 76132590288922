export class MapColors {
  primary?: string;
  primaryContrast?: string;
  accent?: string;
  accentContrast?: string;
  incentive?: string;
  incentiveContrast?: string;

  constructor(obj: MapColors = {}) {
    this.primary = obj.primary || '#3e0b52'; // Purple
    this.primaryContrast = obj.primaryContrast || '#ffffff'; // White
    this.accent = obj.accent || '#007bb7'; // Blue
    this.accentContrast = obj.accentContrast || '#ffffff'; // White
    this.incentive = obj.incentive || '#2e7d32'; // Green
    this.incentiveContrast = obj.incentiveContrast || '#ffffff'; // White
  }
}
