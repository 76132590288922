import { Injectable } from '@angular/core';
import { NetworksService } from '../services/networks.service';
import { Resolve } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Network } from '@interfaces/network.model';

@Injectable()
export class NetworkResolver implements Resolve<any> {
  constructor(private networksService: NetworksService) {}

  resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networksService.resolvedNetwork
        .pipe(filter((network: Network) => !!network))
        .subscribe((network) => resolve(network));
    });
  }
}
