<div class="gps-error-overlay text-center">
  <mat-dialog-content>
    <mat-icon
      data-cy="icon-close"
      svgIcon="dls-times-plain-light"
      (click)="dialogRef.close()"
      (keydown.enter)="dialogRef.close()"
      [attr.aria-label]="'app_global_close' | translate"
    >
    </mat-icon>
    <div class="gps-error-header">
      <span>{{ 'app_global_location' | translate }}</span>
    </div>
    <div class="gps-error-underline">
      <div class="gps-error-underline-bar"></div>
    </div>
    <div class="gps-error-difficulty">
      <h4 [innerHTML]="'gps_error_having_difficulty' | translate"></h4>
    </div>
    <div class="gps-error-refresh">
      <p>{{ 'gps_error_try_refresh' | translate }}</p>
    </div>
    <div class="gps-error-browser">
      <p>{{ 'gps_error_browser_support' | translate }}</p>
    </div>
  </mat-dialog-content>
</div>
