import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { MatRadioModule } from '@angular/material/radio';
import {
  DlsListItemModule,
  ListItemComponent,
  ListItemSubheadComponent,
} from '@zelis/dls/list-item';
import { MatDivider, MatDividerModule } from '@angular/material/divider';

@NgModule({
  imports: [CommonModule, MatRadioModule, DlsListItemModule, MatDividerModule],
  declarations: [ListComponent],
  exports: [
    ListComponent,
    ListItemComponent,
    ListItemSubheadComponent,
    MatDivider,
  ],
})
export class DlsListModule {}
