import { GuidedSearchDialogComponent } from './guided-search-dialog.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { GuidedSearchModule } from '@components/guided-search/guided-search/guided-search.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CloseButtonModule } from '@components/close-button/close-button.module';

@NgModule({
  declarations: [GuidedSearchDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    GuidedSearchModule,
    MatDialogModule,
    CloseButtonModule,
  ],
  exports: [GuidedSearchDialogComponent],
})
export class GuidedSearchDialogModule {}
