export class SearchSortOption {
  query?: string;
  translation?: string;
  random_sort_seed?: boolean;
  tier_header?: boolean;
  tooltip?: string;

  constructor(obj?: any, tier_code?: string) {
    this.query = mapQuery(obj.query, tier_code);
    this.translation = obj.translation || null;
    this.random_sort_seed = obj.random_sort_seed || null;
    this.tier_header = obj.tier_header || null;
    this.tooltip = obj.tooltip || null;
  }
}

// This handles mapping dynamic tier code to the sort query.
// This param is sent to the API as: &sort=tiers:<tier_code>
const mapQuery = (query: string, tier_code: string): string => {
  if (query && query.indexOf('tiers') > -1 && tier_code) {
    return query
      .split(' ')
      .map((piece) => {
        if (
          piece.indexOf('tiers:') > -1 ||
          piece.indexOf('tiers_sortable:') > -1
        ) {
          piece =
            piece.split(':')[0] +
            ':' +
            (tier_code ? tier_code.toLowerCase() : '');
        }
        return piece;
      })
      .join(' ');
  }
  return query;
};
