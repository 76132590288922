<div *ngIf="chatStarted | async" class="chat-window" ngClass.lt-md="mobile">
  <mat-expansion-panel
    [expanded]="isConnected | async"
    hideToggle
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      class="bg-primary-color"
      fxLayoutAlign="start center"
    >
      <mat-panel-title fxLayoutAlign="start center">
        <i
          class="icon-comment-2 text-accent-color margin right-1x"
          [ngClass]="{
            'text-reward-color': (isConnected | async) && !agentIsUnavailable,
            'text-danger-color': agentIsUnavailable
          }"
        ></i>
        <span *ngIf="agentIsUnavailable">{{
          'pat_chat_unavailable' | translate
        }}</span>
        <span *ngIf="!agentIsUnavailable">
          <span *ngIf="(positionInQueue | async) > 0 && (isConnected | async)">
            {{ 'pat_chat_queue_position' | translate: { position: (positionInQueue | async) } }}
          </span>
          <span *ngIf="(isConnected | async) === false">{{
            'pat_chat_ended' | translate
          }}</span>
          <span
            *ngIf="
              (isConnected | async) &&
              ((positionInQueue | async) === null ||
                (positionInQueue | async) === 0) &&
              (isEstablished | async) === false
            "
          >
            {{ 'pat_chat_finding_agent' | translate }}
            <span class="loading_elipsis"></span>
          </span>
          <span *ngIf="!messages.length && (isEstablished | async)">
            {{ 'pat_chat_connecting_agent' | translate }}
            <span class="loading_elipsis"></span>
          </span>
          <span
            class="text-bolder"
            *ngIf="messages.length && (isConnected | async)"
          >
            {{ chatService.agentName }}
          </span>
        </span>
      </mat-panel-title>

      <mat-panel-description class="margin right-0x flex-grow" fxLayoutAlign="end center">
        <zelis-button
          variant="stroked"
          color="accent"
          forwardedClass="btn-danger"
          *ngIf="isConnected | async"
          (clickFn)="endChat($event)"
        >
          {{ 'pat_chat_end_chat' | translate }}
        </zelis-button>
        <zelis-button
          variant="stroked"
          color="primary"
          *ngIf="(isConnected | async) === false"
          (clickFn)="closeChat()"
        >
          {{ 'app_global_close' | translate }}
        </zelis-button>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="scroll-content" ngx-auto-scroll>
      <h3 *ngIf="agentIsUnavailable">{{
        'pat_chat_no_agents' | translate
        }}<a
            *ngIf="('smartshopper_pat_phone' | translate).length > 0"
            class="ga-pat-chat-phone ga-pat-phone"
            href="tel:{{ 'smartshopper_pat_phone' | translate }}"
          >
            {{ 'smartshopper_pat_phone' | translate }} </a
          >.
      </h3>
      <app-chat-message
        *ngFor="let message of messages"
        [message]="message"
        [inbound]="message.inbound"
      >
      </app-chat-message>
    </div>

    <mat-action-row>
      <form
        [formGroup]="form"
        autocomplete="off"
        novalidate
        (submit)="sendMessage()"
        fxLayoutGap="8px"
      >
        <mat-form-field appUnfocus fxFlex="100%" subscriptSizing="dynamic">
          <input
            class="message-input p-0"
            matInput
            type="text"
            formControlName="message"
          />
          <mat-error>
            <span *ngIf="form.controls['message'].errors">
              {{ 'pat_chat_message_not_sent' | translate }}
            </span>
          </mat-error>
        </mat-form-field>
        <zelis-button
          color="primary"
          class="btn-send"
          [disabled]="form.pristine"
          type="submit"
        >
          <span *ngIf="!messageIsSending" class="icon-paper-plane"></span>
          <zelis-progress-spinner
            *ngIf="messageIsSending"
            [diameter]="24"
            [strokeWidth]="2"
          ></zelis-progress-spinner>
        </zelis-button>
      </form>
    </mat-action-row>
  </mat-expansion-panel>
</div>
