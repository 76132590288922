import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MobileActionButtonsComponent } from './mobile-action-buttons.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  declarations: [MobileActionButtonsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
    MatBottomSheetModule,
  ],
  providers: [],
  exports: [MobileActionButtonsComponent],
})
export class MobileActionButtonsModule {}
