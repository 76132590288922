import { Component, Input, HostBinding } from '@angular/core';
export type SpinnerTheme = 'primary' | 'accent' | 'warn';
export type SpinnerMode = 'determinate' | 'indeterminate';

@Component({
  selector: 'zelis-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent {
  @HostBinding() class = 'zelis-dls';
  @Input() color?: SpinnerTheme;
  @Input() mode: SpinnerMode = 'indeterminate';
  @Input() value = 0;
  @Input() strokeWidth?: number;
  @Input() diameter = 100;
  @Input() label = 'progress spinner';
  @Input() dataCy = 'progress-spinner';
}
