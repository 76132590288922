export class AmenityTranslations {
  public amenities?: string;
  public showMore?: string;
  public showLess?: string;
  public adaHeader?: string;
  public adaDescription?: string;
  public adaYes?: string;
  public adaNo?: string;

  constructor(obj?: AmenityTranslations) {
    obj = obj || ({} as AmenityTranslations);
    this.amenities = obj.amenities || 'Location Amenities';
    this.showMore = obj.showMore || 'View More';
    this.showLess = obj.showLess || 'View Less';
    this.adaHeader = obj.adaHeader || 'ADA Building Access';
    this.adaDescription =
      obj.adaDescription ||
      "Listed below are the areas of this provider's facility that are accessible according to the American Disability Act (ADA)";
    this.adaYes = obj.adaYes || 'Yes';
    this.adaNo = obj.adaNo || 'No/Unknown';
  }
}
