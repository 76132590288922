export enum MarketSegment {
  employer = 'Employer',
  retail = 'Retail',
  medicare = 'Medicare',
  medicaid = 'Medicaid'
}

export enum SubMarketSegment {
  retailMedicare = 'Retail Medicare',
  groupMedicare = 'Group Medicare',
}

export enum ProductType {
  hmo = 'HMO',
  ppo = 'PPO',
  pos = 'POS',
  all = 'ALL'
}
