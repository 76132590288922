<zelis-card
  tabindex="0"
  class="focus:outline-system-blue focus:rounded-lg hover:cursor-pointer"
  data-cy="card"
  [stroke]="true"
  (click)="handleSubmit($event)"
  (keydown.enter)="handleSubmit($event)"
>
  <div
    class="text-left text-palette-m3-system-gray-10 w-full min-h-[160px] p-4 grid grid-cols-[1fr_min-content] grid-rows-[min-content_1fr]">
    <h4 class="heading-4 font-bold">{{title}}</h4>
    <div
      class="flex flex-col row-span-2"
      [ngClass]="breakpoints.isMobile ? 'justify-center' : 'justify-end'"
    >
      <div *ngIf="breakpoints.isMobile">
        <mat-icon svgIcon="dls-angle-plain-right-light"></mat-icon>
      </div>

      <div *ngIf="breakpoints.isDesktop">
        <p class="text-palette-m3-system-blue-40"> {{ctaText}}</p>
      </div>
    </div>
    <p class="text-base pt-2 pr-2">{{description}}</p>
  </div>
</zelis-card>