<ng-container *ngIf="!displayMultipleLang">
  <div
    data-cy="mobile-header-option.en"
    class="mobile-header-option"
    (click)="setLanguage('en')"
    tabindex="0"
    role="link"
  >
    <span class="text-medium">English</span>
  </div>
  <div
    data-cy="mobile-header-option.es"
    class="mobile-header-option"
    (click)="setLanguage('es')"
    tabindex="0"
    role="link"
  >
    <span class="text-medium">Español</span>
  </div>
</ng-container>
<ng-container *ngIf="displayMultipleLang">
  <div
    *ngFor="let language of multipleLangArray; trackBy: trackByLanguage"
    class="mobile-header-option"
    (click)="setLanguage(language.code)"
  >
    <span class="text-medium" tabindex="0" role="link">{{
      language.translation
    }}</span>
  </div>
</ng-container>
<div
  *ngIf="meaningfulAccessLanguage"
  class="mobile-header-option more-languages"
  mat-menu-item
  (click)="openLanguagesOverlay()"
>
  <i class="icon-Q-and-A pad right-1x" aria-hidden="true"></i>
  <span class="text-medium">{{
    'global_header_more_languages' | translate
  }}</span>
</div>
