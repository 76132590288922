import { SubscriptionManager } from './../utility/subscription-manager.utility';
import { MapService } from './map.service';
import { FeatureCollection, Feature, Point } from 'geojson';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import {
  Map,
  LngLatBounds,
  FitBoundsOptions,
  LngLatLike,
  LngLat,
} from 'mapbox-gl';
import {
  MapColors,
  MapSvgIcon,
  MapDefaultSvgIcons,
  MapMoveEvent,
  MapTranslations,
  MapFeatureProperties,
  MapFitBounds,
  MapMoveTo,
} from './map.interfaces';
import { MapError } from './map-error.interface';
import { GeoJSONSourceComponent } from 'ngx-mapbox-gl';
import { from, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'platform-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class MapComponent implements OnInit, OnDestroy {
  @Input()
  set geoJson(data: FeatureCollection<Point>) {
    if (this._geoJson) {
      this.deselectAllFeatures();
      this.animate = true;
    }
    this._geoJson = data;
    this.setBounds(data);
    setTimeout(() => this.changeDetectorRef.markForCheck());
  }
  get geoJson(): FeatureCollection<Point> {
    return this._geoJson;
  }
  @Input() fitBoundsOptions: FitBoundsOptions = {
    padding: 100,
    offset: [0, 0],
    animate: false,
  };
  @Input()
  set colors(colors: MapColors) {
    this._colors = new MapColors(colors);
  }
  get colors(): MapColors {
    return this._colors;
  }
  @Input() geolocateControl = false;
  @Input() hideMapboxControls = true;
  @Input()
  set translations(translations: MapTranslations) {
    this._translations = new MapTranslations(translations);
  }
  get translations(): MapTranslations {
    return this._translations;
  }
  @Input() assetPath = './assets/map/assets/';
  @Input()
  set icons(icons: MapSvgIcon[]) {
    this._icons = icons;
  }
  get icons(): MapSvgIcon[] {
    return this._icons;
  }
  @Input() markerPopups = true;

  @Output() move: EventEmitter<MapMoveEvent> = new EventEmitter(true);
  @Output() featureSelect: EventEmitter<MapFeatureProperties> =
    new EventEmitter(true);
  // 'inactive' | 'starting' | 'active' | 'background' | 'unavailable'
  @Output() geolocateStateChange: EventEmitter<string> = new EventEmitter(true);
  @Output() mapError: EventEmitter<MapError> = new EventEmitter(true);
  @Output() mapReady: EventEmitter<Map> = new EventEmitter(true);

  @ViewChild(GeoJSONSourceComponent) geoJsonSource: GeoJSONSourceComponent;

  public style: string = this.mapService.mapTypes.street;
  public center: LngLatLike;
  public zoom: number[];
  public maxZoom = 16;
  public minZoom = 4;
  public geoJsonBounds: LngLatBounds;
  public selectedCluster: Feature<Point>;
  public selectedMarker: Feature<Point>;
  public set animate(animate: boolean) {
    this._animate = animate;
    this.fitBoundsOptions.animate = animate;
  }
  public get animate(): boolean {
    return this._animate;
  }
  public browserSupported = false;
  public clusterProperties: any;

  private _geoJson: FeatureCollection<Point>;
  private _colors: MapColors = new MapColors();
  private _icons: MapSvgIcon[];
  private _animate: boolean;
  private _translations: MapTranslations = new MapTranslations();
  private subs: SubscriptionManager = new SubscriptionManager();
  private map: Map;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private afterMoveQueue: Function[] = [];

  constructor(
    private mapService: MapService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.setClusterProperties();
    this.animate = false;
    this.icons = MapDefaultSvgIcons;
    this.setDefaultCenterAndZoom();
  }

  ngOnInit() {
    this.prepareIcons(this.icons);
    this.checkBrowserSupport();
    this.subscribeToControls();
  }

  ngOnDestroy() {
    this.subs.destroy();
  }

  public onMapLoad(instance: Map): void {
    this.map = instance;
    this.mapService.setMap(this.map);
    this.mapReady.emit(this.map);
    this.setupGeolocateControl();
  }

  public onMapMoveStart(): void {
    if (this.map) {
      this.runBeforeMove();
      this.move.emit({
        start: true,
        stop: false,
        coordinates: this.map.getCenter(),
        distance: this.getDistanceFromBoundsCenter(),
        zoom: this.map.getZoom(),
        maxZoom: this.map.getZoom() === this.map.getMaxZoom(),
        minZoom: this.map.getZoom() === this.map.getMinZoom(),
      });
    }
  }

  public onMapMoveEnd(): void {
    if (this.map) {
      this.move.emit({
        start: false,
        stop: true,
        coordinates: this.map.getCenter(),
        distance: this.getDistanceFromBoundsCenter(),
        zoom: this.map.getZoom(),
        maxZoom: this.map.getZoom() === this.map.getMaxZoom(),
        minZoom: this.map.getZoom() === this.map.getMinZoom(),
      });
      this.runAfterMove();
    }
  }

  public onMapClick(): void {
    if (this.map) {
      this.deselectAllFeatures();
    }
  }

  public onClusterClick(feature: Feature<Point>): void {
    if (this.map && feature) {
      const clusterFeature = this.getClusterChildrenAndExpansionZoom(feature);
      const clusterFeatureSub = clusterFeature.subscribe(
        ([clusterChildren, clusterZoom]) => {
          this.selectOrZoomIntoCluster(feature, clusterChildren, clusterZoom);
        }
      );
      this.subs.add(clusterFeatureSub);
    }
  }

  public onClusterFeatureClick(properties: MapFeatureProperties): void {
    this.onMarkerClick(properties.id, false, false);
    properties.selected = true;
    this.selectedCluster.properties.markers.map((marker) =>
      marker.id !== properties.id ? (marker.selected = false) : true
    );
  }

  public onMarkerClick(
    id: number,
    focus: boolean = false,
    select: boolean = true
  ): void {
    if (this.map) {
      let selectedProperties: MapFeatureProperties = null;
      const feature = this.mapService.getFeature(this.geoJson, id);
      if (feature) {
        this.mapService.selectFeature(this.geoJson, id);
        selectedProperties = feature.properties as MapFeatureProperties;
        if (focus) {
          this.animate = true;
          this.center = new LngLat(
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1]
          );
          this.zoom = [this.maxZoom];
          this.queueAfterMove(() => {
            this.selectedMarker = this.mapService.selectFeature(
              this.geoJson,
              feature.properties.id
            );
            const clusterFeature = this.mapService.getClusterAtPoint(
              feature.geometry.coordinates
            );
            this.onClusterClick(clusterFeature);
          });
        }
        if (select && !focus) {
          this.selectedMarker = this.mapService.selectFeature(
            this.geoJson,
            feature.properties.id
          );
        }
        this.featureSelect.emit(selectedProperties);
      } else {
        this.deselectAllFeatures();
      }
    }
  }

  public onMouseEnter(): void {
    if (this.map) {
      this.map.dragPan.disable();
    }
  }

  public onMouseLeave(): void {
    if (this.map) {
      this.map.dragPan.enable();
    }
  }

  public trackByProviderId(
    index: number,
    properties: MapFeatureProperties
  ): number {
    return properties.id;
  }

  private checkBrowserSupport(): void {
    this.browserSupported = this.mapService.getBrowserSupport();
    if (!this.browserSupported) {
      this.mapError.emit({
        code: 'BROWSER_NOT_SUPPORTED',
        message:
          'Browser is not supported or dramatically low performance detected. Map cannot be rendered.',
      });
    }
  }

  private subscribeToControls(): void {
    this.subscribeToFitBounds();
    this.subscribeToMoveTo();
    this.subscribeToMapType();
    this.subscribeToFeatureSelect();
  }

  private subscribeToFeatureSelect(): void {
    this.subs.add(
      this.mapService.subjectFeatureSelect.subscribe((id: number) => {
        this.onMarkerClick(id, true);
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private subscribeToMapType(): void {
    this.subs.add(
      this.mapService.subjectMapType.subscribe((style: string) => {
        this.style = style;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private subscribeToMoveTo(): void {
    this.subs.add(
      this.mapService.subjectMoveTo.subscribe((move: MapMoveTo) => {
        this.animate = move.animate;
        if (move.center) {
          this.center = new LngLat(move.center.lng, move.center.lat);
        }
        if (move.zoom) {
          this.zoom = [move.zoom];
        }
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private subscribeToFitBounds(): void {
    this.subs.add(
      this.mapService.subjectFitBounds.subscribe((fit: MapFitBounds) => {
        this.animate = fit.animate;
        this.geoJsonBounds = fit.bounds;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private prepareIcons(icons: MapSvgIcon[]): void {
    this.mapService
      .prepareIcons(icons, this.colors, this.assetPath)
      .subscribe();
  }

  private setBounds(geoJson: FeatureCollection<Point>): void {
    const bounds = this.mapService.setGeoJsonAndBounds(
      geoJson,
      this.fitBoundsOptions
    );
    setTimeout(() => (this.geoJsonBounds = bounds));
  }

  private deselectAllFeatures(): void {
    if (this.mapService.deselectAllFeatures(this.geoJson)) {
      this.selectedMarker = null;
      this.featureSelect.emit(null);
    }
    this.selectedCluster = null;
  }

  private setupGeolocateControl(): void {
    if (this.geolocateControl) {
      this.mapService.addGeolocateControl(this.geolocateStateChange);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private runBeforeMove(): void {}

  private runAfterMove(): void {
    this.map.dragPan.enable();
    if (this.afterMoveQueue.length) {
      setTimeout(() => {
        this.afterMoveQueue.forEach((event) => event());
        this.resetAfterMove();
        this.map.resize();
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private queueAfterMove(event): void {
    this.afterMoveQueue.push(event);
  }

  private resetAfterMove(): void {
    this.afterMoveQueue = [];
  }

  private getDistanceFromBoundsCenter(): number {
    if (this.geoJsonBounds && !this.geoJsonBounds.isEmpty()) {
      return this.mapService.getDistance(
        this.geoJsonBounds.getCenter(),
        this.map.getCenter()
      );
    }
    return 0;
  }

  private setDefaultCenterAndZoom(): void {
    if (!this.geoJson) {
      this.center = new LngLat(-95.2762572, 38.4789934); // USA
      this.zoom = [3];
    }
  }

  private selectOrZoomIntoCluster(
    feature: Feature<Point>,
    clusterChildren: any,
    clusterZoom: number
  ): void {
    const currentZoom = this.map.getZoom();
    const maxZoom = this.map.getMaxZoom();
    if (currentZoom === maxZoom) {
      // Show cluster features popup
      this.selectCluster(feature, clusterChildren);
    } else {
      // Zoom/pan into cluster features
      this.zoomIntoCluster(feature, clusterZoom);
    }
    this.changeDetectorRef.markForCheck();
  }

  private zoomIntoCluster(feature: Feature<Point>, clusterZoom: number): void {
    this.animate = true;
    this.center = [
      feature.geometry.coordinates[0],
      feature.geometry.coordinates[1],
    ];
    this.zoom = [clusterZoom];
    this.queueAfterMove(() => {
      const clusterAtPoint = this.mapService.getClusterAtPoint(
        feature.geometry.coordinates
      );
      this.onClusterClick(clusterAtPoint);
    });
  }

  private selectCluster(feature: Feature<Point>, clusterChildren: any) {
    this.selectedCluster = feature;
    this.selectedCluster.properties.markers = clusterChildren;
  }

  private getClusterChildrenAndExpansionZoom(
    feature: Feature<Point>
  ): Observable<[any, number]> {
    const clusterId = feature.properties.cluster_id;
    const clusterChildrenSource = from(
      this.geoJsonSource.getClusterChildren(clusterId)
    ).pipe(map((children) => children.map((child) => child.properties)));
    const clusterExpansionZoomSource = from(
      this.geoJsonSource.getClusterExpansionZoom(clusterId)
    );
    const clusterFeature = forkJoin([
      clusterChildrenSource,
      clusterExpansionZoomSource,
    ]);
    return clusterFeature;
  }

  private setClusterProperties(): void {
    this.clusterProperties = {
      incentive: this.getClusterIncentiveExpression(),
    };
  }

  private getClusterIncentiveExpression(): any {
    const incentiveFilter = ['==', ['get', 'incentive'], true];
    const incentiveAccumulator = ['+', ['case', incentiveFilter, 1, 0]];
    return ['>', incentiveAccumulator, 0];
  }
}
