export class AppParams {
  id?: string;
  ci?: string;
  corp_code?: string;
  name?: string;
  provider_id?: string;
  location_id?: string;
  contract_id?: string;
  network_id?: string;
  geo_location?: string;
  locale?: string;
  procedure_id?: string;
  search_specialty_id?: string;
  hospital_affiliation_ids?: string;
  group_affiliation_ids?: string;
  identifier?: string;
  page?: string;
  filters?: string;
  radius?: string;
  referrer?: string;
  data_language?: string;
  transaction_id?: string;
  member_number?: string;
  linkback_url?: string;
  billing_code?: string;
  state?: string;

  constructor(obj?: AppParams) {
    if (obj) {
      this.id = obj.id || null;
      this.ci = obj.ci || null;
      this.corp_code = obj.corp_code || null;
      this.name = obj.name || null;
      this.provider_id = obj.provider_id || null;
      this.location_id = obj.location_id || null;
      this.contract_id = obj.contract_id || null;
      this.network_id = obj.network_id || null;
      this.geo_location = obj.geo_location || null;
      this.locale = obj.locale || 'en';
      this.procedure_id = obj.procedure_id || null;
      this.search_specialty_id = obj.search_specialty_id || null;
      this.hospital_affiliation_ids = obj.hospital_affiliation_ids || null;
      this.group_affiliation_ids = obj.group_affiliation_ids || null;
      this.identifier = obj.identifier || null;
      this.page = obj.page || null;
      this.filters = obj.filters || null;
      this.referrer = obj.referrer || null;
      this.radius = this.getRadius(obj.radius, this.filters);
      this.data_language = obj.data_language || null;
      this.transaction_id = obj.transaction_id || null;
      this.member_number = obj.member_number || null;
      this.linkback_url = obj.linkback_url || null;
      this.billing_code = obj.billing_code || null;
      this.state = obj.state || null;
    }
  }

  // Parse radius from filters (JSON) if it exists.
  private getRadius?(radius: string, filters: string): string {
    let parsedFilters: any = null;
    try {
      parsedFilters = JSON.parse(filters);
    } catch (e) {}
    if (parsedFilters && parsedFilters.radius) {
      return parsedFilters.radius;
    }
    return radius || null;
  }
}
