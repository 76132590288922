export class TrackByUtilities {
  /**
   *
   * @param property (string) Collection property to track by
   * @returns trackBy function
   */
  public static getTrackByFn(property: string): any {
    return (index: number, item: any) => item[property];
  }

  public static trackByFnItemId(index: number, item: any): number {
    return item.id;
  }

  // Only use as a last resort if no unique identifier is available, such as tracking an array of strings
  public static trackByFnIndex(index): number {
    return index;
  }
}
