export class BalancesTranslations {
  public heading?: string;
  public subHeading?: string;
  public callToActionText?: string;
  public callToActionUrl?: string;
  public tier1Name?: string;
  public tier2Name?: string;
  public tier3Name?: string;
  public individualName?: string;
  public family?: string;
  public deductible?: string;
  public outOfPocket?: string;
  public spent?: string;
  public remaining?: string;
  public total?: string;
  public met?: string;

  constructor(translations?: BalancesTranslations) {
    translations = translations || {};
    this.heading = translations.heading || 'Your Remaining Balances';
    this.subHeading =
      translations.subHeading ||
      'You have a high-deductible plan – you might owe more money if you visit a high cost provider.';
    this.callToActionText =
      translations.callToActionText || 'View plan details';
    this.callToActionUrl =
      translations.callToActionUrl || 'https://www.vitals.com';
    this.tier1Name = translations.tier1Name || 'Preferred Care';
    this.tier2Name = translations.tier2Name || 'Standard Care';
    this.tier3Name = translations.tier3Name || 'Low Tier Care';
    this.individualName = translations.individualName || 'Individual';
    this.family = translations.family || 'Family';
    this.deductible = translations.deductible || 'Deductible';
    this.outOfPocket = translations.outOfPocket || 'Out of Pocket Max';
    this.spent = translations.spent || 'Spent';
    this.remaining = translations.remaining || 'Remaining';
    this.total = translations.total || 'Total';
    this.met = translations.met || 'Met';
  }
}
