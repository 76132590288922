import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'platform-profile-card',
  styleUrls: ['./profile-card.component.scss'],
  templateUrl: './profile-card.component.html',
})
export class ProfileCardComponent {
  @Input() public expanded = true;
  @Input() public headerText: string;
  @Input() public icon: string;
  @Input() public style = 'fixed';
  @Input() public display = 'card-title';
  @Input() public fixed = true;

  constructor(public elementRef: ElementRef) {
    this.headerText = 'Default Header';
    this.icon = 'phone_android';
  }
}
