import { filter, catchError, switchMap, take, map, withLatestFrom } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppParamsService } from './app.params.service';
import { AppParams } from '@interfaces/app.interface.appParams';
import { ConfigurationParams } from '@interfaces/configuration-params.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SignatureResponse } from '@interfaces/signature-response.model';
import { CriticalParamsService } from './critical-params/critical-params.service';
import { isEqual } from 'lodash';
import { AppConfigService } from './app.config.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  public signature = new BehaviorSubject('');
  public signatureAppParamsResolved = new BehaviorSubject<string>('');
  public account_id = new BehaviorSubject<string>('');
  private previousParams: ConfigurationParams;
  private previousCi: string;
  private signatureResolving = new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private appParamsService: AppParamsService,
    private criticalParamsService: CriticalParamsService,
    private appConfigService: AppConfigService
  ) {}

  public signatureResolved(): Observable<boolean>{
    return this.signatureResolving
      .pipe(
        withLatestFrom(this.criticalParamsService.getResolvedCriticalParams()),
        filter(([resolving, criticalParams]) => !resolving && !!criticalParams),
        take(1),
        map(() => true)
      );
  }

  public listenForResolvedSignature(): Observable<string> {
    return this.signatureAppParamsResolved.pipe(filter((sig) => !!sig));
  }

  public getSignature(
    appParams: AppParams,
    auth?: boolean
  ): Observable<string> {
    const params = appParams && { ...appParams };

    if (
      this.configParamsChange(params) &&
      !this.shouldCancelNoParamsRequest(params)
    ) {
      if (!auth) {
        if (params && params.ci) {
          this.criticalParamsService.setCriticalParams(params);
        }
        params.identifier =
          this.criticalParamsService.criticalParamsSubject.getValue().ci;
      }
      return this.appConfigService.config.pipe(
        switchMap((appConfig) =>
          this.requestSignature(this.getHttpParams(params)).pipe(
            switchMap((results: any) => {
              this.updateAccountId(results);
              this.criticalParamsService.updateCi(results, appConfig);
              this.publishNextSignature(results.signature, params);
              this.signatureResolving.next(false);
              return of(results.signature);
            }),
            catchError(() => of(''))
          )
        )
      );
    } else {
      return this.signature;
    }
  }

  public getSignatureByParam(
    param: any,
    publishSignature: boolean = false
  ): Observable<SignatureResponse> {
    const httpParams = new HttpParams({ fromObject: param });
    return this.appConfigService.config.pipe(
      switchMap((appConfig) =>
        this.requestSignature(httpParams).pipe(
          switchMap((results: any) => {
            if (publishSignature) {
              this.updateAccountId(results);
              this.criticalParamsService.updateCi(results, appConfig);
              this.signatureAppParamsResolved.next(results.signature);
              this.signature.next(results.signature);
              this.signatureResolving.next(false);
            }
            return of(results);
          }),
          catchError(() => of(''))
        )
      )
    );
  }

  private updateAccountId(results): any {
    this.account_id.next(results.account_id);
  }

  private requestSignature(params: HttpParams): Observable<string> {
    this.signatureResolving.next(true);
    const url = `/api/configuration_profile/signature.json`;
    return this.http
      .get(url, { params: params, withCredentials: true })
      .pipe(filter((results: any) => !!results.signature));
  }

  private getHttpParams(appParams: AppParams): HttpParams {
    let params = this.appParamsService.setHttpParams(
      new ConfigurationParams(appParams)
    );
    params = params.delete('ci');
    return params;
  }

  private publishNextSignature(sig: string, params: AppParams): void {
    if (sig && this.appParamsHaveResolved(params)) {
      this.signatureAppParamsResolved.next(sig);
    }
    if (sig !== this.signature.value) {
      this.signature.next(sig);
    }
  }

  private appParamsHaveResolved(params: AppParams): boolean {
    let out = true;

    ['network_id', 'geo_location'].forEach((key) => {
      if (!params[key]) {
        out = false;
      }
    });

    return out;
  }

  private configParamsChange(params: AppParams): boolean {
    const currentParams = new ConfigurationParams(params);
    const ciDiff = this.previousCi !== params?.ci;

    if (!isEqual(currentParams, this.previousParams) || ciDiff) {
      this.previousParams = currentParams;
      this.previousCi = params?.ci;
      return true;
    } else {
      return false;
    }
  }

  private shouldCancelNoParamsRequest(params: AppParams): boolean {
    return !!(!params && this.previousParams);
  }
}
