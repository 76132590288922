import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  stagger,
  AnimationEvent,
} from '@angular/animations';
import { BalancesTranslations } from './balances-translations.model';
import { Balances } from './balances.model';
import * as _ from 'lodash';

@Component({
  selector: 'platform-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  animations: [
    trigger('fadeIn', [
      state('out', style({ opacity: 0 })),
      state('in', style({ opacity: 1 })),
      transition('out => in', [animate(250)]),
    ]),
    trigger('flyIn', [
      state('out', style({ visibility: 'hidden' })),
      state('in', style({ visibility: 'visible' })),
      transition('out => in', [
        style({ visibility: 'visible' }),
        query(
          '.animate-fly',
          style({ opacity: 0, transform: 'translateY(100%)' }),
          { optional: true }
        ),
        query(
          '.animate-fly',
          stagger('200ms', [
            animate(
              '500ms cubic-bezier(0.19, 1, 0.22, 1)',
              style({ opacity: 1, transform: 'translateY(0)' })
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger('bubbleIn', [
      transition('out => in', [
        query(
          '.animate-bubble',
          [
            style({ opacity: 0, transform: 'scale(0)' }),
            stagger('80ms', [
              animate(
                '500ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                style({ opacity: 1, transform: 'scale(1)' })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class BalancesComponent {
  @Input() public assetPath = './assets/balances/assets/';
  @Input()
  public set translations(value: BalancesTranslations) {
    this._translations = new BalancesTranslations(value);
  }
  public get translations(): BalancesTranslations {
    return this._translations;
  }
  @Input()
  public set balances(value: Balances[]) {
    this._balances = this.mapBalances(value);
    this.setSpends(this._balances);
    this.setFamilyEnabled(this._balances);
  }
  public get balances(): Balances[] {
    return this._balances;
  }
  @Input() public useMobileCards = true;
  @Input() public showHeading = true;
  @Input() public startAnimation = true;

  public fadeInDone = false;
  public bubbleInDone = false;
  public individualSpend = 0;
  public familySpend = 0;
  public familyEnabled = false;

  private _translations: BalancesTranslations = new BalancesTranslations();
  private _balances: Balances[] = [];

  public onFadeInDone(event: AnimationEvent): void {
    if (event.toState === 'in') {
      this.fadeInDone = true;
    }
    if (event.toState === 'out') {
      this.fadeInDone = false;
      this.bubbleInDone = false;
    }
  }

  public get isTiered(): boolean {
    return this.balances.length > 1;
  }

  private mapBalances(balances: Balances[]): Balances[] {
    balances = balances || [];
    let newBalances = balances.map((bal) => new Balances(bal));
    newBalances = _.orderBy(newBalances, ['tier_number'], ['asc']);
    return newBalances;
  }

  private setSpends(balances: Balances[]): void {
    if (balances && balances[0]) {
      this.individualSpend = balances[0].individual_opx_accum;
      this.familySpend = balances[0].family_opx_accum;
    }
  }

  private setFamilyEnabled(balances: Balances[]): void {
    this.familyEnabled = !!(
      balances &&
      balances[0] &&
      balances[0].family_deductible_limit > 0 &&
      balances[0].family_opx_limit > 0
    );
  }
}
