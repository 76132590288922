export class FacetGeo {
  public value?: string;
  public name?: string;
  public radius?: number;

  constructor(obj: any) {
    obj = obj || {};
    this.value = obj.value || '';
    this.name = obj.name || '';
    this.radius = this.parseRadius(this.value);
  }

  private parseRadius?(value: string): number {
    return parseInt(value.replace('< ', ''), 10);
  }
}
