import { AuthStatus } from '@interfaces/auth-status.model';

export type AuthState = AuthStatus;

export const initialState: AuthStatus = new AuthStatus({
  auth_status: false,
  msa_auth_status: false,
  url: '',
  text: '',
  resolved: false,
});
