export class BvaProviderData {
  name: string;
  address: string;
  phone: string;
  memberCost: string;
  employerCost: string;
  incentiveAmount: string;
  totalCost: string;
  totalCostHigh: string;
  totalCostLow: string;
  identifiers: any;
  blueDistinction: string;

  constructor(obj?) {
    obj = obj || {};
    this.name = obj.name || null;
    this.address = obj.addr_line1
      ? obj.addr_line1 + ' ' + obj.city + ' ' + obj.state + ' ' + obj.zip
      : null;
    this.phone = obj.phone || null;
    this.memberCost = obj.member_cost || null;
    this.employerCost = obj.employer_cost || null;
    this.incentiveAmount = obj.incentive_amount || null;
    this.totalCost = obj.procedure_cost || null;
    this.totalCostHigh = obj.procedure_cost_upper || null;
    this.totalCostLow = obj.procedure_cost_lower || null;
    this.identifiers = this.updateIdentifiers(obj);
    this.blueDistinction = obj.bdc_info || null;
  }

  private updateIdentifiers(obj: any): any[] {
    const out = obj.identifiers ? obj.identifiers : [];
    if (!out.find((i) => i.type_code === 'NPI') && obj.npi_identifier) {
      out.push({ type_code: 'NPI', value: obj.npi_identifier });
    }
    return out;
  }
}
