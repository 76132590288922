import { BehaviorSubject } from 'rxjs';
import { WindowService } from '../../../services/window.service';
import { Injectable } from '@angular/core';
import { Breakpoints } from '../../../classes/breakpoints.class';
import { ISearchView } from '@interfaces/isearch-view.type';

@Injectable({
  providedIn: 'root',
})
export class SearchView {
  public searchActive: boolean = false;
  public viewActivated: BehaviorSubject<{ list: boolean; map: boolean }> =
    new BehaviorSubject(null);

  private defaultView: ISearchView = 'list';
  private view: string = this.defaultView;
  private _filterBarExpanded: boolean = false;
  private _carepathActive: boolean = false;

  constructor(private window: WindowService, private breakpoints: Breakpoints) {
    this.view = this.defaultView;
    this.nextView();
  }

  public get mapViewSelected(): boolean {
    return this.searchActive && this.view === 'map';
  }

  public get listViewSelected(): boolean {
    return this.searchActive && this.view === 'list';
  }

  public set carepathActive(active: boolean) {
    this._carepathActive = active;
    if (active) {
      this.setView('list');
    }
  }
  public get carepathActive(): boolean {
    return this._carepathActive;
  }

  public set filterBarExpanded(expanded: boolean) {
    this._filterBarExpanded = expanded;
  }
  public get filterBarExpanded(): boolean {
    return !this.searchActive || this._filterBarExpanded;
  }

  public get mobileMapFiltersCollapsed(): boolean {
    return (
      this.mapViewSelected &&
      this.breakpoints.isMobile &&
      !this.filterBarExpanded
    );
  }

  public collapseMobileFilters(): void {
    if (this.breakpoints.isMobile) {
      this.filterBarExpanded = false;
    }
  }

  public toggleFilterBarExpanded(): void {
    this.filterBarExpanded = !this.filterBarExpanded;
  }

  public setView(view: ISearchView): void {
    this.view = view;
    this.filterBarExpanded = false;
    if (view === 'list') {
      this.filterBarExpanded = true;
    }
    this.nextView();
  }

  public setSearchEnter(): Promise<boolean> {
    return Promise.resolve(true).then((active) => {
      this.searchActive = active;
      this.nextView();
      return active;
    });
  }

  public setSearchExit(): Promise<boolean> {
    return Promise.resolve(false).then((active) => {
      this.searchActive = active;
      this.nextView();
      return active;
    });
  }

  private nextView(): void {
    this.viewActivated.next({
      list: this.listViewSelected,
      map: this.mapViewSelected,
    });
  }
}
