import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './serp-summary.reducers';
import { SerpResultsSummaryEffects } from './serp-summary.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('serp-summary', reducer),
    EffectsModule.forFeature([SerpResultsSummaryEffects]),
  ],
})
export class SerpSummaryModule {}
