import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogEventService {
  private apiUrl = '/api/log_event';

  constructor(private http: HttpClient) {}

  postLogEvent(eventData: any): Observable<any> {
    const headers = new HttpHeaders({ 'responseType': 'text' });
    return this.http.post<any>(this.apiUrl, eventData, { headers });
  }
}
