<div
  class="close-button"
  [ngClass]="{ 'high-contrast-theme': contrast === 'dark' }"
>
  <zelis-button
    *ngIf="type === 'icon'"
    variant="basic"
    (clickFn)="closeCalled.emit($event)"
    (keydown.enter)="closeCalled.emit($event)"
    (keydown.escape)="closeCalled.emit($event)"
    [ariaLabel]="'app_global_close' | translate"
    data-cy="close-button"
  >
    <mat-icon svgIcon="dls-times-plain-light"></mat-icon>
  </zelis-button>
  <zelis-button
    *ngIf="type === 'text'"
    variant="basic"
    class="text-base font-medium"
    (clickFn)="closeCalled.emit($event)"
    (keydown.enter)="closeCalled.emit($event)"
    (keydown.escape)="closeCalled.emit($event)"
    data-cy="close-button"
  >
    {{ closeText }}
  </zelis-button>
</div>
