import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { first } from 'rxjs/operators';
import { VimAppointmentsService } from '../services/vim-appointments/vim-appointments.service';

@Injectable()
export class VimResolver implements Resolve<any> {
  constructor(private vimAppointmentsService: VimAppointmentsService) {}

  resolve(): Promise<void> {
    return new Promise((resolve) => {
      this.vimAppointmentsService.vimReady
        .pipe(first((resolved) => resolved))
        .subscribe(() => resolve());
    });
  }
}
