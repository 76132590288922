<div class="proactive-chat-container" [ngClass.lt-md]="'proactive-chat-mobile'">
  <div class="secondary-content mb-1">
    {{ 'pat_chat_proactive_message' | translate: { name: memberName } }}
  </div>

  <div class="flex flex-col gap-4">
    <div>
      <app-chat-button
        *ngIf="isAvailable | async"
        chatId="sticky_fab"
        [buttonCopy]="'app_global_yes' | translate"
      >
      </app-chat-button>
    </div>

    <div>
      <zelis-button
        variant="stroked"
        [fullWidth]="true"
        color="accent"
        (clickFn)="proactiveNoClick()"
        data-cy="proactive-chat-container.button-no"
      >
        {{ 'app_global_no' | translate }}
      </zelis-button>
    </div>
  </div>
</div>
