import { MemberDependent } from '@interfaces/member-dependent.model';

export class MemberParams {
  public member_number: string;
  public dob?: string;
  public behavioral_health_eligible?: string;
  public gender?: string;
  public medical_group_numbers?: string[];

  constructor(obj?: MemberDependent) {
    obj = obj || new MemberDependent();
    this.member_number = obj.member_number || obj.id || '';
    this.dob = obj.dob || '';
    this.behavioral_health_eligible =
      typeof obj.behavioral_health_eligible === 'undefined'
        ? null
        : obj.behavioral_health_eligible;
    this.gender = obj.gender || null;
    this.medical_group_numbers = obj.medical_group_numbers || null;
  }
}
