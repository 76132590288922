export class MemberShoppingHistory {
  search_date?: string;
  search_time?: string; // Alias to search_date
  reference_number?: string;
  procedure_name?: string;

  constructor(history: MemberShoppingHistory = {}) {
    this.search_date = history.search_date || history.search_time || null;
    this.search_time = history.search_time || this.search_date || null;
    this.reference_number = history.reference_number || null;
    this.procedure_name = history.procedure_name || null;
  }
}

export interface MobileMemberShoppingHistory {
  date?: string;
  history?: {
    reference_number: string;
    procedure_name: string;
  }[]
}
