import { map, catchError, switchMap, take } from 'rxjs/operators';
import { Observable, forkJoin, of, combineLatest, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { SearchParamsService } from '../search-params.service';
import { SearchFiltersService } from '@components/+search/classes/search-filters.service';
import { SearchFilter } from '@interfaces/search-filter.model';
import { CriticalFilters } from '@interfaces/critical-filters.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NetworksService } from '@services/networks.service';
import { SearchFiltersSettings } from './search-filters-settings.class';
import { MembersService } from '@services/members.service';
import { extend, each, find } from 'lodash';
import { SearchFacetParamsService } from '@components/+search/classes/search-facet-params.service';
import { SortConfig } from '@interfaces/sort-config.model';
import { StorageUtilities } from '@utilities/storage.utilities';
import { RadiusFilter } from '@interfaces/radius-filter.interface';
import { AppParams } from '@interfaces/app.interface.appParams';
import { FiltersUtilities } from '@utilities/filters.utilities';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';
import { SearchFiltersFacetQuery } from '@interfaces/search-filters-facet-query.model';
import { SearchFilterOption } from '@interfaces/search-filter-option.model';
import { SerpService } from '@services/serp/serp.service';

@Injectable({
  providedIn: 'root',
})
export class SearchFilters {
  public areFiltersSelected: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public reset: boolean = false;
  public facetsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public locationGeo: Observable<SearchFilter> =
    this.searchFiltersService.locationGeoItem;
  public configuredDropdown: Observable<SearchFilter[]> =
    this.searchFiltersService.dropdownItems;
  public configuredNestedDropdown: Observable<SearchFilter[]> =
    this.searchFiltersService.nestedDropdownItems;
  public configuredCheckbox: Observable<SearchFilter[]> =
    this.getConfiguredCheckbox();
  public radiusOptionIsValid: boolean = this.isRadiusOptionValid();

  private incentivizedFilters: string[] = ['has_incentive'];
  private selectedFilters: CriticalFilters;
  private storage: StorageUtilities = new StorageUtilities();
  private radiusExpanded: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private route: ActivatedRoute,
    private searchParamsService: SearchParamsService,
    private searchFiltersService: SearchFiltersService,
    private searchFiltersSettings: SearchFiltersSettings,
    private http: HttpClient,
    private networksService: NetworksService,
    private membersService: MembersService,
    private searchFacetParamsService: SearchFacetParamsService,
    private filtersUtilities: FiltersUtilities,
    private serpSerivce: SerpService
  ) {
    this.initSelectedFilters();
  }

  public getSelectedFilters(): any {
    return this.selectedFilters;
  }

  public setSelectedFilters(filters: any, params: AppParams): void {
    if (this.hasPerformanceScore(filters.sort, params)) {
      filters.sort = this.addSpecialtyIdToQuery(filters.sort, params);
    }
    if (filters.radius && typeof filters.radius !== 'string') {
      filters.radius = filters.radius.toString();
    }
    this.selectedFilters = filters;
  }

  public getSort(): SortConfig {
    return this.searchFiltersSettings.search_sort;
  }

  public getRadius(): SearchFilter {
    return this.searchFiltersSettings.search_radius;
  }

  public getConfiguredCheckbox(): Observable<SearchFilter[]> {
    return combineLatest([
      this.membersService.incentivesEnabled,
      this.searchFiltersService.checkboxItems,
    ]).pipe(
      map(([incentivesEnabled, checkboxItems]) =>
        this.filterOnIncentive(incentivesEnabled, checkboxItems)
      )
    );
  }

  // TODO: get sort filters from store
  public getFiltersWithSort(sort: SearchSortOption, params: AppParams): any {
    if (this.hasPerformanceScore(sort.query, params)) {
      sort.query = this.addSpecialtyIdToQuery(sort.query, params);
    }
    const sortFilter = {
      sort: sort.query,
      sort_translation: sort.translation,
    };
    return this.extendFilters(sortFilter);
  }

  public getFiltersWithRadius(radiusFilter: RadiusFilter): any {
    this.getRadius().selected = radiusFilter.radius;
    this.radiusOptionIsValid = this.isRadiusOptionValid();
    this.selectedFilters = this.extendFilters(radiusFilter);
    return this.selectedFilters;
  }

  public getExpandedRadiusFilters(radius: string): any {
    this.radiusExpanded.next(radius);
    return this.getFiltersWithRadius({ radius: radius });
  }

  public extendFilters(selectedFilter: any): any {
    this.selectedFilters = this.encodeFilters(this.getSelectedFilters());
    selectedFilter = this.encodeFilters(selectedFilter);
    return extend(this.getSelectedFilters(), selectedFilter);
  }

  // TODO - anything that updates the filters must go to a action / selector
  public addCriticalFilters(): void {
    this.selectedFilters = extend(
      {
        limit: 10,
        radius: this.getRadius().selected,
        sort: this.selectedFilters.sort,
        sort_translation: this.selectedFilters.sort_translation,
      },
      this.selectedFilters
    );
  }

  public getGroupTierValue(
    networkTierCode: string,
    filterConfig: SearchFilter[]
  ): boolean {
    if (!networkTierCode || !filterConfig) {
      return false;
    }
    const hasTierCode = `tiers:${networkTierCode.toLowerCase()}`;
    const foundTiersFacet = filterConfig.filter((obj: SearchFilter) => {
      return obj.facet === hasTierCode;
    });
    return foundTiersFacet.length > 0 ? foundTiersFacet[0].group_tier : false;
  }

  // This logic is similar to getFacets() but returns search filters that were augmented with facet data
  // These search filters then replace the existing store search filters
  // getFacets() returns the facets response and mutates search filters in other services
  public getFiltersWithFacetData(
    searchParamType: SearchParamType,
    combinedParams: any,
    filtersFromStore: SearchFilter[] = null
  ): Observable<any> {
    combinedParams = this.cleanSpecialtySearchFilters(
      searchParamType,
      combinedParams
    );
    return this.getFilteredConfigByType(
      searchParamType,
      combinedParams,
      filtersFromStore
    ).pipe(
      switchMap((filteredByType: SearchFilter[]) => {
        return combineLatest([
          this.getCombinedFacetRequests(
            combinedParams,
            searchParamType,
            filteredByType
          ),
          this.networksService.resolvedNetwork,
        ]).pipe(
          switchMap(([facets, network]) => {
            const filterWithFacetData =
              this.searchFiltersService.matchFacetOptionsForStoreData(
                facets,
                filteredByType,
                network.tier_code
              );
            return of(filterWithFacetData);
          })
        );
      })
    );
  }

  public getFacets(
    searchParamType: SearchParamType,
    combinedParams: any
  ): Observable<any> {
    combinedParams = this.cleanSpecialtySearchFilters(
      searchParamType,
      combinedParams
    );
    return this.getFilteredConfigByType(searchParamType, combinedParams).pipe(
      switchMap((filteredByType: any) => {
        return combineLatest([
          this.getCombinedFacetRequests(
            combinedParams,
            searchParamType,
            filteredByType
          ),
          this.networksService.resolvedNetwork,
        ]).pipe(
          switchMap(([facets, network]) => {
            this.searchFiltersService.matchFacetOptions(
              facets,
              filteredByType,
              network.tier_code
            );
            this.searchFiltersService.filterMatchedFacets();
            this.facetsLoading.next(false);
            return of(facets);
          })
        );
      })
    );
  }

  public applyURIFilters(
    searchFilters: SearchFilter[],
    searchParamType: SearchParamType
  ): void {
    if (this.selectedFilters.radius) {
      this.getRadius().selected = this.selectedFilters.radius;
    }
    if (this.selectedFilters.sort) {
      const matchedSort = this.filtersUtilities.getMatchedSort(
        searchParamType,
        this.selectedFilters.sort,
        this.getSort()
      );
      this.getSort().selected = matchedSort;
    }
    this.addCriticalFilters();
    this.updateSelectedFilterConfig(searchFilters);
    this.areFiltersSelected.next(this._areFiltersSelected(searchFilters));
  }

  public findSortTranslation(): string {
    return (
      this.getSort().default.find(
        (option) => option.query === this.getSort().selected.query
      ) || {}
    ).translation;
  }

  // TODO: this needs to be refactored - yuck!
  public getResetFilters(
    searchParamType: SearchParamType,
    clearAll: boolean = false,
    urlFilters?: any
  ): any {
    this.selectedFilters = {
      limit: 10,
      radius:
        this.searchFiltersSettings.getIncentivizedRadius() ||
        this.getRadius().default?.[searchParamType] ||
        this.getRadius().selected,
      sort: this.getSort().selected?.query,
    };
    this.selectedFilters = this.maintainSpecialFilters(
      urlFilters,
      searchParamType
    );
    if (clearAll) {
      this.selectedFilters['tiers'] = undefined;
    } else {
      const defaultFilters =
        this.searchFiltersSettings.getFilterDefaultsAsFacets(searchParamType);
      this.selectedFilters = Object.assign(
        {},
        this.selectedFilters,
        defaultFilters
      );
    }
    return this.selectedFilters;
  }

  public initSelectedFilters(): void {
    const sort = this.getSort();
    const defaultFilter = (sort && sort.default && sort.default[0]) || {};
    this.selectedFilters = new CriticalFilters({
      limit: 10,
      radius: this.getRadius().selected,
      sort: defaultFilter.query,
      sort_translation: defaultFilter.translation,
    });
  }

  public buildFacetLocationGeo(radiusOptions: SearchFilterOption[]) {
    const facet = 'location_geo';
    return new SearchFilter({
      facet: facet,
      facet_queries: [
        new SearchFiltersFacetQuery({
          facetQuery: facet,
          value: this.radiusOptionsToString(radiusOptions),
        }),
      ],
    });
  }

  private radiusOptionsToString(radiusOptions: SearchFilterOption[]): string {
    const radiusOptionsString: string[] = [];
    radiusOptions.forEach((option) => radiusOptionsString.push(option.value));
    return radiusOptionsString.join(',');
  }

  private cleanSpecialtySearchFilters(
    searchParamType: SearchParamType,
    filtersWithParams: any
  ): any {
    if (searchParamType === 'serp_lite') {
      delete filtersWithParams.field_specialty_ids;
      delete filtersWithParams.expertise_codes;
    }
    return filtersWithParams;
  }

  // Get facets individually in parallel and combine into a single object.
  // API has better performance when requested individually.
  private getCombinedFacetRequests(
    selectedFilters: CriticalFilters,
    searchParamType: SearchParamType,
    filteredByType: any
  ): Observable<any> {
    const params = this.searchParamsService.setHttpParams(
      this.decodeFilter(selectedFilters),
      null,
      true
    );
    return forkJoin(
      this.getFacetRequests(params, searchParamType, filteredByType)
    ).pipe(map((facetResults: any[]) => extend({}, ...facetResults)));
  }

  private getFacetRequests(
    params: HttpParams,
    searchParamType: SearchParamType,
    searchFilters: SearchFilter[]
  ): Observable<any> {
    let facetRequestParams =
      this.searchFacetParamsService.getFacetRequestParams(
        params,
        searchFilters
      );
    if (searchParamType === 'serp_lite') {
      facetRequestParams.keys().forEach((key: string) => {
        if (this.isFacetParam(key) && !this.isSerpLiteFacet(key)) {
          facetRequestParams = facetRequestParams.delete(key);
        }
      });
    }
    if (searchParamType === 'rates') {
      let serpSummaryParams = this.serpSerivce.httpParams;
      facetRequestParams.keys().forEach((key: string) => {
        if (this.isFacetParam(key)) {
          serpSummaryParams = serpSummaryParams.set(key, facetRequestParams.get(key));
        }
      })
      facetRequestParams = serpSummaryParams;
    }
    return this.requestFacet(facetRequestParams);
  }

  private isSerpLiteFacet(key: string): boolean {
    const serpLiteFacets = ['facet[expertise_codes]', 'facet[field_specialty_ids]'];
    return serpLiteFacets.includes(key);
  }

  private isFacetParam(key: string): boolean {
    return key.includes('facet');
  }

  // Get filtered search filters by current search type
  // Some filters need to be hidden on a particular search type, i.e. hide has_incentive on search_specialty searches
  private getFilteredConfigByType(
    searchParamType: SearchParamType,
    filtersWithParams: any,
    filtersFromStore: SearchFilter[] = null
  ): Observable<SearchFilter[]> {
    if (filtersFromStore === null) {
      this.facetsLoading.next(true);
      this.searchFiltersService.resetMatchedFacets();
    }

    return this.radiusExpanded.pipe(
      take(1),
      switchMap((radius: string) => {
        this.resetFacetParamsOnNewSearchType(searchParamType);
        if (radius) {
          filtersWithParams = { ...filtersWithParams, radius: radius };
        }
        return this.searchFiltersSettings.checkConfigToHideOrDisable(
          searchParamType,
          filtersWithParams,
          filtersFromStore
        );
      })
    );
  }

  private requestFacet(params: HttpParams): Observable<any> {
    params = this.searchFacetParamsService.updateTiersFacetableParam(params);
    const url = `/api/providers/facets.json`;
    return this.http.get(url, { params: params, withCredentials: true }).pipe(
      catchError(() => of(null)),
      map((data: any) => (data && data.facets) || null),
      map((data: any) => this.mapFacets(data))
    );
  }

  private isRadiusOptionValid(): boolean {
    return !!this.getRadius().options.filter(
      (option) => option.value === this.getRadius().selected
    ).length;
  }

  private setFilterSelectedValue(filterObj: SearchFilter): void {
    filterObj.selected = undefined;
    if (this.selectedFilters[filterObj.facet]) {
      filterObj.selected = this.selectedFilters[filterObj.facet];
      filterObj.defaultSelected = true;
    }
  }

  private _areFiltersSelected(searchFilters: SearchFilter[]): boolean {
    return !!find(searchFilters, (filterObj) => {
      if (filterObj.selected) {
        return true;
      }
      return find(filterObj.items, (item) => item.selected);
    });
  }

  private filterOnIncentive(
    incentivesEnabled: boolean,
    filters: SearchFilter[]
  ): SearchFilter[] {
    return filters.filter((item) => {
      if (
        !incentivesEnabled &&
        this.incentivizedFilters.indexOf(item.facet) > -1
      ) {
        return false;
      }
      return item;
    });
  }

  private decodeFilter(
    filters: CriticalFilters,
    filterString = 'provider_type_description'
  ): CriticalFilters {
    if (filters[filterString]) {
      filters[filterString] = decodeURIComponent(filters[filterString]);
    }
    return filters;
  }

  private encodeFilters(filters: any): any {
    const invalidURI = /[ :/?#\[\]@!$&'\(\)*+;=%]+/;
    if (filters) {
      Object.keys(filters).forEach((filterKey) => {
        if (filterKey !== 'sort' && filterKey !== 'sort_translation') {
          if (invalidURI.test(filters[filterKey])) {
            // decoding filter keys before encoding to avoid double encoding
            filters[filterKey] = encodeURIComponent(decodeURIComponent(filters[filterKey]));
          }
        }
      });
    }
    return filters;
  }

  private mapFacets(facets: any): any {
    return this.searchFacetParamsService.updateTiersFacetableProperty(facets);
  }

  private addSpecialtyIdToQuery(query: string, params?: any): string {
    return query
      .split(' ')
      .map((piece) => {
        if (
          piece.indexOf('performance_score:') > -1 &&
          params.search_specialty_id
        ) {
          piece = piece.split(':')[0] + ':s' + params.search_specialty_id;
        }
        return piece;
      })
      .join(' ');
  }

  private resetFacetParamsOnNewSearchType(
    searchParamType: SearchParamType
  ): void {
    const searches = this.storage.sessionStorageGet('searchParamTypes');
    const isAffiliated = [
      'group_affiliation_ids',
      'hospital_affiliation_ids',
    ].includes(searchParamType);
    if (
      searches &&
      searches.previous &&
      searches.previous !== searchParamType &&
      !isAffiliated
    ) {
      this.getResetFilters(searchParamType);
    }
  }

  private updateSelectedFilterConfig(searchFilters: SearchFilter[]): void {
    each(searchFilters, (filterObj) => {
      this.setFilterSelectedValue(filterObj);
      // More filters
      filterObj.items.forEach((item) => {
        this.setFilterSelectedValue(item);
      });
    });
  }

  private hasPerformanceScore(query: string, params: AppParams): boolean {
    return !!(
      query &&
      query.includes('performance_score:') &&
      params &&
      params.search_specialty_id
    );
  }

  private maintainSpecialFilters(
    urlFilters: any,
    searchParamType: string
  ): any {
    if (
      urlFilters?.affiliated_with &&
      searchParamType.includes('affiliation')
    ) {
      this.selectedFilters['affiliated_with'] = urlFilters.affiliated_with;
    }
    if (urlFilters?.provider_type_description) {
      this.selectedFilters['provider_type_description'] =
        urlFilters.provider_type_description;
    }
    return this.selectedFilters;
  }
}
