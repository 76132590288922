import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingCodeStoreModule } from './billing-code-search';
import { AuthStoreModule } from './auth';
import { NetworkStoreModule } from './network';
import { SettingsFiltersStoreModule } from './search-filters/settings-filters/';
import { RadiusFiltersModule } from './search-filters/radius-filters/radius-filters.module';
import { SortFiltersModule } from './search-filters/sort-filters/sort-filters.module';
import { SerpSummaryModule } from './rates-serp/serp-summary/serp-summary.module';
import { SerpDisclaimersModule } from './rates-serp/serp-disclaimers/serp-disclaimers.module';
import { SearchStoreModule } from './search';
import { PrintStoreModule } from './print';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BillingCodeStoreModule,
    AuthStoreModule,
    NetworkStoreModule,
    SettingsFiltersStoreModule,
    RadiusFiltersModule,
    SortFiltersModule,
    SerpSummaryModule,
    SerpDisclaimersModule,
    SearchStoreModule,
    PrintStoreModule,
  ],
})
export class RootStoreModule {}
