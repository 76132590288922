import { NgModule } from '@angular/core';
import { PendoDirective } from './pendo.directive';

@NgModule({
  imports: [],
  providers: [],
  declarations: [PendoDirective],
  exports: [PendoDirective],
})
export class PendoModule {}
