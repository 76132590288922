import { PageTransition } from '@interfaces/page-transition.interface';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Observable, iif, of } from 'rxjs';
import { switchMap, map, first } from 'rxjs/operators';
import { AlphaPrefixConfig } from '@interfaces/alpha-prefix-config.model';
import { SettingsService } from '@services/settings.service';
import { WindowService } from '@services/window.service';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { CcssService } from '@services/ccss/ccss.service';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';

@Component({
  selector: 'app-gated-entry-overlay-home',
  templateUrl: './gated-entry-overlay-home.component.html',
  styleUrls: ['./gated-entry-overlay-home.component.scss'],
})
export class GatedEntryOverlayHomeComponent implements OnInit, OnDestroy {
  @Input() initialAppParams: any;

  @Output() public goToOverlayPage: EventEmitter<PageTransition> =
    new EventEmitter();

  public alphaPrefixConfig: Observable<AlphaPrefixConfig> =
    this.getAlphaPrefixConfig();
  private authUrl: string;
  private subscriptions = new SubscriptionManager();

  constructor(
    public ccss: CcssService,
    private settingsService: SettingsService,
    private window: WindowService,
    private media: MediaObserver,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.setAuthUrl();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onSectionClick(page: string): void {
    if (this.media.isActive('lt-md')) {
      if (page === 'login') {
        this.goToLogin();
        return;
      }
      this.goToOverlayPage.emit({ page: page });
    }
  }

  public onSectionButtonClick(page: string): void {
    if (page === 'login') {
      this.goToLogin();
      return;
    }
    this.goToOverlayPage.emit({ page: page });
  }

  private goToLogin(): void {
    this.window['location'] = this.authUrl;
  }

  private getAlphaPrefixConfig(): Observable<AlphaPrefixConfig> {
    return this.store.pipe(
      select(AuthStoreSelectors.getAuthStatus),
      switchMap((status) =>
        iif(
          // If logged in
          () => status.auth_status === true,
          // Then not enabled
          of({ network_enabled: false }),
          // Else get config
          this.settingsService.getSetting('alpha_prefix', AlphaPrefixConfig)
        )
      ),
      map((config) => new AlphaPrefixConfig(config)),
      // TODO: Remove to re-enable Alpha Prefix option once fully fleshed out.
      map(() => new AlphaPrefixConfig({ network_enabled: false })),
      first()
    );
  }

  private setAuthUrl(): void {
    this.subscriptions.add(
      this.store
        .pipe(select(AuthStoreSelectors.getAuthStatus))
        .subscribe((auth) => (this.authUrl = auth.url))
    );
  }
}
