import { Injectable } from '@angular/core';
import { SnackbarComponent } from './snackbar.component';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';

import { take } from 'rxjs/operators';

const DEFAULT_DISMISSAL_DURATION = 4000;
@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackBarQue: MatSnackBarConfig<any>[] = [];
  constructor(private snackbar: MatSnackBar) {}

  public open(config: MatSnackBarConfig): void {
    if (this.snackbar._openedSnackBarRef) {
      this.snackBarQue.push(config);
      return;
    }
    const transformedConfig = this.transformConfig(config);
    const snackbarRef = this.snackbar.openFromComponent(
      SnackbarComponent,
      transformedConfig
    );
    this.listenToDismissal(snackbarRef);
  }

  private transformConfig(config: MatSnackBarConfig): MatSnackBarConfig {
    config.panelClass = ['bg-palette-m3-system-gray-10', 'max-w-4xl'];
    config.horizontalPosition = 'left';
    config.duration = this.setDuration(config);
    return config;
  }

  private setDuration(config: MatSnackBarConfig): number | undefined {
    const { data } = config;
    return data.cta || data.closeBtn
      ? undefined
      : config.duration || DEFAULT_DISMISSAL_DURATION;
  }

  private listenToDismissal(ref: MatSnackBarRef<any>): void {
    ref
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => {
        const config = this.snackBarQue.shift();
        if (config) {
          this.open(config);
        }
      });
  }
}
