import { Action, createReducer, on } from '@ngrx/store';
import * as SortFilterActions from './sort-filters.actions';
import { SortFiltersState, initialState } from './sort-filters.state';

export const searchFiltersSortReducer = createReducer(
  initialState,
  on(SortFilterActions.requestSortSearchFiltersFromConfig, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    SortFilterActions.requestSortSearchFiltersFromConfigSuccess,
    (_state, { sort_filters }): any => ({
      ...sort_filters,
      loading: false,
      error: null,
    })
  ),
  on(
    SortFilterActions.requestSortSearchFiltersFromConfigFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  )
);

export const reducer = (state: SortFiltersState, action: Action) => {
  return searchFiltersSortReducer(state, action);
};
