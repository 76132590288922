import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsModule } from '@zelis/platform-ui-components';
import { GlobalLocationSelectComponent } from './global-location-select.component';
import { FlexModule } from '@ngbracket/ngx-layout';
import { DlsProgressSpinnerModule } from '@zelis/dls/progress-spinner';
import { DlsIconModule } from '@zelis/dls/icons';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [GlobalLocationSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    AnalyticsModule,
    FlexModule,
    DlsProgressSpinnerModule,
    DlsIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule
  ],
  providers: [],
  exports: [GlobalLocationSelectComponent],
})
export class GlobalLocationSelectModule {}
