import { Component, Input } from '@angular/core';
import { GlobalFooterTranslations } from './global-footer.translations.model';

@Component({
  selector: 'platform-global-footer',
  templateUrl: './global-footer.component.html',
  styleUrls: ['./global-footer.component.scss'],
})
export class GlobalFooterComponent {
  @Input() public lastUpdate = '';
  @Input() public translations: GlobalFooterTranslations =
    new GlobalFooterTranslations();
  @Input() public suppressLogoLink = false;
}
