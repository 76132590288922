export class Network {
  public id?: string;
  public name?: string;
  public tier_code?: string;
  public prefix?: string;
  public hpn?: boolean;
  public rbp?: boolean;
  public contains_all_plans?: boolean;

  constructor(obj: Network) {
    this.id = String(obj.id) || '';
    this.name = obj.name || '';
    this.tier_code = obj.tier_code || '';
    this.prefix = obj.prefix || '';
    this.hpn = obj.hpn || false;
    this.rbp = obj.rbp || false;
    this.contains_all_plans = obj.contains_all_plans || false;
  }

  public setName?(name: string): Network {
    this.name = name;
    return this;
  }
}
