<ng-template>
  <div
    class="platform-tooltip"
    [ngClass.lt-md]="'mobile'"
    [ngClass.gt-sm]="'desktop'"
  >
    <div
      class="platform-tooltip-container"
      *ngIf="open"
      @popInOut
      (@popInOut.done)="onAnimationDone($event)"
      (@popInOut.start)="onAnimationStart($event)"
    >
      <div class="platform-tooltip-inner">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</ng-template>
