import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FetchService {
  /**
   * Fetch a JSON file. Does not use Angular's HttpClient.
   * @param url: string
   * @returns Observable<any>
   */
  public json(url: string): Observable<any> {
    return fromFetch(url).pipe(
      switchMap((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return throwError(response.statusText);
        }
      })
    );
  }
}
