import { Injectable } from '@angular/core';
import { WindowService } from './window.service';
import { Subject, Observable } from 'rxjs';
import { SettingsLoginLinks } from '@interfaces/settings-login-links.model';
import { CriticalParamsService } from './critical-params/critical-params.service';

@Injectable({
  providedIn: 'root',
})
export class LogOutService {
  private loginSettingEnvironment: SettingsLoginLinks;
  private isLoggingOut: boolean = false;
  private cancelRequests: Subject<void> = new Subject();
  private shouldResetAccountOnLogout: boolean = false;

  constructor(
    private windowService: WindowService,
    private criticalParamsService: CriticalParamsService
  ) {
    // Only for default if no yaml loginSetting
    this.loginSettingEnvironment = new SettingsLoginLinks({
      default: `/idp/auth`,
      dynamic_params: '',
      relay_url: '',
      msa_relay_url: '',
    });
  }

  public logout(msa: boolean = false): void {
    if (this.isLoggingOut) {
      return;
    }
    this.isLoggingOut = true;
    this.cancelRequests.next();
    this.clearSessionStorage();
    this.clearLocalStorage();
    if (msa) {
      this.windowService[
        'location'
      ].href = `/auth/msaSSOLogout?RelayState=${this.mapMsaLogoutRelay()}`;
    } else {
      this.windowService[
        'location'
      ].href = `/auth/SSOLogout?RelayState=${this.mapSsoLogoutRelay()}`;
    }
  }

  public logoutMsa(): void {
    this.logout(true);
  }

  public reload(): void {
    this.cancelRequests.next();
    this.windowService['location'].reload();
  }

  public onCancelRequests(): Observable<void> {
    return this.cancelRequests.asObservable();
  }

  public setShouldResetAccountOnLogout(reset: boolean): void {
    this.shouldResetAccountOnLogout = !!reset;
  }

  public setLoginSettingEnvironment(settings: SettingsLoginLinks): void {
    this.loginSettingEnvironment = new SettingsLoginLinks(settings);
  }

  private clearSessionStorage(): void {
    const ci = this.windowService['sessionStorage'].getItem('ci');
    this.windowService['sessionStorage'].clear();

    if (!this.shouldResetAccountOnLogout) {
      this.windowService['sessionStorage'].setItem('ci', ci);
      this.criticalParamsService.setCriticalParams({ ci: ci });
    }
  }

  private clearLocalStorage(): void {
    this.windowService['localStorage'].removeItem('member_number');
  }

  private mapSsoLogoutRelay(): string {
    return this.loginSettingEnvironment.relay_url || this.origin();
  }

  private mapMsaLogoutRelay(): string {
    return (
      this.loginSettingEnvironment.msa_relay_url ||
      this.loginSettingEnvironment.relay_url ||
      this.origin()
    );
  }

  private origin(): string {
    const location = this.windowService['location'];
    const portSequence = location.port ? ':' + location.port : '';
    return location.protocol + '//' + location.hostname + portSequence;
  }
}
