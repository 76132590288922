import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent } from './chip/chip.component';
import { MatChipsModule } from '@angular/material/chips';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  imports: [CommonModule, MatChipsModule, DlsIconModule],
  declarations: [ChipComponent],
  exports: [ChipComponent],
  providers: [MatChipsModule],
})
export class DlsChipModule {}
