import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar/avatar.component';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  imports: [CommonModule, DlsIconModule],
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
})
export class DlsAvatarModule {}
