import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { MleService } from '../services/mle.service';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { MleStatus } from '@interfaces/mle-status.model';

@Injectable()
export class MleGuard implements CanActivate {
  constructor(private mleService: MleService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.mleService.status.pipe(
      filter((status: MleStatus) => !!(status && status.resolved)),
      map((status) => status.isEligible),
      switchMap((isEligible: boolean) => {
        if (isEligible) {
          return of(true);
        } else {
          this.router.navigate([], { queryParamsHandling: 'preserve' });
          return of(false);
        }
      })
    );
  }
}
