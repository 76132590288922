import { BillingCodeBenefitLimits } from '@interfaces/billing-code-benefit-limits.model';
import { BillingCodeOON } from '@interfaces/billing-code-OON.model';
import { BillingCodePreRequisite } from '@interfaces/billing-code-pre-requisite.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as billingCodeActions from './billing-code.actions';

import {
  billingCodeAdapter,
  BillingCodeState,
  initialState,
} from './billing-code.state';

export const billingCodesSearchReducer = createReducer(
  initialState,
  // single code search
    on(billingCodeActions.loadBillingCodeByServiceCode, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    billingCodeActions.loadBillingCodeByServiceCodeSuccess,
    (state, { billing_code }) => {
      state = { ...state, loading: false, error: false };
      return billingCodeAdapter.addOne(billing_code, state);
    }
  ),
  on(billingCodeActions.loadBillingCodeByServiceCodeFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  // autocomplete full text search
  on(billingCodeActions.loadBillingCodesByAutocompleteSearchTerm, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    billingCodeActions.loadBillingCodesByAutocompleteSearchTermSuccess,
    (state, { billing_codes: billing_codes, meta: meta, isProfilePage: isProfilePage }) => {
      state = { ...state, loading: false, error: false, meta: meta };
      if (isProfilePage) {
        return billingCodeAdapter.setAll(billing_codes, state);
      }
      return meta.pages.current === 1
        ? billingCodeAdapter.setAll(billing_codes, state)
        : billingCodeAdapter.addMany(billing_codes, state);
    }
  ),
  on(billingCodeActions.loadBillingCodesByAutocompleteSearchTermFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  // selected billing code
  on(
    billingCodeActions.selectedBillingCode,
    (state, { payload: billing_code }) => ({
      ...state,
      selectedBillingCode: billing_code,
    })
  ),
  // billing code dependents
  on(billingCodeActions.loadBillingCodeBenefitLimits, (state) => ({
    ...state,
    billingCodeBenefitLimits: new BillingCodeBenefitLimits({ loading: true }),
  })),
  on(
    billingCodeActions.loadBillingCodeBenefitLimitsSuccess,
    (state, { payload: benefit_limit }) => ({
      ...state,
      billingCodeBenefitLimits: benefit_limit,
    })
  ),
  on(
    billingCodeActions.loadBillingCodeBenefitLimitsFailure,
    (state, { error }) => ({
      ...state,
      billingCodeBenefitLimits: new BillingCodeBenefitLimits({ error: error }),
    })
  ),
  // billing code pre-requisite
  on(billingCodeActions.loadBillingCodePreRequisite, (state) => ({
    ...state,
    billingCodePreRequisite: new BillingCodePreRequisite({ loading: true }),
  })),
  on(
    billingCodeActions.loadBillingCodePreRequisiteSuccess,
    (state, { payload: pre_requisite }) => ({
      ...state,
      billingCodePreRequisite: pre_requisite,
    })
  ),
  on(
    billingCodeActions.loadBillingCodePreRequisiteFailure,
    (state, { error }) => ({
      ...state,
      billingCodePreRequisite: new BillingCodePreRequisite({ error: error }),
    })
  ),
  // billing code OON
  on(billingCodeActions.loadBillingCodeOON, (state) => ({
    ...state,
    billingCodeOON: new BillingCodeOON({ loading: true }),
  })),
  on(
    billingCodeActions.loadBillingCodeOONSuccess,
    (state, { payload: oon }) => ({
      ...state,
      billingCodeOON: oon,
    })
  ),
  on(billingCodeActions.loadBillingCodeOONFailure, (state, { error }) => ({
    ...state,
    billingCodeOON: new BillingCodeOON({ error: error }),
  })),
  // reset selected billing code dependents
  on(billingCodeActions.ResetSelectedBillingCodeDependents, (state) => ({
    ...state,
    BillingCodeBenefitLimits: null,
    billingCodePreRequisite: null,
    billingCodeOON: null,
  })),
  on(billingCodeActions.clearBillingCodes,
    (state) => {
    state = { ...state, loading: false, error: null, meta: null };
    return billingCodeAdapter.removeAll(state);
  }),
);

export const reducer = (state: BillingCodeState, action: Action) => {
  return billingCodesSearchReducer(state, action)
};
