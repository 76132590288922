import { Component, HostBinding, Input } from '@angular/core';
import { coerceBoolean } from 'coerce-property';

@Component({
  selector: 'zelis-list-item-subhead',
  templateUrl: './list-item-subhead.component.html',
  styleUrls: ['./list-item-subhead.component.scss'],
})
export class ListItemSubheadComponent {
  @HostBinding() class = 'zelis-dls';

  @Input() size: 'small' | 'medium' | 'large' = 'large';
  @Input() primaryText = '';
  @Input() secondaryText = '';
  @Input() @coerceBoolean divider = false;
  @Input() @coerceBoolean transparentBackground = false;
}
