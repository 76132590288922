import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageCardComponent } from './message-card.component';
import { ScrollCardModule } from '../scroll-card/scroll-card.module';
import { ClampMultiLineModule } from '../clamp-multi-line/clamp-multi-line.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  exports: [MessageCardComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ScrollCardModule,
    ClampMultiLineModule,
    MatDialogModule,
  ],
  declarations: [MessageCardComponent],
})
export class MessageCardModule {}
