<!--
  We support having the whole card be clickable, including for full navigation using an anchor tag.
  Therefore, depending on how the component is configured, we need different elements within our card.

  We can capture the common pieces (ng-content for content projection of the consumer provided elements
  and our optional visual emphasis logic) as ng-template's and then put them in the correct location
  using ngTemplateOutlet's without having to repeat any markup or logic.
-->
<ng-template #contentTemplate><ng-content></ng-content></ng-template>

<ng-template #emphasisTemplate>
  <ng-container *ngIf="emphasis">
    <div
      data-cy="card-emphasis"
      *ngIf="emphasis.position === 'top'"
      class="absolute top-0 left-0 right-0 h-2 rounded-t-md"
      [ngStyle]="ngStyleExpr"
    ></div>
    <div
      data-cy="card-emphasis"
      *ngIf="emphasis.position === 'left'"
      class="absolute top-0 bottom-0 left-0 w-2 rounded-tl-md rounded-bl-md"
      [ngStyle]="ngStyleExpr"
    ></div>
  </ng-container>
</ng-template>

<!--
  We reset the padding to zero by default so that we can support all possible use-cases
  for content projected elements (including the first party use-case of having an <a>
  tag take up the full area of the card so that the whole card is clickable for the
  purposes of navigation).
-->
<mat-card
  class="relative h-full p-0 rounded-lg bg-white"
  data-cy="card-mat-card"
  [class.border]="stroke"
  [class.border-transparent]="!stroke || hovered || elevated"
  [class.border-palette-m3-system-gray-85]="stroke && !hovered && !elevated"
  [class.mat-elevation-z3]="hovered"
  [class.no-box-shadow]="!hovered && !elevated"
  [class.mat-elevation-z1]="elevated && !hovered"
  [class.cursor-pointer]="href"
  [class.overflow-hidden]="oveflowHidden"
  (mouseover)="hovered = true && !disableHover"
  (mouseout)="hovered = false"
>
  <ng-container
    *ngIf="href; then anchorElement; else noAnchorElement"
  ></ng-container>

  <ng-template #anchorElement>
    <a
      [href]="href"
      [target]="target"
      rel="noopener noreferrer"
      class="block w-full h-full"
    >
      <ng-container *ngTemplateOutlet="emphasisTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </a>
  </ng-template>

  <ng-template #noAnchorElement>
    <ng-container *ngTemplateOutlet="emphasisTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </ng-template>
</mat-card>
