import {
  trigger,
  style,
  animate,
  transition,
  AnimationTriggerMetadata,
} from '@angular/animations';
import { EaseInOutCubic } from './easings';

/**
 * Slide up and down animation
 * Usage: <div [@moveLeftRight]="{value: 'start', params: {left: '20%'}}">...</div>
 */
export const MoveLeftRightAnimation: AnimationTriggerMetadata = trigger(
  'moveLeftRight',
  [
    transition('* <=> *', [
      animate(`500ms ${EaseInOutCubic}`, style({ left: '{{ left }}' })),
    ]),
  ]
);
