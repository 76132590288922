import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { AppParamsService } from '@services/app.params.service';
import { ConfigurationService } from '@services/configuration.service';

@Injectable()
export class AccountsService {
  constructor(
    private http: HttpClient,
    private appParams: AppParamsService,
    private configService: ConfigurationService
  ) {}

  public getPages(): Observable<any> {
    return this.getConfigSig().pipe(
      switchMap((configSig) => {
        const params: HttpParams = this.appParams.setHttpParams({
          config_signature: configSig,
        });
        return this.http.get('/api/accounts/pages.json', { params: params });
      })
    );
  }

  private getConfigSig(): Observable<string> {
    return this.configService.signatureAppParamsResolved.pipe(
      filter((sig) => !!sig),
      first((sig) => !!sig)
    );
  }
}
