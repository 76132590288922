import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { from, Observable, of, race, Subject } from 'rxjs';

@Injectable()
export class DynamicAssetLoaderService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  public loadAsset(
    asset: string,
    assetType: string,
    id: string = '',
    async: boolean = false
  ): Observable<boolean> {
    // Load a dynamic script
    if (assetType === 'script') {
      return this.loadScriptAsset(asset, id, async);
    }

    // Load a dynamic css file
    if (assetType === 'css') {
      return this.loadCssAsset(asset);
    }
  }

  private loadScriptAsset(
    asset: string,
    id: string = '',
    async: boolean
  ): Observable<boolean> {
    const node: HTMLScriptElement = this.document.createElement('script');
    const onLoad: Subject<boolean> = new Subject();
    node.src = asset;
    node.type = 'text/javascript';
    node.async = async;
    if (id !== '') {
      node.id = id;
    }
    node.onload = () => onLoad.next(true);
    node.onerror = () => onLoad.next(false);
    node.onabort = () => onLoad.next(false);
    this.document.head.appendChild(node);
    return onLoad;
  }

  private loadCssAsset(asset: string): Observable<boolean> {
    const node: HTMLLinkElement = this.document.createElement('link');
    const onLoad: Subject<boolean> = new Subject();
    node.href = asset;
    node.rel = 'stylesheet';
    node.onload = () => onLoad.next(true);
    node.onerror = () => onLoad.next(false);
    node.onabort = () => onLoad.next(false);
    this.document.head.appendChild(node);
    return onLoad;
  }
}
