import { createAction, props } from '@ngrx/store';
import { SortConfig } from '@interfaces/sort-config.model';

export enum SortFiltersSearchActionsTypes {
  requestSortFilters = '[GET] Get all sort search filters from config',
  requestSortFiltersSuccess = '[GET] Get all sort search filters from config success',
  requestSortFiltersFailure = '[GET] Get all sort search filters from config failed',
}

export const requestSortSearchFiltersFromConfig = createAction(
  SortFiltersSearchActionsTypes.requestSortFilters
);

export const requestSortSearchFiltersFromConfigSuccess = createAction(
  SortFiltersSearchActionsTypes.requestSortFiltersSuccess,
  props<{ sort_filters: SortConfig }>()
);

export const requestSortSearchFiltersFromConfigFailure = createAction(
  SortFiltersSearchActionsTypes.requestSortFiltersFailure,
  props<{ error: any }>()
);
