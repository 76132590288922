import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChatService } from '../../../services/pat-chat/chat.service';

@Component({
  selector: 'app-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
})
export class ChatButtonComponent implements OnInit {
  @Input() public chatId: string;
  @Input() public showChat: string;
  @Input() public buttonCopy: string;
  public isAvailable: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  ) as BehaviorSubject<boolean>;
  public chatStarted: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  ) as BehaviorSubject<boolean>;
  public chatRequested: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private chatService: ChatService) {}

  ngOnInit() {
    this.chatService.checkAvailabilityWithoutStartingChat();
    this.isAvailable = this.chatService.isAvailable;
    this.chatStarted = this.chatService.chatStarted;
    this.chatRequested = this.chatService.chatRequested;
  }

  public startChat() {
    this.chatService.startChatAfterAvailabilityCheck();
  }
}
