import {
  trigger,
  state,
  style,
  animate,
  transition,
  AnimationTriggerMetadata,
} from '@angular/animations';
import { EaseInOutCubic } from './easings';

/**
 * Fade in and out animation
 * Usage: <div [@fadeInOut]="isVisible ? 'in' : 'out'">...</div>
 */
export const FadeInOutAnimation: AnimationTriggerMetadata = trigger(
  'fadeInOut',
  [
    state('in', style({ opacity: 1 })),
    state('out', style({ opacity: 0 })),
    transition('in <=> out', [animate(`500ms ${EaseInOutCubic}`)]),
  ]
);
