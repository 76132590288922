import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicareSelectionComponent } from './medicare-selection.component';
import { TranslateModule } from "@ngx-translate/core";
import { WizardCardLayoutTemplateModule } from "../wizard-card-layout-template/wizard-card-layout-template.module";
import { WizardSelectionCardModule } from "../wizard-selection-card/wizard-selection-card.module";
import { WizardLoginLinkModule } from "../wizard-login-link/wizard-login-link.module";

@NgModule({
  declarations: [MedicareSelectionComponent],
  imports: [
    CommonModule, 
    TranslateModule, 
    WizardCardLayoutTemplateModule, 
    WizardSelectionCardModule, 
    WizardLoginLinkModule
  ],
  exports: [MedicareSelectionComponent],
})
export class MedicareSelectionModule {}
