import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Place } from '@classes/place.class';
import { CriticalParamsService } from '../critical-params/critical-params.service';
import { CriticalParams } from '@interfaces/critical-params.interface';

const CITY_LIMIT = '6';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(
    private http: HttpClient,
    private criticalParamsService: CriticalParamsService
  ) {}

  public forPlace(loc: Place): Observable<Place[]> {
    if (!loc || (!loc.name && !loc.geo)) {
      return of(null);
    }

    const params = this.getRequestParams(loc);

    return this.http
      .get(`/api/places/cities.json`, {
        params: params,
      })
      .pipe(
        catchError(() => of({})),
        filter((results: any) => !!results.cities),
        map((results: any) => results.cities.map((city) => new Place(city)))
      );
  }

  public searchedCity(): Observable<Place> {
    return this.criticalParamsService.criticalParamsSubject.pipe(
      filter((criticalParams: CriticalParams) => !!criticalParams.geo_location),
      distinctUntilKeyChanged('geo_location'),
      switchMap((criticalParams) => {
        const place = new Place({
          geo: criticalParams.geo_location,
        });
        return this.forPlace(place).pipe(map((results) => results[0]));
      })
    );
  }

  private getRequestParams(place: Place): HttpParams {
    let params: HttpParams = new HttpParams().set('limit', CITY_LIMIT);

    if (place.geo) {
      const coordinates = place.geo.split(',');
      params = params
        .set('lat', coordinates[0])
        .set('lng', coordinates[1])
        .set('sort', 'distance asc, city_type desc');
    } else {
      let name = place.name;
      // if city, state zip - only pass zip
      if (/([\w| ]+), (\w{2}) (\d{5})/.test(name)) {
        const nameSplit = name.split(' ');
        name = nameSplit[nameSplit.length - 1];
      }
      params = params.set('place', name);
    }
    return params;
  }
}
