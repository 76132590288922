import { Input, Component, Output, EventEmitter, OnChanges } from '@angular/core';
import { AlphaListItemTranslations } from '@interfaces/alpha-list-item-translations.model';
import { AlphaListItem } from '@services/alpha-list/alpha-list.interface.item';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-alpha-list-item',
  templateUrl: './alpha-list-item.component.html',
  styleUrls: ['./alpha-list-item.component.scss'],
})
export class AlphaListItemComponent implements OnChanges {
  @Input() public name: string;
  @Input() public description: string;
  @Input() public highIncentiveAmount: number;
  @Input() public episodeProcedure = false;
  @Input() translations: AlphaListItemTranslations;
  @Input() item: AlphaListItem;
  @Input() lozengeClasses: string;
  @Output() navigateToItem = new EventEmitter();
  @Output() selectItem: EventEmitter<AlphaListItem> = new EventEmitter();

  public metadata = '';
  public secondaryText = '';
  public lozengeText = '';
  public iconName = '';
  public tooltipText = '';

  constructor(
    private translateService: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnChanges(): void {
    this.setSecondaryText();
    this.setLozengeText();
  }

  private setSecondaryText(): void {
    this.secondaryText = this.description
      ? this.translateService.instant(this.description)
      : this.translations.noDescription;
    if (this.episodeProcedure) {
      this.metadata = 'Carepath';
      this.secondaryText = `${this.secondaryText}
        <div class="py-1 mt-3 bg-slate-100">
          ${this.translateService.instant('eoc_carepath_description')}
        </div>`;
    }
  }

  private setLozengeText(): void {
    if (this.highIncentiveAmount) {
      this.lozengeText =
        this.translations.upTo +
        ' ' +
        this.currencyPipe.transform(
          this.highIncentiveAmount,
          'USD',
          'symbol',
          '1.0-0'
        );
      this.iconName = 'dls-info-circle-light';
      this.tooltipText = this.translations.earnInfo;
    } else {
      this.lozengeText = '';
      this.iconName = '';
      this.tooltipText = '';
    }
  }
}
