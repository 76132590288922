import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  state('in', style({ opacity: 0, transform: 'translateX(0)' })),
  transition('* => *', [
    style({ opacity: 0, transform: 'translateY(-100%)' }),
    animate(150),
  ]),
  transition('* => void', [
    animate(150, style({ opacity: 0, transform: 'translateX(100%)' })),
  ]),
]);

export const fadeInOut = trigger('fadeInOut', [
  state('true', style({ opacity: 1 })),
  state('false', style({ opacity: 0 })),
  transition('0 => 1', animate('.5s')),
  transition('1 => 0', animate('.5s')),
]);
