import { Observable } from 'rxjs';
import { SettingsI } from '@interfaces/settings-i.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Settings {
  public award_supplemental: Observable<SettingsI>;
  public blue_compare_iconography: Observable<SettingsI>;
  public disclaimers: Observable<SettingsI>;
  public external_link_tiles: Observable<SettingsI>;
  public footerLinks: Observable<SettingsI>;
  public guided_search: Observable<SettingsI>;
  public msa: Observable<SettingsI>;
  public pcp: Observable<SettingsI>;
  public profile: Observable<SettingsI>;
  public search_filters: Observable<SettingsI>;
  public search_radius: Observable<SettingsI>;
  public search_sort: Observable<SettingsI>;
  public Logo: Observable<SettingsI>;
  public login_links: Observable<SettingsI>;
  public ccss: Observable<SettingsI>;
  public suppressed_identifiers: Observable<SettingsI>;
  public promotionals: Observable<SettingsI>;
}
