import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { DlsButtonModule } from '@zelis/dls/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectionsSelectorComponent } from './directions-selector.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@NgModule({
  exports: [DirectionsSelectorComponent],
  imports: [CommonModule, DlsButtonModule, FlexLayoutModule],
  providers: [DeviceDetectorService],
  declarations: [DirectionsSelectorComponent],
})
export class DirectionsSelectorModule {}
