import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserDetectService } from './browser-detect.service';
import { DynamicAssetLoaderModule } from '../dynamic-asset-loader/dynamic-asset-loader.module';

@NgModule({
  imports: [CommonModule, DynamicAssetLoaderModule],
  declarations: [],
  providers: [BrowserDetectService],
})
export class BrowserDetectModule {}
