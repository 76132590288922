import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  map,
  catchError,
  filter,
  switchMap,
  distinctUntilChanged,
} from 'rxjs/operators';
import { AppConfigService } from './app.config.service';
import { AppParamsService } from './app.params.service';
import { Settings } from '../classes/settings.class';
import { SettingsI } from '@interfaces/settings-i.model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private http: HttpClient,
    private appParams: AppParamsService,
    private settings: Settings,
    private configurationService: ConfigurationService
  ) {}

  /**
   * Get a single setting/config by name.
   * @param setting (string) Setting name
   * @param model (any) Optional model/class to apply to setting value
   * @param enableCache (boolean) Optional setting to enable caching. Default: true
   */
  public getSetting(
    setting: string,
    model: any = null,
    enableCache: boolean = true
  ): Observable<any> {
    return this.configurationService.signature.pipe(
      filter((sig: string) => !!sig),
      distinctUntilChanged(),
      switchMap((sig: string) => {
        return this.requestSetting(sig, setting, enableCache).pipe(
          catchError(() => of({ settings: [{}] })),
          map((resp) => this.mapSetting(resp, setting, model))
        );
      })
    );
  }

  public requestSetting(
    id: string,
    setting: string,
    enableCache: boolean = true
  ): Observable<any> {
    let params: HttpParams = this.appParams.setHttpParams({
      config_signature: id,
    });

    if (!enableCache) {
      params = params.set('cache', 'false');
    }

    const url = `/api/configuration_profile/settings/${setting}.json`;
    return (this.settings[setting] = this.http
      .get(url, { params: params, withCredentials: true })
      .pipe(
        catchError(() => of(null)),
        map((data) => {
          if (data) {
            return data;
          } else {
            throw new HttpErrorResponse({ status: 204 });
          }
        })
      ));
  }

  public getCachedSetting(setting: string): Observable<SettingsI> {
    return this.settings[setting];
  }

  private mapSetting(resp: any, setting: string, model: any): any {
    let mapped = null;
    if (
      resp &&
      resp.settings &&
      resp.settings[0] &&
      resp.settings[0][setting] !== undefined
    ) {
      mapped = resp.settings[0][setting];
    }
    if (model) {
      return new model(resp.settings[0][setting]);
    }
    return mapped;
  }
}
