import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerStateSelectionComponent } from './employer-state-selection.component';
import { DlsButtonModule } from "@zelis/dls/button";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from "@ngx-translate/core";
import { WizardLoginLinkModule } from "@components/network-selection-wizard/wizard-login-link/wizard-login-link.module";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [EmployerStateSelectionComponent],
  imports: [
    CommonModule, 
    DlsButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule, 
    WizardLoginLinkModule, 
    ReactiveFormsModule],
  exports: [EmployerStateSelectionComponent],
})
export class EmployerStateSelectionModule {}
