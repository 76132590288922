import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[zelisEventStopPropagation]',
})
export class EventStopPropagationDirective {
  @Input() public targetTags: string[] = [];

  @HostListener('click', ['$event']) onClick(event: any): void {
    if (this.targetTags.length) {
      const target = event.target as HTMLElement;
      const tagName = target?.tagName;
      this.isTargetTag(tagName) ? event.stopPropagation() : null;
      return;
    }
    event.stopPropagation();
  }

  private isTargetTag(tagName: string): boolean {
    return this.targetTags.some(
      (targetTag) => tagName.toUpperCase() === targetTag.toUpperCase()
    );
  }
}
