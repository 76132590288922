export class AuthStatus {
  auth_status?: boolean;
  msa_auth_status?: boolean;
  url?: string;
  text?: string;
  resolved?: boolean;

  constructor(obj?: AuthStatus) {
    if (obj) {
      this.auth_status = obj.auth_status || false;
      this.msa_auth_status = obj.msa_auth_status || false;
      this.url = obj.url || '';
      this.text = obj.text || 'app_global_login';
      this.resolved = obj.resolved || false;
    }
  }
}
