import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Member } from '@components/members';
import { MembersService } from '@services/members.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RxSavingsGuard implements CanActivate {
  constructor(private membersService: MembersService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.membersService.member.pipe(
      filter((member: Member) => !!member),
      map(
        (member: Member) =>
          member.rx_savings_eligible ||
          this.router.createUrlTree(['/'], { queryParamsHandling: 'preserve' })
      )
    );
  }
}
