import { MarketSegment, ProductType, SubMarketSegment } from '@enums/uux-marketing-plans.enum';

export const defaultTitle = 'Default Title';

export interface TitleConfig {
  title: string;
  description: string;
}

export const planListTitleConfig = {
  [MarketSegment.employer]: {
    [ProductType.hmo]: {
      title: 'network_selection_wizard_plan_list_employer_hmo_title',
      description: 'network_selection_wizard_plan_list_employer_hmo_description'
    },
    [ProductType.ppo]: {
      title: 'network_selection_wizard_plan_list_employer_ppo_title',
      description: 'network_selection_wizard_plan_list_employer_ppo_description'
    }
  },

  [MarketSegment.retail]: {
    title: 'network_selection_wizard_plan_list_retail_title',
    description: 'network_selection_wizard_plan_list_retail_description',
  },

  [MarketSegment.medicare]: {
    [SubMarketSegment.retailMedicare]: {
      [ProductType.hmo]: {
        title: 'network_selection_wizard_plan_list_medicare_hmo_title',
        description: 'network_selection_wizard_plan_list_medicare_hmo_description'
      },
      [ProductType.ppo]: {
        title: 'network_selection_wizard_plan_list_medicare_ppo_title',
        description: 'network_selection_wizard_plan_list_medicare_ppo_description'
      }
    },
    [SubMarketSegment.groupMedicare]: {
      [ProductType.hmo]: {
        title: 'network_selection_wizard_plan_list_medicare_hmo_title',
        description: 'network_selection_wizard_plan_list_medicare_hmo_description'
      },
      [ProductType.ppo]: {
        title: 'network_selection_wizard_plan_list_medicare_ppo_title',
        description: 'network_selection_wizard_plan_list_medicare_ppo_description'
      }
    }
  },

  [MarketSegment.medicaid]: {
    title: 'network_selection_wizard_plan_list_medicaid_title',
    description: 'network_selection_wizard_plan_list_medicaid_description'
  },

  default: {
    title: 'network_selection_wizard_plan_list_default_title',
    description: 'network_selection_wizard_plan_list_default_description'
  }
};
