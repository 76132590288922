import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fixed-tooltip',
  templateUrl: './fixed-tooltip.component.html',
  styleUrls: ['./fixed-tooltip.component.scss'],
})
export class FixedTooltipComponent {
  @Input() public title: string;
  @Input() public text: string;
  @Input() public callToActionText: string;
  @Input() public showDismissButton: boolean = false;
  @Input() public show: boolean = true;
  @Input() public link: string;
  @Input() public linkText: string;
  @Input() public hasLoadChangeClick: boolean;
  @Input() public dontShowAgainBtn: boolean;
  @Input() public dontShowAgainText: string;

  @Output() public closeElement: EventEmitter<boolean> = new EventEmitter();
  @Output() public callToActionClick: EventEmitter<void> = new EventEmitter();
  @Output() public dismissClick: EventEmitter<void> = new EventEmitter();
  @Output() public dismissForSession: EventEmitter<void> = new EventEmitter();

  public dismissBtnClick(): void {
    this.dismissClick.emit();
    this.closeTooltip();
  }

  public dismissForSessionClick(): void {
    this.dismissForSession.emit();
    this.closeTooltip();
  }

  public closeTooltip(): void {
    /*
    NOTE: the hasLoadChangeClick input is being used because when initializing this tooltip
    after a router navigation the "platformClickOutside" event is being invoked.
    An example of this can be seen when navigating back home from SERP on a uux network change
    */
    if (this.hasLoadChangeClick) {
      this.hasLoadChangeClick = false;
      return;
    }
    this.onClose();
  }

  public onClose(): void {
    this.show = false;
    this.closeElement.emit(true);
  }
}
