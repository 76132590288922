import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { NetworksService } from './networks.service';
import { Network } from '@interfaces/network.model';
import { SignatureResponse } from '@interfaces/signature-response.model';

@Injectable({
  providedIn: 'root',
})
export class AlphaPrefixService {
  constructor(
    private configurationService: ConfigurationService,
    private networksService: NetworksService
  ) {}

  public getNetworksByAlphaPrefix(
    alphaPrefix: string,
    defaultAccountLookup?: boolean
  ): Observable<Network[]> {
    return this.configurationService
      .getSignatureByParam({ alphaPrefCd: alphaPrefix })
      .pipe(
        switchMap((signatureResponse: SignatureResponse) => {
          // Account 170 indicates there is no account configured with the given alpha prefix code
          if (defaultAccountLookup && signatureResponse.account_id === 170) {
            return of(null);
          }
          return this.networksService.getMappedNetworksBySignature(
            signatureResponse.signature
          );
        })
      );
  }
}
