import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class CustomFilterOptions {
  public bdcOptionSort(options) {
    // Sort BDTC first, then BDC+ by alpha, then BDC by alpha
    let bdtc = [];
    let bdcp = [];
    let bdc = [];
    options.forEach((item) => {
      if (item.name.indexOf('+') !== -1) {
        bdcp.push(item);
      } else {
        bdc.push(item);
      }
    });

    bdtc = _.filter(options, ['value', 'BDTC']);
    bdcp = _.orderBy(bdcp, ['name'], ['asc']);
    bdc = _.orderBy(bdc, ['name'], ['asc']);
    bdc = _.reject(bdc, ['value', 'BDTC']);
    let out = [];
    out = out.concat(bdtc, bdcp, bdc);

    return out;
  }
}
