import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsaFormComponent } from './msa.form.component';

const msaRoutes: Routes = [
  {
    path: '',
    component: MsaFormComponent,
  },
];
export const MsaRouting: ModuleWithProviders<RouterModule> =
  RouterModule.forChild(msaRoutes);
