import { BvaService } from '../bva.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-bva-intended-providers',
  templateUrl: './intended-providers.component.html',
  styleUrls: ['./intended-providers.component.scss'],
})
export class BvaIntendedProvidersComponent implements OnInit {
  @Input() public show: boolean = true;
  @Input() public procedure: string;
  @Input() public results: any;
  @Output() public providerSelected = new EventEmitter<any>();
  @Output() public intendedProviderCleared = new EventEmitter<boolean>();

  public facilitySearching: boolean;
  public filteredOptions: Observable<any>;
  public form: UntypedFormGroup;
  public facility: UntypedFormControl;
  public active: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public bvaService: BvaService
  ) {}

  ngOnInit() {
    this.form = this.buildForm();
    this.setUpSlides();
  }

  public setUpSlides(): void {
    this.active = 'stepOne';
    this.setupAutocomplete();
  }

  public goToChooseProviderStep(): void {
    this.bvaService.clearIntendedProvider();
    this.intendedProviderCleared.emit(true);
    this.show = true;
    this.active = 'chooseProvider';
  }

  public close() {
    this.form.reset();
    this.show = false;
  }

  public hasNoProvider(): void {
    this.show = false;
    this.close();
  }

  public facilityDisplay(result: any): any {
    if (result && result.name) {
      return result.name;
    } else {
      return null;
    }
  }

  public handleSelectedFacility(option): void {
    this.bvaService.addIntendedProvider(option);
    this.providerSelected.emit(option);
    this.close();
  }

  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      facility: new UntypedFormControl(null, []),
    });
  }

  private setupAutocomplete(): void {
    this.filteredOptions = this.form.get('facility').valueChanges.pipe(
      switchMap((term) => {
        if (term && typeof term === 'string') {
          this.facilitySearching = true;
        }
        const results = this.filter(term);
        this.facilitySearching = false;
        return results;
      })
    );
  }

  private filter(term: string): Observable<any> {
    let filteredResult;
    if (term && term !== undefined && typeof term === 'string') {
      if (this.results) {
        filteredResult = this.results.filter(
          (result) => result.name.toLowerCase().indexOf(term.toLowerCase()) > -1
        );
      }
    } else {
      filteredResult = this.results;
    }
    return of(filteredResult);
  }
}
