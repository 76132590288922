import { ParsedPath } from './parsed-path.model';

export class ParsedUrl {
  public protocol?: string;
  public host?: string;
  public hostname?: string;
  public port?: string;
  public pathname?: string;
  public pathObject?: ParsedPath;
  public search?: string;
  public searchObject?: any;
  public hash?: string;

  constructor(obj?: any) {
    obj = obj || {};
    (this.protocol = (obj.protocol || '').replace(':', '')),
      (this.host = obj.host),
      (this.hostname = obj.hostname),
      (this.port = obj.port),
      (this.pathname = obj.pathname),
      (this.pathObject = this.parsePath(obj.pathname)),
      (this.search = obj.search),
      (this.searchObject = this.queryStringToObject(obj.search)),
      (this.hash = obj.hash.replace('#', ''));
  }

  private parsePath?(path: string): ParsedPath {
    if (path) {
      return new ParsedPath(path);
    }
    return null;
  }

  private queryStringToObject?(query: string): any {
    query = query || '';
    const searchObject: any = {};
    if (query) {
      const queries = query.replace(/^\?/, '').split('&');
      if (queries && queries.length > 0) {
        queries.forEach((item) => {
          const [queryKey, queryValue] = item.split('=');
          if (queryKey) {
            const newKey = decodeURIComponent(queryKey);

            if (newKey.includes('[]')) {
              searchObject[newKey] = searchObject[newKey] || [];
              searchObject[newKey].push(decodeURIComponent(queryValue))
              return searchObject;
            }

            searchObject[decodeURIComponent(queryKey)] = decodeURIComponent(queryValue);
            return searchObject;
          }
        });
      }
    }
    return searchObject;
  }
}
