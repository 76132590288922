import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileHeaderOverlayComponent } from '../mobile-header-overlay/mobile-header-overlay.component';

@Component({
  selector: 'app-mobile-hamburger-menu',
  templateUrl: './mobile-hamburger-menu.component.html',
  styleUrls: ['./mobile-hamburger-menu.component.scss'],
})
export class MobileHamburgerMenuComponent {
  constructor(public dialog: MatDialog) {}

  public openMenu(): void {
    this.dialog.open(MobileHeaderOverlayComponent, {
      backdropClass: 'fullscreen-overlay-header',
      panelClass: 'fullscreen-overlay',
      height: '100vh',
    });
  }
}
