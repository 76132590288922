import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PageTransition } from '@interfaces/page-transition.interface';
import { Observable } from 'rxjs';
import { Logo } from '@interfaces/logo.model';
import { startWith } from 'rxjs/operators';
import { CcssService } from '@services/ccss/ccss.service';
import { GlobalHeaderSettings } from '@components/global-header/global-header-settings.class';
import { GlobalHeaderService } from '@components/global-header/global-header.service';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { SettingsService } from '@services/settings.service';

@Component({
  selector: 'app-overlay-header',
  templateUrl: './overlay-header.component.html',
})
export class OverlayHeaderComponent {
  @Output() public goToOverlayPage: EventEmitter<PageTransition> =
    new EventEmitter();

  public headerLogo: Observable<Logo> = this.globalHeaderSettings.logo;
  public suppressLanguageSelect: Observable<boolean> = this.settingsService
    .getSetting('suppress_language')
    .pipe(startWith(true));

  constructor(
    public ccss: CcssService,
    public globalHeaderSettings: GlobalHeaderSettings,
    public globalHeaderService: GlobalHeaderService,
    public media: MediaObserver,
    private settingsService: SettingsService
  ) {}
}
