import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { NetworksService } from '../services/networks.service';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Network } from '@interfaces/network.model';

@Injectable()
export class NetworkGuard implements CanActivate {
  constructor(private networksService: NetworksService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.networksService.resolvedNetwork
      .pipe(filter((network: Network) => !!network))
      .pipe(map((network) => true));
  }
}
