export class AuthStatus {
  auth_status?: boolean;
  url?: string;
  text?: string;

  constructor(obj?: AuthStatus) {
    if (obj) {
      this.auth_status = obj.auth_status || false;
      this.url = obj.url || '';
      this.text = obj.text || 'app_global_login';
    }
  }
}
