<div class="average-cost-container">
  <div class="average-label">
    {{ translations.averageLabel }}
  </div>
  <div class="average-rail">
    <div class="average-line"></div>
    <div class="rail-path"></div>
    <div
      class="average-indicator"
      [ngClass]="{ low: percentage <= 50, high: percentage > 50 }"
      [ngStyle]="{ left: endPercentage + '%' }"
      [@moveLeftRight]="{
        value: percentage,
        params: { left: percentage + '%' }
      }"
      (@moveLeftRight.start)="onIndicatorAnimationStart()"
      (@moveLeftRight.done)="onIndicatorAnimationDone()"
    ></div>
  </div>
  <div class="message" [@fadeInOut]="indicatorAnimationComplete ? 'in' : 'out'">
    {{ translations[messageKey] }}
  </div>
</div>
