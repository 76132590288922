export interface QualtricsMetadata {
  pendoVisitorId: string;
  authenticated: boolean;
  location: string;
  gender: string;
  pageType: string;
  pageUrl: string;
  searchType: string;
  searchMethod: string;
  searchTerm: string;
  networkName: string;
  client: string;
  accountId: string;
}

export interface SearchData {
  searchName: string;
  searchMethod: string;
  searchTerm: string;
  networkName?: string;
}

export enum MetadataProperty {
  PendoVisitorId = 'pendoVisitorId',
  Authenticated = 'authenticated',
  Location = 'location',
  Gender = 'gender',
  PageType = 'pageType',
  PageUrl = 'pageUrl',
  SearchType = 'searchType',
  SearchMethod = 'searchMethod',
  SearchTerm = 'searchTerm',
  NetworkName = 'networkName',
  Client = 'client',
  AccountId = 'accountId',
}
