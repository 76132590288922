import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { PageTransition } from '../../../interfaces/page-transition.interface';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MemberCardMagnify } from '@zelis/platform-ui-components';
import { Network } from '@interfaces/network.model';
import { NetworksService } from '../../../services/networks.service';
import { SettingsService } from '../../../services/settings.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LocationAutosuggest } from '../../../classes/location-autosuggest.class';
import { PageAnimations } from '../../../utilities/page.animations';
import { LocationService } from '../../../services/location/location.service';
import { GatedEntryOverlayConfig } from '@interfaces/gated-entry-overlay-config.model';
import { Place } from '../../../classes/place.class';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { StorageUtilities } from '@utilities/storage.utilities';

@Component({
  selector: 'app-gated-entry-overlay-alpha-prefix',
  templateUrl: './gated-entry-overlay-alpha-prefix.component.html',
  styleUrls: ['./gated-entry-overlay-alpha-prefix.component.scss'],
  animations: PageAnimations,
})
export class GatedEntryOverlayAlphaPrefixComponent
  implements OnInit, OnDestroy
{
  @Input() initialAppParams: any;
  @Input() public gatedEntryConfig: GatedEntryOverlayConfig;
  @Input() loggedIn: boolean;
  @Input() public pageTransition: PageTransition;

  @Output() public goToOverlayPage: EventEmitter<PageTransition> =
    new EventEmitter();
  @Output() public closeOverlay: EventEmitter<void> = new EventEmitter();
  @Output() public goBack: EventEmitter<void> = new EventEmitter();

  public alphaPrefixLength: number = 3;
  public alphaPrefixForm: UntypedFormGroup = new UntypedFormGroup({
    alphaPrefix: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(this.alphaPrefixLength),
      Validators.maxLength(this.alphaPrefixLength),
    ]),
    network: new UntypedFormControl(null, [Validators.required]),
  });
  public memberCardMagnify: MemberCardMagnify;
  public networks: Network[];
  public suppressLogo: boolean = false;
  public locationSelected: boolean = false;

  private subscriptions: SubscriptionManager = new SubscriptionManager();

  constructor(
    public locationAutosuggest: LocationAutosuggest,
    public locationService: LocationService,
    public networksService: NetworksService,
    private settingsService: SettingsService,
    private storage: StorageUtilities
  ) {}

  ngOnInit() {
    this.initMemberCardMagnify();
    this.requestSuppressLogo().subscribe(
      (setting) => (this.suppressLogo = setting)
    );
    this.subscribeToGeoChange();
    this.initLocationSelected();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onLocationSelected(place: Place): void {
    this.locationAutosuggest.onLocationSelect(place);
    this.locationSelected = !!(place && place.geo);
  }

  public onAlphaPrefixChange(): void {
    const alphaPrefix = this.alphaPrefixForm.get('alphaPrefix');
    if (alphaPrefix.valid) {
      this.filterNetworksByPrefix(alphaPrefix.value);
    }
  }

  public onAlphaPrefixSubmit(): void {
    const networkId: string = this.alphaPrefixForm.get('network').value;
    this.networksService.setNetwork(networkId);
    this.storage.sessionStorageSet('gatedEntryCompleted', true);
    this.closeOverlay.emit();
  }

  public onAlphaPrefixFocus(): void {
    this.magnifyAlphaPrefix();
  }

  public onNetworkFocus(): void {
    this.magnifyPlanName();
  }

  public trackByNetworkId(network: Network): string {
    return network.id;
  }

  private initMemberCardMagnify(): void {
    setTimeout(() => this.magnifyAlphaPrefix(), 800);
  }

  private filterNetworksByPrefix(prefix: string): void {
    this.networksService.mappedNetworks
      .pipe(
        first((networks: Network[]) => !!(networks && networks.length)),
        map((networks: Network[]) =>
          networks.filter(
            (item: Network) => (item.prefix || '').indexOf(prefix) !== -1
          )
        )
      )
      .subscribe((networks: Network[]) => {
        this.setNetworks(networks);
        this.setNetworkFieldValue(networks);
      });
  }

  private setNetworks(networks: Network[]): void {
    this.networks = networks;
  }

  private setNetworkFieldValue(networks: Network[]): void {
    let networkId = null;
    if (networks.length === 1) {
      networkId = networks[0].id;
    }
    this.alphaPrefixForm.get('network').patchValue(networkId);
    if (networks.length > 1) {
      this.magnifyPlanName();
    }
  }

  private magnifyAlphaPrefix(): void {
    this.memberCardMagnify = 'ALPHA_PREFIX';
  }

  private magnifyPlanName(): void {
    this.memberCardMagnify = 'PLAN_NAME';
  }

  private requestSuppressLogo(): Observable<boolean> {
    return this.settingsService.getSetting('suppress_member_card_logo');
  }

  private subscribeToGeoChange(): void {
    this.subscriptions.add(
      this.locationService.geo.subscribe((place: Place) =>
        this.setLocationSelected(place)
      )
    );
  }

  private initLocationSelected(): void {
    this.locationSelected = !!(
      this.gatedEntryConfig.locationAutoResolve ||
      (this.initialAppParams && this.initialAppParams.geo_location)
    );
  }

  private setLocationSelected(place: Place): void {
    this.locationSelected = !!(place && place.geo);
  }
}
