<h3 class="text-center margin bottom-4x" *ngIf="translations.heading">
  {{ translations.heading }}
</h3>
<div class="directions-buttons">
  <div
    *ngFor="let service of services; trackBy: trackByFn"
    class="margin bottom-1x"
  >
    <zelis-button
      *ngIf="serviceEnabled(service)"
      [href]="serviceUrl(service, origin, destination)"
      [target]="serviceTarget(service)"
      variant="stroked"
      [ngClass]="service + '-button'"
      [ariaLabel]="serviceName(service)"
      size="large"
    >
      <span class="directions-text-container">
        <img
          [attr.src]="assetPath + serviceIcon(service)"
          alt=""
          width="48"
          height="48"
        />
        <span class="button-text">{{ serviceName(service) }}</span>
      </span>
    </zelis-button>
  </div>
</div>
