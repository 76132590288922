import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicAssetLoaderService } from './dynamic-asset-loader.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [DynamicAssetLoaderService],
})
export class DynamicAssetLoaderModule {}
