import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Place } from '@classes/place.class';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { Breakpoints } from '@classes/breakpoints.class';
import { LocationService } from '@services/location/location.service';
import { LocationAutosuggest } from '@classes/location-autosuggest.class';
import { NetworksService } from '@services/networks.service';
import { MatDialog } from '@angular/material/dialog';
import { StorageUtilities } from '@utilities/storage.utilities';
import { MobileAutosuggestOverlayComponent } from '@components/mobile-autosuggest-overlay';
import { combineLatest } from 'rxjs';
import { LocationGpsStatus } from '@interfaces/location-gps-status.interface';
import { NetworkSelectionWizardService } from "@services/network-selection-wizard/network-selection-wizard.service";

@Component({
  selector: 'app-location-of-care',
  templateUrl: './location-of-care.component.html',
})
export class LocationOfCareComponent implements OnInit, OnDestroy {
  @Input() public isDefaultLocationPage: boolean;
  @Output() public handleLocationSubmit: EventEmitter<void> = new EventEmitter();
  @Output() public handleModifyLocation: EventEmitter<void> = new EventEmitter();

  public locationSelected: boolean = false;
  public noValidNetworks: boolean;
  public gpsIsLoading: boolean;
  public hasSelectedOption: boolean = false;
  public locationAutosuggestLocations: Place[];
  public searchForCareTitle: string;
  public isBroker: boolean;
  private subscriptions = new SubscriptionManager();
  private usedGps: boolean = false;

  constructor(
    public breakpoints: Breakpoints,
    public locationService: LocationService,
    public locationAutosuggest: LocationAutosuggest,
    public networksService: NetworksService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private storage: StorageUtilities,
    private networkSelectWizardService: NetworkSelectionWizardService,
  ) {}

  ngOnInit() {
    this.isBroker = this.networkSelectWizardService.isBroker;
    this.subscribeToNoValidNetworks();
    this.subscribeToLocationAutosuggest();
    this.setSubHeader();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public onContinueClick() {
    const isReturnUser = this.networkSelectWizardService.isReturnUser;
    const noLocationSelected = !this.locationSelected && !this.hasSelectedOption;
    const disabledButton = this.continueButtonDisabled()

    if (disabledButton) {
      return;
    } else {
      if (noLocationSelected && !isReturnUser) {
        // set default location to local storage if no other location was chosen
        const selectedLocation = this.locationAutosuggest.selectedLocation;
        this.networkSelectWizardService.saveSelectedLocation(selectedLocation);
      }

      if (!this.isDefaultLocationPage) {
        this.handleModifyLocation.emit();
        return;
      }

      this.handleLocationSubmit.emit();
    }
  }

  public onLocationSelect(place: Place): void {
    this.hasSelectedOption = true;
    this.locationAutosuggest.onLocationSelect(place);
  }

  public onGpsLocateSelect(): void {
    this.usedGps = true;
    this.locationAutosuggest.requestBrowserLocation();
  }

  public onOptionSelect(event?: Place | string): void {
    if (event !== 'current-location') {
      this.usedGps = false;
    }
    this.subscribeToGeoChange();
  }

  public openMobileLocation(): void {
    if (this.breakpoints.isMobile) {
      const dialogRef = this.dialog.open(MobileAutosuggestOverlayComponent, {
        backdropClass: 'fullscreen-overlay-autosuggest',
        panelClass: 'fullscreen-overlay',
        height: '100%',
        restoreFocus: false,
        data: { locationSelected: this.locationSelected },
      });
      this.subscriptions.add(
        dialogRef.afterClosed().subscribe((res) => {
          if (res && res.optionSelected) {
            this.onOptionSelect();
            this.changeDetectorRef.detectChanges();
          }
        })
      );
    }
  }

  public continueButtonDisabled(): boolean {
    const noLocationSelected = !this.locationSelected && !this.hasSelectedOption;
    const noPreviousSelection = !this.storage.sessionStorageGet('userSelectedPlace');

    return this.gpsIsLoading ||
      (noLocationSelected && noPreviousSelection) || this.noValidNetworks;
  }

  private setSubHeader(): void {
    this.searchForCareTitle = this.isDefaultLocationPage ?
      'network_selection_wizard_location_search_for_care' :
      'network_selection_wizard_location_search_for_care_return_user'
  }

  private setLocationSelected(place: Place, gps: LocationGpsStatus): void {
    if (this.usedGps && !gps.place) {
      this.locationSelected = false;
    } else {
      this.locationSelected = !!(place && place.geo);
    }
  }

  private subscribeToGeoChange(): void {
    this.subscriptions.add(
      combineLatest([
        this.locationService.geo,
        this.locationAutosuggest.gpsStatus,
      ]).subscribe(([geo, gps]) => {
        this.gpsIsLoading = gps?.loading;
        this.setLocationSelected(geo, gps);
      })
    );
  }

  private subscribeToNoValidNetworks(): void {
    this.subscriptions.add(
      this.networksService
        .getNoValidNetworks()
        .subscribe((noValid: boolean) => {
          this.noValidNetworks = noValid;
        })
    );
  }

  private subscribeToLocationAutosuggest(): void {
    this.subscriptions.add(
      this.locationAutosuggest.autosuggestLocations.subscribe((places) => {
        this.locationAutosuggestLocations = places;
        this.changeDetectorRef.detectChanges();
      })
    );
  }
}
