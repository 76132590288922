export class SessionSettings {
  public sessionDuration?: number; // Time (seconds) until session expires server-side.
  public pollingInterval?: number; // Time (seconds) polling interval between hasSession API requests.
  public pollingTimeout?: number; // Time (seconds) to allow the hasSession API call to complete.

  constructor(settings?: SessionSettings) {
    settings = settings || {};
    this.sessionDuration = this.parseNumber(settings.sessionDuration, 60);
    this.pollingInterval = this.parseNumber(settings.pollingInterval, 20);
    this.pollingTimeout = this.parseNumber(settings.pollingTimeout, 5);
  }

  private parseNumber?(value: any, defaultValue: number) {
    const parsed = parseFloat(String(value));
    if (value && parsed) {
      return parsed;
    }
    return defaultValue;
  }
}
