export class HnnyPcpService {
  private disable: boolean = false;

  public cannotSelect(
    provider: any,
    storedGetParams: any,
    ci: string
  ): boolean {
    this.disable = false;
    const now = new Date().getTime();

    if (storedGetParams.selection_eligibility_date) {
      this.disableBasedOnDate(storedGetParams, provider, now);
    } else {
      this.ciIsNotPcp(ci);
    }

    this.disableBasedOnContractId(provider.contract_id);

    return this.disable;
  }

  private ciIsNotPcp(ci: string): void {
    if (ci.toLowerCase().indexOf('pcp') === -1) {
      this.disable = true;
    }
  }

  private disableBasedOnDate(
    storedGetParams: any,
    provider: any,
    now: number
  ): void {
    const formattedPCPDateArray =
      storedGetParams.selection_eligibility_date.split('-');
    const formattedPCPReceiveParams =
      formattedPCPDateArray[1] +
      '/' +
      formattedPCPDateArray[0] +
      '/' +
      formattedPCPDateArray[2];
    let testDate = new Date(
      storedGetParams.selection_eligibility_date
    ).getTime();

    testDate = this.convertTestDate(
      testDate,
      formattedPCPReceiveParams,
      storedGetParams,
      now
    );

    this.compareDates(provider, testDate);
  }

  private convertTestDate(
    testDate: number,
    formattedPCPReceiveParams: any,
    storedGetParams: any,
    now: number
  ): number {
    // convert user selection eligibility date to internal date format
    if (isNaN(testDate)) {
      testDate = new Date(formattedPCPReceiveParams).getTime();
    }
    if (isNaN(testDate)) {
      testDate = new Date(
        storedGetParams.selection_eligibility_date + 'T12:00:00'
      ).getTime();
    }
    if (isNaN(testDate) || testDate < now) {
      testDate = now - 1; // unknown format so use value in past to allow selection
    }

    return testDate;
  }

  private compareDates(provider: any, testDate: number): void {
    let leave, join;

    if (provider.future_termination_date) {
      leave = new Date(provider.future_termination_date).getTime();
      if (!isNaN(leave) && leave < testDate) {
        this.disable = true;
      }
    }

    if (provider.future_effective_date) {
      join = new Date(provider.future_effective_date).getTime();
      if (!isNaN(join) && join > testDate) {
        this.disable = true;
      }
    }
  }

  private disableBasedOnContractId(contract_id: number): void {
    if (String(contract_id).substr(0, 2) !== '55') {
      this.disable = true;
    }
  }
}
