import { WindowService } from '../window.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(
    private windowService: WindowService,
    private translateService: TranslateService
  ) {
    if (this.windowService['dataLayer'] === undefined) {
      this.windowService['dataLayer'] = [];
    }
  }

  public pushToDataLayer(gtmVariables): void {
    this.windowService['dataLayer'].push(gtmVariables);
  }

  public translate(data: any, props: string[], interpolation?: any): any {
    data = _.clone(data);
    if (data && props && props.length) {
      props.forEach((prop) => {
        if (data[prop]) {
          const interpolationData =
            (interpolation && interpolation[prop]) || null;
          data[prop] = this.translateService.instant(
            data[prop],
            interpolationData
          );
        }
      });
    }
    return data;
  }
}
