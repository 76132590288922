import {
  trigger,
  state,
  style,
  animate,
  transition,
  AnimationTriggerMetadata,
} from '@angular/animations';
import { EaseInOutCubic } from './easings';

/**
 * Slide open and close animation
 * Usage: <div [@slideOpenClose]="isOpened ? 'opened' : 'closed'">...</div>
 */
export const SlideOpenCloseAnimation: AnimationTriggerMetadata = trigger(
  'slideOpenClose',
  [
    state('opened', style({ height: '*', opacity: 1 })),
    state('closed', style({ height: '0px', opacity: 0 })),
    transition('opened <=> closed', [animate(`500ms ${EaseInOutCubic}`)]),
  ]
);
