export class ParsedPath {
  private _path?: string;
  private _segments?: string[];
  private _filename?: string;
  private _file?: string;
  private _extension?: string;

  constructor(path: string) {
    this.parsePath({ path: path });
  }

  public set path(value: string) {
    this.parsePath({ path: value });
  }
  public get path(): string {
    return this._path;
  }

  public set segments(value: string[]) {
    this.parsePath({ segments: value });
  }
  public get segments(): string[] {
    return this._segments;
  }

  public set filename(value: string) {
    this.parsePath({ filename: value });
  }
  public get filename(): string {
    return this._filename;
  }

  public set file(value: string) {
    this.parsePath({ file: value });
  }
  public get file(): string {
    return this._file;
  }

  public set extension(value: string) {
    this.parsePath({ extension: value });
  }
  public get extension(): string {
    return this._extension;
  }

  public toString(): string {
    return `/${this.segments.join('/')}`;
  }

  private parsePath?(parsed: any): void {
    if (parsed) {
      this.updateFilenameSegments(parsed);
      const segments =
        parsed.segments ||
        this.parseSegments(parsed.path) ||
        this.segments ||
        [];
      const pathname = this.parsePathname(segments);
      const filename = this.parseFilename(segments);
      const file = this.parseFile(filename);
      const extension = this.parseExtension(filename);

      this._path = pathname;
      this._segments = segments;
      this._filename = filename;
      this._file = file;
      this._extension = extension;
    }
  }

  private updateFilenameSegments(parsed: any): void {
    if (parsed.filename) {
      this.updateFilename(parsed.filename);
    }
    if (parsed.file) {
      this.updateFile(parsed.file);
    }
    if (parsed.extension) {
      this.updateExtension(parsed.extension);
    }
  }

  private parseSegments?(path: string): string[] {
    if (path) {
      return path.replace(/^\//g, '').split('/');
    }
    return null;
  }

  private parsePathname?(segments: string[]): string {
    if (segments && segments.length > 0) {
      return (
        '/' +
        (segments.length <= 1
          ? ''
          : segments.slice(0, segments.length - 1).join('/'))
      );
    }
    return null;
  }

  private parseFilename?(segments: string[]): string {
    if (segments && segments.length > 0) {
      return segments[segments.length - 1] || '';
    }
    return null;
  }

  private parseFile?(filename: string): string {
    if (filename) {
      const dotIndex: number = filename.indexOf('.');
      // Dot index > 0 to account for '.file' files where '.file' is the file name.
      if (dotIndex > 0) {
        return filename.substring(0, dotIndex);
      }
      return filename;
    }
    return null;
  }

  private parseExtension?(filename: string): string {
    if (filename) {
      const dotIndex: number = filename.indexOf('.');
      // Dot index > 0 to account for '.file' files where '.file' is the file name and there is no extension.
      if (dotIndex > 0) {
        return filename.substring(dotIndex + 1);
      }
      return '';
    }
    return null;
  }

  private updateFilename?(filename: string): void {
    if (filename && this.segments) {
      const filenameIndex = this.segments.length - 1 || 0;
      this._segments[filenameIndex] = filename;
    }
  }

  private updateFile?(file: string): void {
    if (file && this.segments) {
      const filenameIndex = this.segments.length - 1 || 0;
      const currentFilename = this.segments[filenameIndex];
      const currentExtension = this.parseExtension(currentFilename);
      const dot = currentExtension ? '.' : '';
      this._segments[filenameIndex] = `${file}${dot}${currentExtension}`;
    }
  }

  private updateExtension?(extension: string): void {
    if (this.segments) {
      const filenameIndex = this.segments.length - 1 || 0;
      const currentFilename = this.segments[filenameIndex];
      const currentFile = this.parseFile(currentFilename);
      const dot = extension ? '.' : '';
      this._segments[filenameIndex] = `${currentFile}${dot}${extension}`;
    }
  }
}
