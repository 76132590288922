<button
  mat-fab
  class="platform-button button-fab-peeker"
  type="button"
  tabindex="0"
  [color]="color"
  [attr.aria-label]="text"
  (click)="onClick()"
  (focus)="onFocus()"
  (blur)="onBlur()"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <ng-content></ng-content>
  <span class="button-text text-left" [@peekInOut]="peek ? 'in' : 'out'">
    <span class="pad left-1x">{{ text }}</span>
  </span>
</button>
