import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RouteUtilities } from '../../utilities/route.utilities';
import { Router } from '@angular/router';

@Component({
  selector: 'app-incompatible-browser-overlay',
  templateUrl: './incompatible-browser-overlay.component.html',
  styleUrls: ['./incompatible-browser-overlay.component.scss'],
})
export class IncompatibleBrowserOverlayComponent {
  private routeUtilities = new RouteUtilities();
  constructor(
    public dialogRef: MatDialogRef<IncompatibleBrowserOverlayComponent>,
    private router: Router
  ) {}

  public homeClick(): void {
    // check route, if on home page just close dialog, else redirect to home
    if (this.routeUtilities.getState(this.router.url) !== 'home') {
      this.router.navigate(['/']);
    }
    this.dialogRef.close();
  }
}
