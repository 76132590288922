import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";
import {
  BehaviorSubject,
  Observable,
  catchError,
  defer,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AuthService } from '@services/auth.service';
import { HttpClient } from '@angular/common/http';
import { CouponsDrugSearchResults } from '@interfaces/coupons-drug-search-results.interface';
import { CouponsDrugSearchResult } from '@interfaces/coupons-drug-search-result.interface';
import { PharmacyPricing } from "@zelis/dls/coupon-list-item";
import { CouponsDrugPricingResults } from "@interfaces/coupons-drug-pricing-results.interface";
import { CouponsDrugInfoResults } from "@interfaces/coupons-drug-info.interface";
import { CouponsDrugData } from "@interfaces/coupons-drug-data.interface";
import { CouponView } from "@interfaces/coupon-view.interface";
import { CouponShare } from "@interfaces/coupon-share.interface";

@Injectable({
  providedIn: 'root',
})
export class CouponsService {
  private rxCouponsEligibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(undefined);

  constructor(
    private settingsService: SettingsService,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  get isRxCouponsEligible(): Observable<boolean> {
    return this.rxCouponsEligibleSubject
      .asObservable()
      .pipe(filter((eligible) => eligible !== undefined));
  }

  public setRxCouponsEligibleSetting(): Observable<boolean> {
    return this.authService.storeAuthStatus().pipe(
      filter((auth) => auth?.resolved),
      take(1),
      switchMap((auth) =>
        defer(() => {
          return auth.auth_status
            ? this.getRxCouponsEligibleSetting()
            : of(false);
        })
      ),
      tap((eligible) => this.rxCouponsEligibleSubject.next(!!eligible))
    );
  }

  public getDrugAutoCompleteOptions(
    searchTerm: string
  ): Observable<CouponsDrugSearchResult[]> {
    const url = `/api/coupons/drug_query.json`;
    return this.http
      .get<CouponsDrugSearchResults>(url, {
        withCredentials: true,
        params: { query: searchTerm },
      })
      .pipe(
        map((results: CouponsDrugSearchResults) => results.drug_query),
        catchError(() => of([]))
      );
  }

  public getDrugPricing(
    ndc: string,
    quantity: number,
    zipCode: string
  ): Observable<PharmacyPricing[]>{
    const url = '/api/coupons/drug_pricing.json'
    return this.http
      .get<CouponsDrugPricingResults>(url, {
        withCredentials: true,
        params: { 
          ndc: ndc,
          quantity: quantity,
          zipcode: zipCode
        },
      })
      .pipe(
        map((results: CouponsDrugPricingResults) => results?.pharmacy_pricings 
          ? results.pharmacy_pricings 
          : []
        ),
        catchError(() => of([]))
      );
  }

  public getDrugInfo(
    ndc: string,
  ): Observable<CouponsDrugInfoResults>{
    const url = '/api/coupons/drug_info.json'
    return this.http
      .get<CouponsDrugInfoResults>(url, {
        withCredentials: true,
        params: { ndc: ndc },
      })
      .pipe(
        catchError(() => of({} as CouponsDrugInfoResults))
      );
  }

  public getDrugData(
    seo_name: string
  ): Observable<CouponsDrugData>{
    const url = "/api/coupons/drug_data.json";
    return this.http
      .get<CouponsDrugData>(url, {
        withCredentials: true,
        params: {
          seo_name: seo_name
        }
      })
      .pipe(
        map((result) =>  result?.available_forms 
            ? result
            :  null
        ),
        catchError(() => of(null))
      );
  }

  public getViewCoupon(
    drugName: string,
    pharmacy: string,
    cost: number,
    form: string,
    dosage: string,
    quantity: number
  ): Observable<CouponView>{
    const url = "/api/coupons/view.json";
    return this.http
      .get<CouponView>(url, {
        withCredentials: true,
        params: {
          drug_name: drugName,
          pharmacy_name: pharmacy,
          cost: cost, 
          form: form, 
          dosage: dosage,
          quantity: quantity
        }
      })
      .pipe(
        catchError(() => of({} as CouponView))
      );
  }

  public emailCoupon(
    drugName: string,
    pharmacy: string,
    cost: number,
    form: string,
    dosage: string,
    quantity: number,
    email: string
  ): Observable<any> {
    const url = "/api/coupons/email.json";
    return this.http
      .get<CouponShare>(url, {
        withCredentials: true,
        params: {
          drug_name: drugName,
          pharmacy_name: pharmacy,
          cost: cost, 
          form: form, 
          dosage: dosage,
          quantity: quantity,
          email: email
        }
      })
      .pipe(
        catchError(() => of({} as CouponShare))
      );
  }

  public textCoupon(
    drugName: string,
    pharmacy: string,
    cost: number,
    form: string,
    dosage: string,
    quantity: number,
    phone: string,
  ) {
    const url = "/api/coupons/sms.json";
    return this.http
      .get<CouponShare>(url, {
        withCredentials: true,
        params: {
          drug_name: drugName,
          pharmacy_name: pharmacy,
          cost: cost, 
          form: form, 
          dosage: dosage,
          quantity: quantity,
          phone_number: phone
        }
      })
      .pipe(
        catchError(() => of({} as CouponShare))
      );
  }

  private getRxCouponsEligibleSetting(): Observable<boolean> {
    return this.settingsService.getSetting('rx_coupons_eligible');
  }
}
