import {
  catchError,
  mergeMap,
  first,
  map,
  switchMap,
  timeout,
  retry,
  filter,
  takeWhile,
  tap,
  distinctUntilChanged,
  debounceTime,
  startWith,
} from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { AppConfigService } from './app.config.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { WindowService } from './window.service';
import {
  Observable,
  Subscription,
  BehaviorSubject,
  of,
  interval,
  iif,
  concat,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { SettingsService } from './settings.service';
import { SettingsLoginLinks } from '@interfaces/settings-login-links.model';
import { HttpClient } from '@angular/common/http';
import { RouteUtilities } from '@utilities/route.utilities';
import { SessionSettings } from '@interfaces/session-settings.model';
import { Auth } from '@interfaces/auth.interface';
import { LogOutService } from './logout.service';
import { FeaturesService } from './features/features.service';
import { FeatureFlagSet } from '@interfaces/feature-flag-set.interface';
import { StorageUtilities } from '@utilities/storage.utilities';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors, AuthStoreActions } from '@store/auth';
import { AppConfig } from '@interfaces/app-config.model';
import { LoginLinks } from '@interfaces/login-links.model';
import { NavigationEnd, Router } from '@angular/router';

const DEFAULT_LOGIN_SETTING_ENVIRONMENT = new SettingsLoginLinks({
  default: '/idp/auth',
  dynamic_params: '',
  relay_url: '',
  msa_relay_url: '',
});

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authStatus: Observable<AuthStatus> = this.storeAuthStatus();
  public msaAuthStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public routeUtilities = new RouteUtilities();

  private loggedIn: boolean;
  private loginSettingEnvironment: SettingsLoginLinks;
  private suppressLogin: boolean = true;
  private clientKeepAliveUrl: string = '';
  private env: string;
  private variant: string;

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private windowService: WindowService,
    private settingsService: SettingsService,
    private ngZone: NgZone,
    private logoutService: LogOutService,
    private featuresService: FeaturesService,
    private storage: StorageUtilities,
    private store: Store<any>,
    private router: Router
  ) {
    // Only for default if no yaml loginSetting
    this.loginSettingEnvironment = DEFAULT_LOGIN_SETTING_ENVIRONMENT;
    // Client or account level login_links setting
  }

  public storeAuthStatus(): Observable<AuthStatus> {
    return this.store.pipe(select(AuthStoreSelectors.getAuthStatus));
  }

  public subscribeToLoginLinksFromSetting(
    appConfig: AppConfig
  ): Observable<any> {
    return combineLatest([
      this.getLoginLinks(),
      this.checkAuthStatus(),
      this.listenToNavEnd(),
    ]).pipe(
      switchMap(([loginLinks, auth]) =>
        this.setLoginSettingEnvironment(loginLinks, auth, appConfig)
      )
    );
  }

  public isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // If log in status has been set from hasSession.json
      if (this.loggedIn !== null) {
        resolve(this.loggedIn);
      } else {
        // If not, detect hasSession.json response and resolve promise on that callback
        this.authStatus
          .pipe(first((auth: any) => auth.resolved))
          .subscribe((auth) => {
            resolve(auth);
          });
      }
    });
  }

  public initPollSession(): Subscription {
    return this.ngZone.runOutsideAngular(() => {
      return this.pollSession().subscribe({
        next: (auth: Auth) => {
          if (this.shouldReload(auth)) {
            this.setSessionCreationTimestampToStorage(auth.created_at);
            this.logoutService.reload();
            return;
          }
          if (auth && auth.auth_status && auth.created_at) {
            this.setSessionCreationTimestampToStorage(auth.created_at);
          }
          this.mapAuthStatus(auth);
          // this is coming from auth object already
          this.msaAuthStatus.next(auth.msa_auth_status);
          this.ngZone.run(() => {
            this.setLoggedIn(auth.auth_status);
          });
        },
      });
    });
  }

  public setKeepAliveUrl(keepAliveUrl: string): void {
    this.clientKeepAliveUrl = keepAliveUrl;
  }

  public logoutMsa() {
    this.logoutService.logoutMsa();
  }

  public logout() {
    this.logoutService.logout();
  }

  public getSuppressLogoutConfig(): Observable<boolean> {
    return this.getLoginLinks().pipe(
      map((result) => !!result?.suppress_logout)
    );
  }

  private listenToNavEnd(): Observable<any> {
    return this.router.events.pipe(
      filter((event) => {
        return event instanceof NavigationEnd;
      }),
      debounceTime(100),
      startWith(true)
    );
  }

  private getLoginLinks(): Observable<LoginLinks | null> {
    return this.settingsService
      .getSetting('login_links')
      .pipe(map((links) => (links ? new LoginLinks(links) : links)));
  }

  private checkAuthStatus(): Observable<AuthStatus> {
    return this.authStatus.pipe(
      filter((auth) => auth.resolved),
      distinctUntilChanged(
        (prev, curr) => prev.auth_status === curr.auth_status
      )
    );
  }

  private checkSession(requestTimeout: number = 10000): Observable<Auth> {
    const url = `/auth/hasSession.json`;
    return this.http
      .get(url, { withCredentials: true, params: { cache: 'false' } })
      .pipe(
        timeout(requestTimeout),
        retry(3),
        catchError(() => of(this.getSessionErrorResponse())),
        tap((auth: any) => {
          if (this.clientKeepAliveUrl && auth?.data?.auth_status) {
            this.http.get(this.clientKeepAliveUrl).subscribe();
          }
        }),
        map((auth: any) => auth?.data || this.getSessionErrorResponse())
      );
  }

  private mapAuthStatus(authStatus: AuthStatus | Auth): AuthStatus {
    let authObj = new AuthStatus({
      resolved: true,
      auth_status: authStatus?.auth_status,
      msa_auth_status:
        authStatus?.msa_auth_status
    });

    if (authStatus?.auth_status || !this.suppressLogin) {
      authObj = this.mapLoginLogout(authObj, authStatus);
    }
    this.store.dispatch(AuthStoreActions.setAuthStatus({ auth: authObj }));
    return authObj;
  }

  private mapLoginLogout(
    authObj: AuthStatus,
    authStatus: AuthStatus | Auth
  ): AuthStatus {
    if (authStatus?.auth_status) {
      authObj.msa_auth_status = authStatus.msa_auth_status;
      authObj.url = `/auth/SSOLogout?RelayState=${this.mapSsoLogoutRelay()}`;
      authObj.text = 'Log Out';
      return authObj;
    }
    authObj.url = this.buildLoginLink();
    return authObj;
  }

  private pollSession(): Observable<Auth> {
    return this.appConfigService.config.pipe(
      switchMap((appConfig: AppConfig) =>
        forkJoin([
          this.checkSession(),
          this.featuresService.getFeatureFlags().pipe(first()),
        ]).pipe(
          map(([auth, features]: [Auth, FeatureFlagSet]) => {
            return this.getInitialAuthStatus(auth, features);
          }),
          switchMap((auth: Auth) =>
            iif(
              () => auth.auth_status,
              concat(of(auth), this.startPollingSession(appConfig, auth)),
              of(auth)
            )
          ),
          distinctUntilChanged((prev, curr) => this.authIsDistinct(prev, curr))
        )
      )
    );
  }

  private startPollingSession(
    appConfig: AppConfig,
    auth: AuthStatus
  ): Observable<Auth> {
    if (auth.auth_status) {
      this.storage.localStorageRemove('idleLogout');
    }
    const settings = new SessionSettings({
      sessionDuration: appConfig.client_configs.session_duration,
      pollingInterval: appConfig.client_configs.session_polling_interval,
      pollingTimeout: appConfig.client_configs.session_polling_timeout,
    });
    return interval(settings.pollingInterval * 1000).pipe(
      mergeMap(() => this.checkSession(settings.pollingTimeout * 1000)),
      takeWhile((authData: Auth) => authData.auth_status, true)
    );
  }

  private getSessionErrorResponse(): Auth {
    return {
      auth_status: false,
      msa_auth_status: false,
      created_at: null,
    };
  }

  private authIsDistinct(prev: Auth, curr: Auth): boolean {
    return (
      prev.auth_status === curr.auth_status &&
      prev.msa_auth_status === curr.msa_auth_status &&
      prev.created_at === curr.created_at
    );
  }

  private setLoggedIn(auth: boolean): void {
    // State changes from logged in to logged out
    if (this.loggedIn && !auth) {
      this.logout();
      this.storage.localStorageSet('idleLogout', true);
    }
    this.loggedIn = auth;
  }

  private setLoginSettingEnvironment(
    loginLinks: LoginLinks,
    auth: AuthStatus,
    appConfig: AppConfig
  ): Observable<any> {
    this.env = appConfig.environment;
    this.variant = appConfig.variant;
    if (loginLinks) {
      this.suppressLogin = !!loginLinks.suppress_login;
      this.loginSettingEnvironment = new SettingsLoginLinks(
        loginLinks[this.env] ||
          loginLinks['development'] ||
          DEFAULT_LOGIN_SETTING_ENVIRONMENT
      );
      this.mapAuthStatus(auth);
      this.logoutService.setLoginSettingEnvironment(
        this.loginSettingEnvironment
      );
    }
    return of(this.loginSettingEnvironment);
  }

  private buildLoginLink(): string {
    const dynamicParams = this.loginSettingEnvironment.dynamic_params;
    let loginLink = (this.loginSettingEnvironment[this.variant] !== '' && this.loginSettingEnvironment[this.variant] !== undefined) ?
      this.loginSettingEnvironment[this.variant] :
      this.loginSettingEnvironment.default;

    if (dynamicParams['redirect_url']) {
      const sourceUrl = new URL(this.windowService['location'].href);
      // Use default RelayState as redirect param name if running on localhost.
      let relayStateParamName = dynamicParams['redirect_url'];
      if (sourceUrl.hostname === 'localhost') {
        relayStateParamName = 'RelayState';
      }
      sourceUrl.searchParams.delete('network_id');
      loginLink = this.routeUtilities.appendToLink(
        loginLink,
        relayStateParamName,
        encodeURIComponent(sourceUrl.toString())
      );
    }

    if (dynamicParams['metadata']) {
      loginLink = this.routeUtilities.appendToLink(
        loginLink,
        dynamicParams['metadata'],
        this.origin() + '/auth/metadata.xml'
      );
    }
    return loginLink;
  }

  private origin(): string {
    const location = this.windowService['location'];
    const portSequence = location.port ? ':' + location.port : '';
    return location.protocol + '//' + location.hostname + portSequence;
  }

  // Reload app when a new session has been created (in a new tab)
  private shouldReload(auth: Auth): boolean {
    const sessionCreatedAtSessionStorage =
      this.getSessionCreationTimestampFromSessionStorage();
    return (
      auth &&
      auth.auth_status &&
      auth.created_at &&
      sessionCreatedAtSessionStorage &&
      auth.created_at !== sessionCreatedAtSessionStorage
    );
  }

  // Appear logged out until a new session is created, even in new tabs.
  private shouldAppearLoggedOut(auth: Auth, features: FeatureFlagSet): boolean {
    const sessionCreatedAtSessionStorage =
      this.getSessionCreationTimestampFromSessionStorage();
    const sessionCreatedAtLocalStorage =
      this.getSessionCreationTimestampFromLocalStorage();
    return (
      this.getNewTabSessionsEnabled(features) &&
      auth &&
      auth.auth_status &&
      auth.created_at &&
      sessionCreatedAtSessionStorage === null &&
      sessionCreatedAtLocalStorage === auth.created_at
    );
  }

  private getSessionCreationTimestampFromSessionStorage(): string {
    return (
      this.windowService['sessionStorage'].getItem('session_created_at') || null
    );
  }

  private getSessionCreationTimestampFromLocalStorage(): string {
    return (
      this.windowService['localStorage'].getItem('session_created_at') || null
    );
  }

  private setSessionCreationTimestampToStorage(timestamp: string): void {
    this.windowService['localStorage'].setItem('session_created_at', timestamp);
    this.windowService['sessionStorage'].setItem(
      'session_created_at',
      timestamp
    );
  }

  private getNewTabSessionsEnabled(features: FeatureFlagSet): boolean {
    return (features && !!features['new_tab_sessions']) || false;
  }

  private getInitialAuthStatus(auth: Auth, features: FeatureFlagSet): Auth {
    if (this.shouldAppearLoggedOut(auth, features)) {
      auth.auth_status = false;
    }
    return auth;
  }

  private mapSsoLogoutRelay(): string {
    return this.loginSettingEnvironment.relay_url || this.origin();
  }
}
