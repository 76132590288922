<div class="bva-recommended-providers-container">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'bva_recommended_providers' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div
      *ngFor="let provider of bvaService.providers?.recommended; let i = index"
    >
      <mat-divider></mat-divider>
      <div
        class="margin top-2x bottom-2x"
        [attr.data-cy]="'recommended-providers.container-' + i"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <a
            [routerLink]="
              navigationService.providerProfileLink(provider, 'procedure_id')
            "
            queryParamsHandling="preserve"
            fxFlex="grow"
          >
            <div class="provider-name">{{ provider.name }}</div>
          </a>

          <button
            class="platform-button button-dark button-small"
            [attr.data-cy]="'recommended-providers.remove-provider-' + i"
            mat-icon-button
            type="button"
            tabindex="0"
            (click)="bvaService.clearRecommendedProvider(provider)"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M12,13.414 L8.707,16.707 C8.512,16.902 8.256,17 8,17 C7.744,17 7.488,16.902 7.293,16.707 C6.902,16.317 6.902,15.683 7.293,15.293 L10.586,12 L7.293,8.707 C6.902,8.317 6.902,7.683 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.683 17.098,8.317 16.707,8.707 L13.414,12 L16.707,15.293 C17.098,15.683 17.098,16.317 16.707,16.707 C16.512,16.902 16.256,17 16,17 C15.744,17 15.488,16.902 15.293,16.707 L12,13.414 Z"
              ></path>
            </svg>
          </button>
        </div>

        <div class="margin top-1x" fxLayout="row" fxLayoutAlign="start center">
          <span class="incentive-amount" *ngIf="getIncentiveAmount(provider)">
            <strong>{{ getIncentiveAmount(provider) }} </strong>
            {{ 'bva_reward' | translate }}
          </span>
          <span
            class="estimated-cost"
            *ngIf="getCostData(provider, 'member_cost')"
            >{{ getCostData(provider, 'member_cost') }}
            {{ 'estimated_cost' | translate }}</span
          >
        </div>

        <div
          class="text-small half-margin-top"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span class="insurance-cost">
            <ng-container *ngIf="getCostData(provider, 'employer_cost')">
              {{ 'bva_insurance_pays' | translate }}
              {{ getCostData(provider, 'employer_cost') }}.
            </ng-container>
            {{ 'bva_total_cost' | translate }}
            {{ getCostData(provider, 'procedure_cost') }}.
          </span>
        </div>

        <div
          class="text-small margin top-1x address"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <i
            >{{ provider.addr_line1 }} {{ provider.city }},
            {{ provider.state }} {{ provider.postal_code }}</i
          >
        </div>

        <div
          class="half-margin-top phone"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          Phone: {{ provider.phone | phone }}
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
