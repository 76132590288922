import { filter, map, tap } from 'rxjs/operators';
import { Injectable, Inject, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SettingsService } from '../settings.service';
import { CcssSettings } from '@interfaces/ccss-settings.model';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CcssService {
  public ccss = new CcssSettings({
    marketing_hero_image: null,
  });
  public getCcss: Observable<CcssSettings> = this.requestCcss();
  public resolved = new BehaviorSubject(false);
  public loginButtonClass: Observable<string> =
    this.listenToClass('login_button');

  constructor(
    private settingsService: SettingsService,
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone
  ) {}

  public removeBodyClass(name: string): void {
    this.document.body.classList.remove(name);
  }

  public applyClassToBody(name: string): void {
    this.document.body.classList.add(name);
  }

  public applyClassToHtml(className: string): void {
    this.document.querySelector('html').classList.add(className);
  }

  public removeHtmlClass(className: string): void {
    this.document.querySelector('html').classList.remove(className);
  }

  public listenToClass(className: string): Observable<string> {
    return this.resolved.asObservable().pipe(
      filter((resolved) => resolved),
      map(() => this.getClass(className))
    );
  }

  public getClass(className: string): string {
    return (this.ccss[className] && className) || 'no-op';
  }

  private addCcssToBody(ccss: CcssSettings): void {
    Object.keys(ccss).forEach((cssClass) => {
      if (this.hasNestedProperties(ccss[cssClass])) {
        Object.keys(ccss[cssClass]).forEach((subClass) =>
          this.createCssClass(ccss, cssClass, subClass)
        );
      } else {
        this.createCssClass(ccss, cssClass, '');
      }
    });
  }

  private createCssClass(
    ccss: CcssSettings,
    cssClass: string,
    subClass?: string
  ): void {
    const cssProperties =
      ccss[cssClass] && ccss[cssClass][subClass]
        ? ccss[cssClass][subClass]
        : ccss[cssClass];
    if (cssProperties) {
      const styleEl: HTMLStyleElement = this.document.createElement('style');
      styleEl.innerHTML = `.${cssClass} ${subClass} {${cssProperties}}`;
      this.zone.runOutsideAngular(() =>
        this.document.head.appendChild(styleEl)
      );
    }
  }

  private requestCcss(): Observable<CcssSettings> {
    return this.settingsService.getSetting('ccss').pipe(
      map((setting: any) => new CcssSettings(setting)),
      tap((ccss: CcssSettings) => {
        this.ccss = ccss;
        this.addCcssToBody(ccss);
        this.resolved.next(true);
      })
    );
  }

  private hasNestedProperties(klass): boolean {
    return !!(klass && klass.constructor === Object);
  }
}
