import { RouteUtilities } from '../utilities/route.utilities';
const routeUtilities = new RouteUtilities();

export class Logo {
  public header: Logos;
  public client: string;

  constructor(obj?: Logo) {
    this.header = new Logos(obj.client, obj.header);
  }
}

export class Logos {
  public primary_logo: string;
  public mobile_logo: string;

  constructor(client: string, obj?: Logos) {
    this.primary_logo = obj.primary_logo
      ? routeUtilities.stripProtocol(obj.primary_logo)
      : '../../assets/images/' + client + '.png';
    this.mobile_logo = obj.mobile_logo
      ? routeUtilities.stripProtocol(obj.mobile_logo)
      : this.primary_logo;
  }
}
