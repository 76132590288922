import { CareGuidanceFlags } from '@interfaces/care-guidance-flags.model';

export class CareGuidanceEligibility {
  surgical_decision: CareGuidanceFlags;
  medical_guidance: CareGuidanceFlags;

  constructor(obj?: CareGuidanceEligibility) {
    if (obj) {
      this.surgical_decision = obj.surgical_decision || null;
      this.medical_guidance = obj.medical_guidance || null;
    }
  }
}
