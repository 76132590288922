import { BvaService } from './../bva.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-bva-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class BvaNotesComponent {
  constructor(public bvaService: BvaService) {}
}
