import { AmenityTranslations } from './profile-amenities.translations.model';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ProfileAmenitiesAmenity } from './profile-amenities.interface.amenity';
import { MappedAdaAmenity } from './profile-amenities.interface.mapped-ada-amenity';
import { AmenitiesResponse } from './profile-amenities.amenities-response.interface';
import * as _ from 'lodash';

@Component({
  selector: 'platform-profile-amenities',
  styleUrls: ['./profile-amenities.component.scss'],
  templateUrl: './profile-amenities.component.html',
})
export class ProfileAmenitiesComponent implements OnInit, OnChanges {
  @Input() public amenities: AmenitiesResponse;
  @Input() public translations: AmenityTranslations = new AmenityTranslations();
  @Input() public telehealthCode: string;
  @Input() public adaAmenities: ProfileAmenitiesAmenity[];
  @Input() public limit = 1;

  public mappedAdaAmenities: MappedAdaAmenity[];

  private amenityMap: any = {
    APF: 'smartphone',
    APT: 'airport_shuttle',
    BGW: 'airport_shuttle',
    BHL: 'hearing',
    BHM: 'accessible',
    EHR: 'smartphone',
    EMR: 'tablet_mac',
    ERX: 'smartphone',
    HA: 'accessible',
    TTY: 'hearing',
  };

  ngOnInit(): void {
    this.mapAdaAmenities();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.telehealthCode) {
      this.mapTelehealthCode();
    }
    if (changes && (changes.amenities || changes.adaAmenities)) {
      this.mapAdaAmenities();
    }
  }

  public mapTelehealthCode(): void {
    if (this.telehealthCode) {
      this.amenityMap[this.telehealthCode] = 'video_call';
    }
  }

  public mapIcon(typeCode: string): string {
    let icon = 'domain'; // default material icon to use
    if (typeCode in this.amenityMap) {
      icon = this.amenityMap[typeCode];
    }
    return icon;
  }

  public getLimit(lim?: number): number {
    if (lim) {
      return this.limit;
    } else {
      return undefined;
    }
  }

  public trackByFn(index: number, amenity: any): string {
    return amenity.type;
  }

  private mapAdaAmenities(): void {
    this.mappedAdaAmenities = _.map(this.adaAmenities, (amenity) => {
      if (_.find(this.amenities.ada_building_amenities, amenity)) {
        return { type: amenity.type, displayText: this.translations.adaYes };
      } else {
        return { type: amenity.type, displayText: this.translations.adaNo };
      }
    });
  }
}
