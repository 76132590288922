<mgl-map
  *ngIf="browserSupported"
  [class.hide-mapbox-controls]="hideMapboxControls"
  [style]="style"
  [maxZoom]="maxZoom"
  [minZoom]="minZoom"
  [zoom]="zoom"
  [center]="center"
  [trackResize]="true"
  [fitBounds]="geoJsonBounds"
  [fitBoundsOptions]="fitBoundsOptions"
  [movingMethod]="animate ? 'flyTo' : 'jumpTo'"
  [attributionControl]="false"
  (load)="onMapLoad($event)"
  (moveStart)="onMapMoveStart()"
  (moveEnd)="onMapMoveEnd()"
  (click)="onMapClick()"
>
  <!-- GeoJSON Clustering -->
  <mgl-geojson-source
    *ngIf="geoJson"
    id="clusterSource"
    [data]="geoJson"
    [cluster]="true"
    [clusterRadius]="80"
    [clusterMaxZoom]="maxZoom"
    [clusterProperties]="clusterProperties"
  >
  </mgl-geojson-source>

  <mgl-markers-for-clusters *ngIf="geoJson" source="clusterSource">
    <!-- Cluster -->
    <ng-template mglClusterPoint let-feature>
      <div
        class="marker-cluster accent-color-background accent-color-contrast"
        tabindex="0"
        [ngClass]="{
          'incentive-color-background incentive-color-contrast':
            feature.properties.incentive
        }"
        (click)="onClusterClick(feature)"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        eventPreventDefault
        eventStopPropagation
      >
        {{ feature.properties.point_count }}
      </div>

      <!-- Cluster Popup -->
      <mgl-popup
        *ngIf="
          selectedCluster &&
          selectedCluster.properties.cluster_id ===
            feature.properties.cluster_id
        "
        [lngLat]="selectedCluster.geometry.coordinates"
        [offset]="[0, -22]"
        [closeButton]="false"
        [closeOnClick]="false"
      >
        <div class="cluster-popup inner-link-blue">
          <small class="text-color-detract">{{
            translations.providersAtLocation
          }}</small>
          <a
            href=""
            *ngFor="
              let properties of selectedCluster.properties.markers;
              trackBy: trackByProviderId
            "
            [ngClass]="{
              selected: properties.selected
            }"
            (click)="onClusterFeatureClick(properties)"
            eventPreventDefault
            eventStopPropagation
          >
            {{ properties.name }}
          </a>
        </div>
      </mgl-popup>
    </ng-template>

    <!-- Marker -->
    <ng-template mglPoint let-feature>
      <div
        class="marker"
        tabindex="0"
        [class.selected]="
          selectedMarker &&
          selectedMarker.properties.id === feature.properties.id
        "
        [attr.data-type]="feature.properties.provider_type"
        [attr.data-incentive]="feature.properties.incentive"
        (click)="onMarkerClick(feature.properties.id)"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        eventPreventDefault
        eventStopPropagation
      ></div>

      <!-- Marker Popup -->
      <mgl-popup
        *ngIf="
          markerPopups &&
          selectedMarker &&
          selectedMarker.properties.id === feature.properties.id
        "
        [lngLat]="feature.geometry.coordinates"
        [offset]="[0, -70]"
        [closeButton]="false"
        [closeOnClick]="false"
      >
        <div class="marker-popup">
          {{ feature.properties.name }}
        </div>
      </mgl-popup>
    </ng-template>

    <!-- Control: Mapbox Attribution -->
    <mgl-control mglAttribution position="bottom-right" [compact]="true">
    </mgl-control>
  </mgl-markers-for-clusters>
</mgl-map>
