import type { SerpDisclaimers } from '@interfaces/serp-disclaimers.interface';

// These could be extracted and generalized, the error type could be generic if needed.
// A single property for the status is easier to manage and prevent issues that
// arise when having to keep multiple properties in sync.
export type LoadingStatus = 'Init' | 'Loading' | 'Loaded';
export type FailureStatus = { error: string };
export type RequestStatus = LoadingStatus | FailureStatus;

export type SerpDisclaimersState = {
  disclaimers?: SerpDisclaimers;
  status: RequestStatus;
};

export const initialState: SerpDisclaimersState = { status: 'Init' };
