import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HierarchyListComponent } from './hierarchy-list.component';
import { MatIconModule } from '@angular/material/icon';
import { HierarchyListNestedOptionsModule } from '../hierarchy-list-nested-options/hierarchy-list-nested-options.module';
import { GlobalNetworkDropdownModule } from '../global-header/network/global-network-dropdown.module';
import { GlobalLocationSelectModule } from '../global-header/location/global-location-select.module';
import { SearchCheckboxModule } from '@components/+search/search-checkbox/search-checkbox.module';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  declarations: [HierarchyListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    HierarchyListNestedOptionsModule,
    GlobalNetworkDropdownModule,
    GlobalLocationSelectModule,
    SearchCheckboxModule,
    DlsIconModule
  ],
  exports: [HierarchyListComponent],
})
export class HierarchyListModule {}
