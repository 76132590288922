import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AverageCostTranslations } from './average-cost-translations.model';
import { MoveLeftRightAnimation } from '../animations/animation.move-left-right';
import { FadeInOutAnimation } from '../animations/animation.fade-in-out';

@Component({
  selector: 'platform-average-cost',
  templateUrl: './average-cost.component.html',
  styleUrls: ['./average-cost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  animations: [MoveLeftRightAnimation, FadeInOutAnimation],
})
export class AverageCostComponent {
  @Input()
  public set cost(value: number) {
    this._cost = parseFloat(String(value));
    this.calculatePercentage();
  }
  public get cost(): number {
    return this._cost;
  }
  @Input()
  public set high(value: number) {
    this._high = parseFloat(String(value));
    this.calculatePercentage();
  }
  public get high(): number {
    return this._high;
  }
  @Input()
  public set low(value: number) {
    this._low = parseFloat(String(value));
    this.calculatePercentage();
  }
  public get low(): number {
    return this._low;
  }
  @Input()
  public set translations(value: AverageCostTranslations) {
    this._translations = new AverageCostTranslations(value);
  }
  public get translations(): AverageCostTranslations {
    return this._translations;
  }

  public percentage = 0;
  public endPercentage: number = null;
  public messageKey = 'messageWellBelow';
  public indicatorAnimationComplete = false;

  private _cost = 0;
  private _high = 0;
  private _low = 0;
  private _translations: AverageCostTranslations =
    new AverageCostTranslations();
  private messageKeyToSet: string;

  public onIndicatorAnimationStart(): void {
    this.indicatorAnimationComplete = false;
    this.endPercentage = null;
  }

  public onIndicatorAnimationDone(): void {
    this.messageKey = this.messageKeyToSet;
    this.indicatorAnimationComplete = true;
    this.endPercentage = this.percentage;
  }

  private calculatePercentage(): void {
    this.percentage = 0;
    if (this.cost && this.high) {
      this.percentage =
        ((this.cost - this.low) / (this.high - this.low)) * 100 || 0;
      this.setPercentageLimits();
    }
    this.setMessageKey();
  }

  private setPercentageLimits(): void {
    if (this.percentage < 0) {
      this.percentage = 0;
    }
    if (this.percentage > 100) {
      this.percentage = 100;
    }
  }

  private setMessageKey(): void {
    if (this.percentage >= 0 && this.percentage < 25) {
      this.messageKeyToSet = 'messageWellBelow';
    } else if (this.percentage >= 25 && this.percentage < 50) {
      this.messageKeyToSet = 'messageSlightlyBelow';
    } else if (this.percentage === 50) {
      this.messageKeyToSet = 'messageIsAverage';
    } else if (this.percentage > 50 && this.percentage <= 75) {
      this.messageKeyToSet = 'messageSlightlyAbove';
    } else {
      this.messageKeyToSet = 'messageWellAbove';
    }
  }
}
