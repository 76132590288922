import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { fadeInOut } from '../animations/animation-triggers';

@Component({
  // TODO: Fix tslint
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss'],
  animations: [fadeInOut],
})
export class FlashMessageComponent implements OnChanges {
  @Input() show = false;
  @Input() message: string;
  @Input() fadeOutTime = 3000;
  @Input() type: string;
  @Input() iconClass: string;

  public trigger = false;

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.show &&
      changes.show.currentValue &&
      this.fadeOutTime
    ) {
      setTimeout(() => {
        this.trigger = true;
      });
      setTimeout(() => {
        this.trigger = false;
      }, this.fadeOutTime);
    }
  }
}
