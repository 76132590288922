import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  // TODO: Rename component to platform-a-z-list-legend
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-a-z-list-legend',
  templateUrl: './a-z-list-legend.component.html',
  styleUrls: ['./a-z-list-legend.component.scss'],
})
export class AZListLegendComponent {
  @Input() activeLetters: string[] = [];
  @Output() selectedLetter: EventEmitter<string> = new EventEmitter<string>();
  @Output() focusListItem: EventEmitter<boolean> = new EventEmitter<boolean>();

  public alphabet: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');

  public isActive(letter: string): boolean {
    if (this.activeLetters && this.activeLetters.length) {
      return this.activeLetters.indexOf(letter) > -1;
    }
    return true;
  }

  public handleKeypress(event) {
    event.stopPropagation();
    if (event.key === 'ArrowDown') {
      this.focusListItem.emit(true);
    }
  }
}
