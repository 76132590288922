import { EventPreventDefaultModule } from './../event-prevent-default/event-prevent-default.module';
import { DirectionsSelectorModule } from './../directions-selector/directions-selector.module';
import { DisclaimerIconModule } from './../disclaimer-icon/disclaimer-icon.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationComponent } from './location.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HighlightModule } from '../highlight/highlight.module';

@NgModule({
  exports: [LocationComponent],
  imports: [
    CommonModule,
    DisclaimerIconModule,
    DirectionsSelectorModule,
    EventPreventDefaultModule,
    MatDialogModule,
    HighlightModule,
  ],
  declarations: [LocationComponent],
})
export class LocationModule {}
