import { Action, createReducer, on } from '@ngrx/store';
import * as RadiusFilterActions from './radius-filters.actions';
import { RadiusFiltersState, initialState } from './radius-filters.state';

export const searchFiltersRadiusReducer = createReducer(
  initialState,
  on(RadiusFilterActions.requestRadiusSearchFiltersFromConfig, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    RadiusFilterActions.requestRadiusSearchFiltersFromConfigSuccess,
    (_state, { radius_filters }): any => ({
      ...radius_filters,
      loading: false,
      error: null,
    })
  ),
  on(
    RadiusFilterActions.requestRadiusSearchFiltersFromConfigFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    RadiusFilterActions.setSelectedSettingsFiltersFromConfig,
    (state, { selected_radius_filter }) => ({
      ...state,
      selected: selected_radius_filter,
    })
  )
);

export const reducer = (state: RadiusFiltersState, action: Action) => {
  return searchFiltersRadiusReducer(state, action);
};
