import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import { GuidedSearchComponent } from './guided-search.component';
import { AlphaListModule } from '@components/alpha-list/alpha-list.module';
import {
  AZListLegendModule,
  EventPreventDefaultModule,
} from '@zelis/platform-ui-components';
import { GlobalHeaderAutosuggestModule } from '@components/global-header/autosuggest/global-header-autosuggest.module';
import { UnfocusModule } from '@directives/unfocus/unfocus.module';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { PageScrollService } from 'ngx-page-scroll-core';
import { CloseButtonModule } from '@components/close-button/close-button.module';
import { LoginPromptModule } from '@components/login-prompt/login-prompt.module';
import { DlsEventStopPropagationDirectiveModule } from '@zelis/dls/event-stop-propagation-directive';
import { DlsIconModule } from '@zelis/dls/icons';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    UnfocusModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AlphaListModule,
    AZListLegendModule,
    GlobalHeaderAutosuggestModule,
    NgxPageScrollModule,
    CloseButtonModule,
    EventPreventDefaultModule,
    LoginPromptModule,
    DlsEventStopPropagationDirectiveModule,
    DlsIconModule,
  ],
  declarations: [GuidedSearchComponent],
  exports: [GuidedSearchComponent],
  providers: [PageScrollService],
})
export class GuidedSearchModule {}
