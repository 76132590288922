import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { LocationAutosuggest } from '../../classes/location-autosuggest.class';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { PageAnimations } from '../../utilities/page.animations';
import { PageTransition } from '../../interfaces/page-transition.interface';
import { SearchFilters } from '../../components/+search/classes/search-filters.class';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import { SearchFilter } from '@interfaces/search-filter.model';
import { SortConfig } from '@interfaces/sort-config.model';

@Component({
  selector: 'app-hierarchy-list',
  templateUrl: './hierarchy-list.component.html',
  styleUrls: ['./hierarchy-list.component.scss'],
  animations: PageAnimations,
})
export class HierarchyListComponent implements OnInit {
  public selectedFilter: any;
  public pageTransition: PageTransition = {
    page: 'list',
    direction: 'none',
  };
  public filters: SearchFilter[];
  public checkboxFilters;
  public radiusFilters: SearchFilter;
  public sortConfig: SortConfig;
  public type: string;
  public searchTerm: string;
  public isSpecialtySearch: boolean;
  public canClearAll: boolean = false;
  public incented: boolean;
  public searchParamType: string;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public searchFilters: SearchFilters,
    public locationAutosuggest: LocationAutosuggest,
    private changeDetectorRef: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    private sheetRef: MatBottomSheetRef
  ) {}

  ngOnInit() {
    this.type = this.data.type;
    this.isSpecialtySearch = this.data.isSpecialtySearch;
    this.handleType();
  }

  public onGoToOverlayPage(transition: PageTransition, name?: string): void {
    this.pageTransition.direction = transition.direction || 'forward';
    this.changeDetectorRef.detectChanges();
    this.pageTransition.page = transition.page;

    this.setSelectedFilter(name);
  }

  public closeOverlay(): void {
    this.type = null;
    this.bottomSheet.dismiss();
  }

  public clearFilters(): void {
    this.sheetRef.dismiss({ clear: true });
  }

  public onCheckboxSelection(event: any): void {
    this.sheetRef.dismiss({ selection: event, category: 'checkbox' });
  }

  public onSortSelected(option: SearchSortOption): void {
    this.sheetRef.dismiss({ selection: option, category: 'refineSortBy' });
  }

  public trackByFilter(index: number, filter: any): number {
    return index;
  }

  private setSelectedFilter(name?: string): void {
    switch (this.type) {
      case 'filter':
        this.selectedFilter = this.data.children.filter(
          (filter) => filter.name === name
        )[0];
        break;
      case 'refine':
        this.selectedFilter = this.radiusFilters;
        break;
      case 'sort':
        this.selectedFilter = this.sortConfig;
        break;
    }
  }

  private handleSortByOption(): void {
    this.sortConfig = this.data.children.sort;
    this.searchParamType = this.data.searchParamType;
    this.onGoToOverlayPage({ page: 'nested-options', direction: 'forward' });
  }

  private handleFiltersOption(): void {
    this.filters = this.data.children.filter(
      (filter) => filter.options.length >= 2
    );
    const selected = this.filters.filter((filter) => filter.selected);
    const unselected = this.filters.filter((filter) => !filter.selected);
    this.shouldClearAll(selected);
    this.filters = selected.concat(unselected);
  }

  private shouldClearAll(selectedFilters: SearchFilter[]): void {
    if (selectedFilters.length > 0) {
      this.canClearAll = true;
    }
  }

  private handleRefineOption(): void {
    if (this.isSpecialtySearch) {
      this.sortConfig = this.data.children.sort;
      this.searchParamType = this.data.children.searchParamType;
      this.sortConfig.selected =
        this.sortConfig.selected || this.sortConfig.search_specialty_id[0];
    } else {
      this.checkboxFilters = this.data.children.checkbox;
      this.radiusFilters = this.data.children.radius;
      this.searchTerm = this.data.children.searchTerm;
      this.incented = this.data.incented;
    }
  }

  private handleType(): void {
    switch (this.type) {
      case 'filter':
        this.handleFiltersOption();
        break;
      case 'refine':
        this.handleRefineOption();
        break;
      case 'sort':
        this.handleSortByOption();
        break;
    }
  }
}
