import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginLinkComponent } from './login-link.component';

@NgModule({
  declarations: [LoginLinkComponent],
  imports: [CommonModule],
  providers: [],
  exports: [LoginLinkComponent],
})
export class LoginLinkModule {}
