import { Injectable } from '@angular/core';
import { getClientCanonicalId } from '@utilities/provider.utilities';
import { SearchParamsService } from '@components/+search/search-params.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { WindowService } from './window.service';
import { SearchParamType } from '@interfaces/search-param-type.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private routeUtilities = new RouteUtilities();

  constructor(
    private searchParamsService: SearchParamsService,
    private windowService: WindowService
  ) {}

  /**
   * Generate a provider profile route navigation command set
   * @param provider Provider | IntendedProvider
   * @param searchParamType SearchParamType ("procedure_id", etc.)
   * @param referrerParams any (optional) Use if not on a search route. Example: {procedure_id: '1324', radius: '25'}
   * @param expandedRadius any (optional) Radius passed in if search expanded radius Example: '25'}
   */
  public providerProfileLink(
    provider: any,
    searchParamType: SearchParamType,
    referrerParams?: any,
    expandedRadius?: string
  ): string[] {
    return [
      '/profile',
      provider.provider_id,
      provider.location_id,
      this.referrerParamJSON(
        provider,
        searchParamType,
        referrerParams,
        expandedRadius
      ),
    ];
  }

  public referrerParamJSON(
    provider: any,
    searchParamType: SearchParamType,
    referrerParams?: any,
    expandedRadius?: string
  ): string {
    if (referrerParams) {
      return JSON.stringify(
        this.getReferrerParams(
          searchParamType,
          referrerParams[searchParamType],
          expandedRadius || referrerParams.radius,
          getClientCanonicalId(provider),
          provider.latitude + ',' + provider.longitude,
          referrerParams['fulltext'],
          this.includeRatesResponseID(searchParamType, referrerParams),
          provider.state,
          provider.npi_identifier
        )
      );
    }

    return JSON.stringify(
      this.referrerParamsFromRoute(provider, searchParamType, expandedRadius)
    );
  }

  private includeRatesResponseID(
    searchParamType: SearchParamType,
    referrer: any
  ): string {
    if (searchParamType === 'billing_code') {
      return referrer.rates_response_id;
    }
    return null;
  }

  private getReferrerParams(
    searchParamType: SearchParamType,
    typeId: string,
    radius: number,
    clientCanonicalId: string,
    providerGeo: string,
    fulltext: string,
    rates_response_id?: string,
    state?: string,
    npi_identifier?: string
  ): any {
    const out: any = {
      radius: radius,
      provider_geo: providerGeo,
    };
    out[searchParamType] = typeId;
    if (clientCanonicalId) {
      out.client_canonical_id = clientCanonicalId;
    }
    if (fulltext) {
      out.name = fulltext;
    }
    if (rates_response_id) {
      out.rates_response_id = rates_response_id;
    }
    if (state) {
      out.state = state;
    }
    if (npi_identifier) {
      out.npi_identifier = npi_identifier;
    }
    return out;
  }

  private referrerParamsFromRoute(
    provider: any,
    searchParamType: SearchParamType,
    expandedRadius?: string
  ): string {
    const providerGeo = provider.latitude
      ? provider.latitude + ',' + provider.longitude
      : provider.location_geo;
    return this.searchParamsService.getReferrerParams(
      searchParamType,
      expandedRadius ||
        this.routeUtilities.getProfileParsedReferrerParamsFromRoute(
          this.windowService['location'].pathname
        )['radius'],
      getClientCanonicalId(provider),
      providerGeo
    );
  }
}
