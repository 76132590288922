<ul class="legend" fxLayout="start stretch">
  <li *ngFor="let letter of alphabet" fxFlex>
    <a
      [href]=""
      [ngClass]="{ inactive: !isActive(letter) }"
      (keydown)="handleKeypress($event)"
      (keydown.enter)="selectedLetter.emit(letter)"
      (click)="selectedLetter.emit(letter)"
      [tabindex]="isActive(letter) ? 0 : -1"
    >
      {{ letter | uppercase }}
    </a>
  </li>
</ul>
