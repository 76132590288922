import { Component, ContentChildren, QueryList } from "@angular/core";
import { WizardSelectionCardComponent } from "../wizard-selection-card/wizard-selection-card.component";

@Component({
  selector: 'app-wizard-card-layout-template',
  templateUrl: './wizard-card-layout-template.component.html',
  styleUrls: ['./wizard-card-layout-template.component.scss'],
})
export class WizardCardLayoutTemplateComponent {
  @ContentChildren(WizardSelectionCardComponent)
  public cards!: QueryList<WizardSelectionCardComponent>;
}
