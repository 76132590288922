import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardSelectionCardComponent } from './wizard-selection-card.component';
import { DlsCardModule } from "@zelis/dls/card";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [WizardSelectionCardComponent],
  imports: [
    CommonModule, 
    DlsCardModule, 
    MatIconModule
  ],
  exports: [WizardSelectionCardComponent]
})
export class WizardSelectionCardModule {}
