import { StoreFeatures } from '@enums/store-features.enum';
import { SearchStoreState } from '@interfaces/search-state.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const searchState = createFeatureSelector<SearchStoreState>(StoreFeatures.Search);

export const getSearchState = createSelector(searchState, (state: SearchStoreState) => state);

export const getSearchMethod = createSelector(getSearchState, (state: SearchStoreState) => state.searchMethod);

export const getFacilitySuppressWriteReview =
  createSelector(
    getSearchState,
    (state: SearchStoreState) => state.searchClientSettings.facilitySuppressWriteReview
  );
