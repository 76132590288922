import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CcssService } from '../services/ccss/ccss.service';

@Injectable()
export class CcssResolver implements Resolve<string> {
  constructor(private ccssService: CcssService) {}

  resolve(): Promise<any> {
    return new Promise((resolve) => {
      this.ccssService.resolved.subscribe((resolved) => {
        if (resolved) {
          resolve(null);
        }
      });
    });
  }
}
