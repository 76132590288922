export class GlobalHeaderAutosuggestCategories {
  limit: number;
  defaultLimit: number;
  showMore: boolean;
  seeMoreText: string;
  seeMoreMaxLimit: number;
  title: string;
  actionText: string;

  constructor(obj: GlobalHeaderAutosuggestCategories) {
    this.limit = obj.limit || 0;
    this.defaultLimit = obj.defaultLimit || 0;
    this.showMore = obj.showMore || false;
    this.seeMoreText = obj.seeMoreText || '';
    this.seeMoreMaxLimit = obj.seeMoreMaxLimit || 10;
    this.title = obj.title || '';
    this.actionText = obj.actionText || '';
  }
}
