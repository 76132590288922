import { Network } from '@interfaces/network.model';
import { createAction, props } from '@ngrx/store';

export enum NetworkSearchActionsTypes {
  setResolvedNetwork = '[SET] Set resolved network',
}

export const setResolvedNetwork = createAction(
  NetworkSearchActionsTypes.setResolvedNetwork,
  props<{ network: Network }>()
);
