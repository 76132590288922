import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkSelectionWizardComponent } from './network-selection-wizard.component';
import { OverlayHeaderModule } from '@components/overlay-header/overlay-header.module';
import { DlsTrapFocusModule } from '@zelis/dls/trap-focus';
import { ExtendedModule, FlexModule } from '@ngbracket/ngx-layout';
import { PageContainerModule } from '@zelis/platform-ui-components';
import { LocationOfCareModule } from '@components/network-selection-wizard/location-of-care/location-of-care.module';
import { PlanTypeSelectionModule } from "@components/network-selection-wizard/plan-type-selection/plan-type-selection.module";
import { EmployerStateSelectionModule } from "@components/network-selection-wizard/employer-state-selection/employer-state-selection.module";
import { PlanListSelectionModule } from "@components/network-selection-wizard/plan-list-selection/plan-list-selection.module";
import { ProductTypeSelectionModule } from "@components/network-selection-wizard/product-type-selection/product-type-selection.module";
import { MedicareSelectionModule } from "@components/network-selection-wizard/medicare-selection/medicare-selection.module";
import { ConfirmPreviousSelectionsModule } from "@components/network-selection-wizard/confirm-previous-selections/confirm-previous-selections.module";
import { ModifyPreviousSelectionsModule } from "@components/network-selection-wizard/modify-previous-selections/modify-previous-selections.module";
import { DlsButtonModule } from "@zelis/dls/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [NetworkSelectionWizardComponent],
  imports: [
    CommonModule,
    OverlayHeaderModule,
    DlsTrapFocusModule,
    ExtendedModule,
    FlexModule,
    PageContainerModule,
    LocationOfCareModule,
    PlanTypeSelectionModule,
    EmployerStateSelectionModule,
    DlsButtonModule,
    MatIconModule,
    TranslateModule,
    PlanListSelectionModule,
    ProductTypeSelectionModule,
    MedicareSelectionModule,
    ConfirmPreviousSelectionsModule,
    ModifyPreviousSelectionsModule
  ],
  exports: [NetworkSelectionWizardComponent]
})
export class NetworkSelectionWizardModule {}
