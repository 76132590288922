import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmPreviousSelectionsComponent } from './confirm-previous-selections.component';
import { DlsButtonModule } from '@zelis/dls/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ConfirmPreviousSelectionsComponent],
  imports: [
    CommonModule,
    DlsButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule
  ],
  exports: [ConfirmPreviousSelectionsComponent]
})
export class ConfirmPreviousSelectionsModule {}
