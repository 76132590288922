import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchDisclaimersService } from '@components/+search/classes/search-disclaimers.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SerpDisclaimersEffects } from './serp-disclaimers.effects';
import { featureKey, reducer } from './serp-disclaimers.reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, reducer),
    EffectsModule.forFeature([SerpDisclaimersEffects]),
  ],
  providers: [SearchDisclaimersService],
})
export class SerpDisclaimersModule {}
