export class ObservableError implements Error {
  readonly name = 'ObservableError';
  readonly message: string;
  readonly ok = false;

  constructor(
    init: {
      message?: string;
    } = {}
  ) {
    this.message = init.message || 'Undefined Error';
  }
}
