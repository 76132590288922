import { BillingCodeBenefitLimits } from '@interfaces/billing-code-benefit-limits.model';
import { BillingCode } from '@interfaces/billing-code.interface';
import { BillingCodePreRequisite } from '@interfaces/billing-code-pre-requisite.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BillingCodeOON } from '@interfaces/billing-code-OON.model';
import { BillingCodeMeta } from '@interfaces/billing-code-meta.interface';

export interface BillingCodeState extends EntityState<BillingCode> {
  loading: boolean;
  error: string | any;
  meta: BillingCodeMeta | null;
  selectedBillingCode: BillingCode | null;
  billingCodeBenefitLimits: BillingCodeBenefitLimits | null;
  billingCodePreRequisite: BillingCodePreRequisite | null;
  billingCodeOON: BillingCodeOON | null;
}

export const billingCodeAdapter: EntityAdapter<BillingCode> =
  createEntityAdapter<BillingCode>({
    selectId: (billingCode: BillingCode) => billingCode.service_code,
  });

export const initialState: BillingCodeState =
  billingCodeAdapter.getInitialState({
    loading: true,
    error: '',
    meta: null,
    selectedBillingCode: null,
    billingCodeBenefitLimits: null,
    billingCodePreRequisite: null,
    billingCodeOON: null,
  });
