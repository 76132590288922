import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkSelectionDropdownComponent } from './network-selection-dropdown.component';
import { DlsIconModule } from '@zelis/dls/icons';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from '@zelis/platform-ui-components';
import { DlsListItemModule } from '@zelis/dls/list-item';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [NetworkSelectionDropdownComponent],
  imports: [
    CommonModule,
    DlsIconModule,
    TranslateModule,
    ClickOutsideModule,
    MatMenuModule,
    DlsListItemModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [NetworkSelectionDropdownComponent]
})
export class NetworkSelectionDropdownModule {}
