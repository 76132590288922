import { Injectable } from '@angular/core';
import { SortConfig } from '@interfaces/sort-config.model';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';

@Injectable({
  providedIn: 'root',
})
export class FiltersUtilities {
  public getMatchedSort(
    searchParamType: SearchParamType,
    query: string,
    sortConfig: SortConfig,
    serpLite?: boolean
  ): SearchSortOption {
    const options = sortConfig[searchParamType] || sortConfig.default || [];
    const queryMatch = this.getQueryMatch(options, query);
    const translation = serpLite
      ? 'serp_lite_default'
      : `${searchParamType}_default`;
    const translationMatch = this.getTranslationMatch(
      options,
      sortConfig[translation]
    );
    return queryMatch || translationMatch || options[0];
  }

  private getQueryMatch(
    options: SearchSortOption[],
    query: string
  ): SearchSortOption {
    return options.find((option: SearchSortOption) => {
      return option.query === query;
    });
  }

  private getTranslationMatch(
    options: SearchSortOption[],
    translation: string
  ): SearchSortOption {
    return options.find((option: SearchSortOption) => {
      return option.translation === translation;
    });
  }
}
