import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../../services/pat-chat/chat.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-passive-chat',
  templateUrl: 'passive-chat.component.html',
  styleUrls: ['./passive-chat.component.scss'],
})
export class PassiveChatComponent implements OnInit {
  public isAvailable: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.isAvailable = this.chatService.isAvailable;
  }
}
