import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyLogoComponent } from './company-logo.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [CompanyLogoComponent],
  imports: [CommonModule, TranslateModule],
  declarations: [CompanyLogoComponent],
})
export class CompanyLogoModule {}
