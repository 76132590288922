import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, iif } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Member } from '../members';
import { MembersService } from '../../services/members.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { MleService } from '../../services/mle.service';
import * as _ from 'lodash';
import { MleStatus } from '@interfaces/mle-status.model';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';

@Injectable({
  providedIn: 'root',
})
export class GlobalHeaderService {
  public currentTerm = new BehaviorSubject('');
  public profileMobileShowToggle: boolean = false;

  constructor(
    private memberService: MembersService,
    private mleService: MleService,
    private store: Store<any>
  ) {}

  public getAccountLabel(): Observable<string> {
    return this.store.pipe(
      select(AuthStoreSelectors.getAuthStatus),
      switchMap((authStatus: AuthStatus) =>
        iif(
          () => authStatus.auth_status,
          this.getMemberName(),
          of('global_header_profile')
        )
      )
    );
  }

  public updateTerm(term): void {
    this.currentTerm.next(term);
  }

  public isSsEnabled(): Observable<boolean> {
    return combineLatest([
      this.memberService.member,
      this.mleService.status,
    ]).pipe(
      switchMap(([member, mleStatus]: [Member, MleStatus]) => {
        return of(
          member &&
            member.incentives_enabled &&
            mleStatus &&
            mleStatus.isEligible
        );
      })
    );
  }

  private getMemberName(): Observable<string> {
    return this.memberService.member.pipe(
      first((member: Member) => member !== undefined),
      switchMap((member: Member) => {
        if (!!member && member.full_name) {
          return of(member && member.full_name);
        }
        return of('global_header_profile');
      })
    );
  }
}
