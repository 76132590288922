import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LozengeComponent } from './lozenge/lozenge.component';
import { ThemeLozengeComponent } from './theme-lozenge/theme-lozenge.component';
import { HighEmphasisYellowLozengeComponent } from './high-emphasis-yellow-lozenge/high-emphasis-yellow-lozenge.component';
import { LowEmphasisGrayLozengeComponent } from './low-emphasis-gray-lozenge/low-emphasis-gray-lozenge.component';
import { LowEmphasisGreenLozengeComponent } from './low-emphasis-green-lozenge/low-emphasis-green-lozenge';

@NgModule({
  imports: [CommonModule],
  declarations: [
    LozengeComponent,
    ThemeLozengeComponent,
    HighEmphasisYellowLozengeComponent,
    LowEmphasisGrayLozengeComponent,
    LowEmphasisGreenLozengeComponent,
  ],
  exports: [
    ThemeLozengeComponent,
    HighEmphasisYellowLozengeComponent,
    LowEmphasisGrayLozengeComponent,
    LowEmphasisGreenLozengeComponent,
  ],
})
export class DlsLozengeModule {}
