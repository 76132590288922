import {
  Component,
  Input,
  QueryList,
  ChangeDetectionStrategy,
  ContentChildren,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import { CarouselItemDirective } from './carousel.item.directive';
import { SubscriptionManager } from '../utility/subscription-manager.utility';

@Component({
  selector: 'platform-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class CarouselComponent implements AfterContentInit, OnDestroy {
  @Input() public animation = 'elastic-slide';

  @ContentChildren(CarouselItemDirective)
  public items: QueryList<CarouselItemDirective>;

  public activeIndex = 0;
  public previousIndex: number = null;
  public carouselReady = false;

  private sm: SubscriptionManager = new SubscriptionManager();

  ngAfterContentInit() {
    this.setItemStatus(this.items, this.activeIndex, this.previousIndex, true);
    this.sm.add(
      this.items.changes.subscribe((items) => {
        this.activeIndex = 0;
        this.previousIndex = null;
        this.setItemStatus(items, this.activeIndex, this.previousIndex, true);
      })
    );
    this.carouselReady = true;
  }

  ngOnDestroy() {
    this.sm.destroy();
  }

  public nextItem(): void {
    const nextIndex = this.activeIndex + 1;
    if (this.indexIsValid(nextIndex)) {
      this.goToIndex(nextIndex, true);
    } else {
      this.goToIndex(0, true);
    }
  }

  public previousItem(): void {
    const prevIndex = this.activeIndex - 1;
    if (this.indexIsValid(prevIndex)) {
      this.goToIndex(prevIndex, false);
    } else {
      this.goToIndex(this.items.length - 1, false);
    }
  }

  public goToIndex(index: number, byIncrement: boolean): void {
    this.previousIndex = this.activeIndex;
    this.activeIndex = index;
    this.setItemStatus(
      this.items,
      this.activeIndex,
      this.previousIndex,
      byIncrement
    );
  }

  public hasItems(): boolean {
    return this.items && this.items.length > 0;
  }

  private indexIsValid(index: number): boolean {
    return index <= this.items.length - 1 && index >= 0;
  }

  private setItemStatus(
    items: CarouselItemDirective[] | QueryList<CarouselItemDirective>,
    activeIndex: number,
    previousIndex: number,
    byIncrement: boolean
  ): void {
    if (items && items.length > 0) {
      items.forEach((item: CarouselItemDirective, index: number) => {
        const classes = { add: [], remove: [] };
        this.setItemDirectionClass(classes, item, byIncrement);
        this.setItemEnterClass(classes, item, index, activeIndex);
        this.setItemLeaveClass(classes, item, index, previousIndex);
        item.addElementClass(classes.add.join(' '));
        item.removeElementClass(classes.remove.join(' '));
      });
    }
  }

  private setItemDirectionClass(
    classes: any,
    item: CarouselItemDirective,
    byIncrement: boolean
  ): void {
    if (byIncrement) {
      classes.add.push('item-next');
      classes.remove.push('item-previous');
    } else {
      classes.add.push('item-previous');
      classes.remove.push('item-next');
    }
  }

  private setItemEnterClass(
    classes: any,
    item: CarouselItemDirective,
    index: number,
    activeIndex: number
  ): void {
    if (index === activeIndex) {
      classes.add.push('item-enter');
    } else {
      classes.remove.push('item-enter');
    }
  }

  private setItemLeaveClass(
    classes: any,
    item: CarouselItemDirective,
    index: number,
    previousIndex: number
  ): void {
    if (index === previousIndex) {
      classes.add.push('item-leave');
    } else {
      classes.remove.push('item-leave');
    }
  }
}
