export class LoginLinks {
  public development?;
  public qa?;
  public uat?;
  public production?;
  public suppress_login?;
  public suppress_logout?;

  constructor(obj: LoginLinks) {
    this.development = obj.development || null;
    this.qa = obj.qa || null;
    this.uat = obj.uat || null;
    this.production = obj.production || null;
    this.suppress_login = obj.suppress_login || null;
    this.suppress_logout = obj.suppress_logout || null;
  }
}
