import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { ProactiveChatComponent } from './proactive-chat.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChatButtonModule } from '../chat-button/chat-button.module';
import { DlsButtonModule } from '@zelis/dls/button';

@NgModule({
  declarations: [ProactiveChatComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    ChatButtonModule,
    DlsButtonModule,
  ],
  exports: [ProactiveChatComponent],
})
export class ProactiveChatModule {}
