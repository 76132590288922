import { FocusMonitor } from '@angular/cdk/a11y';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonSize, ButtonVariant } from '@zelis/dls/button';
import { coerceBoolean } from 'coerce-property';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { SearchInputDirective } from './search-input.directive';

const PRIMARY_PADDING_RIGHT = 16;
const SECONDARY_PADDING_RIGHT = 4;
const CLEAR_BUTTON_PADDING = 24;

export type SearchButtonVariant = Extract<ButtonVariant, 'flat' | 'stroked'>;

@Component({
  selector: 'zelis-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  @HostBinding() class = 'zelis-dls';

  @Input() variant!: 'primary' | 'secondary';

  @Input() @coerceBoolean hideButton?: boolean;

  /**
   * Determines the variant of the search button
   */
  @Input() buttonVariant?: SearchButtonVariant = 'flat';

  /**
   * Whether to display the audio input button
   */
  @Input() @coerceBoolean audioInput?: boolean;

  /**
   * Whether to style the input for a dark background
   */
  @Input() @coerceBoolean onDarkBackground?: boolean;

  /**
   * Whether the search button is in a loading state
   */
  @Input() @coerceBoolean loading?: boolean;

  @Output() searchButtonClick = new EventEmitter<MouseEvent>();

  @Output() audioInputButtonClick = new EventEmitter<MouseEvent>();

  @Output() clearClick = new EventEmitter<MouseEvent>();

  @ViewChild('buttonsContainer') buttonsContainer?: ElementRef<HTMLElement>;

  @ViewChild('container') container?: ElementRef<HTMLElement>;

  @ContentChild(SearchInputDirective) input!: SearchInputDirective;

  isMouseOver$ = new BehaviorSubject(false);
  isFocusWithin$ = new BehaviorSubject(false);
  buttonsContainerWidth = 0;
  isTouchDevice: boolean;
  showClearButton$!: Observable<boolean>;

  private destroyed = new Subject<void>();

  get isPrimary() {
    return this.variant === 'primary';
  }

  get isSecondary() {
    return this.variant === 'secondary';
  }

  get buttonSize(): ButtonSize {
    return this.isPrimary ? 'large' : 'medium';
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.isMouseOver$.next(true);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isMouseOver$.next(false);
  }

  constructor(
    private mediaMatcher: MediaMatcher,
    private focusMonitor: FocusMonitor
  ) {
    this.isTouchDevice = this.mediaMatcher.matchMedia('(hover: none)').matches;
  }

  ngAfterContentInit() {
    this.showClearButton$ = combineLatest([
      this.input.valueChanges$,
      this.isFocusWithin$,
      this.isMouseOver$,
    ]).pipe(
      map(([value, isFocusWithin, isMouseOver]) => {
        return (
          (value ?? '').length > 0 &&
          (isFocusWithin || isMouseOver || this.isTouchDevice)
        );
      })
    );
  }

  ngAfterViewInit() {
    if (this.container) {
      this.focusMonitor
        .monitor(this.container.nativeElement, true)
        .pipe(takeUntil(this.destroyed), map(Boolean))
        .subscribe((value) => this.isFocusWithin$.next(value));
    }
  }

  ngOnDestroy() {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }

  clear(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.input.clear();
    this.clearClick.emit();
  }

  onButtonsContainerResize(entries: ResizeObserverEntry[]) {
    if (!entries || !entries.length) {
      return;
    }
    const { width } = entries[0].contentRect;
    this.showClearButton$.pipe(take(1)).subscribe((showClearButton) => {
      this.input.paddingRight =
        this.calculateInputPaddingRight(width, showClearButton) + 'px';
    });
  }

  private calculateInputPaddingRight(
    buttonsContainerWidth: number,
    showClearButton: boolean
  ) {
    return (
      buttonsContainerWidth +
      (this.isPrimary ? PRIMARY_PADDING_RIGHT : SECONDARY_PADDING_RIGHT) +
      (showClearButton ? CLEAR_BUTTON_PADDING : 0)
    );
  }
}
