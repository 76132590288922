export class HeaderParams {
  network_id?: string;
  geo_location?: string;
  locale?: string;

  constructor(obj?: HeaderParams) {
    this.network_id = obj.network_id || '';
    this.geo_location = obj.geo_location || '';
    this.locale = obj.locale || 'en';
  }
}
