export class BvaProviderCostData {
  employerCost?: any;
  incentiveAmount?: any;
  memberCost?: any;
  procedureCost?: any;
  referenceBasePricing?: any;

  constructor(obj?) {
    this.formatNumbersNoDecimal(obj);
    this.employerCost = obj.employer_cost || {};
    this.incentiveAmount = obj.incentive_amount || {};
    this.memberCost = obj.member_cost || {};
    this.procedureCost = obj.procedure_cost || {};
    this.referenceBasePricing = obj.reference_base_pricing || {};
  }

  private formatNumbersNoDecimal(obj) {
    Object.keys(obj).forEach((key) => {
      switch (typeof obj[key]) {
        case 'number':
        case 'string':
          obj[key] = Number.parseInt(obj[key], 10);
          break;
        case 'object':
          if (obj[key] !== null) {
            this.formatNumbersNoDecimal(obj[key]);
          }
          break;
      }
    });
  }
}
