import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { svgGroupings } from './svgs';

@Injectable()
export class IconRegistryService {
  static readonly svgGroupings = svgGroupings;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  loadSvgIcons() {
    for (const grouping of IconRegistryService.svgGroupings) {
      for (const icon of grouping.icons) {
        this.matIconRegistry.addSvgIcon(
          icon.name,
          this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
        );
      }
    }
  }
}
