import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'zelis-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @HostBinding() class = 'zelis-dls';
}
