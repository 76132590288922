export class HttpMockIndexItem {
  public file: string;
  public index: number;

  private localVcrPath?: string = './mocks/';
  private builtVcrPath?: string = './assets/e2e/mocks/';

  constructor(item: HttpMockIndexItem) {
    if (item) {
      this.file = (item.file || '').replace(
        this.localVcrPath,
        this.builtVcrPath
      );
      this.index = item.index || 0;
    }
  }
}
