import { GuidedSearchType } from '@interfaces/guided-search-type.interface';
import { Specialty } from '@interfaces/specialty.model';
import { Procedure } from '@interfaces/procedure.model';
import { IncentivizedProcedure } from '@interfaces/incentivized-procedure.model';
import { noop } from 'rxjs';

export class GuidedSearchSetting {
  name: string;
  description?: string;
  type?: GuidedSearchType;
  id?: string;
  filters?: any;
  suppress?: boolean;
  options?: GuidedSearchSetting[];
  specialties?: Specialty[];
  procedures?: Procedure[];
  incentivizedProcedures?: IncentivizedProcedure[];
  incentivizedSpecialties?: Specialty[];
  providerTypes?: GuidedSearchSetting[];
  episode_procedure?: boolean;
  icon?: string;
  useAlphaList?: boolean;
  nestedSetting?: boolean;
  unauthenticatedProcedures?: boolean;
  onClick?: Function;
  primary_specialties_tile?: boolean;
  high_incentive_amount?: any;

  constructor(
    obj: GuidedSearchSetting,
    type?: GuidedSearchType,
    nestedSetting?: boolean
  ) {
    if (obj) {
      this.name = obj.name || '';
      this.description = obj.description || '';
      this.type = obj.type || type || null;
      this.id = obj.id || '';
      this.filters = obj.filters || null;
      this.options = this.objectToArray(obj.options, true);
      this.specialties = obj.specialties || null;
      this.procedures = obj.procedures || null;
      this.incentivizedProcedures = obj.incentivizedProcedures || null;
      this.incentivizedSpecialties = obj.incentivizedSpecialties || null;
      this.providerTypes = obj.providerTypes || null;
      this.suppress = obj.suppress || null;
      this.episode_procedure = !!obj.episode_procedure;
      this.icon = obj.icon || null;
      this.useAlphaList = !!obj.useAlphaList;
      this.nestedSetting = nestedSetting;
      this.unauthenticatedProcedures = obj.unauthenticatedProcedures || null;
      this.onClick = typeof obj.onClick === 'function' ? obj.onClick : noop;
      this.primary_specialties_tile = obj.primary_specialties_tile || null;
      this.high_incentive_amount = obj.high_incentive_amount || null;
    }
  }

  private objectToArray?(
    options: any,
    nestedSetting: boolean
  ): GuidedSearchSetting[] {
    if (options) {
      return Object.values(options).map(
        (option: any) =>
          new GuidedSearchSetting(option, this.type, nestedSetting)
      );
    }
    return null;
  }
}
