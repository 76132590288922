import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipTriggerDirective } from './tooltip-trigger.directive';

@NgModule({
  exports: [TooltipComponent, TooltipTriggerDirective],
  imports: [CommonModule, FlexLayoutModule, OverlayModule],
  declarations: [TooltipComponent, TooltipTriggerDirective],
})
export class TooltipModule {}
