<mat-dialog-content>
  <div class="mat-dialog-content" id="dialog-content">
    <iframe
      fxHide.lt-md
      class="popup-video"
      [attr.src]="url | sanitize"
    ></iframe>
    <iframe
      fxHide.gt-sm
      class="mobile-popup-video"
      [attr.src]="url | sanitize"
    ></iframe>
  </div>
</mat-dialog-content>
