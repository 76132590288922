import { Component, Input } from '@angular/core';

@Component({
  selector: 'platform-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
})
export class CompanyLogoComponent {
  @Input() public logoAltText: string;
}
