import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  exports: [TimelineComponent],
  imports: [CommonModule, FlexLayoutModule],
  declarations: [TimelineComponent],
})
export class TimelineModule {}
