import {
  Directive,
  Input,
  ElementRef,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import {
  Overlay,
  OverlayRef,
  OverlayConfig,
  PositionStrategy,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { first } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { SubscriptionManager } from '../utility/subscription-manager.utility';
import { MediaObserver } from '@ngbracket/ngx-layout';

@Directive({
  selector: '[platformTooltip]',
  exportAs: 'platformTooltipTrigger',
})
export class TooltipTriggerDirective implements OnDestroy {
  @Input()
  public set tooltipOpen(open: boolean) {
    this._tooltipOpen = !!open;
    if (this._tooltipOpen) {
      this.openOverlay();
    } else {
      this.closeOverlay();
    }
  }
  public get tooltipOpen(): boolean {
    return this._tooltipOpen;
  }
  @Input('platformTooltip') public tooltip: TooltipComponent;
  @Input() public mobileFullscreen = true;

  private _tooltipOpen = false;
  private portal: TemplatePortal;
  private overlayRef: OverlayRef;
  private subs: SubscriptionManager = new SubscriptionManager();

  constructor(
    private element: ElementRef,
    private container: ViewContainerRef,
    private overlay: Overlay,
    private mediaQuery: MediaObserver
  ) {}

  ngOnDestroy() {
    this.destroyOverlay();
    this.subs.destroy();
  }

  private openOverlay(): void {
    if (!this.overlayRef || !this.overlayRef.hasAttached()) {
      this.attachOverlay();
    }

    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.tooltip.open = true;
    }
  }

  private closeOverlay(): Observable<boolean> {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      const observable: Subject<boolean> = new Subject();
      this.tooltip.open = false;
      this.tooltip.closed.pipe(first()).subscribe(() => {
        this.overlayRef.detach();
        observable.next(true);
        this.destroyOverlay();
      });
      return observable.pipe(first());
    } else {
      of(true);
    }
  }

  private attachOverlay(): void {
    if (!this.tooltip) {
      throw Error(
        'Attempting to open an undefined instance of `platform-tooltip`. ' +
          'Make sure that the id passed to the `platformTooltip` is correct and that ' +
          "you're attempting to open it after the ngAfterContentInit hook."
      );
    }

    if (!this.overlayRef) {
      this.portal = new TemplatePortal(this.tooltip.template, this.container);
      this.overlayRef = this.overlay.create(this.getOverlayConfig());
    }

    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      this.overlayRef.attach(this.portal);
      this.subscribeToOverlayClosingEvents();
    }
  }

  private getOverlayConfig(): OverlayConfig {
    if (this.mobileFullscreen && this.mediaQuery.isActive('lt-md')) {
      return new OverlayConfig({
        positionStrategy: this.getMobileOverlayPosition(),
        scrollStrategy: this.overlay.scrollStrategies.block(),
        hasBackdrop: false,
        width: '100%',
        height: '100%',
      });
    }

    return new OverlayConfig({
      positionStrategy: this.getDesktopOverlayPosition(),
    });
  }

  private getDesktopOverlayPosition(): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetX: -82,
        },
      ]);
  }

  private getMobileOverlayPosition(): PositionStrategy {
    return this.overlay.position().global();
  }

  private subscribeToOverlayClosingEvents(): void {
    this.subs.add(
      this.overlayRef.keydownEvents().subscribe((event) => {
        if (event.key === 'Escape') {
          this.closeOverlay();
        }
      })
    );
  }

  private destroyOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
