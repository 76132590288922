export class MemberPreferredContactMethods {
  receive_email?: boolean;
  receive_mail?: boolean;
  receive_phone_calls?: boolean;
  receive_text_messages?: boolean;

  constructor(methods: MemberPreferredContactMethods = {}) {
    this.receive_email = !!(methods.receive_email || false);
    this.receive_mail = !!(methods.receive_mail || false);
    this.receive_phone_calls = !!(methods.receive_phone_calls || false);
    this.receive_text_messages = !!(methods.receive_text_messages || false);
  }
}
