import { isPlatformServer } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IconRegistryService } from './icon-registry.service';

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
  providers: [IconRegistryService],
})
export class DlsIconModule {
  constructor(
    iconRegistry: IconRegistryService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    if (isPlatformServer(platformId)) {
      return;
    }
    iconRegistry.loadSvgIcons();
  }
}
