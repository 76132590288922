import { Network } from '@interfaces/network.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NetworkState } from './network.state';

const getNetworkState = createFeatureSelector<Network>('network');

export const getResolvedNetwork = createSelector(
  getNetworkState,
  (state: NetworkState) => state
);
