export class Balances {
  public tier_number?: number;
  public preferred?: boolean;
  public individual_deductible_limit: number;
  public individual_deductible_accum: number;
  public individual_deductible_remaining?: number;
  public individual_deductible_met?: boolean;
  public family_deductible_limit: number;
  public family_deductible_accum: number;
  public family_deductible_remaining?: number;
  public family_deductible_met?: boolean;
  public individual_opx_limit: number;
  public individual_opx_accum: number;
  public individual_opx_remaining?: number;
  public individual_opx_met?: boolean;
  public family_opx_limit: number;
  public family_opx_accum: number;
  public family_opx_remaining?: number;
  public family_opx_met?: boolean;

  constructor(balances: any = {}) {
    this.tier_number = this.toNumber(balances.tier_number) || 1; // Tier 0 is a Tier 1
    this.preferred = this.tier_number === 1;
    this.individual_deductible_limit = this.toNumber(
      balances.individual_deductible_limit
    );
    this.individual_deductible_accum = this.toNumber(
      balances.individual_deductible_accum
    );
    this.individual_deductible_remaining = this.getRemaining(
      balances.individual_deductible_accum,
      this.individual_deductible_limit
    );
    this.family_deductible_limit = this.toNumber(
      balances.family_deductible_limit
    );
    this.family_deductible_accum = this.toNumber(
      balances.family_deductible_accum
    );
    this.family_deductible_remaining = this.getRemaining(
      balances.family_deductible_accum,
      this.family_deductible_limit
    );
    this.individual_opx_limit = this.toNumber(balances.individual_opx_limit);
    this.individual_opx_accum = this.toNumber(balances.individual_opx_accum);
    this.individual_opx_remaining = this.getRemaining(
      balances.individual_opx_accum,
      this.individual_opx_limit
    );
    this.family_opx_limit = this.toNumber(balances.family_opx_limit);
    this.family_opx_accum = this.toNumber(balances.family_opx_accum);
    this.family_opx_remaining = this.getRemaining(
      balances.family_opx_accum,
      this.family_opx_limit
    );
    this.family_deductible_met = this.getMet(
      this.family_deductible_accum,
      this.family_deductible_limit
    );
    this.family_opx_met = this.getMet(
      this.family_opx_accum,
      this.family_opx_limit
    );
    this.individual_deductible_met =
      this.getMet(
        this.individual_deductible_accum,
        this.individual_deductible_limit
      ) ||
      this.family_deductible_met ||
      this.family_opx_met;
    this.individual_opx_met =
      this.getMet(this.individual_opx_accum, this.individual_opx_limit) ||
      this.family_opx_met;
  }

  protected toNumber?(val: number | string): number {
    return parseInt(String(val || 0), 10);
  }

  protected getRemaining?(accum: number, limit: number): number {
    return limit - accum;
  }

  protected getMet?(accum: number, limit: number): boolean {
    return !!(accum && limit && accum >= limit);
  }
}
