/**
 * Subscription Manager
 * Keep track of observable subscriptions for garbage cleanup.
 * Usage:
 * let sm = new SubscriptionManager(); // Instantiate new instance
 * sm.add(mySubscription); // Add a subscription
 * sm.destroy(); // Unsubscribe from all
 */
export class SubscriptionManager {
  private subscriptions: any[] = [];

  public add(sub: any) {
    this.subscriptions.push(sub);
  }

  public destroy(): void {
    this.subscriptions.forEach((sub: any) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
    this.subscriptions = [];
  }
}
