<zelis-button
  *ngIf="isEnabled | async"
  data-cy="guided-search-trigger.button"
  class="guided-search-button button-mobile-text"
  [ngClass]="ccssService.getClass('guided_search')"
  size="large"
  color="primary"
  [variant]="breakpoints.isMobile ? 'flat' : 'stroked'"
  forwardedClass="guided-search-button"
  [fullWidth]="false"
  [disabled]="(isReady | async) !== true"
  (clickFn)="onTriggerClick()"
  >
  <span *ngIf="breakpoints.isMobile">{{
    'guided_search_browse' | translate
  }}</span>
  <span *ngIf="breakpoints.isDesktop">{{
    'home_browse_by_category' | translate
  }}</span>
</zelis-button>
