import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CouponsService } from '@services/coupons.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RxCouponsGuard implements CanActivate {
  constructor(private couponsService: CouponsService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.couponsService.isRxCouponsEligible
      .pipe(
        map(
          (couponsEligible: boolean) => 
            couponsEligible ||
            this.router.createUrlTree(['/'], { queryParamsHandling: 'preserve' })
        )
      );
  }
}
