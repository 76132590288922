import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[zelisButtonIcon]',
})
export class ButtonIconDirective {
  @HostBinding('class.lead')
  @HostBinding('class.mr-2')
  get mr2(): boolean {
    return this.isLeftPosition;
  }

  @HostBinding('class.trail')
  @HostBinding('class.ml-2')
  get ml2(): boolean {
    return this.isRightPosition;
  }

  get isLeftPosition(): boolean {
    return !!this.element.nativeElement.nextSibling;
  }
  get isRightPosition(): boolean {
    return !!this.element.nativeElement.previousSibling;
  }

  constructor(private element: ElementRef) {}
}
