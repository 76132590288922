import {
  Component,
  Input,
  QueryList,
  ContentChildren,
  AfterContentInit,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Inject,
  forwardRef,
  Output,
  EventEmitter,
  AfterContentChecked,
} from '@angular/core';
import { SubscriptionManager } from '../utility/subscription-manager.utility';
import { HorizontalScrollItemDirective } from './horizontal-scroll-item.directive';
import { EasingLogic, PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { HorizontalScrollTranslations } from './horizontal-scroll-translations.model';

@Component({
  selector: 'platform-horizontal-scroll',
  templateUrl: './horizontal-scroll.component.html',
  styleUrls: ['./horizontal-scroll.component.scss'],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
})
export class HorizontalScrollComponent
  implements AfterContentInit, AfterContentChecked, OnDestroy
{
  @Input() public showControls = true;
  @Input() public hideMobileControls = true;
  @Input() public scrollDuration = 800;
  @Input() public scrollOffset = 8;
  @Input() public set translations(value: HorizontalScrollTranslations) {
    this._translations = new HorizontalScrollTranslations(value);
  }
  public get translations(): HorizontalScrollTranslations {
    return this._translations;
  }

  @Output() public activeIndexChange: EventEmitter<number> = new EventEmitter();

  @ContentChildren(forwardRef(() => HorizontalScrollItemDirective), {
    read: ElementRef,
    descendants: true,
  })
  public items: QueryList<ElementRef>;

  @ViewChild('ScrollStage') private scrollStage: ElementRef;
  @ViewChild('ScrollItemContainer') private scrollItemContainer: ElementRef;

  public activeIndex = 0;
  public previousIndex: number = null;
  public isStageScrollable: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private subscriptions: SubscriptionManager = new SubscriptionManager();
  private _translations: HorizontalScrollTranslations =
    new HorizontalScrollTranslations();

  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngAfterContentInit() {
    this.subscribeToItemChanges();
    this.resetActiveIndex();
  }

  ngAfterContentChecked() {
    this.setStageIsScrollable();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }

  public nextItem(): void {
    const nextIndex = this.activeIndex + 1;
    if (this.indexIsValid(nextIndex)) {
      this.goToIndex(nextIndex);
    } else {
      this.goToIndex(0);
    }
  }

  public previousItem(): void {
    const prevIndex = this.activeIndex - 1;
    if (this.indexIsValid(prevIndex)) {
      this.goToIndex(prevIndex);
    } else {
      this.goToIndex(this.items.length - 1);
    }
  }

  public goToIndex(index: number): void {
    this.previousIndex = this.activeIndex;
    this.activeIndex = index;
    this.scrollTo(index);
    this.activeIndexChange.emit(index);
  }

  public hasItems(): boolean {
    return this.items && this.items.length > 0;
  }

  private setStageIsScrollable(): void {
    this.isStageScrollable.next(this.stageIsScrollable());
  }

  private stageIsScrollable(): boolean {
    const stageElement = this.scrollStage?.nativeElement;
    // First child node is the scrollable content.
    const itemContainerElement =
      this.scrollItemContainer?.nativeElement.childNodes[0];
    if (!stageElement || !itemContainerElement) {
      return false;
    }
    return itemContainerElement.offsetWidth > stageElement.offsetWidth;
  }

  private scrollTo(index: number): void {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: this.items.get(index).nativeElement,
      scrollViews: [this.scrollStage?.nativeElement],
      verticalScrolling: false,
      duration: this.scrollDuration,
      scrollOffset: this.scrollOffset,
      easingLogic: this.easeInOutExpo,
    });
  }

  private resetActiveIndex(): void {
    this.activeIndex = 0;
    this.previousIndex = null;
    this.activeIndexChange.emit(null);
  }

  private subscribeToItemChanges(): void {
    this.subscriptions.add(
      this.items.changes.subscribe(() => this.resetActiveIndex())
    );
  }

  private indexIsValid(index: number): boolean {
    return index <= this.items.length - 1 && index >= 0;
  }

  private easeInOutExpo: EasingLogic = (
    t: number,
    b: number,
    c: number,
    d: number
  ): number => {
    if (t === 0) {
      return b;
    }
    if (t === d) {
      return b + c;
    }
    if ((t /= d / 2) < 1) {
      return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    }
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  };
}
