import { trigger, transition, style, animate } from '@angular/animations';
import { EaseInOutCubic } from '@zelis/platform-ui-components';

export const PageAnimations = [
  trigger('slideInOutAnimation', [
    transition(
      'void => forward', // <--- Entering <---
      [
        style({
          transform: 'translateX(100%)',
          opacity: 0,
        }),
        animate(
          `500ms ${EaseInOutCubic}`,
          style({
            transform: 'translateX(0%)',
            opacity: 1,
          })
        ),
      ]
    ),
    transition(
      'forward => void', // <--- Leaving <---
      [
        style({
          transform: 'translateX(0%)',
          opacity: 1,
        }),
        animate(
          `500ms ${EaseInOutCubic}`,
          style({
            transform: 'translateX(-100%)',
            opacity: 0,
          })
        ),
      ]
    ),
    transition(
      'void => back', // ---> Entering --->
      [
        style({
          transform: 'translateX(-100%)',
          opacity: 0,
        }),
        animate(
          `500ms ${EaseInOutCubic}`,
          style({
            transform: 'translateX(0%)',
            opacity: 1,
          })
        ),
      ]
    ),
    transition(
      'back => void', // ---> Leaving --->
      [
        style({
          transform: 'translateX(0%)',
          opacity: 1,
        }),
        animate(
          `500ms ${EaseInOutCubic}`,
          style({
            transform: 'translateX(100%)',
            opacity: 0,
          })
        ),
      ]
    ),
  ]),
];
