import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EpisodesOfCareConfigService } from '../services/eoc/eoc.config.service';

@Injectable()
export class EpisodesGuard implements CanActivate {
  constructor(
    private eocConfigService: EpisodesOfCareConfigService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.eocConfigService.isEnabled().pipe(
      tap((enabled: boolean) => {
        if (enabled === false) {
          this.router.navigate([], { queryParamsHandling: 'preserve' });
        }
      })
    );
  }
}
