import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthStatus } from '@interfaces/auth-status.model';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { select, Store } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import { NetworkSelectionWizardService } from '@services/network-selection-wizard/network-selection-wizard.service';
import { UuxNetwork } from '@interfaces/uux-network.model';
import { StorageUtilities } from '@utilities/storage.utilities';

@Component({
  selector: 'app-wizard-login-link',
  templateUrl: './wizard-login-link.component.html',
})

export class WizardLoginLinkComponent implements OnInit, OnDestroy {
  @Input() hideLinks?: boolean;
  public auth: AuthStatus;
  public suppressLoginFromConfig: boolean;
  public isBroker: boolean;
  private subscriptions = new SubscriptionManager();
  
  constructor(
    private store: Store<any>,
    private networkSelectWizardService: NetworkSelectionWizardService,
    private storage: StorageUtilities,
  ) {}

  ngOnInit(): void {
    this.isBroker = this.networkSelectWizardService.isBroker;
    this.setAuth();
    this.setSuppressLoginLinks();
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
  }
  
  public handleSeeAllNetworks() {
    const networks: UuxNetwork[] = this.storage.localStorageGet('allPlans');
    const defaultPlan = networks.find(network => network.defaultPlan);
    this.networkSelectWizardService.saveSelectedPlan(defaultPlan);
    this.networkSelectWizardService.setSelections();
    this.storage.sessionStorageSet('hasSetWizardSelections', true);
    this.networkSelectWizardService.closeOverlay();
  } 
  
  private setSuppressLoginLinks(): void {
    this.suppressLoginFromConfig = this.networkSelectWizardService.suppressLogin;
  }

  private setAuth(): void {
    this.subscriptions.add(
      this.store
        .pipe(select(AuthStoreSelectors.getAuthStatus))
        .subscribe((auth: AuthStatus) => (this.auth = auth)),
    );
  }
}
