export class MemberCardTranslations {
  public planName?: string;
  public employerGroup?: string;
  public memberName?: string;
  public memberId?: string;
  public dependents?: string;
  public groupNumber?: string;
  public bin?: string;
  public benefitPlan?: string;
  public effectiveDate?: string;
  public plan?: string;
  public officeVisit?: string;
  public specialistCopay?: string;
  public emergency?: string;
  public deductible?: string;

  constructor(translations?: MemberCardTranslations) {
    translations = translations || {};
    this.planName = translations.planName || 'Plan Name';
    this.employerGroup = translations.employerGroup || 'Employer Group';
    this.memberName = translations.memberName || 'Member Name';
    this.memberId = translations.memberId || 'Member ID';
    this.dependents = translations.dependents || 'Dependents';
    this.groupNumber = translations.groupNumber || 'Group No.';
    this.bin = translations.bin || 'BIN';
    this.benefitPlan = translations.benefitPlan || 'Benefit Plan';
    this.effectiveDate = translations.effectiveDate || 'Effective Date';
    this.plan = translations.plan || 'Plan';
    this.officeVisit = translations.officeVisit || 'Office Visit';
    this.specialistCopay = translations.specialistCopay || 'Specialist Copay';
    this.emergency = translations.emergency || 'Emergency';
    this.deductible = translations.deductible || 'Deductible';
  }
}
