import { Injectable } from '@angular/core';
import { HttpSpecMockConfig } from '@interfaces/http-spec-mock-config.model';
import { RouteUtilities } from '@utilities/route.utilities';
import { HttpMockConfig } from '@interceptors/http.mock.config';
import { StringParseUtilities } from '@utilities/string-parse.utilities';
import { StorageUtilities } from '@utilities/storage.utilities';

@Injectable({
  providedIn: 'root',
})
export class HttpSpecMockConfigService {
  public config: HttpSpecMockConfig;

  private httpMockConfig: HttpMockConfig = new HttpMockConfig();
  private routeUtilities: RouteUtilities = new RouteUtilities();
  private stringParseUtilities: StringParseUtilities =
    new StringParseUtilities();
  private storage: StorageUtilities = new StorageUtilities();

  constructor() {
    this.config = this.parseConfig();
  }

  private parseConfig(): HttpSpecMockConfig {
    const config: HttpSpecMockConfig =
      this.parseConfigFromUrl() ||
      this.parseConfigFromStorage() ||
      new HttpSpecMockConfig();
    this.saveConfigToStorage(config);
    return config;
  }

  private parseConfigFromUrl(): HttpSpecMockConfig {
    const configJson = this.routeUtilities.getParamFromUrl(
      this.httpMockConfig.keyName
    );
    if (configJson) {
      const parsedConfig = this.stringParseUtilities.jsonParse(
        decodeURIComponent(configJson)
      );
      return new HttpSpecMockConfig(parsedConfig);
    }
    return null;
  }

  private parseConfigFromStorage(): HttpSpecMockConfig {
    const config = this.storage.localStorageGet(this.httpMockConfig.keyName);
    if (config) {
      return new HttpSpecMockConfig(config);
    }
    return null;
  }

  private saveConfigToStorage(config: HttpSpecMockConfig): void {
    this.storage.localStorageSet(this.httpMockConfig.keyName, config);
  }
}
