import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ChatService } from '../../../services/pat-chat/chat.service';
import { BehaviorSubject } from 'rxjs';
import { MembersService } from '../../../services/members.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SubscriptionManager } from '@zelis/platform-ui-components';

@Component({
  selector: 'app-proactive-chat',
  templateUrl: 'proactive-chat.component.html',
  styleUrls: ['./proactive-chat.component.scss'],
})
export class ProactiveChatComponent implements OnInit, OnDestroy {
  @Output() closeBubble = new EventEmitter();

  public isAvailable: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public memberName: string;
  private subscriptions = new SubscriptionManager();

  constructor(
    private chatService: ChatService,
    private membersService: MembersService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.subscribeToMember());
    this.isAvailable = this.chatService.isAvailable;
  }

  public subscribeToMember(): Subscription {
    return this.membersService.member
      .pipe(first((member) => member !== undefined))
      .subscribe((member) => {
        this.memberName = member && member.first_name;
      });
  }

  public proactiveNoClick(): void {
    this.closeBubble.emit();
    this.chatService.proactiveChat.next(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.destroy();
  }
}
