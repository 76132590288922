import { BvaProviderCostData } from '@interfaces/bva-provider-cost-data.model';
import { SearchTrigger } from '@classes/search-trigger.class';
import { SearchParamsService } from '../../+search/search-params.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { BvaProviders } from './bva.providers';
import { Provider } from '@interfaces/provider.model';
import { BvaData } from '@interfaces/bva-data.model';
import { BvaProviderData } from '@interfaces/bva-provider-data.model';
import { reject } from 'lodash';
import { switchMap } from 'rxjs/operators';

const FLASH_MESSAGE_TIMEOUT = 4000;

@Injectable({
  providedIn: 'root',
})
export class BvaService {
  public providers = new BvaProviders();
  public results = { providers: null, _meta: null };
  public syncStatus: string = '';
  public notes: string = '';
  public lastSearchResults = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private searchParamsService: SearchParamsService,
    private searchTrigger: SearchTrigger
  ) {}

  public addIntendedProvider(provider: Provider): void {
    this.providers.intended = new BvaProviderData(provider);
  }

  public addRecommendedProvider(provider: Provider): void {
    if (!this.providers.recommended.find((prov) => prov.id === provider.id)) {
      this.providers.recommended.push(provider);
    }
  }

  public clearProviders(): void {
    this.clearResults();
    this.providers = new BvaProviders();
  }

  public clearResults(): void {
    this.results = { providers: null, _meta: null };
  }

  public clearIntendedProvider(): void {
    this.providers.intended = {};
  }

  public clearRecommendedProvider(provider: any): void {
    this.providers.recommended = reject(
      this.providers.recommended,
      (prov) => prov.id === provider.id
    );
  }

  public requestProviders(): Observable<any> {
    const endpoint = `/api/search/referred_provider_autocomplete.json`;

    return this.searchParamsService.lastSearchParams.pipe(
      switchMap((params: HttpParams) => {
        params = params.set('limit', '-1');
        params = params.delete('page');
        return this.http.get(endpoint, {
          params: params,
          withCredentials: true,
        });
      })
    );
  }

  public sync(): void {
    this.postBvaData().subscribe(
      () => {
        this.onSyncSuccess();
      },
      () => {
        this.onSyncError();
      }
    );
  }

  public onSyncSuccess(): void {
    this.syncStatus = 'success';
    setTimeout(() => {
      this.syncStatus = '';
    }, FLASH_MESSAGE_TIMEOUT);
  }

  public onSyncError(): void {
    this.syncStatus = 'error';
  }

  public providersAreClean(): boolean {
    return (
      Object.keys(this.providers.intended).length === 0 &&
      !this.providers.recommended.length
    );
  }

  private mapBvaData(): BvaData {
    const hasMeta =
      this.lastSearchResults.value && this.lastSearchResults.value._meta;
    const meta = hasMeta ? this.lastSearchResults.value._meta : {};
    return new BvaData({
      notes: this.notes,
      providers: {
        procedure: this.searchTrigger.selectedTerm.getValue(),
        radius: String(meta.radius_used || meta.radius),
        zip: meta.zip,
        treatmentCategoryCode: this.searchTrigger.selectedTcc.getValue(),
        rewardsConfirmation: this.rewardConfirmationNumber(),
        cost: new BvaProviderCostData(meta.cost.average),
        intended: this.providers.intended,
        recommended: this.providers.recommended.map(
          (prov) =>
            new BvaProviderData(
              this.results.providers.find(
                (res) => res.provider_id === prov.provider_id
              )
            )
        ),
      },
    });
  }

  private rewardConfirmationNumber(): string {
    let reward = null;
    if (this.results.providers && this.results.providers.length > 0) {
      reward = this.results.providers[0]['cash_reward_reference_number'];
    }
    return reward;
  }

  private postBvaData(): Observable<any> {
    const postEndpoint = `/api/bva`;
    const bvaData = this.mapBvaData();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(postEndpoint, bvaData, {
      headers: headers,
      withCredentials: true,
    });
  }
}
