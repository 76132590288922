// Utility Methods

/**
 * Supplant
 * Replace placeholders in a string with values.
 * Example: template = "The {size} sky is {color}."
 * Usage: supplant(template, {size: 'big', color: 'blue'})
 * Output: "The big sky is blue."
 * @param str (string)
 * @param values (object)
 * @return (string)
 */
export const supplant = (str: string, values: any): string => {
  if (str && values) {
    return str.replace(/{ ?([^{}]*) ?}/g, (sub: any, match: string) => {
      let newVal = values[match];
      if (typeof newVal !== 'string' && typeof newVal !== 'number') {
        newVal = '';
      }
      return newVal;
    });
  }
  return str;
};

/**
 * Limit
 * Limit an array to a given length.
 * Usage: limit([1, 4], 1)
 * Output: [1]
 * @param arr (Array<any>)
 * @param int (number)
 * @return (array)
 */
export const limit = (arr: Array<any>, int: number): Array<any> => {
  if (arr && arr.length && int && int > 0) {
    return arr.slice(0, int);
  } else {
    return arr;
  }
};

/**
 * @param rawPhone (string)
 * @return (string)
 */
export const formatPhone = (rawPhone: string, parenthesis = false): string => {
  if (rawPhone.length === 10) {
    const area = rawPhone.slice(0, 3) + '';
    const local = '' + rawPhone.slice(3, 6) + '-' + rawPhone.slice(6);
    const areaFormat = parenthesis ? '(' + area + ') ' : '' + area + '-';
    return areaFormat + local;
  } else {
    return rawPhone;
  }
};

/**
 * From JSON
 * Parse a JSON string into an object/array.
 * @param json (string)
 * @return (any)
 */
export const fromJson = (json: string): any => {
  let obj = null;
  if (json) {
    try {
      obj = JSON.parse(json);
    } catch (e) {
      obj = null;
    }
  }
  return obj;
};

/**
 * To JSON
 * Convert an object or array into a JSON string.
 * @param value (any)
 * @param spaces (any)
 * @return (string)
 */
export const toJson = (value: any, spaces?: any): string => {
  if (value) {
    return JSON.stringify(value, null, spaces || null);
  }
  return '';
};

/**
 *
 * Builds a profile link from providerId, locationId, contractId
 * @param providerId (string)
 * @param locationId (string)
 * @param contractId (string)
 * @return (string)
 */
export const buildProfileLink = (
  providerId: string,
  locationId: string,
  contractId: string
): string => {
  return 'profile/' + providerId + '/' + locationId + '/' + contractId;
};

/**
 *
 * Returns provider_type for SERP results and profile routes
 * @param prefix (string)
 * @return (string)
 */
export const getProviderType = (prefix: string): string => {
  if (prefix[0].toLowerCase() === 'f') {
    return 'facility';
  } else if (prefix[0].toLowerCase() === 'p') {
    return 'professional';
  }
};

/**
 *
 * Returns a string with the first letter of each word capitalized
 * @param line (string)
 * @return (string)
 */
export const capitalizeFirstLetter = (line: string): string => {
  if (!line) {
    return '';
  }
  const words = line.split(' ');
  return words
    .map((word) => {
      word = word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

/**
 *
 * Strips dashes form string (phone number, dob)
 * @param dateString (string)
 * @return (string)
 */
export const stripDashes = (dateString: string): string => {
  return dateString.replace(/[-/[./[))(]/g, '');
};

/**
 *
 * Returns formatted one-line provider addres
 * @param location (any)
 * @return (string)
 */
export const oneLineAddress = (location: any): string => {
  let out = '';
  if (location && location.addr_line1) {
    out += location.addr_line1 + ', ';

    if (location.addr_line2) {
      out += location.addr_line2 + ', ';
    }
    if (location.city && location.state && location.postal_code) {
      out += location.city + ', ' + location.state + ' ' + location.postal_code;
    }
  }

  return out;
};
/**
 * Takes any number of input arrays and returns a single array containing balanced elements from each up to max.
 *  @param arr1 (any[])
 *  @param arr2 (any[])
 *  @param max (number)
 *  @return (any[])
 * Usage Example:
 * const arr1 = ['foo', 'bar', 'baz'];
 * const arr2 = ['bing', 'bang', 'boom'];
 * balancedChunks([arr1, arr2], 4);
 * Output: ['foo', 'bing', 'bar', 'bang']
 */
export const balancedChunks = (input: any[][], max: number): any[] => {
  const out = [];
  let index = 0;
  let done = false;
  while (!done) {
    let anyDefined = false;
    // TODO: Fix tslint
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < input.length; i++) {
      const value = input[i][index];
      if (typeof value !== 'undefined') {
        anyDefined = true;
        if (out.length < max) {
          out.push(value);
        } else {
          done = true;
          break;
        }
      }
    }
    index++;
    if (!anyDefined) {
      done = true;
    }
  }
  return out;
};
