import { createAction, props } from '@ngrx/store';
import { SearchFilter } from '@interfaces/search-filter.model';
import { SearchParams } from '@interfaces/search-params.model';

export enum SettingsFiltersSearchActionsTypes {
  requestSettingsSearchFilters = '[GET] Get all settings search filters from config',
  requestSettingsSearchFiltersSuccess = '[GET] Get all settings search filters from config success',
  requestSettingsSearchFiltersFailure = '[GET] Get all settings search filters from config failed',
  requestFacets = '[GET] request facets',
  requestFacetsFailure = '[GET] request facets failed',
  setSearchFiltersWithFacetData = '[SET] Set filters with facet data',
  setSearchFiltersWithLocationGeoFacet = '[Set] Set filters with location_geo facet',
}

export const requestSettingsSearchFiltersFromConfig = createAction(
  SettingsFiltersSearchActionsTypes.requestSettingsSearchFilters
);

export const requestSettingsSearchFiltersFromConfigSuccess = createAction(
  SettingsFiltersSearchActionsTypes.requestSettingsSearchFiltersSuccess,
  props<{ settings_filters: { filters: SearchFilter[] } }>()
);

export const requestSettingsSearchFiltersFromConfigFailure = createAction(
  SettingsFiltersSearchActionsTypes.requestSettingsSearchFiltersFailure,
  props<{ error: any }>()
);

export const requestFacets = createAction(
  SettingsFiltersSearchActionsTypes.requestFacets,
  props<{ search_params: SearchParams }>()
);

export const requestFacetsFailure = createAction(
  SettingsFiltersSearchActionsTypes.requestFacetsFailure,
  props<{ error: TypeError }>()
);

export const setSearchFiltersWithFacetData = createAction(
  SettingsFiltersSearchActionsTypes.setSearchFiltersWithFacetData,
  props<{ appliedFilters: SearchFilter[] }>()
);

export const setSearchFiltersWithLocationGeoFacet = createAction(
  SettingsFiltersSearchActionsTypes.setSearchFiltersWithLocationGeoFacet,
  props<{ location_geo_filter: SearchFilter }>()
);
