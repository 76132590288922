import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './settings-filters.reducer';
import { SettingsFiltersEffects } from '../search-filters.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('settings_filters', reducer),
    EffectsModule.forFeature([SettingsFiltersEffects]),
  ],
})
export class SettingsFiltersModule {}
