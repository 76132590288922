import { Injectable } from '@angular/core';
import { StringParseUtilities } from './string-parse.utilities';

@Injectable({
  providedIn: 'root',
})
export class StorageUtilities {
  private stringParse: StringParseUtilities = new StringParseUtilities();

  /**
   * Local Storage
   */

  public localStorageSet(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public localStorageGet(key: string, onErrorValue: any = null): any {
    return this.stringParse.jsonParse(
      window.localStorage.getItem(key),
      onErrorValue
    );
  }

  public localStorageRemove(key: string): void {
    window.localStorage.removeItem(key);
  }

  public localStorageClear(): void {
    window.localStorage.clear();
  }

  /**
   * Session Storage
   */

  public sessionStorageSet(key: string, value: any): void {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  public sessionStorageGet(key: string, onErrorValue: any = null): any {
    return this.stringParse.jsonParse(
      window.sessionStorage.getItem(key),
      onErrorValue
    );
  }

  public sessionStorageRemove(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  public sessionStorageClear(): void {
    window.sessionStorage.clear();
  }
}
