import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as SearchActions from './serp-summary.actions';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SerpService } from '@services/serp/serp.service';
import { of } from 'rxjs';
import { SearchParams } from '@interfaces/search-params.model';
import { Results } from '@interfaces/results.interface';
import { resetRatesResults } from '@store/rates-serp/serp-summary/serp-summary.actions'
import { UpdateSelectedBillingCodeDependents } from '@store/billing-code-search/billing-code.actions'

@Injectable()
export class SerpResultsSummaryEffects {
  getSerpResultsSummary = createEffect(() =>
    this.actions.pipe(
      ofType(SearchActions.requestRatesResults),
      map((action) => action.search_params),
      switchMap((search_params: SearchParams) =>
        this.serpSummaryService
          .getSerpResultsWhenAuthenticated(search_params)
          .pipe(
            map((results: Results) =>
              SearchActions.requestRatesResultsSuccess({
                results: results,
              })
            ),
            catchError((error) =>
              of(
                SearchActions.requestRatesResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  dispatchClearSummaryResults = createEffect(() =>
    this.actions.pipe(
      ofType(UpdateSelectedBillingCodeDependents),
      map(() => resetRatesResults())
    )
  );

  constructor(
    private actions: Actions,
    private serpSummaryService: SerpService
  ) {}
}
