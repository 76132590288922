<platform-fab-peeker
  class="platform-balances-sticky-fab"
  [ngStyle]="fabStyle"
  [ngClass.lt-md]="{ 'mobile-tooltip-open': openTooltip }"
  [text]="fabText"
  [color]="fabColor"
  [peek]="fabPeek || !openTooltip"
  [peekOnFocus]="!openTooltip"
  [peekOnHover]="!openTooltip"
  [platformTooltip]="tooltip"
  [tooltipOpen]="openTooltip"
  (clicked)="onPeekerFabClick()"
>
  <i class="icon-bar-chart" aria-hidden="true" [fxHide]="openTooltip"></i>
  <mat-icon svgIcon="dls-times-plain-light" [fxHide]="!openTooltip"></mat-icon>
</platform-fab-peeker>
<platform-tooltip
  class="platform-balances-tooltip"
  #tooltip
  (tooltipReady)="tooltipReady = $event"
  (closed)="openTooltip = false"
>
  <platform-balances
    [balances]="balances"
    [startAnimation]="tooltipReady"
    [translations]="translations"
    [assetPath]="assetPath"
  >
  </platform-balances>
</platform-tooltip>
