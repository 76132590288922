<div
  class="w-full"
  [ngClass]="{
    'buffer-mode': mode === 'buffer',
    'system-blue': color === 'system-blue'
  }"
  data-cy="progress-bar"
>
  <mat-progress-bar
    [color]="color"
    [mode]="mode"
    [value]="value"
    [bufferValue]="bufferValue"
    [attr.aria-label]="label"
    data-cy="progress-bar-zelis"
  ></mat-progress-bar>
</div>
