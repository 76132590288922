export class DefaultMember {
  public member_number?: string;
  public subscriber_id?: string;
  public dob?: string;
  public account?: string;
  public MLEEligible?: string;
  public inAllowedLocation?: boolean;

  constructor(member?: DefaultMember) {
    member = member || {};
    this.member_number = member.member_number || null;
    this.subscriber_id = member.subscriber_id || null;
    this.dob = member.dob || null;
    this.account = member.account || null;
    this.MLEEligible = member.MLEEligible || null;
    this.inAllowedLocation = member.inAllowedLocation || null;
  }

  public setInAllowedLocation?(allowed: boolean): DefaultMember {
    this.inAllowedLocation = allowed;
    return this;
  }
}
