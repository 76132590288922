import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { GlobalMembersDropdownComponent } from './global-members-dropdown.component';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [GlobalMembersDropdownComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
  ],
  providers: [],
  exports: [GlobalMembersDropdownComponent],
})
export class GlobalMembersDropdownModule {}
