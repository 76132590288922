import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselItemQuoteComponent } from './carousel-item-quote.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  exports: [CarouselItemQuoteComponent],
  imports: [CommonModule, FlexLayoutModule],
  declarations: [CarouselItemQuoteComponent],
})
export class CarouselItemQuoteModule {}
