<div
  *ngIf="
    telehealthCode !== undefined &&
    amenities &&
    amenities.amenities &&
    amenities.amenities.length > 0
  "
>
  <div class="heading">{{ translations.amenities }}</div>
  <ul>
    <li
      *ngFor="
        let item of amenities.amenities | slice: 0:getLimit(limit);
        trackBy: trackByFn
      "
    >
      <i
        ng-if="item.type_code"
        class="material-icons amenity_align"
        aria-hidden="true"
        >{{ mapIcon(item.type_code) }}</i
      >
      {{ item.type }}
    </li>
    <div *ngIf="amenities.amenities.length > 1">
      <button
        *ngIf="limit === 1"
        (click)="limit = 0"
        class="click_pointer link-blue"
        type="button"
      >
        {{ translations.showMore }}
      </button>
      <button
        *ngIf="limit === 0"
        (click)="limit = 1"
        class="click_pointer link-blue"
        type="button"
      >
        {{ translations.showLess }}
      </button>
    </div>
  </ul>
</div>

<div *ngIf="adaAmenities">
  <div class="heading">
    <i class="material-icons amenity_align" aria-hidden="true">accessible</i>
    {{ translations.adaHeader }}
  </div>
  <div class="description">
    {{ translations.adaDescription }}
  </div>
  <ul>
    <li *ngFor="let amenity of mappedAdaAmenities; trackBy: trackByFn">
      <strong>{{ amenity.type }}:</strong> {{ amenity.displayText }}
    </li>
  </ul>
</div>
