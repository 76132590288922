import { EventStopPropagationModule } from './../event-stop-propagation/event-stop-propagation.module';
import { EventPreventDefaultModule } from './../event-prevent-default/event-prevent-default.module';
import { MapComponent } from './map.component';
import { MapService } from './map.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
  exports: [MapComponent],
  imports: [
    CommonModule,
    EventPreventDefaultModule,
    EventStopPropagationModule,
    NgxMapboxGLModule.withConfig({
      // Sapphire365 Token
      accessToken:
        'pk.eyJ1Ijoidml0YWxzY2hvaWNlIiwiYSI6IngwVHR5bTgifQ.qtI30egijiHjtzjTBilcgw',
    }),
  ],
  providers: [MapService],
  declarations: [MapComponent],
})
export class MapModule {}
