<div
  data-cy="nav-button"
  class="py-2 flex items-center"
  [ngClass]="{ active: active }"
>
  <mat-icon
    *ngIf="leadingIcon"
    class="-mr-6 ml-3 align-middle transition-transform"
    [svgIcon]="leadingIcon"
  ></mat-icon>
  <button
    mat-button
    class="px-0 mx-4 leading-7"
    [disableRipple]="true"
    (click)="buttonClicked($event)"
    [attr.aria-label]="ariaLabel"
    [ngClass]="{
      'pl-7 ml-0': leadingIcon,
      'pr-8 mr-0': trailingIcon
    }"
  >
    {{ label }}
  </button>
  <mat-icon
    *ngIf="trailingIcon"
    class="-ml-6 mr-3 align-middle transition-transform"
    [svgIcon]="trailingIcon"
  ></mat-icon>
</div>
