import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  AfterViewChecked,
  AfterContentChecked,
} from '@angular/core';

@Directive({
  selector: '[zelisHighlight]',
})
export class HighlightDirective
  implements AfterViewChecked, AfterContentChecked
{
  @Input('zelisHighlight')
  public set highlightTerm(value: string) {
    this._highlightTerm = value;
    this.doHighlight();
  }
  public get highlightTerm(): string {
    return this._highlightTerm;
  }

  private text = '';
  private _highlightTerm = '';
  private previousElementText = '';
  private previousHighlightTerm = '';

  constructor(public elementRef: ElementRef, public renderer: Renderer2) {}

  ngAfterViewChecked() {
    this.doHighlight();
  }

  ngAfterContentChecked() {
    this.doHighlight();
  }

  private doHighlight(): void {
    this.text = this.elementRef.nativeElement.textContent;
    if (this.shouldHighlight()) {
      this.setPreviousValues();
      this.setElementText(this.cleanHighlightTerm());
    }
  }

  private shouldHighlight(): boolean {
    return !!(
      !!this.text &&
      this.highlightTerm &&
      (this.text !== this.previousElementText ||
        this.highlightTerm !== this.previousHighlightTerm)
    );
  }

  private setPreviousValues(): void {
    this.previousElementText = this.elementRef.nativeElement.textContent;
    this.previousHighlightTerm = this.highlightTerm;
  }

  private setElementText(pattern: RegExp): void {
    if (this.text.match(pattern)) {
      const newText = this.text.replace(
        pattern,
        (term) => `<span class='font-medium'>${term}</span>`
      );
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'innerHTML',
        newText
      );
    } else {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        'innerHTML',
        this.text
      );
    }
  }

  private cleanHighlightTerm(): RegExp {
    const stripSpecialChars = this.highlightTerm.replace(
      /!|@|#|%|\$|\^|\\|\/|\*|\+/g,
      ''
    );
    const stripMultiSpaces = stripSpecialChars.replace(/\s\s+/g, ' ');
    const replaceWithRegex = stripMultiSpaces.replace(/ /g, '|');
    return new RegExp(replaceWithRegex, 'gi');
  }
}
