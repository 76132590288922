export class UuxNetwork {
  public ci?: string;
  public defaultPlan?: boolean;
  public displayName?: string;
  public id?: string;
  public ppo_network?: boolean;
  public name?: string;
  public state_code?: string;
  public state_name?: string;
  public marketing_name?: string;
  public market_segment?: string | null;
  public sub_market_segment?: string | null;
  public sort_order?: number;
  public alphaPrefCd?: string;
  public related_plans?: string;
  public description?: string;
  public label?: string;
  public product_type?: string;

  constructor(data: UuxNetwork) {
    this.ci = data.ci || null;
    this.defaultPlan = data.sort_order === 0;
    this.displayName = data.marketing_name || data.name;
    this.id = data.id || null; // not in marketing_plans.csv file
    this.ppo_network = !!data.ppo_network;
    this.state_code = data.state_code || null;
    this.state_name = data.state_name || null;
    this.alphaPrefCd = data.alphaPrefCd || null; // not in marketing_plans.csv file
    this.related_plans = data.related_plans || null;
    this.description = data.description || null;
    this.label = data.label || null;
    this.product_type = data.product_type || null;
    this.sub_market_segment = data.sub_market_segment || null;
    this.market_segment = data.market_segment || null;
  }
}
