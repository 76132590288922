export class AutocompleteResults {
  public providers?;
  public search_specialties?;
  public procedures?;
  public telehealth?;
  public rx_coupons?;
  public rx_savings?;
  public hasResults?: boolean;
  public showDefaultMenu?: boolean;
  public recentSearches?;
  public initialPrompt?;
  public noResultsPrompt?;
  public categoriesWithResults?: number;

  constructor(obj?: AutocompleteResults) {
    obj = obj || {};
    this.providers = obj.providers || [];
    this.search_specialties = obj.search_specialties || [];
    this.procedures = obj.procedures || [];
    this.telehealth = obj.telehealth || [];
    this.rx_coupons = obj.rx_coupons || [];
    this.rx_savings = obj.rx_savings || [];
    this.recentSearches = obj.recentSearches || [];
    this.noResultsPrompt = obj.noResultsPrompt || [];
    this.initialPrompt = obj.initialPrompt || [];
    this.showDefaultMenu = obj.showDefaultMenu || false;
    this.categoriesWithResults = this.countCategoriesWithResults(obj);
    this.hasResults = this.categoriesWithResults > 0;
  }

  private countCategoriesWithResults?(obj): number{
    const categories = [
      'providers', 
      'search_specialties', 
      'procedures', 
      'telehealth', 
      'rx_savings', 
      'rx_coupons'
    ];
    let count = 0;
    categories.forEach((category)=> {
      if (this[category].length > 0) {
        count++;
      }
    });
    return count;
  }
}
