import { Component, Input } from '@angular/core';

@Component({
  selector: 'platform-login-link',
  templateUrl: './login-link.component.html',
  styleUrls: ['./login-link.component.scss'],
})
export class LoginLinkComponent {
  @Input() secondaryColor = false;
  @Input() text: string;
  @Input() url: string;
}
