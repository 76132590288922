import { filter, map, tap, switchMap, mergeWith } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { SettingsService } from '../settings.service';
import { ConfigurationService } from '../configuration.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StorageUtilities } from '@utilities/storage.utilities';

const SETTING_NAME = 'translationKeys';

@Injectable({
  providedIn: 'root',
})
export class TranslationsSettingsService {
  public translationsResolved: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private settingsService: SettingsService,
    private configService: ConfigurationService,
    private translateService: TranslateService,
    private storageUtilities: StorageUtilities
  ) {}

  public updateTranslations(): Observable<any> {
    return this.listenToConfigSign().pipe(
      switchMap((configSignature: string) =>
        combineLatest([
          this.settingsChanges(configSignature),
          this.languageChanges(),
        ])
      ),
      tap(([settings, language]) => {
        this.update({ settings: settings, language: language });
      })
    );
  }

  private listenToConfigSign(): Observable<string> {
    return this.configService.signature.pipe(filter((sig) => !!sig));
  }

  private update(options: { settings: any; language: string }): void {
    const settings = options.settings;
    let language = options.language;
    if (language === 'null' || language === 'nu' || !language) {
      language = 'en';
    }
    if (settings.settings[0] && settings.settings[0].translationKeys) {
      const accountTranslations = settings.settings[0].translationKeys;

      if (!accountTranslations) {
        return;
      }

      this.updateEnglishDefaultTranslations(accountTranslations)
      if( language !== 'en'){
        this.augmentAndSetTranslations(language, accountTranslations)
      }
      this.translationsResolved.next(true);
    }
  }

  private updateEnglishDefaultTranslations(accountTranslations: object): void {
    const updatedTranslations = this.augmentAndSetTranslations('en', accountTranslations)
    this.storageUtilities.sessionStorageSet('englishTranslations', updatedTranslations);
  }

  private augmentAndSetTranslations(language: string, accountTranslations: object): object {
    const updatedTranslations = this.mergeNewAndDefaultTranslations(
      accountTranslations[language],
      language
    );
    this.translateService.setTranslation(language, updatedTranslations, true);
    return updatedTranslations
  }

  private mergeNewAndDefaultTranslations(
    newTranslations: object,
    lang: string
  ): object {
    const translations = lang
      ? require(`../../../assets/translations/${lang}.json`)
      : {};
    return { ...translations, ...newTranslations };
  }

  private settingsChanges(configSignature: string): Observable<any> {
    return this.settingsService.requestSetting(
      configSignature,
      SETTING_NAME,
      true
    );
  }

  private languageChanges(): Observable<any> {
    const currentLang = this.translateService.currentLang;
    const langChanges = this.translateService.onLangChange.pipe(
      map((langChange: LangChangeEvent) => langChange.lang)
    );
    return of(currentLang).pipe(mergeWith(langChanges));
  }
}
