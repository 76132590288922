import { AuthService } from './../auth.service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, forkJoin } from 'rxjs';
import { WindowService } from '../window.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { Place } from '@classes/place.class';
import { map, filter, mergeMap, first } from 'rxjs/operators';
import { AuthStatus } from '@interfaces/auth-status.model';
import { ConfigurationService } from '../configuration.service';
import { AppParamsService } from '../app.params.service';
import { UserSelectedCritical } from '@interfaces/user-selected-critical.model';
import { CriticalParamsService } from '../critical-params/critical-params.service';
import { CityService } from './city-lookup.service';

const TEN_MINUTES = 10 * 60 * 1000; // in milliseconds
const NULL_PLACE = new Place({});

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public geo = new BehaviorSubject<Place>(null);
  public isDetectedLocation = new BehaviorSubject<boolean>(null);
  public isSsoLocation = new BehaviorSubject<boolean>(null);
  public resolveLocation: any;
  public savedCriticalLocation: Place;

  private apiSubscription: Subscription;
  private citySubscription: Subscription;

  constructor(
    private appParamsService: AppParamsService,
    private authService: AuthService,
    private configurationService: ConfigurationService,
    private criticalParamsService: CriticalParamsService,
    private http: HttpClient,
    private routeUtilities: RouteUtilities,
    private cityService: CityService,
    @Inject(WindowService) private windowRef: Window
  ) {
    this.apiLocate();
  }

  public apiLocate(): void {
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }

    this.apiSubscription = this.waitForResolve().subscribe((results) => {
      if (results.isValid()) {
        this.geo.next(results);
        this.isDetectedLocation.next(results.is_detected_location);
        this.isSsoLocation.next(results.sso_location);
      }
    });
  }

  public locationSelected(place: Place): void {
    this.storeUserSelectedLocation(place);
  }

  public browserLocate(): Observable<Place> {
    const options = {
      // Maximum allowable age of GPS data before re-calculating
      maximumAge: TEN_MINUTES,
    };

    return new Observable((finished) => {
      this.windowRef.navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          // success
          const browserPlace = new Coordinates(
            position.coords.latitude,
            position.coords.longitude
          ).toPlace();

          if (browserPlace.isValid()) {
            this.citySubscription?.unsubscribe();

            this.citySubscription = this.cityService.forPlace(browserPlace)
              .subscribe((place: Place[]) => {
                // preserve GeolocationPosition as per PUI-21742
                place[0].geo = browserPlace.geo;
                this.geo.next(place[0]);
                this.isDetectedLocation.next(true);
                finished.next(place[0]);
              });
            return this.citySubscription;
          } else {
            finished.next(null);
          }

          finished.complete();
        },
        () => {
          // error
          finished.next(null);
          finished.complete();
        },
        options
      );

      return () => {
        this.citySubscription?.unsubscribe();
      }
    });
  }

  private waitForResolve(): Observable<any> {
    const urlParams = this.routeUtilities.getParamsFromUrl();
    return forkJoin([
      this.getAuth().pipe(first()),
      this.getCi().pipe(first()),
      this.getSignature().pipe(first()),
    ]).pipe(
      mergeMap(([auth, ci, sig]) => {
        return this.requestLocationResolve(auth, ci, sig, urlParams);
      })
    );
  }

  private getAuth(): Observable<AuthStatus> {
    return this.authService.authStatus.pipe(filter((auth) => auth.resolved));
  }

  private getCi(): Observable<string> {
    return this.criticalParamsService.criticalParamsSubject.pipe(
      filter((criticalParams) => !!criticalParams.ci),
      map((cParams) => cParams.ci)
    );
  }

  private getSignature(): Observable<string> {
    return this.configurationService.signature.pipe(filter((sig) => !!sig));
  }

  private requestLocationResolve(
    auth: AuthStatus,
    ci: string,
    sig: string,
    urlParams: any
  ): Observable<Place> {
    const url = `/api/geolocation/resolve.json`;
    const params = new HttpParams({
      fromObject: this.resolveLocationWithParams(auth, ci, sig, urlParams),
    });
    return this.http
      .get(url, { params: params, withCredentials: true })
      .pipe(map((results) => new Place(results)));
  }

  private resolveLocationWithParams(
    auth: AuthStatus,
    ci: string,
    sig: string,
    urlParams: any
  ): any {
    this.resolveLocation = { ...urlParams };
    this.getSavedCriticalLocation(ci);
    if (sig) {
      this.resolveLocation.config_signature = sig;
    }
    if (
      !auth.msa_auth_status &&
      !this.resolveLocation.geo_location &&
      this.savedCriticalLocation &&
      this.savedCriticalLocation.geo
    ) {
      this.resolveLocation.geo_location = this.savedCriticalLocation.geo;
      this.resolveLocation.saved_location = '1';
    }
    return this.resolveLocation;
  }

  private getSavedCriticalLocation(ci: string): void {
    const userSavedCritical = this.getSavedCritical();
    if (userSavedCritical && userSavedCritical.length >= 1) {
      const userSavedCriticalLocation = userSavedCritical.find(
        (crit) => crit.ci === ci
      );

      this.savedCriticalLocation =
        userSavedCriticalLocation && userSavedCriticalLocation.location
          ? new Place(userSavedCriticalLocation.location)
          : this.getFirstLocationFound(userSavedCritical);
    }
  }

  private getFirstLocationFound(
    userSavedCritical: UserSelectedCritical[]
  ): Place {
    const criticalWithLocation = userSavedCritical.find(
      (crit) => !!crit.location
    );
    if (
      criticalWithLocation &&
      criticalWithLocation.location &&
      criticalWithLocation.location.geo
    ) {
      return new Place(criticalWithLocation.location);
    }
  }

  private getSavedCritical(): UserSelectedCritical[] {
    return this.appParamsService.getUserSelectedCritical();
  }

  private storeUserSelectedLocation(place: Place): void {
    if (place && place.geo) {
      this.appParamsService.setUserSelectedCritical('location', place);
    }
  }
}

class Coordinates {
  constructor(private lat: number, private lng: number) {}

  public toPlace(): Place {
    if (!this.lat || !this.lng) {
      return NULL_PLACE;
    }

    return new Place({
      geo: [this.lat, this.lng].join(','),
    });
  }
}
