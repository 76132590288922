import * as _ from 'lodash';

export class MemberDependent {
  public id: string;
  public member_number: string; // Alias: id
  public last_name: string;
  public first_name: string;
  public middle_name: string;
  public full_name: string;
  public dob: string;
  public isPrimary: boolean = false;
  public behavioral_health_eligible?: string;
  public gender?: string;
  public medical_group_numbers?: string[];

  constructor(dep: any = {}) {
    this.id = dep.number || dep.member_number || null;
    this.member_number = dep.member_number || this.id;
    this.last_name = dep.last_name || null;
    this.first_name = dep.first_name || null;
    this.middle_name = dep.middle_name || null;
    this.full_name = this.getFullName();
    this.dob = dep.dob || null;
    this.gender = dep.gender || null;
    this.behavioral_health_eligible =
      typeof dep.behavioral_health_eligible === 'undefined'
        ? null
        : dep.behavioral_health_eligible;
    this.medical_group_numbers = dep.medical_group_numbers || null;
  }

  private getFullName(): string {
    return _.compact([this.first_name, this.middle_name, this.last_name]).join(
      ' '
    );
  }
}
