import { AutocompleteResultsCounter } from '@interfaces/autocomplete-results-counter.model';

export class AutocompleteResultsCount {
  public providers = new AutocompleteResultsCounter();
  public search_specialties = new AutocompleteResultsCounter();
  public procedures = new AutocompleteResultsCounter();

  constructor(obj?: AutocompleteResultsCount) {
    return;
  }
}
