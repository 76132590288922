import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SnackbarService } from './snackbar/snackbar.service';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { DlsIconModule } from '@zelis/dls/icons';
import { DlsFocusModule } from '@zelis/dls/focus';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, DlsIconModule, DlsFocusModule],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent, MatSnackBarModule],
  providers: [SnackbarService],
})
export class DlsSnackbarModule {}
