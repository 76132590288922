import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  AnimationEvent,
} from '@angular/animations';

@Component({
  selector: 'platform-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  animations: [
    trigger('popInOut', [
      state('void', style({ width: 50, height: 50, opacity: 0 })),
      state('*', style({ width: '*', height: '*', opacity: 1 })),
      transition(':enter, :leave', [
        animate('500ms cubic-bezier(0.175, 0.885, 0.32, 1.275)'),
      ]),
    ]),
  ],
})
export class TooltipComponent {
  @Input() public open = true;

  @Output() public tooltipReady: EventEmitter<boolean> = new EventEmitter(true);
  @Output() public closed: EventEmitter<void> = new EventEmitter(true);

  @ViewChild(TemplateRef) public template: TemplateRef<any>;

  public onAnimationStart(event: AnimationEvent): void {
    if (event.toState === 'void') {
      this.tooltipReady.emit(false);
    }
  }

  public onAnimationDone(event: AnimationEvent): void {
    if (event.toState === null) {
      this.tooltipReady.emit(true);
    }

    if (event.toState === 'void') {
      this.closed.emit();
    }
  }
}
