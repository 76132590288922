import { Component, Input, OnInit } from '@angular/core';

@Component({
  // TODO: Fix tslint
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  @Input() url: string;

  ngOnInit() {
    this.url = this.parseUrl(this.url);
  }

  private parseUrl(url) {
    return url.replace('watch?v=', 'v/');
  }
}
