import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { CompanyLogoModule } from './../company-logo/company-logo.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalFooterComponent } from './global-footer.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [GlobalFooterComponent],
  imports: [CommonModule, CompanyLogoModule, RouterModule, FlexLayoutModule],
  exports: [GlobalFooterComponent],
})
export class GlobalFooterModule {}
