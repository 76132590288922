export class GlobalFooterTranslations {
  public legals?: string;
  public copyright?: string;
  public lastUpdated?: string;
  public poweredBy?: string;
  public termsOfUse?: string;
  public privacyPolicy?: string;
  public logoAltText?: string;

  constructor(obj?: GlobalFooterTranslations) {
    obj = obj || ({} as GlobalFooterTranslations);
    this.legals = obj.legals || '';
    this.copyright = obj.copyright || '';
    this.logoAltText = obj.logoAltText || 'Sapphire part of Zelis logo';
    this.lastUpdated = obj.lastUpdated || 'Provider data last updated';
    this.poweredBy = obj.poweredBy || 'Powered by';
    this.termsOfUse = obj.termsOfUse || 'Terms of Use';
    this.privacyPolicy = obj.privacyPolicy || 'Privacy Policy';
  }
}
