import { SearchFilterOption } from '@interfaces/search-filter-option.model';
import { SearchFiltersFacetQuery } from '@interfaces/search-filters-facet-query.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';

export class SearchFilter {
  name?: string;
  facet?: string;
  type?: string;
  selected?: string;
  default?: any;
  hide?: string[];
  disable?: string[];
  disabled?: boolean;
  option_mapping?: Record<string, string> | null;
  option_sort?: string;
  items?: SearchFilter[];
  options?: SearchFilterOption[];
  tooltip?: string;
  facet_queries?: SearchFiltersFacetQuery[];
  group_tier?: boolean;
  defaultSelected?: boolean;

  constructor(obj?: SearchFilter, tierCode?: string) {
    this.name = obj.name || null;
    this.facet = mapFacet(obj.facet, tierCode);
    this.type = obj.type || null;
    this.default = obj.default || null;
    this.selected = obj.selected;
    this.hide = obj.hide || [];
    this.disable = obj.disable || [];
    this.disabled = obj.disabled === true ? true : false;
    this.option_mapping = obj.option_mapping || null;
    this.option_sort = obj.option_sort || null;
    this.items = mapItems(obj.items);
    this.options = obj.options || [];
    this.tooltip = obj.tooltip || '';
    this.facet_queries = mapFacetQueries(obj, this.facet);
    this.group_tier = obj.group_tier || null;
    this.defaultSelected = obj.defaultSelected || null;
  }
}

const mapItems = (items: any): SearchFilter[] => {
  const mappedItems = [];

  for (const key in items) {
    if (items[key]) {
      mappedItems.push(new SearchFilter(items[key]));
    }
  }

  return mappedItems;
};

const mapFacet = (facet: string, tierCode: string): string => {
  if (facet && facet.indexOf('tiers') > -1) {
    facet =
      facet.split(':')[0] + ':' + (tierCode ? tierCode.toLowerCase() : '');
  }
  return facet;
};

const mapFacetQueries = (obj: any, facet: any): Array<any> => {
  if (
    facet &&
    facet.indexOf('tiers') < 0 &&
    obj.hasOwnProperty('facet_queries')
  ) {
    return obj.facet_queries;
  }
  return [new SearchFiltersFacetQuery({ facetQuery: facet, value: 'true' })];
};

export const setDefaultSelected = (
  obj: SearchFilter,
  searchParamType: SearchParamType
): string => {
  return obj.default && obj.default[searchParamType]
    ? obj.default[searchParamType]
    : obj.selected;
};
