import { SearchFiltersFacet } from '@interfaces/search-filters-facet.model';

export class FacetsContainingAwards {
  award_type_codes?: SearchFiltersFacet[];
  blue_compare?: SearchFiltersFacet[];

  constructor(facets?: FacetsContainingAwards) {
    facets = facets || {};
    this.award_type_codes = facets.award_type_codes || [];
    this.blue_compare = facets.blue_compare || [];
  }
}

export class AwardTypeFilter {
  readonly awardFacets = [];
  readonly blueCompareFacets = [];

  constructor(allFacets: FacetsContainingAwards) {
    allFacets = new FacetsContainingAwards(allFacets);
    this.awardFacets = allFacets.award_type_codes;

    const blueCompareTrue = allFacets.blue_compare.find(
      (f) => f.value.toString() === 'true'
    );

    if (blueCompareTrue) {
      this.blueCompareFacets = [{ value: 'blue_compare', name: 'BlueCompare' }];
    }
  }

  public facets(): SearchFiltersFacet[] {
    return this.awardFacets.concat(this.blueCompareFacets);
  }
}
