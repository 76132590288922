import { SortConfig } from '@interfaces/sort-config.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchSortOption } from '@interfaces/search-sort-option.model';

export const selectSortFiltersState =
  createFeatureSelector<SortConfig>('sort_filters');

export const selectSortFilters = createSelector(
  selectSortFiltersState,
  (state: SortConfig) => {
    return state;
  }
);

export const selectDefaultRatesSortFilterQuery = createSelector(
  selectSortFiltersState,
  (state: SortConfig) => {
    return state.default.find(
      (option: SearchSortOption) => option.translation === state.rates_default
    ).query;
  }
);
