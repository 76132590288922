import { Action, createReducer, on } from '@ngrx/store';
import * as networkActions from './network.actions';

import { NetworkState, initialState } from './network.state';

export const networkReducer = createReducer(
  initialState,
  on(
    networkActions.setResolvedNetwork,
    (_state, { network }): NetworkState => ({ ...network })
  )
);

export const reducer = (state: NetworkState, action: Action) => {
  return networkReducer(state, action);
};
