import { Injectable, NgZone } from '@angular/core';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  takeUntil,
  take,
  tap,
  switchMap,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, of, forkJoin, Subject } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { ChatSessionInterface } from '@interfaces/chat-session-interface.model';
import { MembersService } from '../members.service';
import { WindowService } from '../window.service';
import { Member } from '../../components/members';
import { TranslateService } from '@ngx-translate/core';
import { AgentAvailability } from './agent-availability.class';
import {
  ChatMessage,
  ChatMessagesBundle,
  ChatMessagePayload,
  ChatMessagesResponse,
} from './chat.message.class';
import { FeaturesService } from '../features/features.service';
import { StickyFabService } from '../sticky-fab.service';

const AVAILABILITY_POLLING_SECONDS = 20;

type Interval = number;

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public agentName = 'PAT Agent';
  public messages: BehaviorSubject<ChatMessage[]> = new BehaviorSubject([]);
  public isAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isEstablished: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public chatStarted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public agentIsTyping: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public positionInQueue: BehaviorSubject<number> = new BehaviorSubject(null);
  public chatRequested: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public chatEnded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public persistChat: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public proactiveChat: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public userOpened: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public stopPolling = new Subject<void>();

  private apiVersion = '45';
  private readonly chatInitUrl: string;
  private readonly buttonId: string;
  private readonly screenResolution: string;
  private session: ChatSessionInterface;
  private member: Observable<Member>;
  private chatAvailabilityInterval: Interval;

  constructor(
    private http: HttpClient,
    private windowService: WindowService,
    private membersService: MembersService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private featuresService: FeaturesService,
    private stickyFabService: StickyFabService
  ) {
    this.screenResolution = `${this.windowService['innerWidth']}x${this.windowService['innerHeight']}`;
    this.buttonId = '57341000000Pjey';
    this.chatInitUrl = '/api/chat';
    this.subscribeToMember();
  }

  public pollAvailability(): void {
    if (this.chatAvailabilityInterval) {
      clearInterval(this.chatAvailabilityInterval);
    }

    this.checkAvailability();
    this.ngZone.runOutsideAngular(() => {
      this.chatAvailabilityInterval = window.setInterval(
        () => this.checkAvailability(),
        AVAILABILITY_POLLING_SECONDS * 1000
      );
    });
  }

  public checkAvailabilityWithoutStartingChat(): void {
    this.checkAvailability();
  }

  public sendMessage(text: string): Observable<boolean> {
    const queryParams = new HttpParams().set(
      'ack',
      this.session.seq.toString()
    );
    const config = {
      headers: this.headers(),
      params: queryParams,
    };
    return this.http
      .post(
        this.chatInitUrl + '/rest/Chasitor/ChatMessage',
        { text: text },
        config
      )
      .pipe(
        catchError(() => of(null)),
        map((response) => true)
      );
  }

  public userStartedTyping(): void {
    if (!this.isConnected.getValue()) {
      return;
    }

    const endpoint = '/rest/Chasitor/ChasitorTyping';
    const config = {
      headers: this.headers(),
    };
    this.http
      .post(this.chatInitUrl + endpoint, {}, config)
      .pipe(catchError(() => of(null)))
      .subscribe();
  }

  public userStoppedTyping(): void {
    if (!this.isConnected.getValue()) {
      return;
    }

    const endpoint = '/rest/Chasitor/ChasitorNotTyping';
    const config = {
      headers: this.headers(),
    };
    this.http
      .post(this.chatInitUrl + endpoint, {}, config)
      .pipe(catchError(() => of(null)))
      .subscribe();
  }

  public startChatAfterAvailabilityCheck(): void {
    this.chatRequested.next(true);

    this.createSession().subscribe(() => {
      if (this.isAvailable.getValue()) {
        this.initiateChat().subscribe((member: Member) => {
          this.chatStarted.next(true);
          this.isConnected.next(true);
          if (this.chatAvailabilityInterval) {
            clearInterval(this.chatAvailabilityInterval);
          }
          this.chatLoop();
        });
      } else {
        this.chatRequested.next(false);
      }
    });
  }

  public endChat(): void {
    if (this.isConnected.getValue()) {
      this.stopPolling.next()
    }
    
    const config = {
      headers: this.headers(),
    };

    this.http
      .post(
        this.chatInitUrl + '/rest/Chasitor/ChatEnd',
        { reason: 'client' },
        config
      )
      .pipe(
        catchError(() => of(null)),
        map(() => {
          this.messages.next([]);
          this.session = null;
          this.isEstablished.next(false);
          this.isConnected.next(false);
        }),
        first()
      )
      .subscribe();
  }

  public endChatBeforeItBegins(): void {
    this.isConnected.next(false);
  }

  public chatDisabled(): Observable<boolean> {
    return forkJoin([
      this.stickyFabService.fabDisabled(),
      this.getChatFeature(),
    ]).pipe(map(([fabDisabled, chatDisabled]) => fabDisabled || chatDisabled));
  }

  public displayOrHideChat(
    incentivizedSearch: boolean,
    chatDelay: number
  ): void {
    if (incentivizedSearch) {
      this.persistChat.next(true);
      this.isAvailable
        .pipe(
          filter((available) => available),
          take(1)
        )
        .subscribe(() => {
          if (!this.hasProactiveChatBeenShown()) {
            this.proactiveChat.next(true);
            this.openFabOnDelay(chatDelay);
          } else {
            this.stickyFabService.openBubble.next(false);
            this.proactiveChat.next(false);
          }
        });
    } else {
      this.hideChatUnlessConnected();
    }
  }

  public hideChatUnlessConnected(): any {
    if (!this.isConnected.getValue()) {
      this.persistChat.next(false);
      this.proactiveChat.next(false);
    }
  }

  private openFabOnDelay(chatDelay): void {
    of(true)
      .pipe(
        delay(chatDelay),
        takeUntil(
          this.userOpened.pipe(
            filter((opened) => opened),
            tap(() => this.setProactiveChatShown())
          )
        )
      )
      .subscribe(() => {
        this.stickyFabService.openBubble.next(true);
        this.setProactiveChatShown();
      });
  }

  private setProactiveChatShown(): void {
    this.windowService['sessionStorage'].setItem('proactiveChatShown', true);
  }

  private hasProactiveChatBeenShown(): boolean {
    return (
      this.windowService['sessionStorage'].getItem('proactiveChatShown') ===
      'true'
    );
  }

  private getChatFeature(): Observable<boolean> {
    return this.featuresService.getFeatureFlags().pipe(
      first((features) => !!features),
      map((features) => features?.disable_salesforce_liveagent)
    );
  }

  private checkAvailability(): void {
    const queryParams = new HttpParams()
      .set('Availability.ids', '[' + this.buttonId + ']')
      .set('cache', 'false');

    const config = {
      headers: this.headers(),
      params: queryParams,
    };

    this.http
      .get(this.chatInitUrl + '/rest/Visitor/Availability', config)
      .pipe(catchError((error) => this.handleAvailability(error)))
      .pipe(
        map(
          (availabilityResponse) => new AgentAvailability(availabilityResponse)
        )
      )
      .subscribe((agentAvailability) =>
        this.isAvailable.next(agentAvailability.isAvailable)
      );
  }

  private handleAvailability(
    error: HttpErrorResponse
  ): Observable<boolean | HttpErrorResponse> {
    if (error && error.status === 503) {
      return of(false);
    } else {
      return of(error);
    }
  }

  private createSession(): Observable<ChatSessionInterface> {
    const queryParams = new HttpParams().set('cache', 'false');
    const config = {
      headers: this.headers(),
      params: queryParams,
    };

    return this.http
      .get<ChatSessionInterface>(
        this.chatInitUrl + '/rest/System/SessionId',
        config
      )
      .pipe(
        map(
          (res: ChatSessionInterface) =>
            (this.session = new ChatSessionInterface(res))
        ),
        first()
      );
  }

  private initiateChat(): Observable<Member> {
    return this.member.pipe(
      switchMap((member) => {
        if (!this.isAvailable.getValue()) {
          return of(null);
        }

        const data = {
          buttonId: this.buttonId,
          sessionId: this.session.id,
          screenResolution: this.screenResolution,
          visitorName: member.full_name,
          userAgent: this.windowService['navigator'].userAgent,
          language: this.translateService.currentLang,
          receiveQueueUpdates: true,
          isPost: true,
          prechatDetails: this.prechatDetails(member),
          prechatEntities: this.prechatEntities(),
        };

        return this.http
          .post(this.chatInitUrl + '/rest/Chasitor/ChasitorInit', data, {
            headers: this.headers(),
          })
          .pipe(catchError(() => of(null)));
      })
    ) as Observable<Member>;
  }

  private subscribeToMember(): void {
    this.member = this.membersService.member.pipe(first((member) => !!member));
  }

  private chatLoop(): void {
    if (this.isConnected.getValue()) {
      this.ngZone.runOutsideAngular(() => {
         this.getMessages().subscribe((status: number) => {
           if (this.session && (status === 204 || status === 200)) {
             this.chatLoop();
           }
         });
      });
    }
  }

  private getMessages(): Observable<number> {
    const queryParams = new HttpParams()
      .set('ack', this.session.seq.toString())
      .set('cache', 'false');

    const config = {
      headers: this.headers(),
      observe: 'response' as const,
      params: queryParams,
    };

    return this.http
      .get(this.chatInitUrl + '/rest/System/Messages', config)
      .pipe(
        map((response: HttpResponse<ChatMessagesResponse>) => {
          const body = new ChatMessagesBundle(response.body);
          if (body.isSuccess) {
            if (body.isValid) {
              this.session.seq = body.sequence;

              body.messages.forEach((payload: ChatMessagePayload) =>
                this.processMessagePayload(payload)
              );
            }
            return response.status;
          } else {
            return null;
          }
        }),
        takeUntil(this.stopPolling)
      );
  }

  private processMessagePayload(payload: ChatMessagePayload): void {
    switch (payload.type) {
      case 'ChatMessage':
        this.agentName = payload.message.name;
        this.messages.next([payload.message]);
        break;
      case 'ChatEstablished':
        this.isEstablished.next(true);
        this.chatRequested.next(false);
        this.positionInQueue.next(null);
        break;
      case 'ChatRequestSuccess':
        this.chatRequested.next(false);
        this.positionInQueue.next(payload.positionInQueue);
        break;
      case 'QueueUpdate':
        this.positionInQueue.next(payload.positionInQueue);
        break;
      case 'ChatRequestFail':
        this.messages.next([payload.message]);
        break;
      case 'ChatEnded':
        this.endChat();
        this.isConnected.next(false);
        this.chatEnded.next(true);
        break;
    }
  }

  private headers(): HttpHeaders {
    let _headers = new HttpHeaders()
      .set('X-LIVEAGENT-API-VERSION', this.apiVersion)
      .set('X-LIVEAGENT-AFFINITY', this.affinity())
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json');

    if (this.session) {
      _headers = _headers.set('X-LIVEAGENT-SESSION-KEY', this.session.key);
    }

    return _headers;
  }

  private affinity(): string {
    return this.session ? this.session.affinityToken : 'null';
  }

  private prechatDetails(member: Member) {
    return [
      {
        label: 'MemberID',
        value: member.id,
        transcriptFields: ['Member_ID__c'],
        displayToAgent: true,
      },
      {
        label: 'PlanId',
        value: member.internal_plan_id,
        transcriptFields: ['Plan_ID__c'],
        displayToAgent: true,
      },
      {
        label: 'DateofBirth',
        value: member.dob,
        transcriptFields: ['Birthdate'],
        displayToAgent: true,
      },
    ];
  }

  private prechatEntities() {
    return [
      {
        entityName: 'Contact',
        linkToEntityField: 'Member_ID__c',
        saveToTranscript: 'ContactId',
        entityFieldsMaps: [
          {
            fieldName: 'Member_ID__c',
            label: 'MemberID',
            doFind: true,
            isExactMatch: true,
            doCreate: false,
          },
        ],
      },
      {
        entityName: 'Contact',
        linkToEntityField: 'Plan_ID__c',
        saveToTranscript: 'ContactId',
        entityFieldsMaps: [
          {
            fieldName: 'Plan_ID__c',
            label: 'PlanId',
            doFind: true,
            isExactMatch: true,
            doCreate: false,
          },
        ],
      },
      {
        entityName: 'Contact',
        linkToEntityField: 'Birthdate',
        saveToTranscript: 'ContactId',
        entityFieldsMaps: [
          {
            fieldName: 'Birthdate',
            label: 'Dateofbirth',
            doFind: true,
            isExactMatch: true,
            doCreate: false,
          },
        ],
      },
    ];
  }
}
