import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { IncompatibleBrowserOverlayComponent } from './incompatible-browser-overlay.component';

@NgModule({
  declarations: [IncompatibleBrowserOverlayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    FormsModule,
  ],
  providers: [],
  exports: [IncompatibleBrowserOverlayComponent],
})
export class IncompatibleBrowserOverlayModule {}
