import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RatingComponent } from '../rating/rating.component';

@NgModule({
  declarations: [RatingComponent],
  imports: [CommonModule, FormsModule, MatIconModule],
  providers: [],
  exports: [RatingComponent],
})
export class RatingModule {}
