import { Component, Input } from '@angular/core';
import { MemberCardTranslations } from './member-card.translations.model';
import { MemberCardMagnify } from './member-card.magnify.interface';

@Component({
  selector: 'platform-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent {
  @Input()
  public set translations(value: MemberCardTranslations) {
    this._translations = new MemberCardTranslations(value);
  }
  public get translations(): MemberCardTranslations {
    return this._translations;
  }
  @Input() public magnify: MemberCardMagnify;
  @Input() public assetPath = './assets/member-card/assets/';
  @Input() public suppressLogo = false;

  private _translations: MemberCardTranslations = new MemberCardTranslations();
}
