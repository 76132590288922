<div [ngClass]="{ 'default-spinner-color': !color }">
  <mat-progress-spinner
    [color]="color"
    [mode]="mode"
    [value]="value"
    [diameter]="diameter"
    [strokeWidth]="strokeWidth"
    [attr.data-cy]="dataCy"
    [attr.aria-label]="label"
  ></mat-progress-spinner>
</div>
