import { Component, Input, HostBinding } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
export type ProgressBarTheme = 'primary' | 'accent' | 'system-blue';

@Component({
  selector: 'zelis-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @HostBinding() class = 'zelis-dls';
  @Input() color?: ProgressBarTheme;
  @Input() mode!: ProgressBarMode;
  @Input() value = 0;
  @Input() bufferValue = 0;
  @Input() label = 'progress bar';
}
