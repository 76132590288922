import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteUtilities } from '@utilities/route.utilities';
import { StorageUtilities } from '@utilities/storage.utilities';
import { Observable } from 'rxjs';
import { MembersService } from '@services/members.service';
import { filter, map } from 'rxjs/operators';
import { Member } from '@components/members';
import { CareGuidanceEligibility } from '@interfaces/care-guidance-eligibility.model';
import { CareGuidanceFlags } from '@interfaces/care-guidance-flags.model';
import careGuidanceMap from '@utilities/care-guidance-map.utilities';

@Injectable({ providedIn: 'root' })
export class CareGuidanceService {
  private dismissStorageKey: string = 'surgerySupportDismissed';
  private searchRefQueryParam: string = 'search_ref';

  constructor(
    private router: Router,
    private routeUtils: RouteUtilities,
    private storageUtils: StorageUtilities,
    private membersService: MembersService
  ) {}

  public getEligibility(): Observable<CareGuidanceEligibility> {
    return this.membersService.member.pipe(
      filter((member) => !!member),
      map((member: Member) => member?.care_guidance)
    );
  }

  /**
   * Get eligibility based on care guidance type.
   * @param type: string (current options are surgery-support or expert-medical)
   * @returns Observable<CareGuidanceFlags>
   */
  public getEligibilityByType(type: string): Observable<CareGuidanceFlags> {
    const mappedType = careGuidanceMap[type];
    return this.getEligibility().pipe(
      map((eligibility: CareGuidanceEligibility) => eligibility[mappedType])
    );
  }

  public navigateToPage(route: string): void {
    this.router.navigate([`/${route}`], {
      queryParams: {
        search_ref: this.router.routerState.snapshot.url,
      },
      queryParamsHandling: 'merge',
    });
  }

  public getSearchRefUrl(route: ActivatedRoute): string {
    return decodeURIComponent(
      route.snapshot.queryParams[this.searchRefQueryParam] || '/'
    );
  }

  public dismissPage(url: string): void {
    if (this.isPageDismissed(url)) {
      return;
    }
    const path = this.getPath(url);
    const dismissed = this.getDismissed();
    dismissed.push(path);
    this.storageUtils.sessionStorageSet(this.dismissStorageKey, dismissed);
  }

  public navigateToUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  public isPageDismissed(url?: string): boolean {
    const path = this.getPath(url);
    const dismissed = this.getDismissed();
    return dismissed.includes(path);
  }

  private getDismissed(): string[] {
    return this.storageUtils.sessionStorageGet(this.dismissStorageKey) || [];
  }

  private getPath(url: string): string {
    const parsedUrl = this.routeUtils.parseUrl(url);
    return parsedUrl.pathObject.segments.slice(0, 3).join('/').toLowerCase();
  }
}
