import { PrsQuestionAttributes } from './prs.interface.question-attributes';
import { PrsQuestionMetadata } from './prs.interface.question-metadata';

export class PrsQuestion {
  type: string;
  id: number; // Internal identifier
  name: string; // Name of the question, i.e. Cleanliness
  kind: string; // Kind of question: N = numeric (rating), T = text (review), B = boolean, U = unlimited numeric
  display_title: string; // Title of the question for display purposes
  display_text: string; // Text of the question
  required: boolean; // Whether or not the question is required to be answered for a review to be considered valid
  slope: number; // Slope of the question (quantized value = value * slope + intercept)<br/>
  intercept: number; // Intercept of the question (quantized value = value * slope + intercept)<br/>
  metadata: PrsQuestionMetadata; // Arbitrary metadata associated with the question

  constructor(
    id: number | string,
    attributes: PrsQuestionAttributes,
    type?: string
  ) {
    this.type = type || 'questions';
    this.id = parseInt(String(id), 10);
    this.name = attributes.name;
    this.kind = attributes.kind;
    this.display_title = attributes.display_title;
    this.display_text = attributes.display_text;
    this.slope = attributes.slope;
    this.intercept = attributes.intercept;
    this.required = attributes.required;
    this.metadata = new PrsQuestionMetadata(attributes.metadata);
  }
}
