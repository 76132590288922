<div
  class="platform-balances"
  [@fadeIn]="startAnimation ? 'in' : 'out'"
  (@fadeIn.done)="onFadeInDone($event)"
>
  <!-- Desktop -->
  <div
    class="desktop-balances"
    [ngClass]="{ 'animation-done': bubbleInDone }"
    fxHide.lt-md
    [@bubbleIn]="fadeInDone ? 'in' : 'out'"
    (@bubbleIn.done)="bubbleInDone = $event.toState === 'in'"
  >
    <!-- Heading -->
    <div
      class="balances-heading margin bottom-3x"
      *ngIf="showHeading"
      data-cy="balances-grid-heading"
    >
      <h4 class="text-bold">{{ translations.heading }}</h4>
      <div>
        {{ translations.subHeading }}
        <a
          [href]="translations.callToActionUrl"
          target="_blank"
          class="text-nowrap"
          *ngIf="translations.callToActionText && translations.callToActionUrl"
        >
          {{ translations.callToActionText }}
          <mat-icon
            class="call-to-action-icon"
            svgIcon="dls-windows-open-light"
          >
          </mat-icon>
        </a>
      </div>
    </div>

    <!-- Balances Grid -->
    <div class="balances-grid text-nowrap" fxLayout="row">
      <div class="pad right-2x bottom-1x" fxFlexAlign="end">
        <div
          class="pad top-3x bottom-3x animate-bubble"
          data-cy="balances-grid-member-balance"
        >
          <div
            class="member-name text-emphasis"
            data-cy="balances-grid-member-name"
          >
            {{ translations.individualName }}
          </div>
          <div class="member-spent text-small text-light margin top-1x">
            {{ translations.spent }}:
            <strong class="text-medium">{{
              individualSpend | currency: 'USD':'symbol':'1.0-0'
            }}</strong>
          </div>
        </div>
        <div
          class="pad top-3x bottom-3x animate-bubble"
          *ngIf="familyEnabled"
          data-cy="balances-grid-family-balance"
        >
          <div
            class="member-name text-emphasis"
            data-cy="balances-grid-family-name"
          >
            {{ translations.family }}
          </div>
          <div class="member-spent text-small text-light margin top-1x">
            {{ translations.spent }}:
            <strong class="text-medium">{{
              familySpend | currency: 'USD':'symbol':'1.0-0'
            }}</strong>
          </div>
        </div>
      </div>

      <!-- Tier -->
      <div
        class="pad top-3x right-1x left-1x"
        *ngFor="let tier of balances; let i = index"
        [ngClass]="{ 'background-color-medium': tier.preferred }"
        fxFlex
        [attr.data-cy]="'balances-grid-tiers-' + i"
      >
        <h4 class="text-center" *ngIf="isTiered">
          <span class="tier margin right-1x">
            <img
              [attr.src]="assetPath + 'tier-' + tier.tier_number + '-icon.svg'"
              alt=""
            />
          </span>
          <span>{{ translations['tier' + tier.tier_number + 'Name'] }}</span>
        </h4>
        <div class="text-center" *ngIf="isTiered">
          <span class="short-sep background-color-dark"></span>
        </div>
        <div class="margin top-2x" fxLayout="row" fxLayoutAlign="center center">
          <div class="text-micro text-center pad bottom-1x" fxFlex>
            {{ translations.deductible }}
          </div>
          <div class="text-micro text-center pad bottom-1x" fxFlex>
            {{ translations.outOfPocket }}
          </div>
        </div>
        <!-- Individual -->
        <div
          class="row-sep pad top-3x right-1x bottom-3x left-1x"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="24px"
        >
          <!-- Individual Deductible -->
          <div class="text-center animate-bubble" fxFlex>
            <div
              class="balance-remaining display-inline-block"
              *ngIf="!tier.individual_deductible_met"
            >
              <div class="text-right display-inline-block margin right-1x">
                <span class="display-inline-block text-color-dark">
                  <h2 [ngClass]="{ 'text-color-detract': !tier.preferred }">
                    {{
                      tier.individual_deductible_remaining
                        | currency: 'USD':'symbol':'1.0-0'
                    }}
                  </h2>
                  <div
                    class="text-uppercase text-micro text-light"
                    [ngClass]="{ 'text-color-underemphasis': !tier.preferred }"
                  >
                    {{ translations.remaining }}
                  </div>
                </span>
              </div>
              <div class="display-inline-block">
                <span
                  class="text-right display-inline-block text-color-underemphasis total-amount"
                >
                  <span class="text-light"
                    >/{{
                      tier.individual_deductible_limit
                        | currency: 'USD':'symbol':'1.0-0'
                    }}</span
                  ><br />
                  <span class="text-uppercase text-micro text-light">{{
                    translations.total
                  }}</span>
                </span>
              </div>
            </div>
            <div
              class="balance-met text-color-incentive"
              *ngIf="tier.individual_deductible_met"
              data-cy="balances-grid-individual-deductible-met"
            >
              <div><i class="icon-check-circle" aria-hidden="true"></i></div>
              <div>
                <span class="text-bold">{{
                  tier.individual_deductible_limit
                    | currency: 'USD':'symbol':'1.0-0'
                }}</span>
                <span class="text-uppercase"> {{ translations.met }}</span>
              </div>
            </div>
          </div>
          <!-- Individual OPX -->
          <div class="text-center animate-bubble" fxFlex>
            <div
              class="display-inline-block"
              *ngIf="!tier.individual_opx_met"
              data-cy="balances-grid-individual-opx-remaining"
            >
              <div class="text-right display-inline-block margin right-1x">
                <span class="display-inline-block text-color-dark">
                  <h2
                    class="text-light"
                    [ngClass]="{ 'text-color-detract': !tier.preferred }"
                  >
                    {{
                      tier.individual_opx_remaining
                        | currency: 'USD':'symbol':'1.0-0'
                    }}
                  </h2>
                  <div
                    class="text-uppercase text-micro text-light"
                    [ngClass]="{ 'text-color-underemphasis': !tier.preferred }"
                  >
                    {{ translations.remaining }}
                  </div>
                </span>
              </div>
              <div class="display-inline-block">
                <span
                  class="text-right display-inline-block text-color-underemphasis total-amount"
                >
                  <span class="text-light"
                    >/{{
                      tier.individual_opx_limit
                        | currency: 'USD':'symbol':'1.0-0'
                    }}</span
                  ><br />
                  <span class="text-uppercase text-micro text-light">{{
                    translations.total
                  }}</span>
                </span>
              </div>
            </div>
            <div
              class="balance-met text-color-incentive"
              *ngIf="tier.individual_opx_met"
              data-cy="balances-grid-individual-opx-met"
            >
              <div><i class="icon-check-circle" aria-hidden="true"></i></div>
              <div>
                <span class="text-bold">{{
                  tier.individual_opx_limit | currency: 'USD':'symbol':'1.0-0'
                }}</span>
                <span class="text-uppercase"> {{ translations.met }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Family -->
        <div
          class="row-sep pad top-3x right-1x bottom-3x left-1x"
          *ngIf="familyEnabled"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="24px"
        >
          <!-- Family Deductible -->
          <div class="text-center animate-bubble" fxFlex>
            <div
              class="display-inline-block"
              *ngIf="!tier.family_deductible_met"
            >
              <div class="text-right display-inline-block margin right-1x">
                <span class="display-inline-block text-color-dark">
                  <h2 [ngClass]="{ 'text-color-detract': !tier.preferred }">
                    {{
                      tier.family_deductible_remaining
                        | currency: 'USD':'symbol':'1.0-0'
                    }}
                  </h2>
                  <div
                    class="text-uppercase text-micro text-light"
                    [ngClass]="{ 'text-color-underemphasis': !tier.preferred }"
                  >
                    {{ translations.remaining }}
                  </div>
                </span>
              </div>
              <div class="display-inline-block">
                <span
                  class="text-right display-inline-block text-color-underemphasis total-amount"
                >
                  <span class="text-light"
                    >/{{
                      tier.family_deductible_limit
                        | currency: 'USD':'symbol':'1.0-0'
                    }}</span
                  ><br />
                  <span class="text-uppercase text-micro text-light">{{
                    translations.total
                  }}</span>
                </span>
              </div>
            </div>
            <div
              class="balance-met text-color-incentive"
              *ngIf="tier.family_deductible_met"
              data-cy="balances-grid-family-deductible-met"
            >
              <div><i class="icon-check-circle" aria-hidden="true"></i></div>
              <div>
                <span class="text-bold">{{
                  tier.family_deductible_limit
                    | currency: 'USD':'symbol':'1.0-0'
                }}</span>
                <span class="text-uppercase"> {{ translations.met }}</span>
              </div>
            </div>
          </div>
          <!-- Family OPX -->
          <div class="text-center animate-bubble" fxFlex>
            <div
              class="display-inline-block"
              *ngIf="!tier.family_opx_met"
              data-cy="balances-grid-family-remaining"
            >
              <div class="text-right display-inline-block margin right-1x">
                <span class="display-inline-block text-color-dark">
                  <h2
                    class="text-light"
                    [ngClass]="{ 'text-color-detract': !tier.preferred }"
                  >
                    {{
                      tier.family_opx_remaining
                        | currency: 'USD':'symbol':'1.0-0'
                    }}
                  </h2>
                  <div
                    class="text-uppercase text-micro text-light"
                    [ngClass]="{ 'text-color-underemphasis': !tier.preferred }"
                  >
                    {{ translations.remaining }}
                  </div>
                </span>
              </div>
              <div class="display-inline-block">
                <span
                  class="text-right display-inline-block text-color-underemphasis total-amount"
                >
                  <span class="text-light"
                    >/{{
                      tier.family_opx_limit | currency: 'USD':'symbol':'1.0-0'
                    }}</span
                  ><br />
                  <span class="text-uppercase text-micro text-light">{{
                    translations.total
                  }}</span>
                </span>
              </div>
            </div>
            <div
              class="balance-met text-color-incentive"
              *ngIf="tier.family_opx_met"
              data-cy="balances-grid-family-opx-met"
            >
              <div><i class="icon-check-circle" aria-hidden="true"></i></div>
              <div>
                <span class="text-bold">{{
                  tier.family_opx_limit | currency: 'USD':'symbol':'1.0-0'
                }}</span>
                <span class="text-uppercase"> {{ translations.met }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div
    class="mobile-balances"
    fxHide.gt-sm
    [@flyIn]="fadeInDone ? 'in' : 'out'"
  >
    <div
      class="balances-heading animate-fly"
      [ngClass]="{ 'section-card': useMobileCards }"
      *ngIf="showHeading"
    >
      <h4 class="text-bold">{{ translations.heading }}</h4>
      <div>
        {{ translations.subHeading }}
        <a
          [href]="translations.callToActionUrl"
          target="_blank"
          class="text-nowrap"
          *ngIf="translations.callToActionText && translations.callToActionUrl"
        >
          {{ translations.callToActionText }}
          <i
            class="icon-windows-open call-to-action-icon"
            aria-hidden="true"
          ></i>
        </a>
      </div>
    </div>

    <!-- Tier -->
    <div
      class="animate-fly text-nowrap"
      *ngFor="let tier of balances"
      [ngClass]="{
        'section-card': useMobileCards,
        'margin top-2x': !useMobileCards
      }"
    >
      <h4 class="text-center" *ngIf="isTiered">
        <span class="tier margin right-1x">
          <img
            [attr.src]="assetPath + 'tier-' + tier.tier_number + '-icon.svg'"
            alt=""
          />
        </span>
        <span>{{ translations['tier' + tier.tier_number + 'Name'] }}</span>
      </h4>
      <div class="text-center" *ngIf="isTiered">
        <span
          class="short-sep"
          [ngClass]="{
            'background-color-black': tier.preferred,
            'background-color-dark': !tier.preferred
          }"
        >
        </span>
      </div>
      <div class="margin top-1x" fxLayout="row" fxLayoutAlign="center center">
        <div class="text-center text-medium pad bottom-1x" fxFlex>
          {{ translations.individualName }}
        </div>
        <div
          class="text-center text-medium pad bottom-1x"
          *ngIf="familyEnabled"
          fxFlex
        >
          {{ translations.family }}
        </div>
      </div>
      <!-- Deductible -->
      <div
        class="mobile-section-heading text-center text-uppercase text-micro text-color-dark text-light background-color-dark"
      >
        {{ translations.deductible }}
      </div>
      <div
        class="pad top-1x bottom-1x"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <!-- Individual Deductible -->
        <div class="text-center pad top-1x bottom-1x" fxFlex>
          <div
            class="balance-remaining"
            *ngIf="!tier.individual_deductible_met"
          >
            <h3 [ngClass]="{ 'text-color-detract': !tier.preferred }">
              {{
                tier.individual_deductible_remaining
                  | currency: 'USD':'symbol':'1.0-0'
              }}
              <span
                class="text-uppercase text-micro text-light text-vertical-middle"
                [ngClass]="{
                  'text-color-dark': tier.preferred,
                  'text-color-underemphasis': !tier.preferred
                }"
              >
                {{ translations.remaining }}
              </span>
            </h3>
            <div class="text-color-underemphasis text-light margin top-1x">
              {{
                tier.individual_deductible_limit
                  | currency: 'USD':'symbol':'1.0-0'
              }}
              <span
                class="text-uppercase text-micro text-light text-vertical-middle"
                >{{ translations.total }}</span
              >
            </div>
          </div>
          <div
            class="balance-met text-color-incentive"
            *ngIf="tier.individual_deductible_met"
          >
            <div><i class="icon-check-circle" aria-hidden="true"></i></div>
            <div>
              <span class="text-bold">{{
                tier.individual_deductible_limit
                  | currency: 'USD':'symbol':'1.0-0'
              }}</span>
              <span class="text-uppercase"> {{ translations.met }}</span>
            </div>
          </div>
        </div>
        <!-- Family Deductible -->
        <div
          class="col-sep text-center pad top-1x bottom-1x"
          *ngIf="familyEnabled"
          fxFlex
        >
          <div *ngIf="!tier.family_deductible_met">
            <h3 [ngClass]="{ 'text-color-detract': !tier.preferred }">
              {{
                tier.family_deductible_remaining
                  | currency: 'USD':'symbol':'1.0-0'
              }}
              <span
                class="text-uppercase text-micro text-color-dark text-light text-vertical-middle"
                [ngClass]="{
                  'text-color-dark': tier.preferred,
                  'text-color-underemphasis': !tier.preferred
                }"
              >
                {{ translations.remaining }}
              </span>
            </h3>
            <div class="text-color-underemphasis text-light margin top-1x">
              {{
                tier.family_deductible_limit | currency: 'USD':'symbol':'1.0-0'
              }}
              <span
                class="text-uppercase text-micro text-light text-vertical-middle"
                >{{ translations.total }}</span
              >
            </div>
          </div>
          <div
            class="balance-met text-color-incentive"
            *ngIf="tier.family_deductible_met"
          >
            <div><i class="icon-check-circle" aria-hidden="true"></i></div>
            <div>
              <span class="text-bold">{{
                tier.family_deductible_limit | currency: 'USD':'symbol':'1.0-0'
              }}</span>
              <span class="text-uppercase"> {{ translations.met }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Out of Pocket Max -->
      <div
        class="mobile-section-heading text-center text-uppercase text-micro text-color-dark text-light background-color-dark"
      >
        {{ translations.outOfPocket }}
      </div>
      <div
        class="pad top-1x bottom-1x"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <!-- Individual OPX -->
        <div class="text-center pad top-1x bottom-1x" fxFlex>
          <div *ngIf="!tier.individual_opx_met">
            <h3
              class="text-light"
              [ngClass]="{ 'text-color-detract': !tier.preferred }"
            >
              {{
                tier.individual_opx_remaining | currency: 'USD':'symbol':'1.0-0'
              }}
              <span
                class="text-uppercase text-micro text-color-dark text-light text-vertical-middle"
                [ngClass]="{
                  'text-color-dark': tier.preferred,
                  'text-color-underemphasis': !tier.preferred
                }"
              >
                {{ translations.remaining }}
              </span>
            </h3>
            <div class="text-color-underemphasis text-light margin top-1x">
              {{ tier.individual_opx_limit | currency: 'USD':'symbol':'1.0-0' }}
              <span
                class="text-uppercase text-micro text-light text-vertical-middle"
                >{{ translations.total }}</span
              >
            </div>
          </div>
          <div
            class="balance-met text-color-incentive"
            *ngIf="tier.individual_opx_met"
          >
            <div><i class="icon-check-circle" aria-hidden="true"></i></div>
            <div>
              <span class="text-bold">{{
                tier.individual_opx_limit | currency: 'USD':'symbol':'1.0-0'
              }}</span>
              <span class="text-uppercase"> {{ translations.met }}</span>
            </div>
          </div>
        </div>
        <!-- Family OPX -->
        <div
          class="col-sep text-center pad top-1x bottom-1x"
          *ngIf="familyEnabled"
          fxFlex
        >
          <div *ngIf="!tier.family_opx_met">
            <h3
              class="text-light"
              [ngClass]="{ 'text-color-detract': !tier.preferred }"
            >
              {{ tier.family_opx_remaining | currency: 'USD':'symbol':'1.0-0' }}
              <span
                class="text-uppercase text-micro text-color-dark text-light text-vertical-middle"
                [ngClass]="{
                  'text-color-dark': tier.preferred,
                  'text-color-underemphasis': !tier.preferred
                }"
              >
                {{ translations.remaining }}
              </span>
            </h3>
            <div class="text-color-underemphasis text-light margin top-1x">
              {{ tier.family_opx_limit | currency: 'USD':'symbol':'1.0-0' }}
              <span
                class="text-uppercase text-micro text-light text-vertical-middle"
                >{{ translations.total }}</span
              >
            </div>
          </div>
          <div
            class="balance-met text-color-incentive"
            *ngIf="tier.family_opx_met"
          >
            <div><i class="icon-check-circle" aria-hidden="true"></i></div>
            <div>
              <span class="text-bold">{{
                tier.family_opx_limit | currency: 'USD':'symbol':'1.0-0'
              }}</span>
              <span class="text-uppercase"> {{ translations.met }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
