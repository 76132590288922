export enum SearchMethods {
    DirectLink = 'Direct Link',
    FullText = 'Search Bar',
    Autosuggest = 'Autosuggest',
    CommonSearchLink = 'Common Search Link',
    BrowseByCategory = 'Browse by Category',
    SmartShopperHomePage = 'Smart Shopper - Home Page',
    SmartShopperHomePageViewAll = 'Smart Shopper - Home Page - View All Procedures',
    SmartShopperMarketingPage = 'Smart Shopper - Marketing Page',
    ProfilePageProcedureCost = 'Profile Page - Procedure Cost Module',
    RxSavingsMedFinder = 'RX Savings / Medication Finder',
    RxCouponsMedFinder = 'RX Coupons / Medication Finder',
    Tinc = 'TinC',
}
