import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { SlideOpenCloseAnimation } from '../animations/animation.slide-open-close';
import { ExpandableSectionDisplay } from './expandable-section.display.interface';

@Component({
  selector: 'platform-expandable-section',
  templateUrl: './expandable-section.component.html',
  styleUrls: ['./expandable-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  animations: [SlideOpenCloseAnimation],
})
export class ExpandableSectionComponent {
  @Input() public collapsedText: string;
  @Input() public display: ExpandableSectionDisplay = 'section-header';
  @Input() public expandDirection: 'up' | 'down' = 'down';
  @Input() public expandedText: string;
  @Input() public expand = false;
  @Input() public fixed = false;

  @Output() public expanded: EventEmitter<void> = new EventEmitter();
  @Output() public collapsed: EventEmitter<void> = new EventEmitter();

  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.fixed) {
      this.toggleExpansion();
    }
  }

  private toggleExpansion(): void {
    this.expand = !this.expand;
    if (this.expand) {
      this.expanded.emit();
    } else {
      this.collapsed.emit();
    }
  }
}
