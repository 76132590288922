import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageContainerComponent } from './page-container.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  imports: [CommonModule, FlexLayoutModule],
  declarations: [PageContainerComponent],
  exports: [PageContainerComponent],
})
export class PageContainerModule {}
