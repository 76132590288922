import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
@Directive({
  selector: '[zelisFocus]',
})
export class FocusDirective implements OnChanges {
  @Input('zelisFocus') public focusValue!: boolean;

  constructor(public elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['focusValue'] && changes['focusValue'].currentValue) {
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
      });
    }
  }
}
