import { ClientConfigs } from '../interfaces/client-configs.interface';
export class AppConfig {
  public title: string;
  public client: string;
  public environment: string;
  public variant: string;
  public api_host: string;
  public auth_host: string;
  public proxy_host: string;
  public revision_sha: string;
  public branch_tag: string;
  public client_configs: ClientConfigs;
  public rollbar_token: string;

  // App is being E2E tested
  public e2e: boolean;
  public e2e_allow_recording: boolean;

  constructor(obj?: any) {
    this.setConfig(obj);
  }

  public setConfig(obj?: any): void {
    obj = obj || {};
    this.title = obj.title || 'Sapphire365';
    this.client = obj.client || 'vch3';
    this.environment = obj.environment || 'qa';
    this.variant = obj.variant || '';
    this.api_host = obj.api_host || '';
    this.auth_host = obj.auth_host || '';
    this.proxy_host = obj.proxy_host || '';
    this.revision_sha = obj.revision_sha || '';
    this.branch_tag = obj.branch_tag || '';
    this.client_configs = obj.client_configs || {};
    this.rollbar_token = obj.rollbar_token || '';
    this.e2e = obj.e2e !== undefined ? obj.e2e : false;
    this.e2e_allow_recording =
      obj.e2e_allow_recording !== undefined ? obj.e2e_allow_recording : false;
  }

  public isClient(client: string): boolean {
    return this.client.toLowerCase() === client.toLowerCase();
  }
}
