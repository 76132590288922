export class ConfigurationParams {
  network_id?: string;
  geo_location?: string;
  locale?: string;
  identifier?: string;
  alphaPrefCd?: string;

  constructor(obj?: ConfigurationParams) {
    if (obj) {
      this.network_id = obj.network_id || null;
      this.geo_location = obj.geo_location || null;
      this.locale = obj.locale || 'en';
      this.identifier = obj.identifier || null;
      this.alphaPrefCd = obj.alphaPrefCd || null;
    }
  }
}
