export class TelehealthDetails {
  teleHealthCd?: string;
  teleHealthVendCd?: string;
  teleHealthProdType?: string;
  teleHealthUrl?: string;

  constructor(obj: TelehealthDetails) {
    obj = obj || {};
    this.teleHealthCd = obj.teleHealthCd || null;
    this.teleHealthVendCd = obj.teleHealthVendCd || null;
    this.teleHealthProdType = obj.teleHealthProdType || null;
    this.teleHealthUrl = obj.teleHealthUrl || null;
  }
}
