import { FeaturesService } from './features/features.service';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import { switchMap, map, first, catchError } from 'rxjs/operators';
import { MembersService } from './members.service';
import { SettingsService } from './settings.service';
import { Member } from '../components/members';

@Injectable({
  providedIn: 'root',
})
export class StickyFabService {
  public openBubble: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private featuresService: FeaturesService,
    private settingsService: SettingsService,
    private membersService: MembersService
  ) {}

  public fabDisabled(): Observable<boolean> {
    return forkJoin([this.getMember(), this.getFabConfig()]).pipe(
      switchMap(([member, fabSuppressed]) =>
        this.shouldBeDisabled(member, fabSuppressed)
      )
    );
  }

  private shouldBeDisabled(
    member: Member,
    fabSuppressed: boolean
  ): Observable<boolean> {
    const memberIneligible = member ? !member.incentives_enabled : true;
    if (fabSuppressed || memberIneligible) {
      return of(true);
    }
    return of(false);
  }

  private getFabConfig(): Observable<boolean> {
    return this.settingsService.getSetting('suppress_ss_contact_fab').pipe(
      first(),
      map((suppressed: boolean) => !(suppressed === false))
    );
  }

  private getMember(): Observable<Member> {
    return this.membersService.member.pipe(
      catchError(() => of(null)),
      first((member) => member !== undefined)
    );
  }
}
