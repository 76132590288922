import { map, switchMap, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppParamsService } from '../app.params.service';
import { AppParams } from '../../interfaces/app.interface.appParams';

@Injectable({
  providedIn: 'root',
})
export class ProviderTypesService {
  public specialties: BehaviorSubject<string[]> = new BehaviorSubject(
    undefined
  );

  constructor(private http: HttpClient, private appParams: AppParamsService) {}

  public requestProviderTypes(): Observable<string[]> {
    return this.appParams.resolved.pipe(
      first((params) => params !== null),
      switchMap((params: AppParams) => {
        return this.http
          .get('/api/provider_types.json', {
            params: this.buildParams(params),
          })
          .pipe(
            map((results) => {
              this.specialties.next(results['provider_types']);
              return results['provider_types'];
            })
          );
      })
    );
  }

  private buildParams(params: AppParams): HttpParams {
    let requestParams = new HttpParams({
      fromObject: {
        network_id: params.network_id,
      },
    });
    requestParams = requestParams.set(
      'facet[provider_type_description]',
      'true'
    );
    return requestParams;
  }
}
