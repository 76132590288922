import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { PageTransition } from '../../interfaces/page-transition.interface';
import { SearchFilterOption } from '@interfaces/search-filter-option.model';
import { SearchSortOption } from '@interfaces/search-sort-option.model';

@Component({
  selector: 'app-hierarchy-list-nested-options',
  templateUrl: './hierarchy-list-nested-options.component.html',
  styleUrls: ['./hierarchy-list-nested-options.component.scss'],
})
export class HierarchyListNestedOptionsComponent implements OnInit {
  @Input() filter: any;
  @Input() type: string;
  @Input() isSpecialtySearch: boolean = false;
  @Input() searchParamType: string = '';
  @Output() public goToOverlayPage: EventEmitter<PageTransition> =
    new EventEmitter();

  public title: string;
  public options: SearchFilterOption[] | SearchSortOption[];
  private selectedFilter: string;

  constructor(private sheetRef: MatBottomSheetRef) {}

  ngOnInit() {
    this.setValues();
  }

  public facetOptionSelected(option: any): void {
    const selection =
      this.type === 'filter' && !this.isSpecialtySearch
        ? { [this.filter.facet]: option.value }
        : option;
    this.sheetRef.dismiss({ selection, category: this.type });
  }

  public isSelectedOption(option: any): any {
    option = { ... option };
    if (this.type === 'sort') {
      option.selected = option.query === this.selectedFilter;
    } else {
      option.selected = (
        option.value === this.selectedFilter ||
        (!option.value && !this.selectedFilter)
      );
    }
    return option;
  }

  public goBack(): void {
    if (this.type === 'sort') {
      this.sheetRef.dismiss();
    }
    this.goToOverlayPage.emit({ page: 'list', direction: 'back' });
  }

  public trackByOption(index: number, option: any): string {
    return option.name;
  }

  private setValues(): void {
    this.selectedFilter = this.filter.selected;
    if (this.type === 'sort') {
      this.selectedFilter = this.filter.selected.query;
      this.title = 'search_sort_by';
      this.options = this.filter[this.searchParamType];
    } else {
      this.title =
        this.filter.facet === 'radius'
          ? 'search_filter_radius'
          : this.filter.name;
      this.options = this.filter.options;
    }
    this.options = this.options.map(option => this.isSelectedOption(option));
  }
}
