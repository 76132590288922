import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MetaInformationComponent } from './meta-information.component';

@NgModule({
  declarations: [MetaInformationComponent],
  imports: [CommonModule, TranslateModule],
  providers: [],
  exports: [MetaInformationComponent],
})
export class MetaInformationModule {}
