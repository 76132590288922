import { Injectable } from '@angular/core';
import { SearchFiltersSettings } from '../components/+search/classes/search-filters-settings.class';
import { Resolve } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { NetworksService } from '../services/networks.service';

@Injectable()
export class SearchSettingsResolver implements Resolve<boolean> {
  constructor(
    private searchFiltersSettings: SearchFiltersSettings,
    private networksService: NetworksService
  ) {}

  resolve(): Promise<any> {
    return new Promise((resolve) => {
      this.networksService.resolvedNetwork
        .pipe(
          filter((network) => !!network),
          distinctUntilChanged()
        )
        .subscribe((network) => {
          this.searchFiltersSettings.resolved
            .pipe(filter((resolved) => !!resolved))
            .subscribe(() => resolve(true));
        });
    });
  }
}
