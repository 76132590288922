import { BvaService } from '../bva.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';

@Component({
  selector: 'app-bva-intended-provider',
  templateUrl: './intended-provider.component.html',
  styleUrls: ['./intended-provider.component.scss'],
})
export class BvaIntendedProviderComponent {
  @Input() public results: any;
  @Input() public provider: any;
  @Output() public clearIntendedProvider = new EventEmitter<boolean>();

  constructor(
    public navigationService: NavigationService,
    private bvaService: BvaService
  ) {}

  public changeProvider(): void {
    this.bvaService.clearIntendedProvider();
    this.provider = null;
    this.clearIntendedProvider.emit(true);
  }

  public hasCost(): boolean {
    return !!(this.provider.member_cost && this.provider.procedure_cost);
  }

  public getCost(): any {
    // TODO account for mleLite scenario
    return {
      amount:
        '$' +
        Math.round(parseFloat(this.provider.member_cost)).toLocaleString(),
      translation: 'profile_cost_member_link',
    };
  }

  public getIncentiveAmount(): string {
    if (this.provider.incentive_amount) {
      return (
        '$' +
        Number(parseInt(this.provider.incentive_amount, 10)).toLocaleString()
      );
    }
  }

  public getInsuranceCost(): string {
    const totalCost =
      parseInt(this.provider.procedure_cost, 10) -
      parseInt(this.provider.member_cost, 10);
    return '$' + Number(totalCost).toLocaleString();
  }

  public getTotalCost(): string {
    return (
      '$' + Number(parseInt(this.provider.procedure_cost, 10)).toLocaleString()
    );
  }
}
