import { BvaService } from '../bva.service';
import { Component } from '@angular/core';
import {
  getIncentiveAmount,
  getCostData,
} from '../../../../utilities/provider.utilities';
import { NavigationService } from '../../../../services/navigation.service';

@Component({
  selector: 'app-bva-recommended-providers',
  templateUrl: './recommended-providers.component.html',
  styleUrls: ['./recommended-providers.component.scss'],
})
export class BvaRecommendedProvidersComponent {
  public getIncentiveAmount = getIncentiveAmount;
  public getCostData = getCostData;

  constructor(
    public bvaService: BvaService,
    public navigationService: NavigationService
  ) {}
}
