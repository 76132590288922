export class BillingCodePreRequisite {
  preAuthorization: boolean;
  concurrentReview: boolean;
  stepTherapy: boolean;
  loading?: boolean;
  error?: string;

  constructor(obj?: any) {
    this.preAuthorization = obj?.preAuthorization || false;
    this.concurrentReview = obj?.concurrentReview || false;
    this.stepTherapy = obj?.stepTherapy || false;
    this.loading = obj?.loading || false;
    this.error = obj?.error || null;
  }
}
