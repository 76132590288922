import {
  Directive,
  Input,
  OnInit,
  HostListener,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { GoogleTagManagerService } from '../../services/analytics/google-tag-manager.service';

/**
 * GtmDirective
 *   gtmOnShow: Logs on element load.
 *   gtmOnClick:
 *     When true: Logs on element click.
 *     When class name, example '.foo': Logs on child element click that has a class of "foo".
 *     When ID, example '#foo': Logs on child element click that has an ID of "foo".
 *     When tag name, example 'a': Logs on child element click that is an 'a' tag.
 *   gtmOnClickIf: Only logs click event when true.
 *   gtmOnTrue: Logs when value becomes true.
 *   gtmData: Data to log.
 */
@Directive({
  selector: '[appGtm]',
})
export class GtmDirective implements OnInit, OnChanges {
  @Input() gtmData;
  @Input() gtmOnShow: boolean = false;
  @Input() gtmOnClick: boolean | string = false;
  @Input() gtmOnClickIf: boolean = true;
  @Input() gtmOnTrue: boolean = false;

  constructor(private googleTagManagerService: GoogleTagManagerService) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.handleOnClick(event);
  }

  ngOnInit() {
    this.handleOnShow();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleOnTrue(changes);
  }

  private handleOnClick(event: MouseEvent): void {
    if (this.targetClicked(this.gtmOnClick, this.gtmOnClickIf, event)) {
      this.googleTagManagerService.pushToDataLayer(this.gtmData);
    }
  }

  private handleOnShow(): void {
    if (this.gtmOnShow) {
      this.googleTagManagerService.pushToDataLayer(this.gtmData);
    }
  }

  private handleOnTrue(changes: SimpleChanges): void {
    if (changes['gtmOnTrue'] && changes['gtmOnTrue'].currentValue) {
      this.googleTagManagerService.pushToDataLayer(this.gtmData);
    }
  }

  private targetClicked(
    target: boolean | string,
    whenTrue: boolean,
    event: MouseEvent
  ): boolean {
    // Ignore click
    if (!target || !whenTrue) {
      return false;
    }
    // Directive element clicked
    if (target === true) {
      return true;
    }
    // Use target for clicked determination
    const targetEl = this.getTargetElement(event);
    if (targetEl) {
      if (this.targetIsClass(target)) {
        return this.classTargetClicked(target, targetEl);
      } else if (this.targetIsId(target)) {
        return this.idTargetClicked(target, targetEl);
      } else {
        return this.tagTargetClicked(target, targetEl);
      }
    }
    return false;
  }

  private getTargetElement(event: MouseEvent): any {
    return event.target || event.srcElement || event.currentTarget;
  }

  private targetIsClass(target: string): boolean {
    return target.substring(0, 1) === '.';
  }

  private targetIsId(target: string): boolean {
    return target.substring(0, 1) === '#';
  }

  private classTargetClicked(target: string, targetEl: any): boolean {
    const klass = target.substring(1);
    const classes = String(targetEl['className']).split(' ');
    if (classes.indexOf(klass) !== -1) {
      return true;
    }
    return false;
  }

  private idTargetClicked(target: string, targetEl: any): boolean {
    const id = target.substring(1);
    if (targetEl['id'] === id) {
      return true;
    }
    return false;
  }

  private tagTargetClicked(target: string, targetEl: any): boolean {
    if (targetEl['nodeName'] === target.toUpperCase()) {
      return true;
    }
    return false;
  }
}
