import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollCardComponent } from './scroll-card.component';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  exports: [ScrollCardComponent],
  imports: [CommonModule, MatCardModule],
  declarations: [ScrollCardComponent],
})
export class ScrollCardModule {}
