export class CriticalFilters {
  limit?: number;
  radius?: string;
  page?: number;
  sort?: string;
  sort_translation?: string;
  provider_type_description?: string;

  constructor(obj?: CriticalFilters) {
    this.limit = obj.limit || null;
    this.radius = obj.radius || null;
    this.page = obj.page || null;
    this.sort = obj.sort || null;
    this.sort_translation = obj.sort_translation || null;
    this.provider_type_description = obj.provider_type_description || null;
  }
}
