export class PrsQuestionMetadata {
  group?: number | string; // Group number the question should appear in, starting at 1.
  hide?: boolean | string; // Do not show
  text_limit?: number | number; // Limit text input length

  constructor(obj?: PrsQuestionMetadata) {
    obj = obj || {};
    this.group = obj.group ? parseInt(String(obj.group || 1), 10) : 1;
    this.hide = obj.hide === true || obj.hide === 'true' ? true : false;
    this.text_limit = obj.text_limit || null;
  }
}
