import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'platform-fab-peeker',
  templateUrl: './fab-peeker.component.html',
  styleUrls: ['./fab-peeker.component.scss'],
  animations: [
    trigger('peekInOut', [
      state('in', style({ width: '*', opacity: 1 })),
      state('out', style({ width: '0px', opacity: 0 })),
      transition(
        'in <=> out',
        animate('500ms cubic-bezier(0.68, -0.55, 0.265, 1.55)')
      ),
    ]),
  ],
})
export class FabPeekerComponent {
  @Input() public text: string;
  @Input() public color: string;
  @Input()
  public set peek(value: boolean | number) {
    this.setPeek(value);
  }
  public get peek(): boolean | number {
    return this._peek;
  }
  @Input() public peekOnFocus = false;
  @Input() public peekOnHover = false;

  @Output() public peeking: EventEmitter<boolean> = new EventEmitter(true);
  @Output() public clicked: EventEmitter<null> = new EventEmitter(true);

  private _peek: boolean | number = false;
  private peekTimeout: any;

  public onClick(): void {
    this.clicked.emit(null);
  }

  public onFocus(): void {
    if (this.peekOnFocus) {
      this.toggle(true);
    }
  }

  public onBlur(): void {
    if (this.peekOnFocus) {
      this.toggle(false);
    }
  }

  public onMouseEnter(): void {
    if (this.peekOnHover) {
      this.toggle(true);
    }
  }

  public onMouseLeave(): void {
    if (this.peekOnHover) {
      this.toggle(false);
    }
  }

  public toggle(to?: boolean): void {
    if (typeof to === 'boolean') {
      this.peek = to;
    } else {
      this.peek = !this.peek;
    }
    this.peeking.emit(this.peek);
  }

  private setPeek(value: boolean | number): void {
    if (this.peekTimeout) {
      clearTimeout(this.peekTimeout);
    }
    if (typeof value === 'number') {
      this._peek = true;
      this.peekTimeout = setTimeout(() => (this._peek = false), value);
    } else {
      this._peek = value;
    }
  }
}
