import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../services/pat-chat/chat.service';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { StickyFabService } from '../../services/sticky-fab.service';

@Component({
  selector: 'app-sticky-contact-fab',
  templateUrl: './sticky-contact-fab.component.html',
  styleUrls: ['./sticky-contact-fab.component.scss'],
})
export class StickyContactFabComponent implements OnInit {
  public showBubble: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public chatStarted: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showProactiveChat: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private chatService: ChatService,
    private stickyFabService: StickyFabService
  ) {}

  ngOnInit(): void {
    this.chatStarted = this.chatService.chatStarted;
    this.isAvailable = this.chatService.isAvailable;
    this.showProactiveChat = this.chatService.proactiveChat;
    this.showBubble = this.stickyFabService.openBubble;
  }

  public toggleBubble(action?: boolean): void {
    if (action) {
      this.showBubble.next(action);
    } else {
      this.chatService.userOpened.next(true);
      this.showBubble.next(!this.showBubble.getValue());
    }
  }

  public get displayProactive(): Observable<boolean> {
    return combineLatest([this.isAvailable, this.showProactiveChat]).pipe(
      map(([available, showProactive]) => !!(available && showProactive))
    );
  }
}
