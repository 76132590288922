import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GlobalLanguageSelectComponent } from './global-language-select.component';
import { AccountsService } from '../../../services/accounts.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DlsNavButtonModule } from '@zelis/dls/nav-button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    DlsNavButtonModule
  ],
  providers: [AccountsService],
  declarations: [GlobalLanguageSelectComponent],
  exports: [GlobalLanguageSelectComponent],
})
export class GlobalLanguageSelectModule {}
