import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'zelis-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavButtonComponent {
  @Input() label = 'Nav Button';
  @Input() active?: boolean = false;
  @Input() ariaLabel?: string;
  @Input() leadingIcon?: string;
  @Input() trailingIcon?: string;

  @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();

  @HostBinding() class = 'zelis-dls';

  buttonClicked(event: Event) {
    this.clicked.emit(event);
  }
}
