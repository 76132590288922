import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalNetworkDropdownComponent } from './global-network-dropdown.component';
import { FixedTooltipModule } from '../../fixed-tooltip/fixed-tooltip.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { NetworkAlphaPrefixDialogModule } from '../../network-alpha-prefix-dialog/network-alpha-prefix-dialog.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';

@NgModule({
  declarations: [GlobalNetworkDropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    FixedTooltipModule,
    MatDialogModule,
    NetworkAlphaPrefixDialogModule,
    MatBottomSheetModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'wide-panel' }
    }
  ],
  exports: [GlobalNetworkDropdownComponent],
})
export class GlobalNetworkDropdownModule {}
