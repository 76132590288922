import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, filter, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings.service';
import {
  GlobalFooterDisclaimer,
  GlobalFooterLinks,
} from '@zelis/platform-ui-components';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(
    private http: HttpClient,
    private configService: ConfigurationService,
    private settingsService: SettingsService
  ) {}

  public getUpdatedAt(): Observable<string> {
    return this.http.get('/api/info.json').pipe(
      map((results) => {
        if (results && results['info'] && results['info'].length) {
          return results['info'][0].data_loaded_at;
        }
        return '';
      })
    );
  }

  public getDisclaimers(): Observable<GlobalFooterDisclaimer[]> {
    return this.getConfigSig().pipe(
      switchMap((config) => this.fetchDisclaimers(config))
    );
  }

  public getLinks(): Observable<GlobalFooterLinks> {
    return this.getConfigSig().pipe(
      switchMap((config) => this.fetchLinks(config))
    );
  }

  private fetchDisclaimers(config: string): Observable<any> {
    return this.settingsService
      .requestSetting(config, 'disclaimers')
      .pipe(map((settings) => settings.settings[0]['disclaimers']['footer']));
  }

  private fetchLinks(config: string): Observable<any> {
    return this.settingsService
      .requestSetting(config, 'footerLinks')
      .pipe(map((settings) => settings.settings[0]['footerLinks']));
  }

  private getConfigSig(): Observable<any> {
    return this.configService.signature.pipe(filter((sig) => !!sig));
  }
}
