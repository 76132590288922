import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MobileHeaderOverlayComponent } from './mobile-header-overlay.component';
import { RouterModule } from '@angular/router';
import { LoginLinkModule } from '@zelis/platform-ui-components';
import { MobileLanguageModule } from '../mobile-language/mobile-language.module';

@NgModule({
  declarations: [MobileHeaderOverlayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FlexLayoutModule,
    RouterModule,
    LoginLinkModule,
    MobileLanguageModule,
  ],
  providers: [],
  exports: [MobileHeaderOverlayComponent],
})
export class MobileHeaderOverlayModule {}
