<div
  data-cy="global-header-autosuggest-scrim"
  *ngIf="autosuggestOpen && breakpoints.isDesktop"
  class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing fixed"
  [ngClass]="{ 'z-offset': offsetScrim }"
></div>

<div
  class="global-header-autosuggest {{ ccss.getClass('global_autosuggest') }}"
  [ngClass]="{
    mobile: breakpoints.isMobile,
    'autosuggest-full-screen': mobileOverlayOpen
  }"
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start stretch"
>
  <div
    class="autosuggest-form"
    fxFlex.lt-md="noshrink"
    data-cy="global-header-autosuggest.autosuggest-form.mobile-Overlay"
  >
    <ng-container *ngIf="breakpoints.isDesktop">
      <ng-container *ngTemplateOutlet="desktopInput"></ng-container>
    </ng-container>
    <ng-container *ngIf="breakpoints.isMobile">
      <ng-container *ngTemplateOutlet="mobileInput"></ng-container>
    </ng-container>
  </div>

  <span *ngIf="breakpoints.isMobile && mobileOverlayOpen && results?.hasResults">
    <ng-container *ngTemplateOutlet="chipFilters"></ng-container>
  </span>

  <!-- Mobile Results -->
  <div
    class="mobile-autocomplete-results"
    *ngIf="breakpoints.isMobile && mobileOverlayOpen"
    fxFlex="grow"
  >
    <!-- Loading -->
    <div
      class="skeleton-loading pad all-3x"
      *ngIf="isLoading && !results?.hasResults; else mobileList"
    >
      <div>
        <div class="title-loading"></div>
        <div class="pad left-3x top-3x">
          <div class="comment-loading"></div>
          <div class="comment-loading margin top-2x"></div>
        </div>
      </div>
      <div class="margin top-4x">
        <div class="title-loading"></div>
        <div class="pad left-3x top-3x">
          <div class="comment-loading"></div>
          <div class="comment-loading margin top-2x"></div>
        </div>
      </div>
    </div>
    <!-- Results -->
    <div
      [ngClass]="ccss.getClass('global_autosuggest')"
      class="typeahead-container"
    >
      <ng-template #mobileList>
        <span *ngFor="let group of groupOrder; trackBy: trackByFnIndex">
          <mat-optgroup
            [ngClass]="{
              hideLabel:
                group === 'initialPrompt' || group === 'noResultsPrompt',
              'mat-optgroup-hidden': results.categoriesWithResults === 1 || group === 'initialPrompt'
            }"
            [attr.id]="'autosuggest-group-' + group"
            [attr.data-cy]="'autosuggest-group-' + group"
            *ngIf="(selectedChip === 'all_results' || selectedChip === group) && results[group]?.length"
          >
            <label
              *ngIf="categories[group].title && (results.categoriesWithResults > 1 || group === 'recentSearches')"
              [innerHTML]="categories[group].title | translate"
              [ngClass]="{ 'selected-group': group === selectedGroup }"
              class="text-palette-m3-system-gray-35"
              data-cy="autocomplete-input.group-label"
            >
            </label>
            <mat-option
              [value]="result.name"
              (onSelectionChange)="handleSelect(result, result.type, $event)"
              [disabled]="
                group === 'initialPrompt' || group === 'noResultsPrompt'
              "
              class="p-0 h-auto"
              *ngFor="
                let result of results[group] | slice: 0:categories[group].limit;
                trackBy: trackByFnId;
                let i = index
              "
              [attr.aria-label]="result.name + ' ' + result.description"
              [attr.data-cy]="'autosuggest.autocomplete-option.' + i"
            >
              <mat-list>
                <ng-container
                  *ngIf="!showNoResults; else noResults"
                  [ngTemplateOutlet]="
                    group === 'recentSearches' || group === 'initialPrompt'
                      ? defaultMenu
                      : resultsListItem
                  "
                  [ngTemplateOutletContext]="{
                    result: result,
                    autocompleteResult: autocompleteResult,
                    group: group
                  }"
                ></ng-container>
              </mat-list>
            </mat-option>
          </mat-optgroup>
          <ng-container
            [ngTemplateOutlet]="showMore"
            [ngTemplateOutletContext]="{
              group: group
            }"
          ></ng-container>
          <mat-divider
            *ngIf="results[group]?.length && group !== lastGroup"
          ></mat-divider>
        </span>
      </ng-template>
    </div>
  </div>

  <!-- Desktop Results -->
  <mat-autocomplete
    *ngIf="breakpoints.isDesktop"
    #globalAutocomplete="matAutocomplete"
    class="global-header-autocomplete"
    (opened)="toggleScrim(true)"
    (closed)="toggleScrim(false)"
  >
    <span *ngIf="!results['initialPrompt'].length && !results['noResultsPrompt'].length">
      <ng-container *ngTemplateOutlet="chipFilters"></ng-container>
    </span>
    <div
      [ngClass]="ccss.getClass('global_autosuggest')"
      class="typeahead-container"
    >
      <!-- InitialPrompt, RecentSearches, Telehealth, Providers, Specialties, and Procedures -->
      <span *ngFor="let group of groupOrder; trackBy: trackByFnIndex">
        <mat-optgroup
          [attr.id]="'autosuggest-group-' + group"
          [attr.data-cy]="'autosuggest-group-' + group"
          class="global-autosuggest-otpgroup w-full"
          *ngIf="(selectedChip === 'all_results' || selectedChip === group) && results[group]?.length"
          [ngClass]="{ 'mat-optgroup-hidden': results.categoriesWithResults === 1 || group === 'initialPrompt' || group === 'noResultsPrompt'}"
        >
          <label
            *ngIf="categories[group].title && (results.categoriesWithResults > 1 || group === 'recentSearches')"
            [innerHTML]="categories[group].title | translate"
            [ngClass]="{ 'selected-group': group === selectedGroup }"
            class="text-palette-m3-system-gray-35"
            data-cy="autocomplete-input.group-label"
          >
          </label>
          <mat-option
            #listItem
            [value]="result.name"
            (onSelectionChange)="handleSelect(result, result.type, $event)"
            [disabled]="
              group === 'initialPrompt' || group === 'noResultsPrompt'
            "
            class="p-0"
            *ngFor="
              let result of results[group] | slice: 0:categories[group].limit;
              trackBy: trackByFnName;
              let i = index
            "
            [attr.aria-label]="result.name + ' ' + result?.description"
            [attr.data-cy]="'autosuggest.autocomplete-option.' + i"
            [attr.data-group]="group"
            [attr.data-result]="result | json"
          >
            <mat-list-item class="p-0">
              <ng-container
                *ngIf="!showNoResults; else noResults"
                [ngTemplateOutlet]="
                  group === 'recentSearches' || group === 'initialPrompt'
                    ? defaultMenu
                    : resultsListItem
                "
                [ngTemplateOutletContext]="{
                  result: result,
                  autocompleteResult: autocompleteResult,
                  group: group
                }"
              ></ng-container>
            </mat-list-item>
          </mat-option>
        </mat-optgroup>
        <ng-container
          [ngTemplateOutlet]="showMore"
          [ngTemplateOutletContext]="{
            group: group
          }"
        ></ng-container>
        <mat-divider
          *ngIf="results[group]?.length && group !== lastGroup"
        ></mat-divider>
      </span>
    </div>
  </mat-autocomplete>
</div>

<!-- Desktop Input -->
<ng-template #desktopInput>
  <zelis-search-input
    [ngClass]="{ 'z-[1000]': autosuggestOpen}"
    [variant]="homepage ? 'primary' : 'secondary'"
    [onDarkBackground]="homepage"
    [hideButton]="!homepage"
    [loading]="isLoading"
    (searchButtonClick)="onSearchButtonClick($event, autocompleteResult.value)"
  >
    <mat-icon
      *ngIf="!homepage"
      matPrefix
      svgIcon="mdx-spyglass"
      aria-hidden="true"
    ></mat-icon>
    <mat-form-field class="w-full" subscriptSizing="dynamic" appearance="outline">
      <input
        class="p-0"
        zelisSearchInput
        appUnfocus
        [zelisFocus]="focusInput"
        #autosuggestInput
        matInput
        data-cy="autosuggest.input"
        type="search"
        [attr.aria-label]="'home_search_specialties_and_providers' | translate"
        [placeholder]="setPlaceholder() | translate"
        [matAutocomplete]="globalAutocomplete"
        [readonly]="false"
        [formControl]="autocompleteResult"
        (clearInput)="onTermClear()"
        (keyup)="onKeyUp($event, autocompleteResult.value)"
        (keydown)="closeAutoComplete($event)"
        (blur)="unFocusCheck()"
        (focus)="handleFocus()" 
      />
    </mat-form-field>
  </zelis-search-input>
</ng-template>


<!-- Mobile Input -->
<ng-template #mobileInput>
  <zelis-search-input
    [variant]="homepage ? 'primary' : 'secondary'"
    [onDarkBackground]="homepage"
    [hideButton]="true"
    [loading]="isLoading"
    (searchButtonClick)="onSearchButtonClick($event, autocompleteResult.value)"
  >
    <mat-form-field class="w-full" subscriptSizing="dynamic" appearance="outline">
      <input
        zelisSearchInput
        class="p-1"
        appUnfocus
        [zelisFocus]="focusInput"
        #autosuggestInput
        matInput
        data-cy="autosuggest.input"
        type="search"
        [attr.aria-label]="'home_search_specialties_and_providers' | translate"
        [placeholder]="setPlaceholder() | translate"
        [readonly]="false"
        [formControl]="autocompleteResult"
        (clearInput)="onTermClear()"
        (keyup)="onKeyUp($event, autocompleteResult.value)"
        autocomplete="off"
      />
      <mat-icon
        matPrefix
        [svgIcon]="mobileOverlayOpen ? 'dls-arrow-left-light' : 'mdx-spyglass'"
        aria-hidden="true"
        (click)="mobileOverlayOpen && handleMobileCloseOverlay($event)"
      >
      </mat-icon>
    </mat-form-field>
  </zelis-search-input>
</ng-template>

<ng-template #defaultMenu let-group="group" let-result="result">
  <zelis-list-item
    [leftIconName]="groupIcons[group]?.name"
    [highlightTerm]="
      group === 'initialPrompt' ? null : autocompleteResult.value
    "
    [primaryText]="result?.name | translate"
    [selected]="group === 'initialPrompt' ? false : null"
    [ariaLabel]="groupIcons[group]?.ariaLabel | translate"
  ></zelis-list-item>
</ng-template>

<ng-template #noResults>
  <div
    class="text-gray-900 text-base p-4"
    [ngClass]="{
      'whitespace-normal px-4 h-auto': breakpoints.isMobile,
      'text-gray-900 text-base p-4': !breakpoints.isMobile
    }"
    [innerHTML]="
      'global_header_autosuggest_no_results'
        | translate: { searchTerm: autocompleteResult.value | translate }
    "
  ></div>
</ng-template>

<ng-template #resultsListItem let-group="group" let-result="result">
  <zelis-list-item
    [leftIconName]="groupIcons[group]?.name"
    [avatarSrc]="result.photo || result.avatarPlaceholder"
    [avatarPlaceholderIcon]="result.avatarPlaceholder"
    [isProviderAvatar]="true"
    [highlightTerm]="autocompleteResult.value"
    [primaryText]="result.locale_name || (result.name | translate)"
    [secondaryText]="
      result.primary_field_specialty || result.specialty || result.description
        | translate
    "
    [lozengeText]="
      !!result.out_of_network ? ('out_of_network_lozenge' | translate) : ''
    "
    [lozengeType]="'low-emphasis-gray'"
    [attr.data-cy]="'global-header-autosuggest-option'"
    [ariaLabel]="groupIcons[group]?.ariaLabel | translate"
  ></zelis-list-item>
</ng-template>

<ng-template #showMore let-group="group">
  <div
    *ngIf="categories[group].showMore && !results.showDefaultMenu && (selectedChip === 'all_results' || selectedChip === group)"
    #showMoreLink
    class="show-more category-show-more-{{ group }} mx-4 mt-6 mb-3"
    [ngClass]="breakpoints.isMobile ? 'mx-4 mt-6 mb-3' : 'mx-4 my-3'"
    [attr.data-cy]="'autosuggest.category-show-more-'+ group"
    [attr.data-group]="group"
  >
    <button
      (click)="expandLimit($event, group)"
      class="see-more text-base font-medium hover:underline link-blue p-0"
      [attr.data-cy]="'autosuggest.autocomplete.see-more-' + group"
      type="button"
    >
      <mat-icon svgIcon="dls-plus-circle-light" class="float-left mr-7 ml-3"></mat-icon>
      <span>
        {{ categories[group].seeMoreText | translate }} "{{
          autocompleteResult.value
        }}"
      </span>
    </button>
  </div>
</ng-template>

<ng-template #chipFilters>
  <div class="chip-filters-container pt-0 p-4">
    <span class="pt-4 pb-2 inline-block text-palette-m3-system-gray-900 text-sm">
      {{'global_header_autosuggest_chip_filter_label' | translate}}
    </span>
    <mat-chip-listbox>
      <zelis-chip
        class="pr-2"
        [selectable]="true"
        [selected]="selectedChip === 'all_results'"
        (click)="selectedChip = 'all_results'; this.input?.focus();"
        (keyup.enter)="!breakpoints.isDesktop && breakpoints.isSmallDesktop && breakpoints.isLargeMobile; selectedChip = 'all_results'; this.input?.focus();"
        *ngIf="results.categoriesWithResults > 1  "
      >{{ 'global_header_autosuggest_chip_filter_all_results' | translate }}</zelis-chip>
      <span *ngFor="let group of groupOrder; trackBy: trackByFnIndex">
        <zelis-chip
          *ngIf="results[group].length"
          class="pr-2"
          [selectable]="true"
          [selected]="selectedChip === group || results.categoriesWithResults === 1"
          (click)="selectChip(group)"
          (keyup.enter)="!breakpoints.isDesktop && breakpoints.isSmallDesktop && breakpoints.isLargeMobile; selectChip(group)"
        >{{categories[group].title | translate}}</zelis-chip>
      </span>
    </mat-chip-listbox>
  </div>
</ng-template>
