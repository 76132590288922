import { Component, Input } from '@angular/core';
import { BalancesTranslations } from '../balances/balances-translations.model';
import { Balances } from '../balances/balances.model';
import { BalancesStickyFabPosition } from './balances-sticky-fab-position.interface';

@Component({
  selector: 'platform-balances-sticky-fab',
  templateUrl: './balances-sticky-fab.component.html',
  styleUrls: ['./balances-sticky-fab.component.scss'],
  preserveWhitespaces: false,
})
export class BalancesStickyFabComponent {
  @Input() public assetPath = './assets/balances/assets/';
  @Input() public fabText = 'Your Balance';
  @Input() public fabColor = 'accent';
  @Input() public balances: Balances[];
  @Input() public set position(value: BalancesStickyFabPosition) {
    this.setPosition(value);
  }
  @Input() public translations: BalancesTranslations;

  public fabPeek: boolean | number = 4000;
  public fabStyle: any;
  public openTooltip = false;
  public tooltipReady = false;

  public onPeekerFabClick(): void {
    this.fabPeek = false;
    this.openTooltip = !this.openTooltip;
  }

  private setPosition(pos: BalancesStickyFabPosition): void {
    if (pos) {
      this.fabStyle = {};
      if (pos.type === 'inline') {
        this.fabStyle.display = 'inline-block';
      } else {
        this.fabStyle.display = 'block';
        this.fabStyle.position = pos.type;
        this.fabStyle.top = pos.top;
        this.fabStyle.bottom = pos.bottom;
        this.fabStyle.left = pos.left;
        this.fabStyle.right = pos.right;
        this.fabStyle['z-index'] = pos.zIndex;
      }
    } else {
      this.fabStyle = {
        display: 'inline-block',
      };
    }
  }
}
