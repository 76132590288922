export class HorizontalScrollTranslations {
  public scrollRight?: string;
  public scrollLeft?: string;

  constructor(data?: HorizontalScrollTranslations) {
    data = data || {};
    this.scrollRight = data.scrollRight || 'Scroll Right';
    this.scrollLeft = data.scrollLeft || 'Scroll Left';
  }
}
