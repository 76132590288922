import { MatDialogConfig } from '@angular/material/dialog';

const verticalMargins = 48;
const totalVerticalMargin = verticalMargins * 2;
const absoluteMaxHeight = `calc(100vh - ${totalVerticalMargin}px)`;

export const dialogSize = {
  small: 280,
  default: 560,
  big: 840,
};

export const smallDialog: Partial<MatDialogConfig> = {
  width: `${dialogSize.small}px`,
  minHeight: '72px',
  maxHeight: `min(352px, ${absoluteMaxHeight})`,
};

export const defaultDialog: Partial<MatDialogConfig> = {
  width: `${dialogSize.default}px`,
  minHeight: '136px',
  maxHeight: `min(560px, ${absoluteMaxHeight})`,
};

export const bigDialog: Partial<MatDialogConfig> = {
  width: `${dialogSize.big}px`,
  minHeight: '136px',
  maxHeight: `min(560px, ${absoluteMaxHeight})`,
};

export const DIALOG_FULL_SCREEN_CLASS = 'full-screen';
