import { Injectable } from '@angular/core';
import { AppConfig } from '@interfaces/app-config.model';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { WindowService } from '@services/window.service';
import { FetchService } from '@services/fetch.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService extends AppConfig {
  public config: Observable<AppConfig>;

  constructor(
    private window: WindowService,
    private fetchService: FetchService
  ) {
    super();
    this.exposeConfigToConsole();
    this.config = this.getConfig();
    this.config.subscribe();
  }

  // Using FetchService instead of HttpClient since this service is also used
  // by the HTTP interceptor which would cause a dependency loop.
  private getConfig(): Observable<AppConfig> {
    return this.fetchService.json('/assets/config/app.config.json').pipe(
      catchError((error) => {
        console.error(`Error: App Config load failed: ${error}`);
        return of(null);
      }),
      map((config) => new AppConfig(config)),
      // TODO: Remove need for synchronous config properties
      tap((config) => this.setConfig(config)),
      shareReplay(1)
    );
  }

  /**
   * Attach a utility method to the Window object to see current app config.
   * From the browser console, enter: getAppConfig()
   */
  private exposeConfigToConsole(): void {
    if (this.window) {
      this.window['getAppConfig'] = () => {
        console.info(new AppConfig(this));
      };
    }
  }
}
