import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from "@ngx-translate/core";
import { PlanTypeSelectionComponent } from './plan-type-selection.component';
import { WizardSelectionCardModule } from "../wizard-selection-card/wizard-selection-card.module";
import { WizardLoginLinkModule } from "../wizard-login-link/wizard-login-link.module";
import { WizardCardLayoutTemplateModule } from "../wizard-card-layout-template/wizard-card-layout-template.module";

@NgModule({
  declarations: [PlanTypeSelectionComponent],
  imports: [
    CommonModule,
    WizardSelectionCardModule,
    WizardLoginLinkModule,
    TranslateModule,
    WizardCardLayoutTemplateModule
  ],
  exports: [PlanTypeSelectionComponent],
})
export class PlanTypeSelectionModule {}
