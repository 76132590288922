export class BillingCode {
  service_code?: string;
  code_type?: string;
  code?: string;
  covered?: boolean;
  name_en?: string;
  name_es?: string;
  displayName?: string;

  constructor(obj?: any) {
    this.service_code = obj.service_code || '';
    this.code_type = obj.code_type || '';
    this.code = obj.code || '';
    this.covered = obj.covered || true;
    this.name_en = obj.name_en || '';
    this.name_es = obj.name_es || '';
    this.displayName = obj.displayName || '';
  }
}
