export class MleStatus {
  mle?: boolean;
  mle_lite?: boolean;
  resolved?: boolean;
  isEligible?: boolean;

  constructor(obj: any) {
    this.mle = obj.mle || false;
    this.mle_lite = obj.mle_lite || false;
    this.resolved = obj.resolved || false;
    this.isEligible = obj.mle || obj.mle_lite || false;
  }

  public isMleLite?(mleLiteFromData: string): boolean {
    return mleLiteFromData === 'true' || this.mle_lite;
  }
}
