import type { SearchParams } from '@interfaces/search-params.model';
import type { SerpDisclaimers } from '@interfaces/serp-disclaimers.interface';
import { createAction, props } from '@ngrx/store';

export enum SerpDisclaimersActionsTypes {
  requestDisclaimers = '[GET] Request Disclaimers',
  requestDisclaimersSuccess = '[GET] Request Disclaimers Success',
  requestDisclaimersFailure = '[GET] Request Disclaimers Failure',
}

export const requestDisclaimers = createAction(
  SerpDisclaimersActionsTypes.requestDisclaimers,
  props<{ params: SearchParams }>()
);

export const requestDisclaimersSuccess = createAction(
  SerpDisclaimersActionsTypes.requestDisclaimersSuccess,
  props<{ disclaimers: SerpDisclaimers }>()
);

export const requestDisclaimersFailure = createAction(
  SerpDisclaimersActionsTypes.requestDisclaimersFailure,
  props<{ error: string }>()
);
